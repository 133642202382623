import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  CardBody,
  Table,
  Button
} from "reactstrap";

class Nodes extends Component {
  state = {};

  render() {
    return (
      <div className="content">
        <Row>
          <Col xs="12">
            <Card>
              <CardHeader className="tableFilterWrap">
                <Input type="text" placeholder="Search..." style={{width: 180}} />
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead>
                    <tr>
                      <th>Lab ID</th>
                      <th>Lab Name</th>
                      <th>Username</th>
                      <th>Node Name</th>
                      <th>Template</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>12SE34</td>
                      <td>Demo Lab</td>
                      <td>johndoe</td>
                      <td>
                        Win-7-1
                      </td>
                      <td>
                        Windows-7
                      </td>
                      <td>
                        <Button className="viewBtn">
                          Open
                        </Button>
                        <Button className="stopBtn ml-3">
                          Stop
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>12SE33</td>
                      <td>Test Lab</td>
                      <td>thiagosilva</td>
                      <td>
                        Win-10
                      </td>
                      <td>
                        Windows-10
                      </td>
                      <td>
                        <Button className="viewBtn">
                          Open
                        </Button>
                        <Button className="stopBtn ml-3">
                          Stop
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Nodes;
