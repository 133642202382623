import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  CardBody,
  CardFooter,
  Button,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Label,
  CustomInput
} from 'reactstrap';
import { userDetails, userUpdate, addLicenseUser } from '../../../http/http-calls';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { ToastsStore } from 'react-toasts';
import Password from '../components/password';
import Switch from 'react-switch';

class EditUser extends Component {
  state = {
    userName: '',
    user: {
      name: '',
      email: '',
      phone: '',
      isMFAEnabled: false
    },
    isDirty: {
      name: false,
      email: false,
      phone: false
    },
    errors: {},
    id: '',
    licenseList: [],
    isAdmin: false,
    licenseKey: ''
  };

  componentDidMount() {
    this._getUserDetails();
  }

  //get details of user here
  _getUserDetails = () => {
    this.props.showLoader();
    userDetails(this.props.location.state.id)
      .then((resp) => {
        console.log('User details here :- ', resp);
        let user = {
          name: resp.user.name.full,
          email: resp.user.email,
          phone: resp.user.phone,
          isMFAEnabled: resp.user.isMFAEnabled
        };
        let id = resp.user.id;
        let userName = resp.user.username;
        let licenseList = resp.user.licenseStack;
        let isAdmin = resp.user.isAdmin;
        this.setState({ user, userName, licenseList, isAdmin, id });
        this.props.hideLoader();
      })
      .catch((err) => {
        this.props.hideLoader();
        console.log(err);
      });
  };

  //handling input here
  _handleOnChange = (field, value) => {
    let { user, isDirty, isAdmin } = this.state;
    if (field === 'admin') {
      isAdmin = value;
    } else {
      user[field] = value;
      isDirty[field] = true;
    }
    this.setState({ user, isDirty, isAdmin }, () => {
      this._validateField();
    });
  };

  //for validation
  _validateField = () => {
    const { user, isDirty, errors } = this.state;
    Object.keys(user).forEach((each) => {
      if (each === 'name' && isDirty.name) {
        if (!user.name.trim().length) {
          errors[each] = '*Required';
        } else if (user.name.trim().length && !user.name.match(/^[a-zA-Z ]*$/)) {
          errors[each] = 'Invalid Name format';
        } else if (user.name.trim().length && user.name.match(/^[a-zA-Z ]*$/) && user.name.trim().length <= 2) {
          errors[each] = 'Name should be greater than two characters';
        } else {
          delete errors[each];
          isDirty.name = false;
        }
      } else if (each === 'email' && isDirty.email) {
        if (!user.email.trim().length) {
          errors[each] = '*Required';
        } else if (
          user.email.trim().length &&
          !new RegExp('^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$').test(user.email)
        ) {
          errors[each] = 'Enter a valid email ID';
        } else {
          delete errors[each];
          isDirty.email = false;
        }
      } else if (each === 'phone' && isDirty.phone) {
        if (!user.phone.trim().length) {
          errors[each] = '*Required';
        } else if (user.phone.trim().length && !user.phone.match(/^[0-9]{10}$/)) {
          errors[each] = 'Enter valid phone number';
        } else {
          delete errors[each];
          isDirty.phone = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  //update user data here
  _updateUserData = (e) => {
    e.preventDefault();
    let isDirty = {
      name: true,
      email: true,
      phone: true
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateField();
      if (!errors) {
        let userData = {
          email: this.state.user.email,
          phone: this.state.user.phone,
          name: {
            first: this.state.user.name.split(' ')[0],
            last: this.state.user.name.split(' ')[1] ? this.state.user.name.split(' ')[1] : ''
          },
          isAdmin: this.state.isAdmin,
          isMFAEnabled: this.state.user.isMFAEnabled
        };
        this.props.showLoader();
        userUpdate(userData, this.props.location.state.id)
          .then((resp) => {
            console.log('User update response here:- ', resp);
            ToastsStore.success('User updated successfully');
            this.props.hideLoader();
          })
          .catch((err) => {
            ToastsStore.error('User updation failed :- ' + err.reason);
            this.props.hideLoader();
          });
      }
    });
  };

  //get list of licenses here
  _getLicensesList = () => {
    let licensesList = '';
    const { licenseList } = this.state;
    if (licenseList.length) {
      licensesList = licenseList.map((license) => {
        return (
          <ListGroupItem>
            <div>{'XXXX-XXXX-XXXX-XXXX-' + license._license.licenseKey.split('-')[3]}</div>
            <div>
              Validity Days: <span>{license._license.validityInDays}</span>
            </div>
          </ListGroupItem>
        );
      });
    } else {
      licensesList += 'No License found';
    }
    return licensesList;
  };

  //handling licensceKey input here
  _handleSerialChange = (value) => {
    let { licenseKey } = this.state;
    licenseKey = value;
    this.setState({ licenseKey });
  };

  //add new license here
  _addLicense = (e) => {
    e.preventDefault();
    if (!this.state.licenseKey.trim().length) {
      ToastsStore.error('Serial number is empty');
    } else {
      let licenseData = {
        userId: this.state.id,
        license: this.state.licenseKey
      };
      this.props.showLoader();
      addLicenseUser(licenseData)
        .then((resp) => {
          console.log('Add license response here :- ', resp);
          this.props.hideLoader();
          ToastsStore.success('License added successfully');
          this.setState({ licenseKey: '' });
          this._getUserDetails();
        })
        .catch((err) => {
          this.props.hideLoader();
          ToastsStore.error('Adding new license failed :- ' + err.reason);
        });
    }
  };

  render() {
    const { user, errors, userName, isAdmin, licenseKey } = this.state;
    console.log('prpos', this.props);
    return (
      <div className='content'>
        <Row>
          <Col md='7'>
            <Card className='userInfo'>
              <CardHeader>Licenses</CardHeader>
              <CardBody>
                <ListGroup className='licensesList'>{this._getLicensesList()}</ListGroup>
              </CardBody>
              <CardFooter>
                <Input
                  type='text'
                  placeholder='Serial Number'
                  value={licenseKey}
                  onChange={(e) => this._handleSerialChange(e.target.value)}
                />

                <Button className='addBtn' onClick={(e) => this._addLicense(e)}>
                  Add
                </Button>
              </CardFooter>
            </Card>
            <Password type='edit-user' id={this.props.location.state.id} />
          </Col>
          <Col md='5'>
            <Card className='userInfo'>
              <CardHeader>
                Username: <span>{userName}</span>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>Name:</Label>
                  <Input
                    type='text'
                    placeholder='Name'
                    value={user.name}
                    onChange={(e) => this._handleOnChange('name', e.target.value)}
                  />
                  {errors && <div className='validation-error'>{errors.name}</div>}
                </FormGroup>
                <FormGroup>
                  <Label>Email:</Label>
                  <Input
                    type='email'
                    placeholder='Email'
                    value={user.email}
                    onChange={(e) => this._handleOnChange('email', e.target.value)}
                  />
                  {errors && <div className='validation-error'>{errors.email}</div>}
                </FormGroup>
                <FormGroup>
                  <Label>Phone:</Label>
                  <Input
                    type='text'
                    placeholder='Phone'
                    value={user.phone}
                    onChange={(e) => this._handleOnChange('phone', e.target.value)}
                  />
                  {errors && <div className='validation-error'>{errors.phone}</div>}
                </FormGroup>
                <div className='d-flex justify-content-start align-items-center isAdminWrap' style={{ height: 38 }}>
                  <CustomInput
                    type='checkbox'
                    id='isAdmin'
                    label='Admin'
                    className='isAdminCheck'
                    checked={isAdmin}
                    onChange={(e) => this._handleOnChange('admin', e.target.checked)}
                  />
                </div>
                <FormGroup>
                  <Label>Multi Factor Authentication:</Label>
                  <div>
                    <Switch
                      onChange={(value) => this._handleOnChange('isMFAEnabled', value)}
                      checked={user.isMFAEnabled}
                      uncheckedIcon={''}
                      checkedIcon={''}
                      height={30}
                      width={60}
                      className='switch-toggle-button'
                      onColor={'#F18F3C'}
                    />
                  </div>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className='text-center mt-2'>
          <Button className='mx-2 cancelBtn' onClick={() => this.props.history.push('/users')}>
            Cancel
          </Button>
          <Button className='mx-2 saveBtn' onClick={(e) => this._updateUserData(e)}>
            Update
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
