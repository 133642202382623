import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button
} from "reactstrap";

class DeleteLabObjectConfirmationModal extends Component {
  state = {  }
  render() { 
    console.log('this.props.data :>> ', this.props.data);
    return ( <Modal
      isOpen={this.props.isOpen}
      toggle={() => this.props.toggle("cancel")}
    >
      <ModalHeader toggle={() => this.props.toggle("cancel")}>
        Delete Confirmation
      </ModalHeader>
      <ModalBody>
        <div>         
            <p>
              Are you sure to delete this {" "}
              <span style={{textTransform: 'capitalize'}}>
                "{this.props.data?.data.shape ==='rect' ? 'Rectangle' : this.props.data?.data.shape}" ?
              </span>
            </p>        
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="modalBtnCancel"
          onClick={() => this.props.toggle("cancel")}
        >
          No
        </Button>
        <Button
          className="modalBtnSave"
          onClick={(e) => this.props.toggle("delete")}
        >
          Yes, Delete
        </Button>
      </ModalFooter>
    </Modal> );
  }
}
 
export default DeleteLabObjectConfirmationModal;