import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { userInsert, licenseList } from '../../../http/http-calls';
import { ToastsStore } from 'react-toasts';

import swal from 'sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AddUser extends Component {
  state = {
    user: {
      name: '',
      email: '',
      phone: '',
      password: '',
      userName: '',
      license: '',
      isAdmin: false
    },
    isDirty: {
      name: false,
      email: false,
      phone: false,
      password: false,
      userName: false,
      license: false
    },
    errors: {},
    isVisible: false,
    licenseList: []
  };

  //closing modal
  _closeModal = (action) => {
    console.log('in _closeModal');
  };

  componentDidMount() {
    console.log('componentDidMount', this.props);
    this._fetchLicenses();
  }

  _fetchLicenses = () => {
    licenseList({ page: 1, limit: 100 })
      .then((response) => {
        console.log('response ===> ', response);
        this.setState({ licenseList: response.docs });
      })
      .catch((error) => {});
  };

  //handling visibility of password
  _handleVisibilityToggle = () => {
    let { isVisible } = this.state;
    isVisible = !isVisible;
    this.setState({ isVisible });
  };

  //handling toggle of modal here
  _handleToggle = () => {
    this.setState({
      user: {
        name: '',
        email: '',
        phone: '',
        password: '',
        userName: '',
        license: '',
        isAdmin: false
      },
      isDirty: {
        name: false,
        email: false,
        phone: false,
        password: false,
        userName: false,
        license: false
      },
      errors: {}
    });
    this.props.toggle();
    this.props.reload();
  };

  //handling input here
  _handleOnChange = (field, value) => {
    const { user, isDirty } = this.state;
    if (field === 'isAdmin') {
      user[field] = value;
    } else {
      user[field] = value;
      isDirty[field] = true;
    }
    this.setState({ user, isDirty }, () => {
      this._validateField();
    });
  };

  //for validation
  _validateField = () => {
    const { user, isDirty, errors } = this.state;
    Object.keys(user).forEach((each) => {
      if (each === 'name' && isDirty.name) {
        if (!user.name.trim().length) {
          errors[each] = '*Required';
        } else if (user.name.trim().length && !user.name.match(/^[a-zA-Z ]*$/)) {
          errors[each] = 'Invalid Name format';
        } else if (user.name.trim().length && user.name.match(/^[a-zA-Z ]*$/) && user.name.trim().length <= 2) {
          errors[each] = 'Name should be greater than two characters';
        } else {
          delete errors[each];
          isDirty.name = false;
        }
      } else if (each === 'email' && isDirty.email) {
        if (!user.email.trim().length) {
          errors[each] = '*Required';
        } else if (
          user.email.trim().length &&
          !new RegExp('^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$').test(user.email)
        ) {
          errors[each] = 'Enter a valid email ID';
        } else {
          delete errors[each];
          isDirty.email = false;
        }
      } else if (each === 'phone' && isDirty.phone) {
        if (!user.phone.trim().length) {
          errors[each] = '*Required';
        } else if (user.phone.trim().length && !user.phone.match(/^[0-9]{10}$/)) {
          errors[each] = 'Enter valid phone number';
        } else {
          delete errors[each];
          isDirty.phone = false;
        }
      } else if (each === 'password' && isDirty.password) {
        if (!user.password.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.password = false;
        }
      } else if (each === 'userName' && isDirty.userName) {
        if (!user.userName.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.userName = false;
        }
      } else if (each === 'license' && isDirty.license) {
        if (!user.license.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.license = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  //add new user here
  _addUser = (e) => {
    e.preventDefault();
    let isDirty = {
      name: true,
      email: true,
      phone: true,
      password: true,
      userName: true,
      license: true
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateField();
      if (!errors) {
        let userData = {
          email: this.state.user.email,
          phone: this.state.user.phone,
          name: {
            first: this.state.user.name.split(' ')[0],
            last: this.state.user.name.split(' ')[1] ? this.state.user.name.split(' ')[1] : ''
          },
          password: this.state.user.password,
          username: this.state.user.userName,
          licenseKey: this.state.user.license,
          isAdmin: this.state.user.isAdmin
        };
        this.props.showLoader();
        userInsert(userData)
          .then((resp) => {
            console.log(resp);
            this.props.hideLoader();
            // ToastsStore.success("User added successfully");
            setTimeout(() => {
              swal('Success!', 'User added successfully', 'success');
            }, 1500);
            this._handleToggle();
          })
          .catch((err) => {
            this.props.hideLoader();
            // ToastsStore.error('Adding new user failed :- ' + err.reason);
            setTimeout(() => {
              swal('Error!', `Adding new user failed:- ${err.reason}`, 'error');
            }, 1500);
          });
      }
    });
  };

  render() {
    const { user, errors, isVisible } = this.state;

    return (
      <Modal isOpen={this.props.isOpen} toggle={() => this._handleToggle()} className='modal-lg' centered>
        <ModalHeader toggle={() => this._handleToggle()}>Add User</ModalHeader>
        <ModalBody className='innerForm'>
          <Row>
            <Col sm='12'>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type='text'
                  placeholder='Enter full name'
                  value={user.name}
                  onChange={(e) => this._handleOnChange('name', e.target.value)}
                />
                {errors && <div className='validation-error'>{errors.name}</div>}
              </FormGroup>
            </Col>
            <Col sm='6'>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type='email'
                  placeholder='Enter mail'
                  value={user.email}
                  onChange={(e) => this._handleOnChange('email', e.target.value)}
                />
                {errors && <div className='validation-error'>{errors.email}</div>}
              </FormGroup>
            </Col>
            <Col sm='6'>
              <FormGroup>
                <Label>Phone</Label>
                <Input
                  type='text'
                  placeholder='Enter phone'
                  value={user.phone}
                  onChange={(e) => this._handleOnChange('phone', e.target.value)}
                />
                {errors && <div className='validation-error'>{errors.phone}</div>}
              </FormGroup>
            </Col>
            <Col sm='6'>
              <FormGroup>
                <Label>Username</Label>
                <Input
                  type='text'
                  placeholder='Enter username'
                  value={user.userName}
                  onChange={(e) => this._handleOnChange('userName', e.target.value)}
                />
                {errors && <div className='validation-error'>{errors.userName}</div>}
              </FormGroup>
            </Col>
            <Col sm='6'>
              <FormGroup>
                <Label>Password</Label>
                <InputGroup>
                  <Input
                    type={isVisible ? 'text' : 'password'}
                    placeholder='Enter password'
                    value={user.password}
                    onChange={(e) => this._handleOnChange('password', e.target.value)}
                  />
                  <InputGroupAddon addonType='append'>
                    <InputGroupText>
                      <i
                        className={isVisible ? 'fa fa-eye ' : 'fa fa-eye-slash '}
                        onClick={() => this._handleVisibilityToggle()}></i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>

                {errors && <div className='validation-error'>{errors.password}</div>}
              </FormGroup>
            </Col>

            <Col sm='6'>
              <FormGroup>
                <Label>Serial Key</Label>
                <Input
                  type='select'
                  placeholder='Enter license key'
                  value={user.license}
                  onChange={(e) => this._handleOnChange('license', e.target.value)}>
                  <option>Select</option>
                  {this.state.licenseList.map((each, index) => (
                    <option
                      key={index}
                      value={each.licenseKey}>{`${each?.licenseKey} (${each.validityInDays})`}</option>
                  ))}
                </Input>
                {errors && <div className='validation-error'>{errors.license}</div>}
              </FormGroup>
            </Col>
            <Col sm='6'>
              <FormGroup>
                <Label className='invisible'>isAdmin</Label>
                <div className='d-flex justify-content-start align-items-center' style={{ height: 38 }}>
                  <CustomInput
                    type='checkbox'
                    id='isAdmin'
                    label='Admin'
                    className='isAdminCheck'
                    checked={user.isAdmin}
                    onChange={(e) => this._handleOnChange('isAdmin', e.target.checked)}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className='modalBtnCancel' onClick={() => this._handleToggle()}>
            Cancel
          </Button>
          <Button className='modalBtnSave' onClick={(e) => this._addUser(e)}>
            Create
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
