import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Input, Label, FormGroup } from 'reactstrap';
import { addLab, editLab } from '../../../http/http-calls';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { ToastsStore } from 'react-toasts';
import { deepClone, showToast } from '../../../helper-methods';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import './custom.css';
// import LabCreatedAlert from './LabCreated';

class AddLab extends Component {
  state = {
    labData: {
      name: '',
      description: '',
    },
    isDirty: {
      name: false,
      description: false,
    },
    errors: {},
  };

  _closeModal = (action) => {
    console.log('in _closeModal');
  };

  componentDidMount() {
    console.log('componentDidMount', this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      let state = {
        labData: {
          name: this.props.data ? this.props.data.name : '',
          description: this.props.data ? this.props.data.description : '',
        },
        isDirty: {
          name: false,
          description: false,
        },
        errors: {},
      };
      this.setState(state);
    }
  }

  //handling input here
  _handleOnChange = (field, value) => {
    const { labData, isDirty } = this.state;
    labData[field] = value;
    isDirty[field] = true;
    this.setState({ labData, isDirty }, () => {
      this._validateField();
    });
  };

  //for validation
  _validateField = () => {
    const { labData, isDirty, errors } = this.state;
    Object.keys(labData).forEach((each) => {
      if (each === 'name' && isDirty.name) {
        if (!labData.name.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.name = false;
        }
      } else if (each === 'description' && isDirty.description) {
        if (!labData.description.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.description = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  //on submit lab data
  _handleOnSubmit = (e) => {
    e.preventDefault();
    let isDirty = {
      name: true,
      description: true,
    };
    this.setState({ isDirty }, async () => {
      let errors = this._validateField();
      if (!errors) {
        if (this.props.type === 'add') {
          this.props.showLoader();
          try {
            // const status = await axios.get('/ronin/license-status/');
            const status = await axios.get('/ronin/license-status/', {
              headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                // Pragma: 'no-cache',
                Expires: '0',
              },
            });
          } catch (error) {
            this.props.hideLoader();
            toast.error(`Your license has expired. New labs cannot be created.`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return;
          }
          // if (status.data.license_created != null) {
          //   console.info('this is the lab create call and I am stopping it');
          this._addLab();
          // }
          // else {
          //   this.props.hideLoader();
          //   toast.error(`Your license has expired. New labs cannot be created.`, {
          //     position: toast.POSITION.TOP_RIGHT
          //   });
          // }
        } else {
          this._editLab();
        }
      }
    });
  };

  _addLab = () => {
    this.props.showLoader();
    addLab(this.state.labData)
      .then((resp) => {
        console.log('resp :>> ', resp);
        this.props.hideLoader();
        // ToastsStore.success('New lab added successfully');
        toast.success(`New lab added successfully`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // setTimeout(() => {
        //   swal('Success!', 'New lab added successfully', 'success');
        // }, 1800);
        this.props.toggle('save', resp.lab);
      })
      .catch((err) => {
        this.props.hideLoader();
        // ToastsStore.error('Adding new lab failed :- ' + err.reason);
        setTimeout(() => {
          swal('Error!', `Adding new lab failed:- ${err.reason}`, 'error');
        }, 1500);
      });
  };

  _editLab = async () => {
    try {
      this.props.showLoader();
      let res = await editLab(this.props.data.id, this.state.labData);
      console.log('res :>> ', res);
      this.props.hideLoader();
      showToast('Lab updated successfully', 'success');
      this.props.toggle('save');
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  render() {
    const { labData, errors } = deepClone(this.state);
    const { isOpen, type } = deepClone(this.props);

    return (
      <Modal className='color-scheme__modal' isOpen={isOpen} toggle={() => this.props.toggle('cancel')}>
        <ModalHeader toggle={() => this.props.toggle('cancel')}>
          <p className='text-white'>{type === 'add' ? 'Add Lab' : 'Edit Lab'}</p>
          <Button className='modal-minimize'>
            <i class='fa fa-minus' aria-hidden='true'></i>
          </Button>
        </ModalHeader>
        <ModalBody className='innerForm'>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type='text'
              placeholder='Enter name'
              value={labData.name}
              onChange={(e) => this._handleOnChange('name', e.target.value)}
            />
            {errors && <div className='validation-error'>{errors.name}</div>}
          </FormGroup>

          <FormGroup>
            <Label>Description</Label>
            <Input
              type='textarea'
              rows='3'
              placeholder='Enter description'
              value={labData.description}
              onChange={(e) => this._handleOnChange('description', e.target.value)}
            />
            {errors && <div className='validation-error'>{errors.description}</div>}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className='modalBtnCancel' onClick={() => this.props.toggle('cancel')}>
            Cancel
          </Button>
          <Button className='modalBtnSave' onClick={(e) => this._handleOnSubmit(e)}>
            {type === 'add' ? 'Create' : 'Update'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  console.log('state :>> ', state);
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLab);
