import React from 'react'
import { Button } from 'reactstrap';

const VncList = (props) => {

  const _reopen = (item, index) => {
    props.callback(item, index)
  }

  const _close = (item, index) => {
    props.onClose(item, index)
  }

  return (
    <div className="minmize_modal_list d-flex ">
      {/* minimize item start here */}
      {props.list && props.list.map((el, index) => <div className="minmize_item d-flex align-items-center">
        <div className="title" style={{color: "black"}}>
          <h1 style={{color: "black"}}>{el.data?.properties?.name}</h1>
        </div>
        <div className="minimize_icon">
          <Button onClick={() => _reopen(el, index)}>
            <i style={{ fontSize: "20px" }} className="fa fa-external-link" aria-hidden="true"></i>
          </Button>
          <Button onClick={() => _close(el, index)}>
            <i style={{ fontSize: "20px" }} className="fa fa-times" aria-hidden="true"></i>
          </Button>
        </div>
      </div>)}
      {/* minimize item start here */}
      {/* minimize item start here */}
      {/* minimize item start here */}

    </div>
  )
}

export default VncList