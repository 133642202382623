import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  // Input,
  // Label,
  // FormGroup,
} from "reactstrap";
class CloseLabConfirmationModal extends Component {
  state = {  }
  render() { 
    return ( 
      <Modal isOpen={this.props.isOpen} toggle={() => this.props.toggle('cancel')}>
        <ModalHeader toggle={() => this.props.toggle('cancel')}>
          Close Lab Confirmation
        </ModalHeader>
        <ModalBody>
          <p className='text-center'>Are you sure you want to exit this lab?
            {/* <br/>
            Note: If any process is ongoing it will not show immediately till the process finished. */}
             </p>
        </ModalBody>
        <ModalFooter>
          <Button
            className="modalBtnCancel"
            onClick={() => this.props.toggle('cancel')}
          >
            No
          </Button>
          <Button
            className="modalBtnSave"
            onClick={(e) => this.props.toggle('close')}
          >
            Yes, Close
          </Button>
        </ModalFooter>
      </Modal>
     );
  }
}
 
export default CloseLabConfirmationModal;