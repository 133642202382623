import React, { useEffect } from 'react'

export default function ImageComponent({ color, ctx }) {

    // eslint-disable-next-line
    const { vertex, surface, toolkit } = ctx;
    const data = vertex.data;

    // This `useEffect` will cause the node to be repainted if its label has changed. This will run after the label has
    // been changed and React has repainted what it needs to, so it's the right time to tell the renderer, since the new
    // size of the element is known.
    useEffect(() => {
        surface.repaint(vertex);
        // eslint-disable-next-line
    }, [data.label]);
    let parent = document.querySelectorAll(`[data-jtk-node-id="${data.id}"]`)
    if(parent?.[0]){
      parent[0].style.zIndex=data.shapeData.zIndex;
    }
    return (     
      <div
          type="jtk"
          id={data.shapeData?.id}
          style={{
            width:data.w + 'px',
            height:data.h + 'px',
            backgroundImage: `url('${data.shapeData?.url}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: `${data.w}px ${data.h}px`
          }}
        ></div>
    );
}