import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import { forgotPassword } from "../../../http/http-calls";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { ToastsStore } from "react-toasts";

class ForgotPassword extends Component {
  state = {
    user: {
      email: ''
    },
    isDirty: {
      email: false
    },
    errors: {}
  };

  //handling input here
  _handleOnChange = (field, value) => {
    const { user, isDirty } = this.state;
    user[field] = value;
    isDirty[field] = true;
    this.setState({ user, isDirty }, () => {
      this._validateMail();
    });
  }

  //for validation
  _validateMail = () => {
    const { user, errors, isDirty } = this.state;
    Object.keys(user).forEach((each) => {
      if (each === "email" && isDirty.email) {
        if (!user.email.trim().length) {
          errors[each] = "*Required";
        } else if (
          user.email.trim().length &&
          !new RegExp(
            "^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
          ).test(user.email)
        ) {
          errors[each] = "Enter a valid email ID";
        } else {
          delete errors[each];
          isDirty.email = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  //reset password here
  _resetPassword = (e) => {
    e.preventDefault();
    let isDirty = {
      email: true
    }
    this.setState({ isDirty }, () => {
      let errors = this._validateMail();
      if (!errors) {
        let data = {
          handle: this.state.user.email
        }
        this.props.showLoader();
        forgotPassword(data).then((resp) => {
          ToastsStore.success("Password reset link sent to mail :- "+ resp.handle);
          this.setState({
            user: {
              email: ''
            },
            isDirty: {
              email: false
            },
            errors: {}
          });
          this.props.hideLoader();
          this.props.history.push('/login')
        }).catch((err) => {
          this.props.hideLoader();
          ToastsStore.error("Error :- "+ err.reason);
        });
      }
    });
  }

  render() {
    const { user, errors } = this.state;
    return (
      <div className="full-page login-page">

        <div className="authFormWrap">
          <Form onSubmit={(e) => this._resetPassword(e)}>
            <Card className="card-white">
              <CardHeader>
                <img alt="EVOS Logo" className="companyLogo" src="../../../assets/img/evos-logo.png" style={{ marginBottom: 45 }} />

                <h1 className="card-title">Forgot Password</h1>
              </CardHeader>
              <CardBody>
                <Label>Email</Label>
                <FormGroup>
                  <i className="fa fa-envelope-o formIcon"></i>
                  <Input
                    placeholder="Enter"
                    type="email"
                    value={user.email}
                    onChange={(e) =>
                      this._handleOnChange("email", e.target.value)
                    }
                  />
                  {errors && (
                    <div className="validation-error">
                      {errors.email}
                    </div>
                  )}
                </FormGroup>

                <Button
                  className="btn themeBtn"
                  type="submit"
                >
                  Reset Password
                </Button>

                <Link className="link createAccount" to="/login">
                  Back to <span>Login</span>
                </Link>
              </CardBody>
            </Card>
          </Form>
        </div>

        <div className="authFooter">
          EVOS &#169; {new Date().getFullYear()}.

          <div style={{display:'none'}}>
            Powered By: <a href="https://www.logic-square.com/" target="_blank" rel="noreferrer">Logic Square</a>
          </div>
          <div>
            Powered By: <a href="http://www.gurooit.com" target="_blank" rel="noreferrer">GuROO</a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
