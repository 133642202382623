import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  CardBody,
  Table,
  Button
} from "reactstrap";
import NewLicenseModal from "../components/new-license-modal";
import AccessKeyModal from "../components/access-key-modal";
import { licenseList } from "../../../http/http-calls";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { ToastsStore } from "react-toasts";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Paginate } from "./Pagination";

class Licenses extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      newLicenseModal: {
        isOpen: false,
        data: null,
      },
      licenseList: [],
      currentPage: 1,
      totalPages: '',
      searchItem: '',
      isUsed: ''
    };
  }

  componentDidMount() {
    this._getLicenseList(this.state.currentPage);
  }

  //get list of licenses here
  _getLicenseList = (pageNumber) => {
    const { searchItem, isUsed } = this.state;
    let data = {};
    if (isUsed.trim().length) {
      if (searchItem.trim().length) {
        if (isUsed === "active") {
          data.isUsed = false;
        } else {
          data.isUsed = true;
        }
        data.search = searchItem;
      } else {
        if (isUsed === "active") {
          data.isUsed = false;
        } else {
          data.isUsed = true;
        }
      }
    } else {
      if (searchItem.trim().length) {
        data.search = searchItem;
      }
    }
    this.props.showLoader();
    licenseList({ ...data, page: pageNumber }).then((resp) => {
      console.log("License list here :- ", resp);
      this.setState({
        licenseList: resp.docs,
        totalPages: resp.totalPages,
        currentPage: resp.page
      });
      this.props.hideLoader();
    }).catch((err) => {
      this.props.hideLoader();
      ToastsStore.error("License list loading failed :- " + err.reason);
    });
  }

  //toggling modal for adding new license
  _onToggleNewLicenseModal = () => {
    let { newLicenseModal } = JSON.parse(JSON.stringify(this.state));
    newLicenseModal.isOpen = !newLicenseModal.isOpen;
    // subscriberModal.data = creator;
    this.setState(
      {
        newLicenseModal,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  //showing licenses list here
  _showLicensesList = () => {
    let licensesList = "";
    if (!this.state.licenseList.length) {
      licensesList += "No License Found";
    } else {
      licensesList = this.state.licenseList.map((license) => {
        return (
          <tr key={license.id}>
            <td>{license.licenseKey}</td>
            <td>{license.validityInDays + " Days"}</td>
            <td>{license.isUsed ? "Used" : "Active"}</td>
            <td>
              <Button className="viewBtn">
                <CopyToClipboard text={license.licenseKey}
                  onCopy={() => ToastsStore.success("Serial number copied to Clipboard")}
                >
                  <span>Copy</span>
                </CopyToClipboard>
              </Button>
            </td>
          </tr>
        )
      });
    }
    return licensesList;
  }

  //handling status input here 
  _modifyList = (value) => {
    let { isUsed, currentPage } = this.state;
    switch (value) {
      case "Status": {
        isUsed = '';
        currentPage = 1;
        this.setState({ isUsed, currentPage }, () => {
          this._getLicenseList(currentPage);
        });
        break;
      }
      case "Active": {
        isUsed = "active";
        currentPage = 1;
        this.setState({ isUsed, currentPage }, () => {
          this._getLicenseList(currentPage);
        });
        break;
      }
      case "Used": {
        isUsed = "used";
        currentPage = 1;
        this.setState({ isUsed, currentPage }, () => {
          this._getLicenseList(currentPage);
        });
        break;
      }
      default: { }
    }
  }

  //filters list according to search input
  _filterLicenseList = (value) => {
    let { searchItem, currentPage } = this.state;
    searchItem = value;
    currentPage = 1;
    this.setState({ searchItem, currentPage }, () => {
      this._getLicenseList(currentPage);
    });
  }

  //for pagination
  _paginate = (pageNumber) => {
    this._getLicenseList(pageNumber);
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col xs="12">
            <Card>
              <CardHeader className="tableFilterWrap">
                <div className="d-flex">
                  <Input type="text" placeholder="Search..." style={{ width: 180 }}
                    onChange={(e) => this._filterLicenseList(e.target.value)}
                  />

                  <Input type="select" name="select" id="" className="ml-2"
                    onChange={(e) => this._modifyList(e.target.value)}
                  >
                    <option>Status</option>
                    <option>Used</option>
                    <option>Active</option>
                  </Input>
                </div>


                <Button className="addBtn ml-2" onClick={() => this._onToggleNewLicenseModal()}>
                  <i className="fa fa-plus mr-1"></i> Add
                </Button>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead>
                    <tr>
                      <th>Serial Number</th>
                      <th>Validity</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this._showLicensesList()}
                  </tbody>
                </Table>
                <Paginate totalPages={this.state.totalPages}
                  currentPage={this.state.currentPage}
                  paginate={this._paginate}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <NewLicenseModal
          isOpen={this.state.newLicenseModal.isOpen}
          creatorData={this.state.newLicenseModal.data}
          toggle={() => this._onToggleNewLicenseModal()}
          reload={() => this._getLicenseList()}
        ></NewLicenseModal>
        <AccessKeyModal
          isOpen={this.state.newLicenseModal.isOpen}
          creatorData={this.state.newLicenseModal.data}
          toggle={() => this._onToggleNewLicenseModal()}
          reload={() => this._getLicenseList()}
        ></AccessKeyModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Licenses);