import {
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  CardBody,
  Table,
  Button,
  Badge,
  // CustomInput,
} from 'reactstrap';

import './labsCard.css';
import './editIcon.css';

const LabsCard = (props) => {
  return (
    <div className='cardContainer'>
      <div class='labCard'>
        <h3 className='main-color d-inline edit-icon'>
          {props.lab._createdBy.id === props.loggedInUserData._id && (
            // <span class='container'>
            // <span class='wrap' id='phonewrap'>
            <span
              class='icon-pencil icon-3x icon'
              id='phonewrap'
              onClick={() => props._openAddEditLabModal('edit', props.lab)}></span>
            // </span>
            // </span>
          )}
        </h3>
        <br />
        <h3 className='main-color d-inline labName' style={{ fontSize: "2rem" }}>
          {props.lab.name}
          {/* {props.lab._createdBy.id === props.loggedInUserData._id && (
            <i className='fa fa-pencil lab-edit-icon' onClick={() => props._openAddEditLabModal('edit', props.lab)}></i>
          )}{' '} */}
          {/* <span class='container'>
            <span class='wrap' id='phonewrap'>
              <span
                class='icon-pencil icon-3x '
                id='phoneicon'
                onClick={() => props._openAddEditLabModal('edit', props.lab)}></span>
            </span>
          </span> */}
        </h3>

        <p>{props.lab.description}</p>
        <p>
          {props._changeFormat(props.lab.createdAt)}
          <br />
          {props.loggedInUserData.isAdmin && props.lab._createdBy.name.full}
        </p>
        <p>
          {' '}
          {props.lab._assignedTeams?.length > 0
            ? props.lab?._assignedTeams.map((item) => {
              return <Badge color='secondary'>{item?.name}</Badge>;
            })
            : '-'}
        </p>
        <p>
          {props.lab.isImportInProgress ? (
            <p>Lab Import is in Progress</p>
          ) : (
            <div>
              {props.lab._allowedUsers?.map(
                (item) =>
                  item === props.loggedInUserData._id && (
                    <>
                      <Button className='viewBtn mr-3 word' onClick={() => props._openLab(props.lab)}>
                        Open
                      </Button>
                      {props.lab._createdBy.name.full === props.loggedInUserData.fullName ||
                        window.localStorage.isAdmin ? (
                        <Button
                          className='stopBtn mr-3'
                          onClick={() => props._openDeleteLabConfirmationModal(props.lab)}>
                          Delete
                        </Button>
                      ) : null}
                    </>
                  )
              )}

              <Button className='viewBtn' onClick={() => props._exportLab(props.lab)}>
                Export
              </Button>
              {props.loggedInUserData.isAdmin && (
                <Button className='viewBtn ml-2' onClick={() => props._openAssignTeamModal(props.lab)}>
                  Assign
                </Button>
              )}
            </div>
          )}
        </p>
      </div>
    </div>
  );
};

export default LabsCard;
