import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Input, Label, FormGroup } from 'reactstrap';
import { addLicense } from '../../../http/http-calls';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { ToastsStore } from 'react-toasts';
import swal from 'sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class NewLicense extends Component {
  state = {
    license: {
      noOfLicenses: '',
      validity: ''
    },
    isDirty: {
      noOfLicenses: false,
      validity: false
    },
    errors: {}
  };

  //close modal here
  _closeModal = (action) => {
    console.log('in _closeModal');
  };

  componentDidMount() {
    console.log('componentDidMount', this.props);
  }

  //handling toggle of modal here
  _handleToggle = () => {
    this.setState({
      license: {
        noOfLicenses: '',
        validity: ''
      },
      isDirty: {
        noOfLicenses: false,
        validity: false
      },
      errors: {}
    });
    this.props.toggle();
    this.props.reload();
  };

  //handling input here
  _handleOnChange = (field, value) => {
    const { license, isDirty } = this.state;
    license[field] = value;
    isDirty[field] = true;
    this.setState({ license, isDirty }, () => {
      this._validateField();
    });
  };

  //for validation
  _validateField = () => {
    const { license, isDirty, errors } = this.state;
    Object.keys(license).forEach((each) => {
      if (each === 'noOfLicenses' && isDirty.noOfLicenses) {
        if (!license.noOfLicenses.trim().length) {
          errors[each] = '*Required';
        } else if (license.noOfLicenses.trim().length && !license.noOfLicenses.match(/^[0-9]/)) {
          errors[each] = 'Enter numbers only';
        } else {
          delete errors[each];
          isDirty.noOfLicenses = false;
        }
      } else if (each === 'validity' && isDirty.validity) {
        if (!license.validity.trim().length) {
          errors[each] = '*Required';
        } else if (license.validity.trim().length && !license.noOfLicenses.match(/^[0-9]/)) {
          errors[each] = 'Enter numbers only';
        } else {
          delete errors[each];
          isDirty.validity = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  //add new license here
  _addLicense = (e) => {
    e.preventDefault();
    let isDirty = {
      noOfLicenses: true,
      validity: true
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateField();
      if (!errors) {
        let licenseData = {
          noOfLicenses: this.state.license.noOfLicenses,
          validityInDays: this.state.license.validity
        };
        this.props.showLoader();
        addLicense(licenseData)
          .then((resp) => {
            console.log('Add license response here :- ', resp);
            this.props.hideLoader();
            // ToastsStore.success("Added license successfully");
            setTimeout(() => {
              swal('Success!', 'License added successfully', 'success');
            }, 1500);
            this._handleToggle();
          })
          .catch((err) => {
            this.props.hideLoader();
            // ToastsStore.error('Adding new license failed :- ' + err.reason);
            setTimeout(() => {
              swal('Error!', `Adding new license failed:- ${err.reason}`, 'error');
            }, 1500);
          });
      }
    });
  };

  render() {
    const { license, errors } = this.state;
    // console.log('from modal', this.props);
    return (
      <Modal isOpen={this.props.isOpen} toggle={() => this._handleToggle()}>
        <ModalHeader toggle={() => this._handleToggle()}>
          {' '}
          <p className='text-white'>Generate New License</p>
        </ModalHeader>
        <ModalBody className="innerForm">
          <FormGroup>
            <Label>No. of Licenses</Label>
            <Input
              type='text'
              placeholder='Enter number of licenses'
              value={license.noOfLicenses}
              onChange={(e) => this._handleOnChange('noOfLicenses', e.target.value)}
            />
            {errors && <div className='validation-error'>{errors.noOfLicenses}</div>}
          </FormGroup>
          <FormGroup>
            <Label>Validity Days</Label>
            <Input
              type='text'
              placeholder='Enter validity in Days'
              value={license.validity}
              onChange={(e) => this._handleOnChange('validity', e.target.value)}
            />
            {errors && <div className='validation-error'>{errors.validity}</div>}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className='modalBtnCancel' onClick={() => this._handleToggle()}>
            Cancel
          </Button>
          <Button className='modalBtnSave' onClick={(e) => this._addLicense(e)}>
            Generate License
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  console.log('state :>> ', state);
  return {
    loaderData: state.loaderData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLicense);
