import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Input,
  CardBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import { createVmImageReference } from "../../../../http/http-calls";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import { ToastsStore } from "react-toasts";

class VmImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 1,
      input: false,
      vmimage: {
        name: "",
        description: "",
        path: "",
        extension: "",
        filesize: "",
        filename: "",
        destination: "",
      },
      isDirtyVm: {
        name: false,
        path: false,
      },

      errors: {},
      currentPage: 1,
      totalPages: "",
      searchItem: "",
    };
  }

  //for pagination
  _paginate = (pageNumber) => {
    this._getTeamsList(pageNumber);
  };

  // handleChange
  handleChange = () => {
    this.setState({ input: !this.state.input });
  };

  markDirty = (fieldName, fieldvalue) => {
    const { isDirtyTemplate } = this.state;
    console.log(fieldName);
    isDirtyTemplate[fieldName] = true;

    this.setState({ isDirtyTemplate }, () =>
      this._validationTemplate(fieldvalue)
    );
    // console.log(this.state.errors,this.state.isDirtyTemplate);
  };

  _handleChangeVmImage = (e) => {
    console.log(e.target.value, e.target.name);
    // eslint-disable-next-line
    if (e.target.name == "path") {
      let filename = e.target.value.split("/") || "";
      let fileName = filename[filename.length - 1].split(".")[0] || "";
      let fileExtension = filename[filename.length - 1].split(".")[1] || "";
      let destination = filename.slice(0, -1).join("/") || "";
      // console.log(filename.slice(0,-1).join("/"))
      // console.log("pathh",filename,fileExtension,fileName)
      this.setState({
        vmimage: {
          ...this.state.vmimage,
          filename: fileName,
          extension: fileExtension,
          path: e.target.value,
          destination: destination,
        },
      });
    } else {
      this.setState(
        {
          vmimage: {
            ...this.state.vmimage,
            [e.target.name]: e.target.value,
          },
          isDirtyVm: {
            ...this.state.isDirtyVm,
            [e.target.name]: true,
          },
        },
        () => this._validationVmImage()
      );
    }
  };

  _validationVmImage = () => {
    const { vmimage, errors, isDirtyVm } = this.state;

    Object.keys(vmimage).forEach((each) => {
      if (each === "name" && isDirtyVm.name) {
        if (!vmimage.name.trim().length) {
          errors.nameVm = "Name is Required";
        } else {
          delete errors["nameVm"];
          isDirtyVm.name = false;
        }
      } else if (each === "path" && isDirtyVm.path) {
        if (!vmimage.path.trim().length) {
          errors.path = "Path is Required";
        } else {
          delete errors[each];
          isDirtyVm.path = false;
        }
      }
    });
    this.setState({ errors });
    console.log("err", this.state.errors);
    return Object.keys(errors).length ? errors : null;
  };

  _vmImage = () => {

    let { vmimage } = this.state;

    let isDirtyVm = {
      name: true,
      path: true,
    };
    this.setState({ isDirtyVm }, () => {
      let errors = this._validationVmImage();
      if (!errors) {
        console.log("object");
        this.props.showLoader();
        let data = {
          name: vmimage.name || undefined,
          description: vmimage.description || undefined,
          path: vmimage.path || undefined,
          extension: vmimage.extension || undefined,
          fileSize: vmimage.fileSize || undefined,
          mimetype: vmimage.mimetype || undefined,
          encoding: vmimage.encoding || undefined,
          fileOriginalName: vmimage.fileOriginalName || undefined,
          fileName: vmimage.filename || undefined,
          destination: vmimage.destination || undefined,
        }
        createVmImageReference(data).then((resp) => {
          console.log("VM Image Reference response :- ", resp);
          this.props.hideLoader();
          ToastsStore.success("VM Image Reference Created successfully");
          this.setState({
            vmimage: {
              name: "",
              description: "",
              path: "",
              extension: "",
              filesize: "",
              filename: "",
              destination: "",
            },
          })
        }).catch((err) => {
          this.props.hideLoader();
          ToastsStore.error("VM Image Reference Creation failed :- ", err.reason);
        });
      }
    });
  };

  render() {
    console.log(this.state);
    const { errors, vmimage } = this.state;
    return (
      <div className="">
        <Row>
          <Col xs="12">
            <Card>
              <CardBody className="innerCard innerForm ">
                <Row>
                  {/* name */}
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>
                        Name<sup>*</sup>
                      </Label>
                      <Input
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={vmimage.name}
                        // onChange={(e) =>
                        //   this._handleOnChange("name", e.target.value)
                        // }
                        onChange={(e) => this._handleChangeVmImage(e)}
                      />
                      {errors && (
                        <div className="validation-error">{errors.nameVm}</div>
                      )}
                    </FormGroup>
                  </Col>
                  {/* Path */}
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>
                        Path<sup>*</sup>
                      </Label>
                      <Input
                        type="text"
                        placeholder="Path"
                        name="path"
                        value={vmimage.path}
                        // onChange={(e) =>
                        //   this._handleOnChange("name", e.target.value)
                        // }
                        onChange={(e) => this._handleChangeVmImage(e)}
                      />
                      {errors && (
                        <div className="validation-error">
                          {errors.path}
                        </div>
                      )}
                    </FormGroup>
                  </Col>

                  {/* file name */}
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>File Name</Label>
                      <Input
                        type="text"
                        placeholder="File Name"
                        value={vmimage.filename}
                        // onChange={(e) =>
                        //   this._handleOnChange("name", e.target.value)
                        // }
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  {/* Extension */}
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>
                        Extension<sup>*</sup>
                      </Label>
                      <Input
                        type="text"
                        placeholder="Extension"
                        value={vmimage.extension}
                        // onChange={(e) =>
                        //   this._handleOnChange("name", e.target.value)
                        // }
                        readOnly
                      />
                    </FormGroup>
                  </Col>

                  {/* Filesize */}
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>Filesize</Label>
                      <Input
                        type="text"
                        placeholder="File Size"
                        name="filesize"
                        value={vmimage.filesize}
                        // onChange={(e) =>
                        //   this._handleOnChange("name", e.target.value)
                        // }
                        onChange={(e) => this._handleChangeVmImage(e)}
                      />
                    </FormGroup>
                  </Col>

                  {/* Description */}
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        type="textarea"
                        placeholder="Description"
                        name="description"
                        value={vmimage.description}
                        // onChange={(e) =>
                        //   this._handleOnChange("name", e.target.value)
                        // }
                        onChange={(e) => this._handleChangeVmImage(e)}
                      />
                    </FormGroup>
                  </Col>
                  
                  {/* Destination */}
                  <Col md="12">
                    <FormGroup>
                      <Label>Destination</Label>
                      <Input
                        type="textarea"
                        placeholder="Destination"
                        name="destination"
                        value={vmimage.destination}
                        // value={user.name}
                        // onChange={(e) =>
                        //   this._handleOnChange("name", e.target.value)
                        // }
                        // onChange={(e) => this._handleChangeVmImage(e)}
                        readOnly
                      />
                    </FormGroup>
                  </Col>

                </Row>

                <div className="text-right">
                  {" "}
                  <Button
                    className="addBtn"
                    //   onClick={() => this._onToggleAddTeamModal()}
                    onClick={() => this._vmImage()}
                  >
                    Save
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VmImage);
