import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardImg,
  CardTitle,
  ListGroup,
  ListGroupItem,
  CardText,
} from 'reactstrap';
import { teamList, Teamdelete } from '../../../http/http-calls';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { ToastsStore } from 'react-toasts';
import AddTeamModal from '../components/add-team-modal';
import moment from 'moment';
import AddMemberModal from '../components/add-member-modal';
import TeamsCard from './TeamsCard.jsx';

const Teams = ({ showLoader, hideLoader }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [addTeamModal, setAddTeamModal] = useState({
    isOpen: false,
    data: null,
  });
  const [deleteTeamModal, setDeleteTeamModal] = useState({
    isOpen: false,
    id: null,
  });
  const [addMemberModal, setAddMemberModal] = useState({
    isOpen: false,
    data: null,
  });
  const [TeamsData, setTeamsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('');
  const [searchItem, setSearchItem] = useState('');

  useEffect(() => {
    _getTeamsList();
  }, []);

  //get list of users here
  const _getTeamsList = () => {
    showLoader();
    teamList()
      .then((resp) => {
        setTeamsData(resp.teams);
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        ToastsStore.error('User list loading failed :- ' + err.reason);
      });
  };

  //changing format of createdAt
  const _changeFormat = (data) => {
    return moment(data).format('MMM DD, hh:mm a');
  };

  //delete Team here
  const _deleteTeam = () => {
    showLoader();
    Teamdelete(deleteTeamModal.id)
      .then((resp) => {
        console.log('Team delete response :- ', resp);
        hideLoader();
        ToastsStore.success('Team deleted successfully');
        closeDeleteModal();
        _getTeamsList();
      })
      .catch((err) => {
        hideLoader();
        ToastsStore.error('Team deletion failed :- ', err.reason);
      });
  };

  const openDeleteModal = (id) => {
    setDeleteTeamModal({
      isOpen: true,
      id: id,
    });
  };

  const closeDeleteModal = () => {
    setDeleteTeamModal({
      isOpen: false,
      id: null,
    });
  };

  //Toggling add Team modal here
  const _onToggleAddTeamModal = (data) => {
    console.log('data', data);
    setAddTeamModal((prevState) => ({
      isOpen: !prevState.isOpen,
      data: data,
    }));
  };

  //Add Team Modal
  const _onToggleAddMemberModal = (data) => {
    console.log('data', data);
    setAddMemberModal((prevState) => ({
      isOpen: !prevState.isOpen,
      data: data,
    }));
  };

  //for showing list of labs
  const _showUsersList = () => {
    let userListData = '';
    if (!TeamsData.length) {
      userListData += 'No User Found';
    } else {
      userListData = TeamsData.map((user) => {
        return (
          <>
            <Col md={6} lg={4} xl={3} className='mb-3 teams-container' key={user._id}>
              <TeamsCard
                // className=''
                className='bg-dark teams-card'
                img='/assets/img/default_user_icon.png'
                user={user}
                openDeleteModal={openDeleteModal}
                onToggleAddMemberModal={_onToggleAddMemberModal}
                onToggleAddTeamModal={_onToggleAddTeamModal}
              />

              {/* <Card className='userCard labCard userCardReset'>
                <span
                  color='link'
                  style={{ padding: '1rem' }}
                  class='icon-pencil icon-3x icon'
                  id='phonewrap'
                  onClick={() => _onToggleAddTeamModal(user)}></span>
                <CardImg
                  top
                  width='100%'
                  style={{ width: '30%', margin: 'auto' }}
                  className='p-3'
                  src='/assets/img/default_user_icon.png'
                  alt='Card image cap'
                />
                <CardBody className='text-center'>
                  <CardTitle>{user.name}</CardTitle>
                  <CardText className='mt-3'>{user.description}</CardText>
                  <Button
                    style={{ textDecoration: 'none' }}
                    color='link'
                    className='p-0'
                    onClick={() => _onToggleAddMemberModal(user.id)}>
                    <i className='fa fa-users'>({user._memberCount})</i>
                  </Button>
                  <ListGroup className='teamCount'>
                    <ListGroupItem className='justify-content-around p-0'>
                      <button
                        style={{ padding: '1rem' }}
                        onClick={() => openDeleteModal(user._id)}
                        className='button-del centerMe'>
                        <div className='icon'>
                          <i className='fa fa-trash-o'></i>
                        </div>
                        <div className='text'>
                          <span>Delete</span>
                        </div>
                      </button>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card> */}
            </Col>
          </>
        );
      });
    }
    return userListData;
  };

  //filters list according to search input
  const _filterUserList = (value) => {
    setSearchItem(value);
    setCurrentPage(1);
    _getTeamsList();
  };

  //for pagination
  const _paginate = (pageNumber) => {
    _getTeamsList(pageNumber);
  };

  return (
    <div className='content'>
      <Row>
        <Col xs='12'>
          <Card>
            <CardHeader className='tableFilterWrap'>
              <Input
                type='text'
                placeholder='Search...'
                style={{ width: 180 }}
                onChange={(e) => _filterUserList(e.target.value)}
              />
              <Button className='addBtn ml-2' onClick={() => _onToggleAddTeamModal()}>
                <i className='fa fa-plus mr-1'></i> Add
              </Button>
            </CardHeader>
          </Card>
        </Col>
        {_showUsersList()}
      </Row>
      <AddTeamModal
        isOpen={addTeamModal.isOpen}
        creatorData={addTeamModal.data}
        toggle={() => _onToggleAddTeamModal(null)}
        reload={_getTeamsList}
      />
      <AddMemberModal
        isOpen={addMemberModal.isOpen}
        teamId={addMemberModal.data}
        toggle={() => _onToggleAddMemberModal(null)}
        reload={_getTeamsList}
      />
      {/* Delete Team Confirmation Modal */}
      <Modal isOpen={deleteTeamModal.isOpen} toggle={() => closeDeleteModal()} className='modal-lg'>
        <ModalHeader toggle={() => closeDeleteModal()}> Delete Team</ModalHeader>
        <ModalBody>Do you Want to Delete this team ?</ModalBody>
        <ModalFooter>
          <Button className='modalBtnCancel' onClick={() => closeDeleteModal()}>
            Cancel
          </Button>
          <Button className='modalBtnSave' onClick={_deleteTeam}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
