import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
class DrawingListModal extends Component {
  state = {}

  downloadFile = async (url, name) => {
    fetch(url).then(async (res) => {
      console.log('res :>> ', res);
      var element = document.createElement("a");
      const blob = await res.blob()
      element.href = URL.createObjectURL(blob);
      element.download = name;
      element.click();
    })
      ;

    // delete link;
  }


  render() {
    return (<Modal size={"lg"} isOpen={this.props.isOpen} toggle={() => this.props.toggle('cancel')} className="full-page-modal">
      <ModalHeader toggle={() => this.props.toggle('cancel')}>All Drawings</ModalHeader>
      <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
        <div className="drawing-item-wrap">
          {
            React.Children.toArray(this.props?.data?.map((each) =>
              <div className="drawing-item">
                <img src={each._drawing.url} alt="" />
                <div className="drawing-info">
                  <h4>{each.name}</h4>
                  <i className="fa fa-download" onClick={() => this.downloadFile(each._drawing.url, each.name + '.png')}></i>
                </div>
              </div>
            ))
          }
          {this.props?.data?.length === 0 && <h4 className="no-drawings">No Drawings created Yet!</h4>}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => this.props.toggle('cancel')}>Close</Button>
      </ModalFooter>
    </Modal>);
  }
}

export default DrawingListModal;