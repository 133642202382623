import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Input, Label, FormGroup } from 'reactstrap';
import { addSerialKey, licenseList } from '../../../http/http-calls';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { ToastsStore } from 'react-toasts';
import { showToast } from '../../../helper-methods';
import axios from 'axios';
import { toast } from 'react-toastify';

const NewLicense = (props) => {
  const [license, setLicense] = useState({
    accessKey: '',
  });
  const [uploadedFile, setUploadedFile] = useState();
  const [count, setCount] = useState(0);

  const [isDirty, setIsDirty] = useState({
    accessKey: false,
  });

  const [errors, setErrors] = useState({});

  const closeModal = (action) => {
    console.log('in _closeModal');
  };

  const [licenceInfo, setLicenseInfo] = useState({});

  const fetchLicenseInfo = async () => {
    const status = await axios.get('ronin/license-status/');
    if (status.data.license_created) {
      setUploadedFile('License file is valid');
      setLicenseInfo(status.data);
      return;
    } else if (!status.data.license_created) {
      setUploadedFile('License is not valid');
      setLicenseInfo({
        license_created: 'Expired License',
        license_expires: 'Expired License',
      });
      return;
    }
  };
  useEffect(() => {
    fetchLicenseInfo();
    console.log('componentDidMount', props);
  }, []);

  const handleToggle = () => {
    setLicense({
      accessKey: '',
    });

    setIsDirty({
      accessKey: false,
    });

    setErrors({});
    props.toggle();
  };

  const _onFileSelect = async (event) => {
    if (event.target.files) {
      console.info('event.target.files :>> ', event.target.files[0]);
      try {
        props.showLoader();
        const formData = new FormData();
        formData.append('fileupload', event.target.files[0]);
        console.info(event.target.files[0]);
        const res = await axios({
          method: 'POST',
          url: '/ronin/upload-license/',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
          transformRequest: (formData) => formData,
          data: formData,
        });
        setUploadedFile('License file is valid');
        fetchLicenseInfo();
        toast.success(`Successfully uploaded a new License file`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.hideLoader();
      } catch (error) {
        props.showLoader();
        // setUploadedFile('License is not valid')
        // setLicenseInfo({
        //   "license_created": "Expired License",
        //   "license_expires": "Expired License"
        // })

        toast.error(`Error while uploaing the license file`, {
          position: toast.POSITION.TOP_RIGHT,
        });

        props.hideLoader();
        // let res = await uploadFileMultiPart(formData);
        // 65.220.37.101:8051/ronin/upload-license/
        this.props.showLoader();
        this.props.hideLoader();
      }
    }
  };

  const handleOnChange = (field, value) => {
    const updatedLicense = { ...license, [field]: value };
    setLicense(updatedLicense);

    const updatedIsDirty = { ...isDirty, [field]: true };
    setIsDirty(updatedIsDirty);

    validateField();
  };

  const validateField = () => {
    const updatedErrors = { ...errors };
    Object.keys(license).forEach((each) => {
      if (each === 'accessKey' && isDirty.accessKey) {
        if (!license.accessKey.trim().length) {
          updatedErrors[each] = '*Required';
        } else if (license.accessKey.trim().length && !license.accessKey.match(/^[0-9]/)) {
          updatedErrors[each] = 'Enter numbers only';
        } else {
          delete updatedErrors[each];
          setIsDirty({ ...isDirty, accessKey: false });
        }
      }
    });
    setErrors(updatedErrors);
    return Object.keys(updatedErrors).length ? updatedErrors : null;
  };

  const addLicense = (e) => {
    // e.preventDefault();
    props.showLoader();
    setCount(count + 1);
    props.hideLoader();
    console.log('access key ===> ', license.accessKey);
    // let data = { key: license.accessKey };
    // props.showLoader();
    // addSerialKey(data)
    //   .then((response) => {
    //     props.hideLoader();
    //     ToastsStore.success('Added Serial key successfully');
    //   })
    //   .catch((err) => {
    //     props.hideLoader();
    //     ToastsStore.error('Something went wrong');
    //   });
  };

  return (
    <Modal className='license_modal' size={'lg'} isOpen={props.isOpen} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>New License Key</ModalHeader>
      <ModalBody>
        <FormGroup>
          {/* <Label>License key</Label>
          <Input
            type='text'
            placeholder='Enter number of licenses'
            value={license.accessKey}
            onChange={(e) => handleOnChange('accessKey', e.target.value)}
          />
          {errors && <div className='validation-error'>{errors.accessKey}</div>} */}
          <span className='licenseDetails'>{uploadedFile ? uploadedFile : 'No File Added'}</span>
          <Button className='viewBtn import-btn h-v_center'>
            <input type='file' className='file-input' onChange={(e) => _onFileSelect(e)} />
            <span>
              {'Choose a new license file'} <b className='browse'>Browse</b>
            </span>
          </Button>
          <br />
        </FormGroup>
        <div className='license-details'>
          <p>
            <span className='licenseDetails'>License Created On</span>{' '}
            <span className='license_info'>{new Date(licenceInfo.license_created).toUTCString()}</span>
          </p>
          <p>
            <span className='licenseDetails'> License Expires On</span>{' '}
            <span className='license_info'>{new Date(licenceInfo.license_expires).toUTCString()}</span>
          </p>
          {/* <p>
            <span className='licenseDetails'>License Category</span> <span className='license_info'>Golden</span>
          </p>
          <p>
            <span className='licenseDetails'> Number of users</span> <span className='license_info'>500</span>
          </p> */}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className='modalBtnCancel' onClick={handleToggle}>
          Close
        </Button>
        {/* <Button className='modalBtnSave' onClick={addLicense}>
          ADD
        </Button> */}
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  console.log('state :>> ', state);
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLicense);
