import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
// import { githubDarkTheme } from '@uiw/react-json-view/githubDark';
// import JsonView from '@uiw/react-json-view';
import ReactJson from 'react-json-view'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: "65vw",
  // bgcolor: '#171717',
  // border: '2px solid #000',
  // overflow: "scrollY",
  // boxShadow: 24,
  // p: 4
};

export default function TemplateJSONView({ el }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button id='jsoncode' style={{ color: "white", display: "flex", paddingTop: 0, marginTop: 0, paddingLeft: 0, marginLeft: 0 }} onClick={handleOpen}>
        <i className='fa fa-code fa-lg'></i>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>

          {/* <div className='w-100'>
            <JsonView shortenTextAfterLength={100} indentWidth={4} value={el} style={githubDarkTheme} />
          </div> */}

          <div style={{ height: '50vh', width: "50vw", overflow: "scroll", padding: "2rem", background: "#2e2e2e" }}>
            <ReactJson theme={'eighties'} src={el} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
