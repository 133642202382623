// const DeleteTemplateLibraryModal = () => {
//   return (

//   )
// }

// import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import {
  getAllTemplates,
  installTemplates,
  getAllvmTemplates,
  editTemplate,
  deleteTemplate
} from '../../../../http/http-calls.js';
import { hideLoader, showLoader } from '../../../../redux/actions/loader-data.js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#171717',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export default function TemplateDeleteConfirmationModal({ id, onReload }) {
  const [open, setOpen] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleReload = () => {
    onReload(true);
  };

  const deleteTemplateHandler = (id) => {
    // showLoader();
    deleteTemplate(id)
      .then((resp) => {
        // this.props.onDelete(index);
        window.location.reload();
      })
      .catch((err) => console.log(err));
    // handleReload();
  };

  return (
    <div>
      <Button className='button-del centerMe' onClick={handleOpen}>
        <div className='icon'>
          <i className='fa fa-trash-o'></i>
        </div>
        <div className='text'>
          <span>Delete</span>
        </div>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <Typography style={{ color: 'white' }} id='modal-modal-title' variant='h6' component='h2'>
            Are you sure you want to delete this template?
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}></Typography>
          <button
            className='btn'
            style={{ backgroundColor: 'transparent', border: '2px solid gray', backgroundImage: 'none' }}
            onClick={() => {
              setOpen(false);
            }}>
            No
          </button>
          <button
            className='btn'
            style={{ backgroundColor: '#f18f3c', border: '2px solid gray', backgroundImage: 'none' }}
            onClick={() => {
              deleteTemplateHandler(id);
            }}>
            Yes
          </button>
        </Box>
      </Modal>
    </div>
  );
}
