import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import { deepClone } from "../../../../helper-methods";
import Select from "react-dropdown-select";
import styled from "styled-components";

const StyledOption = styled.span`
  padding: 0;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  transition: all 1s ease-in;

  span {
    display: none;
    transition: all 1s ease-in;
  }

  a {
    margin: 0 5px;
  }

  :hover {
    background: #f2f2f2;

    span {
      display: inline;
      margin: 0 0 0 5px;
      color: red;
    }
  }
`;

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;

  :hover {
    background: #f2f2f2;
  }
`;
class EditVNodeModal extends Component {
  state = {
    vNode: {
      name: "",
      description: "",
    },
    isDirty: {
      name: false,
      description: false,
    },
    errors: {},
    selectedFile: null,
    selectedIcons: [],
  };

  _handleOnChange = (field, value) => {
    let { vNode, isDirty } = deepClone(this.state);
    vNode[field] = value;
    isDirty[field] = true;    
    this.setState({ vNode, isDirty }, () => {
      this._validateFields();
    });
  };

  _validateFields = () => {
    const { vNode, isDirty, errors, selectedIcons } = this.state;
    Object.keys(vNode).forEach((each) => {
      if (each === "name" && isDirty.name) {
        if (!vNode.name.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.name = false;
        }
      }
    });
    if(!selectedIcons.length && isDirty.iconId){
      errors.selectedFile = '*Select an Icon'
    } else {
      delete errors.selectedFile;
    }
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  _customOptionRenderer = ({ item, props, state, methods }) => {
    return (
      <StyledOption>
        <img src={item.url} width={30} alt={item.name} />
        &nbsp;
        {item.name}
        <span
          onClick={() => {
            methods.removeItem(null, item);
            this.setState({ selectedIcons: [] }, () => {
               this._validateFields();
            });
          }}
        >
          &times;
        </span>
      </StyledOption>
    );
  };

  _customItemRenderer = ({ item, itemIndex, props, state, methods }) => {
    const { selectedIcons } = deepClone(this.state);

    return (
      <StyledItem>
        <div
          onClick={() => {
            console.log("selectedIcons :>> ", item);
            methods.addItem(item);
            const { isDirty } = deepClone(this.state);
            isDirty.iconId = true;
            const isAlreadyPresent =
              selectedIcons.length && selectedIcons[0].id === item.id;
            this.setState(
              {
                selectedIcons: isAlreadyPresent ? [] : [item],
                selectedFile: null,
                previewImg: null,
                isDirty,
              },
              () => {
                 this._validateFields()
              }
            );
          }}
        >
           {
            item.isFirstDefaultIcon &&
          <div className="custom-select-section">System Icons</div>  
        }
          {
            item.isFirstIcon &&
            <div className="custom-select-section">Custom Icons</div>  

          }
          <img src={item.url} width={30} alt={item.name} /> &nbsp;
          {item.name}
        </div>
      </StyledItem>
    );
  };

  _handleOnSubmit = () => {
    const { vNode, isDirty, selectedIcons } = deepClone(this.state);
    isDirty.name = true;
    isDirty.description = true
    this.setState({ isDirty },()=>{
      let errors = this._validateFields();
      if(!errors){
        this.props.toggle('save', {
          name: vNode.name,
          description: vNode.description,
          iconId: selectedIcons[0]?.id,
          iconURL: selectedIcons[0]?.url,
        })
      }
    })
  };


  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.data) {
        let state = {
          vNode: {
            name: this.props.data?.properties?.name,
            description: this.props.data?.properties?.description || "",
          },
          isDirty: {
            name: false,
            description: false,
          },
          errors: {},
          selectedFile: null,
          selectedIcons: this.props.data?.iconList.filter((e)=>e.id===this.props.data?.properties?.iconId)  || [],
        };
        this.setState(state);
      }
    }
  }
  render() {
    const { vNode, errors } = deepClone(this.state);
    const { data } = deepClone(this.props);

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle("cancel")}
      >
        <ModalHeader toggle={() => this.props.toggle("cancel")}>
          Edit{" "}
          {data?.properties?.networkType === "VNode"
            ? "Virtual Machine"
            : "Virtual Network"}
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
        >
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              placeholder="Enter name"
              value={vNode.name}
              onChange={(e) => this._handleOnChange("name", e.target.value)}
            />
            {errors && <div className="validation-error">{errors.name}</div>}
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <Input
              type="textarea"
              rows="3"
              placeholder="Enter description"
              value={vNode.description}
              onChange={(e) =>
                this._handleOnChange("description", e.target.value)
              }
            />
            {errors && (
              <div className="validation-error">{errors.description}</div>
            )}
          </FormGroup>

          <FormGroup>
          <Label>Icon</Label>
          <div>
          <Select
            placeholder="Select Icon"     
            multi={true} 
            keepOpen={false} 
            searchable={false}
            optionRenderer={this._customOptionRenderer}
            itemRenderer={this._customItemRenderer}
            onChange={(value) => this._handleOnChange('iconId', value)}
            values={this.state.selectedIcons}
            options={data?.iconList}
            labelField={'name'}
            valueField={'id'}
            closeOnSelect={true}
          />           
          </div>
          {errors && (
            <div className="validation-error">{errors.selectedFile}</div>
          )}
        </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            className="modalBtnCancel"
            onClick={() => this.props.toggle("cancel")}
          >
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={(e) => this._handleOnSubmit(e)}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EditVNodeModal;
