import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Input, Label, FormGroup, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { userList, createTeam, editTeam } from '../../../http/http-calls';
import { ToastsStore } from 'react-toasts';

import swal from 'sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AddTeam extends Component {
  state = {
    team: {
      name: '',
      description: ''
    },
    isDirty: {
      name: false
    },
    usersData: [],
    errors: {},
    isVisible: false
  };

  //closing modal
  _closeModal = (action) => {
    console.log('in _closeModal');
  };

  componentDidMount() {
    console.log('componentDidMount', this.props);
    this._getUserList(this.state.currentPage);
  }
  componentDidUpdate(previousProps, previousState) {
    console.log('componentDidMount', this.props);
    if (this.props.creatorData && previousProps.isOpen !== this.props.isOpen) {
      console.log('object :', this.props.creatorData);

      let { creatorData } = this.props;

      let obj = {
        name: creatorData.name,
        description: creatorData.description
      };

      this.setState({ team: obj });
    }
  }

  _getUserList = (pageNumber) => {
    let data = { page: 1 };

    this.props.showLoader();
    userList(data)
      .then((resp) => {
        this.setState({
          usersData: resp.docs,
          totalPages: resp.totalPages,
          currentPage: resp.page
        });
        this.props.hideLoader();
      })
      .catch((err) => {
        this.props.hideLoader();
        // ToastsStore.error("User list loading failed :- " + err.reason);
        setTimeout(() => {
          swal('Error!', `User list loading failed:- ${err.reason}`, 'error');
        }, 1500);
      });
  };
  //handling visibility of password
  _handleVisibilityToggle = () => {
    let { isVisible } = this.state;
    isVisible = !isVisible;
    this.setState({ isVisible });
  };

  //handling toggle of modal here
  _handleToggle = () => {
    this.setState({
      team: {
        name: '',
        description: ''
      },
      isDirty: {
        name: false
      },
      errors: {}
    });
    this.props.toggle();
    // this.props.reload();
  };

  //handling input here
  _handleOnChange = (field, value) => {
    const { team, isDirty } = this.state;
    if (field === 'isAdmin') {
      team[field] = value;
    } else {
      team[field] = value;
      isDirty[field] = true;
    }
    this.setState({ team, isDirty }, () => {
      this._validateField();
    });
  };

  //for validation
  _validateField = () => {
    const { team, isDirty, errors } = this.state;
    Object.keys(team).forEach((each) => {
      if (each === 'name' && isDirty.name) {
        if (!team.name.trim().length) {
          errors[each] = '*Required';
        } else if (team.name.trim().length && team.name.match(/^[a-zA-Z ]*$/) && team.name.trim().length <= 2) {
          errors[each] = 'Name should be greater than two characters';
        } else {
          delete errors[each];
          isDirty.name = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  //add new Team here
  _addTeam = (e) => {
    e.preventDefault();
    let isDirty = {
      name: true
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateField();
      if (!errors) {
        let teamData = {
          name: this.state.team.name,
          description: this.state.team.description
        };
        this.props.showLoader();
        console.log(teamData);
        createTeam(teamData)
          .then((resp) => {
            console.log(resp);
            this.props.hideLoader();
            // ToastsStore.success('Team added successfully');
            setTimeout(() => {
              swal('Success!', 'Team added successfully', 'success');
            }, 1500);
            this._handleToggle();
            this.props.reload();
          })
          .catch((err) => {
            this.props.hideLoader();
            // ToastsStore.error('Adding new Team failed :- ' + err.reason);
            setTimeout(() => {
              swal('Error!', `Adding new team failed:- ${err.reason}`, 'error');
            }, 1500);
          });
      }
    });
  };

  _editTeam = (e) => {
    e.preventDefault();
    let isDirty = {
      name: true
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateField();
      if (!errors) {
        let teamData = {
          name: this.state.team.name,
          description: this.state.team.description
        };
        this.props.showLoader();
        console.log(teamData);
        editTeam(this.props.creatorData.id, teamData)
          .then((resp) => {
            console.log(resp);
            this.props.hideLoader();
            // ToastsStore.success('Team Updated successfully');
            setTimeout(() => {
              swal('Success!', 'Team updated successfully', 'success');
            }, 1500);
            this._handleToggle();
            this.props.reload();
          })
          .catch((err) => {
            this.props.hideLoader();
            // ToastsStore.error('Updating Team failed :- ' + err.reason);
            setTimeout(() => {
              swal('Error!', `Updating team failed:- ${err.reason}`, 'error');
            }, 1500);
          });
      }
    });
  };

  render() {
    const { team, errors } = this.state;
    console.log(this.state);
    return (
      <Modal isOpen={this.props.isOpen} toggle={() => this._handleToggle()} className='modal-md'>
        <ModalHeader toggle={() => this._handleToggle()}>
          {' '}
          <p className='text-white'> {this.props.creatorData ? 'Edit' : 'Add'} Team </p>
        </ModalHeader>
        <ModalBody className='innerForm'>
          <Row>
            <Col sm='12'>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type='text'
                  placeholder='Enter full name'
                  value={team.name}
                  onChange={(e) => this._handleOnChange('name', e.target.value)}
                />
                {errors && <div className='validation-error'>{errors.name}</div>}
              </FormGroup>
            </Col>
            <Col sm='12'>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  type='text'
                  placeholder='Enter Description'
                  value={team.description}
                  onChange={(e) => this._handleOnChange('description', e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className='modalBtnCancel' onClick={() => this._handleToggle()}>
            Cancel
          </Button>
          <Button
            className='modalBtnSave'
            onClick={this.props.creatorData ? (e) => this._editTeam(e) : (e) => this._addTeam(e)}>
            {this.props.creatorData ? 'Save' : 'Create'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTeam);
