import React from 'react';
// nodejs library that concatenates classes
// import classNames from "classnames";
// react plugin used to create charts
import {
  Line,
  //  Bar
} from 'react-chartjs-2';

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col, Label, ButtonGroup } from 'reactstrap';

// core components
import {
  // chartExample2,
  chart1_2_options,
  // chartExample4
} from '../../../variables/charts.js';
import AddLabModal from '../components/add-edit-lab-modal';
import DashboardDockerListModal from '../components/dashboard-docker-list-modal';
import ContactUsModal from '../components/contact-us-modal';
import { getResourceCount, getResourceList, getSystemStats, stopSinglevNode } from '../../../http/http-calls.js';
import moment from 'moment';
import { deepClone, showToast } from '../../../helper-methods';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data.js';
import { connect } from 'react-redux';
import DashboardNetworkListModal from '../components/dashboard-network-list-modal.jsx';
import DashboardNodesListModal from '../components/dashboard-nodes-list-modal';
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { socketConnect } from 'socket.io-react';

// import FullChart from './FullChart';
import FullChart from './FullChart';
import DraggableDialog from '../components/design-lab/chat-gpt-modal.jsx';
import ResizeDragModal from './resizeDragModal.jsx';

import './custom.css';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: 'data1',
      addLabModal: {
        isOpen: false,
        data: null,
      },
      dashboardNetworkListModal: {
        isOpen: false,
        data: null,
      },

      dashboardNodesListModal: {
        isOpen: false,
        data: null,
      },
      dashboardDockerListModal: {
        isOpen: false,
        data: null,
      },
      contactUsModal: {
        isOpen: false,
        data: null,
      },
      cpuUtilizationChatConfig: {},
      ramUtilizationChatConfig: {},
      storageUtilizationChatConfig: {},
      groupBy: 'day',
      totalPercentage: {
        cpu: 0,
        ram: 0,
        storage: 0,
      },
      dashboardCounts: {},
      loggedInUserData: {},
      nodeToDelete: {},
    };
  }

  componentDidMount() {
    if (this.props.userData?.token) {
      this.setState({ loggedInUserData: jwt_decode(this.props.userData.token) });
    }
    this._addJobStatusListener();
    this._getResourceCount();
    this._getSystemStats();
  }

  componentWillUnmount() {
    console.log('unmount dashboard :>> ');
    this.props.socket.off('job-status-changed', this._onJobStatusChange);
  }

  _addJobStatusListener = () => {
    this.props.socket.on('job-status-changed', this._onJobStatusChange);
  };

  _onJobStatusChange = (data) => {
    let { nodeToDelete, dashboardCounts } = deepClone(this.state);
    if (data.jobCategory === 'stop-vm-node') {
      if (data.jobStatus === 'successful') {
        console.log('data stop from dashboard:>> ', data);
        if (data.jobOutput._vNode === nodeToDelete.id) {
          this._closeNodeListModal();
          this.props.hideLoader();
          dashboardCounts.virtualMachinesCount--;
          this.setState({ dashboardCounts });
          showToast('Machine stopped', 'success');
        } else {
          console.log('No such Node :>> ', data.jobOutput._vNode);
        }
      } else if (data.jobStatus === 'failed') {
        this._closeNodeListModal();
        this.props.hideLoader();
      }
    }
  };

  _getResourceCount = async () => {
    try {
      this.props.showLoader();
      let res = await getResourceCount();
      console.log('res :>> ', res);
      this.setState({ dashboardCounts: res });
      this.props.hideLoader();
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  _getSystemStats = async () => {
    const { groupBy } = deepClone(this.state);
    try {
      this.setState({ loadChart: false });
      let res = await getSystemStats({ groupBy });
      console.log('res :>> ', res);
      if (res.groupBy === 'day') {
        this._generateChartData(10, 'days', res, 'DD-MM-YYYY');
      } else if (res.groupBy === 'month') {
        this._generateChartData(12, 'months', res, 'MM-YYYY');
      } else {
        // hour
        this._generateChartData(24, 'hours', res, 'HH:mm DD-MM-YYYY');
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  _generateChartData = (value, unit, statResponse, format) => {
    const { totalPercentage } = deepClone(this.state);
    let dateList = [];
    let cpuUtilizationDataSet = [];
    let ramUtilizationDataSet = [];
    let storageUtilizationDataSet = [];
    for (let i = value - 1; i >= 0; --i) {
      const date = moment().set('minute', 0).subtract(i, unit).format(format);
      if (unit === 'days') {
        dateList.push(date);
      } else if (unit === 'months') {
        dateList.push(moment().subtract(i, unit).format('MMM'));
      } else {
        dateList.push(moment().set('minute', 0).subtract(i, unit).format('HH:mm'));
      }
      let entry = statResponse.docs.find((e) => e.when === date);
      if (entry) {
        cpuUtilizationDataSet.push(entry.averageCpuPercentUsed);
        ramUtilizationDataSet.push(entry.averageRamPercentUsed);
        storageUtilizationDataSet.push(entry.averageDiskPercentUsed);
      } else {
        cpuUtilizationDataSet.push(0);
        ramUtilizationDataSet.push(0);
        storageUtilizationDataSet.push(0);
      }
    }
    console.log('dateList :>> ', dateList, cpuUtilizationDataSet);
    const cpuUtilizationChatConfig = this._getChartConfig(dateList, cpuUtilizationDataSet);
    const ramUtilizationChatConfig = this._getChartConfig(dateList, ramUtilizationDataSet);
    const storageUtilizationChatConfig = this._getChartConfig(dateList, storageUtilizationDataSet);
    totalPercentage.cpu = this._calculateTotalPercentage(cpuUtilizationDataSet);
    totalPercentage.ram = this._calculateTotalPercentage(ramUtilizationDataSet);
    totalPercentage.storage = this._calculateTotalPercentage(storageUtilizationDataSet);
    this.setState({
      cpuUtilizationChatConfig,
      ramUtilizationChatConfig,
      storageUtilizationChatConfig,
      totalPercentage,
      loadChart: true,
    });
  };

  _getChartConfig = (labels, dataSet) => {
    let chartConfig = {
      data: (canvas) => {
        let ctx = canvas.getContext('2d');

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, 'rgba(-5,67,70,0.2)');
        gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
        gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); //blue colors

        // gradientStroke.addColorStop(1, 'rgb(241,143,60)');
        // gradientStroke.addColorStop(0.4, 'rgba(241, 143, 60)');
        // gradientStroke.addColorStop(0, 'rgba(241, 140, 248, 60)'); //blue colors

        return {
          labels: labels,
          datasets: [
            {
              label: 'Data',
              fill: true,
              backgroundColor: gradientStroke,
              // borderColor: '#f18f3c',
              borderColor: '#1f8ef1',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              // pointBackgroundColor: '#f18f3c',
              pointBackgroundColor: '#1f8ef1',
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#1f8ef1',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: dataSet,
            },
          ],
        };
      },
      options: chart1_2_options,
    };
    return chartConfig;
  };

  _onGroupByChange = (value) => {
    this.setState({ groupBy: value }, () => {
      this._getSystemStats();
    });
  };

  _calculateTotalPercentage = (dataSet) => {
    let sum = dataSet.reduce((acc, each) => {
      return (acc = acc + each);
    }, 0);
    return (sum / dataSet.length).toFixed(2);
  };

  _onToggleAddLabModal = (action, labData) => {
    console.log('object :>> ', action, labData);
    let { addLabModal } = JSON.parse(JSON.stringify(this.state));
    addLabModal.isOpen = !addLabModal.isOpen;
    // subscriberModal.data = creator;
    this.setState(
      {
        addLabModal,
      },
      () => {
        if (action === 'save' && labData) {
          console.log('saved', this.state);
          this.props.history.push('/design-lab/' + labData.id);
        }
      }
    );
  };

  _openNetworkListModal = async () => {
    let { dashboardNetworkListModal } = deepClone(this.state);
    try {
      this.props.showLoader();
      let res = await getResourceList({
        category: 'network',
        page: 1,
      });
      console.log('res; :>> ', res);
      dashboardNetworkListModal.isOpen = true;
      dashboardNetworkListModal.data = {
        networkList: res.docs,
        totalPages: res.totalPages,
      };
      this.setState({ dashboardNetworkListModal });
      this.props.hideLoader();
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  _closeNetworkListModal = () => {
    const { dashboardNetworkListModal } = deepClone(this.state);
    dashboardNetworkListModal.isOpen = false;
    dashboardNetworkListModal.data = null;
    this.setState({ dashboardNetworkListModal });
  };

  _openNodesListModal = async () => {
    let { dashboardNodesListModal } = deepClone(this.state);
    try {
      this.props.showLoader();
      let res = await getResourceList({
        category: 'virsh',
        page: 1,
      });
      console.log('res; :>> ', res);
      dashboardNodesListModal.isOpen = true;
      dashboardNodesListModal.data = {
        nodeList: res.docs,
        totalPages: res.totalPages,
      };
      this.setState({ dashboardNodesListModal });
      this.props.hideLoader();
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  _closeNodeListModal = () => {
    const { dashboardNodesListModal } = deepClone(this.state);
    dashboardNodesListModal.isOpen = false;
    dashboardNodesListModal.data = null;
    this.setState({ dashboardNodesListModal });
  };

  _openDockerListModal = async () => {
    let { dashboardDockerListModal } = deepClone(this.state);
    try {
      this.props.showLoader();
      let res = await getResourceList({
        category: 'docker',
        page: 1,
      });
      console.log('res; :>> ', res);
      dashboardDockerListModal.isOpen = true;
      dashboardDockerListModal.data = {
        nodeList: res.docs,
        totalPages: res.totalPages,
      };
      this.setState({ dashboardDockerListModal });
      this.props.hideLoader();
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  _closeDockerListModal = () => {
    const { dashboardDockerListModal } = deepClone(this.state);
    dashboardDockerListModal.isOpen = false;
    dashboardDockerListModal.data = null;
    this.setState({ dashboardDockerListModal });
  };

  _onToggleContactUsModal = () => {
    let { contactUsModal, loggedInUserData } = deepClone(this.state);
    if (loggedInUserData.isAdmin) {
      return;
    }
    contactUsModal.isOpen = !contactUsModal.isOpen;
    this.setState(
      {
        contactUsModal,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  _stopNode = async (node) => {
    this.setState({ nodeToDelete: node });
    try {
      this.props.showLoader();
      let res = await stopSinglevNode({
        jobCode: uuidv4(),
        uuid: node.uuid,
      });
      console.log('res :>> ', res);
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  render() {
    console.log('Here');
    const {
      cpuUtilizationChatConfig,
      loadChart,
      ramUtilizationChatConfig,
      storageUtilizationChatConfig,
      groupBy,
      totalPercentage,
      dashboardCounts,
    } = deepClone(this.state);

    return (
      <div className='content'>
        {/* <DraggableDialog /> */}
        <Row>
          <Col md='6' xl='3'>
            <Card body className='box-shadow dashboardCard' onClick={() => this._openNetworkListModal()}>
              <i className='fa fa-sitemap fa-2x'></i>
              <h3>{dashboardCounts.virtualNetworksCount || 0}</h3>
              <p>Virtual Networks</p>
            </Card>
          </Col>
          <Col md='6' xl='3'>
            <Card body className='box-shadow dashboardCard' onClick={() => this._openNodesListModal()}>
              <i className='fa fa-server fa-2x'></i>
              <h3>{dashboardCounts.virtualMachinesCount || 0}</h3>
              <p>Virtual Machines</p>
            </Card>
          </Col>
          <Col md='6' xl='3'>
            <Card body className='box-shadow dashboardCard' onClick={() => this._openDockerListModal()}>
              {/* <i className='fa-brands fa-docker'></i> */}
              <img src='/assets/img/docker-logo.svg' alt='docker' className='dashboard-docker-icon' />
              <h3>{dashboardCounts.dockerContainersCount || 0}</h3>
              <p>Docker Containers</p>
            </Card>
          </Col>
          <Col md='6' xl='3'>
            <Card body className='box-shadow dashboardCard' onClick={() => this._onToggleContactUsModal()}>
              <i className='fa fa-support fa-2x'></i>
              <h3>{dashboardCounts.supportRequestCount || 0}</h3>
              <p>Support Requests</p>
            </Card>
          </Col>
        </Row>
        <Row>
          {/* <Col lg='12' xl='12'>
            <div className='group-by-wrap'>
              <Label className='mr-2'>Show Statistics By: </Label>
              <select className='form-control' value={groupBy} onChange={(e) => this._onGroupByChange(e.target.value)}>
                <option value='hour'>Hour</option>
                <option value='day'>Day</option>
                <option value='month'>Month</option>
              </select>
            </div>
          </Col> */}
        </Row>

        <Row>
          {/* <div className='div1'>
            <h5 className='card-category'>CPU Utilization</h5>
            <h2>{totalPercentage.ram}</h2>
          </div>
          <div className='div1'>
            <h5 className='card-category'>CPU Utilization</h5>
            <h2>{totalPercentage.storage}</h2>
          </div> */}
          {/* <Col className='center-text' lg='4' xl='4'>
            <div className='group-by-wrap bg-dark'>
              <Label className='mr-2'>Show Statistics By: </Label>
              <select className='form-control' value={groupBy} onChange={(e) => this._onGroupByChange(e.target.value)}>
                <option value='hour'>Hour</option>
                <option value='day'>Day</option>
                <option value='month'>Month</option>
              </select>
            </div>
          </Col> */}

          {/* <div className='div1'>
            <h5 className='card-category'>CPU Utilization</h5>
            <h2>{totalPercentage.cpu}</h2>
          </div> */}

          {/* <ResizeDragModal /> */}

          <FullChart
            loadChart={loadChart}
            totalPercentage={totalPercentage}
            cpuUtilizationChatConfig={cpuUtilizationChatConfig}
            ramUtilizationChatConfig={ramUtilizationChatConfig}
            storageUtilizationChatConfig={storageUtilizationChatConfig}
            groupBy={groupBy}
            _onGroupByChange={this._onGroupByChange}
          />
        </Row>

        <Button className='newLabBtn' onClick={() => this._onToggleAddLabModal()}>
          <i className='fa fa-plus mr-1' /> New Lab
        </Button>

        <AddLabModal
          isOpen={this.state.addLabModal.isOpen}
          type='add'
          creatorData={this.state.addLabModal.data}
          toggle={this._onToggleAddLabModal}
          reload={() => console.log('In Dashboard :- ')}></AddLabModal>

        <DashboardNetworkListModal
          isOpen={this.state.dashboardNetworkListModal.isOpen}
          data={this.state.dashboardNetworkListModal.data}
          toggle={() => this._closeNetworkListModal()}></DashboardNetworkListModal>

        <DashboardNodesListModal
          isOpen={this.state.dashboardNodesListModal.isOpen}
          data={this.state.dashboardNodesListModal.data}
          toggle={() => this._closeNodeListModal()}
          onStopNode={this._stopNode}></DashboardNodesListModal>

        <DashboardDockerListModal
          isOpen={this.state.dashboardDockerListModal.isOpen}
          data={this.state.dashboardDockerListModal.data}
          toggle={() => this._closeDockerListModal()}></DashboardDockerListModal>

        <ContactUsModal
          isOpen={this.state.contactUsModal.isOpen}
          creatorData={this.state.contactUsModal.data}
          toggle={() => this._onToggleContactUsModal()}></ContactUsModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(socketConnect(Dashboard));
