import React, { useEffect, useRef } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import PerfectScrollbar from 'perfect-scrollbar';
import { Nav } from 'reactstrap';

let ps;

const Sidebar = (props) => {
  const sidebarRef = useRef(null);
  const { isAdmin, toggleSidebar } = props;
  const location = useLocation();

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
  }, []);

  let logoImg = null;
  let logoText = null;
  let allowedRoutes = [];
  if (!isAdmin) {
    allowedRoutes = props.routes.filter((e) => !e.isOnlyAdminRoute);
  } else {
    allowedRoutes = props.routes;
  }

  if (props.logo !== undefined) {
    if (props.logo.outterLink !== undefined) {
      logoImg = (
        <a href={props.logo.outterLink} className='simple-text logo-mini' target='_blank' onClick={toggleSidebar}>
          <div className='logo-img'>{/* <img src={props.logo.imgSrc} alt='react-logo' /> */}</div>
        </a>
      );
      logoText = (
        <a href={props.logo.outterLink} className='simple-text logo-normal' target='_blank' onClick={toggleSidebar}>
          {/* {props.logo.text} */}
        </a>
      );
    } else {
      logoImg = (
        <Link to={props.logo.innerLink} className='simple-text logo-mini' onClick={toggleSidebar}>
          <div className='logo-img'>
            <img src={props.logo.imgSrc} alt='react-logo' />
          </div>
        </Link>
      );
      logoText = (
        <Link to={props.logo.innerLink} className='simple-text logo-normal' onClick={toggleSidebar}>
          {props.logo.text}
        </Link>
      );
    }
  }

  return (
    <div className='sidebar' data={props.bgColor}>
      <div className='sidebar-wrapper' ref={sidebarRef}>
        {logoImg !== null || logoText !== null ? (
          <div className='logo'>
            {logoImg}
            {logoText}
          </div>
        ) : null}
        <Nav>
          {allowedRoutes.map((prop, key) => {
            if (prop.redirect) return null;
            return (
              <li className={activeRoute(prop.path) + (prop.pro ? ' active-pro' : '')} key={key}>
                <NavLink to={prop.path} className='nav-link' activeClassName='active' onClick={toggleSidebar}>
                  {!prop.isImage ? <i className={prop.icon} /> : <img src={prop.icon} className='side-menu-img'></img>}
                  <p>{props.rtlActive ? prop.rtlName : prop.name}</p>
                </NavLink>
              </li>
            );
          })}
        </Nav>
      </div>
    </div>
  );
};
// }

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: 'primary',
  routes: [{}]
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(['primary', 'blue', 'green']),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string
  })
};

export default Sidebar;
