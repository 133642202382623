import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Table,
  FormGroup,
  Input,
} from "reactstrap";
import { deepClone } from "../../../../helper-methods";

class LabNetworkListModal extends Component {
  state = { 
    searchTerm:'',
    networkList:[],
    networkListBackup:[]
  }

  _handleOnSearch = (value)=>{
    this.setState({ searchTerm: value });
    const { networkListBackup } = deepClone(this.state);
    let networkList = deepClone(networkListBackup);
    if(value.trim().length){
      networkList = networkList.filter((each)=>{
        return (
          each.name.toLowerCase().includes(value.toLowerCase()) ||
          each.description.toLowerCase().includes(value.toLowerCase()) ||
          each.networkType.toLowerCase().includes(value.toLowerCase()) ||
          each.vmState.toLowerCase().includes(value.toLowerCase())
        )
      })
      this.setState({ networkList });
    } else {
      this.setState({ networkList: networkListBackup });
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.isOpen!==this.props.isOpen){
      if(this.props.data){
        this.setState({ 
          searchTerm: '',
          networkList: this.props.data,
          networkListBackup: this.props.data
         });
      }
    }
  }
  render() { 
    const { networkList, searchTerm } = deepClone(this.state);    
    return (  <Modal
      isOpen={this.props.isOpen}
      toggle={() => this.props.toggle()}
      className="modal-lg"
    >
      <ModalHeader toggle={() => this.props.toggle()}>
        Network List
      </ModalHeader>
      <ModalBody>
        <FormGroup className="d-flex justify-content-end">
          <Input type="text" placeholder="Search..." style={{ width: 240 }} value={searchTerm} onChange={(e)=>this._handleOnSearch(e.target.value)} />
        </FormGroup>

        {networkList.length>0 ? <Table className="tablesorter tableInModal" responsive>
          <thead>
            <tr>
              <th>Network Name</th>
              <th>Description</th>
              <th>Type</th>
              {/* <th>Icon</th> */}
              <th>State</th>
            </tr>
          </thead>
          <tbody>
            {
              React.Children.toArray(networkList.map((each, index)=>
              <tr>
              <td>
                <img src={each._icon.url} alt={"icon-image"+index} width="20" className="mr-2"/>
                {each.name}
              </td>
              <td>{each.description || 'N/A'}</td>
              <td>{each.networkType}</td>             
              <td>{each.vmState}</td>
              {/* <td>
                <Button className="viewBtn">Open</Button>
                <Button className="stopBtn ml-3">Stop</Button>
              </td> */}
            </tr>
              )                               
              )
            }
          </tbody>
        </Table>:
        <p className="text-center">No Networks Found.</p>}
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnSave" onClick={() => this.props.toggle()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>  );
  }
}
 
export default LabNetworkListModal;