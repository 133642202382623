import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { getUsersList, addMember, getTeamDetails, memberDelete } from '../../../http/http-calls';
import { ToastsStore } from 'react-toasts';

import swal from 'sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AddMemberModal extends Component {
  state = {
    member: [],
    memberBackup: [],
    selectedMember: '',
    usersData: [],
    errors: {}
  };

  //closing modal
  _closeModal = (action) => {
    console.log('in _closeModal');
  };

  componentDidMount() {
    console.log('componentDidMount', this.props);
    this._getUserList();
  }

  componentDidUpdate(previousProps, previousState) {
    console.log('componentDidMount', this.props);
    if (this.props.teamId && previousProps.isOpen !== this.props.isOpen) {
      console.log('object :', this.props.creatorData);
      this._getMemberDetail();
    }
  }

  _getUserList = () => {
    this.props.showLoader();
    getUsersList()
      .then((resp) => {
        this.setState({
          usersData: resp.users,
          totalPages: resp.totalPages,
          currentPage: resp.page
        });
        this.props.hideLoader();
      })
      .catch((err) => {
        this.props.hideLoader();
        // ToastsStore.error("User list loading failed :- " + err.reason);
        setTimeout(() => {
          swal('Error!', `User list loading failed :- ${err.reason}`, 'error');
        }, 1500);
      });
  };

  _getMemberDetail = () => {
    this.props.showLoader();
    getTeamDetails(this.props.teamId)
      .then((resp) => {
        console.log(resp.team);
        let member = resp.team._members.map((each) => {
          return { isNew: false, id: each.id };
        });

        this.setState({
          member,
          memberBackup: member
        });
        this.props.hideLoader();
      })
      .catch((err) => {
        this.props.hideLoader();
        // ToastsStore.error('User list loading failed :- ' + err.reason);
        setTimeout(() => {
          swal('Error!', `User list loading failed :- ${err.reason}`, 'error');
        }, 1500);
      });
  };

  //handling toggle of modal here
  _handleToggle = () => {
    this.setState({
      team: {
        name: '',
        description: ''
      },
      member: [],
      memberBackup: [],
      isDirty: {
        name: false
      },
      errors: {}
    });
    this.props.toggle();
    this.props.reload();
  };

  //handling input here
  _handleOnChange = (value) => {
    let data = { isNew: true, id: value };
    this.setState({
      member: [...this.state.member, data],
      selectedMember: ''
    });
  };

  _handleOnDelete = (value) => {
    let list = this.state.member.filter((each) => each.id !== value);

    console.log(list);
    this.setState({
      member: list
    });
  };
  //for validation
  _validateField = () => {
    const { team, isDirty, errors } = this.state;
    Object.keys(team).forEach((each) => {
      if (each === 'name' && isDirty.name) {
        if (!team.name.trim().length) {
          errors[each] = '*Required';
        } else if (team.name.trim().length && team.name.match(/^[a-zA-Z ]*$/) && team.name.trim().length <= 2) {
          errors[each] = 'Name should be greater than two characters';
        } else {
          delete errors[each];
          isDirty.name = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  //add new Team here
  _addMember = (e) => {
    e.preventDefault();
    let newMember = this.state.member
      // eslint-disable-next-line
      .filter((each) => each.isNew == true)
      .map((item) => item.id);
    console.log(newMember);
    let promiseArray = [];
    promiseArray =
      newMember.length &&
      newMember.map((item) =>
        addMember({
          teamId: this.props.teamId,
          userIds: [item]
        })
      );

    console.log(promiseArray);

    if (promiseArray.length > 0) {
      Promise.all(promiseArray).then(
        (resp) => {
          if (resp) {
            this.props.hideLoader();
            // ToastsStore.success('Member added successfully');
            setTimeout(() => {
              swal('Success!', 'Member added successfully', 'success');
            }, 1500);
            this.props.isOpen === true && this._handleToggle();
            this.props.reload();
          }
        },
        (error) => {
          console.log('error :', error);
          this.props.hideLoader();
          // ToastsStore.error('Member Adding to Team failed :- ' + error.reason);
          setTimeout(() => {
            swal('Error!', `Member Adding to Team failed :- ${error.reason}`, 'error');
          }, 1500);
        }
      );
    }
    this._deleteMember();

    // if (this.state.member.length !== this.state.memberBackup.length) {
    //   let memberData = {
    //     teamId: this.props.teamId,
    //     userIds: this.state.member,
    //   };
    //   this.props.showLoader();
    //   console.log(memberData);
    //   addMember(memberData)
    //     .then((resp) => {
    //       console.log(resp);
    //       this.props.hideLoader();
    //       ToastsStore.success("Team added successfully");
    //       this._handleToggle();
    //     })
    //     .catch((err) => {
    //       this.props.hideLoader();
    //       ToastsStore.error("Adding new Team failed :- " + err.reason);
    //     });
    // }
  };

  _deleteMember = () => {
    let unique = this.state.memberBackup
      .map((i) => i.id)
      // eslint-disable-next-line
      .filter((o) => this.state.member.map((i) => i.id).indexOf(o) == -1);

    console.log(unique);
    let promiseArray = [];
    promiseArray =
      unique.length &&
      unique.map((item) =>
        memberDelete({
          teamId: this.props.teamId,
          userId: item
        })
      );

    console.log(promiseArray);

    if (promiseArray.length > 0) {
      Promise.all(promiseArray).then(
        (resp) => {
          if (resp) {
            this.props.hideLoader();
            // ToastsStore.success('Member Deleted successfully');
            setTimeout(() => {
              swal('Success!', 'Member deleted successfully', 'success');
            }, 1500);
            this.props.isOpen === true && this._handleToggle();
            this.props.reload();
          }
        },
        (error) => {
          console.log('error :', error);
          this.props.hideLoader();
          // ToastsStore.error('Member deletion failed :- ' + error.reason);
          setTimeout(() => {
            swal('Error!', `Member deletion failed:- ${error.reason}`, 'error');
          }, 1500);
        }
      );
    }
    // if (member.length) {
    //   this.props.showLoader();
    //   let data = {
    //     teamId: this.props.teamId,
    //     userId: memberId,
    //   };
    //   memberDelete(data)
    //     .then((resp) => {
    //       console.log("Member delete response :- ", resp);
    //       this.props.hideLoader();
    //       ToastsStore.success("Member deleted successfully");
    //       this._handleToggle();
    //     })
    //     .catch((err) => {
    //       this.props.hideLoader();
    //       ToastsStore.error("Member deletion failed :- ", err.reason);
    //     });
    // } else {
    //   let dataList = this.state.member.filter((item) => item !== memberId);
    //   this.setState({ member: dataList });
    // }
  };
  render() {
    console.log(this.state);
    return (
      <Modal isOpen={this.props.isOpen} toggle={() => this._handleToggle()} className='modal-lg'>
        <ModalHeader toggle={() => this._handleToggle()}> Add Member</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm='12'>
              <FormGroup>
                <Label>Select member</Label>
                <Input
                  type='select'
                  placeholder='Enter Member'
                  value={this.state.selectedMember}
                  onChange={(e) => this._handleOnChange(e.target.value)}>
                  <option>Select</option>

                  {this.state.usersData &&
                    this.state.usersData.map((item) => {
                      return (
                        <option
                          value={item.id}
                          disabled={
                            this.state.member &&
                            this.state.member.filter((each) => {
                              return each.id === item.id;
                            })[0]
                              ? true
                              : false
                          }>
                          {item.name.full}
                        </option>
                      );
                    })}
                </Input>

                {/* {this.state.member &&
                  this.state.usersData&&
                  this.state.usersData
                    .filter((item) => {                     
                      return this.state.member.includes(item.id);
                    })
                    .map((each) => {
                      return (
                        <Badge color="secondary">
                          {each.name.full}
                          <Button color="link">
                            <i
                              className="fa fa-times-circle text-danger"
                              onClick={() => this._deleteMember(each.id)}
                            ></i>
                          </Button>
                        </Badge>
                      );
                    })} */}

                {this.state.usersData
                  .filter((each) => this.state.member.some((item) => item.id === each.id))
                  .map((each) => {
                    return (
                      <Badge color='secondary'>
                        {each.name.full}
                        <Button color='link'>
                          <i
                            className='fa fa-times-circle text-danger'
                            onClick={() => this._handleOnDelete(each.id)}></i>
                        </Button>
                      </Badge>
                    );
                  })}

                {console.log(
                  this.state.usersData.filter((each) => this.state.member.some((item) => item.id === each.id))
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className='modalBtnCancel' onClick={() => this._handleToggle()}>
            Cancel
          </Button>
          <Button className='modalBtnSave' onClick={(e) => this._addMember(e)}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMemberModal);
