import { useState } from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { SiVirtualbox } from 'react-icons/si';
import {
  FaDocker,
  FaNetworkWired,
  FaPlus,
  FaServer,
  FaCloud,
  FaObjectUngroup,
  FaDrawPolygon,
  FaFolder,
  FaSignOutAlt,
} from 'react-icons/fa';
import './labSidebar.css';

const actions = [
  { icon: <SiVirtualbox />, name: 'Virtual Machine' },
  { icon: <FaNetworkWired />, name: 'Virtual Network' },
  { icon: <FaDocker />, name: 'Container' },
  { icon: <FaPlus />, name: 'Add Annotation' },
  { icon: <FaServer />, name: 'All Nodes' },
  { icon: <FaCloud />, name: 'All Networks' },
  { icon: <FaObjectUngroup />, name: 'All Annotations' },
  { icon: <FaDrawPolygon />, name: 'Drawings' },
  { icon: <FaFolder />, name: 'Lab Storage' },
  { icon: <FaSignOutAlt />, name: 'Exit Lab' },
];

export default function ControlledOpenSpeedDial(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  return (
    <>
      <Box className='labSidebar'>
        {/* <Box className='labSidebar' sx={{ height: 40, transform: 'translateZ(0px)', flexGrow: 1 }}> */}
        <SpeedDial
          ariaLabel='SpeedDial controlled open example'
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          // onClose={handleClose}
          // onOpen={handleOpen}
          onClick={toggleOpen}
          open={open}>
          {props.actionMenu.map((prop, key) => (
            <SpeedDialAction
              key={key}
              icon={actions[key].icon}
              onClick={(e) => props.actionOnClick(e, prop)}
              tooltipTitle={prop.name}
            />
            // <SpeedDialAction
            //   key={action.name}
            //   icon={action.icon}
            //   tooltipTitle={action.name}
            //   onClick={() => labSidebarMenu(action.name)}
            // />
          ))}
        </SpeedDial>
      </Box>
    </>
  );
}
