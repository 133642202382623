import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DrawingBoard from 'react-drawing-board';

// import { useScreenshot } from "use-react-screenshot";
import { useScreenshot } from './screen-shot';
import Input from 'reactstrap/lib/Input';

let canvas;
let ctx;

const DrawingModal = (props) => {
  // eslint-disable-next-line
  const [image, takeScreenshot, error] = useScreenshot();
  const [drawingName, setDrawingName] = useState();

  useEffect(() => {
    if (props.isOpen && props.data) {
      if (props.isOpen) {
        // console.log('show :>> ');
        props.showLoader('Preparing drawing mode');
      }
      setTimeout(() => {
        canvas = document.getElementsByClassName('drawing-board-sketchpad-canvas')[0];
        ctx = canvas.getContext('2d');
        if (canvas && ctx) {
          // console.log('ctx :>> ', ctx);
          canvas.style.backgroundImage = `url("${props.data}")`;
          canvas.style.backgroundSize = 'contain';
          canvas.style.backgroundRepeat = 'no-repeat';
          canvas.style.backgroundPosition = 'center';
          if (document.getElementsByClassName('drawing-board-toolbar-icon').length) {
            document.getElementsByClassName('drawing-board-toolbar-icon')[10].style.display = 'none';
            document.getElementsByClassName('drawing-board-toolbar-icon')[9].style.display = 'none';
          }
        } else {
          // console.log('canvas :>> ', canvas);
        }
        props.hideLoader();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [props.isOpen, props.data]);

  const _saveImage = async (image) => {
    // console.log('image :>> ', image);
    var background = new Image();
    let ctx = image.canvas.getContext('2d');
    background.src = image.dataUrl;
    background.onload = function () {
      ctx.drawImage(background, 0, 0);
    };
    await takeScreenshot(image.canvas);
  };

  const handleOnChange = (e) => {
    console.log('e :>> ', e);
  };

  const _save = async () => {
    try {
      let res = await takeScreenshot(canvas, { scale: 4 });
      props.toggle('save', {
        dataUrl: res,
        name: drawingName
      });
    } catch (err) { }
  };
  return (
    <Modal size={'lg'} isOpen={props.isOpen} toggle={() => props.toggle('cancel')} className='full-page-modal'>
      <ModalHeader toggle={() => props.toggle('cancel')}>Drawing Mode</ModalHeader>
      <ModalBody>
        {/* {image && <img width={'100%'} src={image} alt={"Screenshot"} />} */}
        <DrawingBoard onSave={(image) => _saveImage(image)} onChange={(e) => handleOnChange(e)} />
      </ModalBody>
      <ModalFooter>
        Name:
        <Input
          type='text'
          className='drawing-name'
          placeholder='Enter drawing name'
          value={drawingName}
          onChange={(e) => setDrawingName(e.target.value)}
        />
        <Button className='modalBtnSave' onClick={_save}>
          Save
        </Button>{' '}
        <Button className='modalBtnCancel' onClick={() => props.toggle('cancel')}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DrawingModal;
