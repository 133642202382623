import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Button
} from "reactstrap";
import jwt_decode from "jwt-decode";
import { deepClone, showToast } from '../../../helper-methods';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { updateUserData } from '../../../redux/actions/user-data';
import { sendOTP, verifyOTP } from '../../../http/http-calls';
// import { Link } from 'react-router-dom';

class VerifyOTP extends Component {
  state = {
    user: {
      otp: ''
    },
    isDirty: {
      otp: false
    },
    errors: {}
  }

  componentDidMount(){
    console.log('this.props.userData :>> ', this.props.userData);
    if(this.props.userData?.token){
      const tokenData = jwt_decode(this.props.userData?.token);
      console.log('tokenData :>> ', tokenData); 
      if(!tokenData.isMFAEnabled || tokenData.isMFAVerified){
        this.props.history.push("/dashboard");
      } else {
        this._sendOTP();
      }
    } else{
      // no-token
      this.props.history.push("/login");
    }
  }

  _sendOTP = async()=>{
    try {
      await sendOTP();
    } catch (error) {
      showToast(error.reason, "error");
    }
  }

  //handling input here
  _handleOnChange = (field, value) => {
    const { user, isDirty } = this.state;
    user[field] = value;
    isDirty[field] = true;
    this.setState({ user, isDirty }, () => {
      this._validateForm();
    });
  }

  //for validation
  _validateForm = () => {
    const { user, errors, isDirty } = this.state;
    Object.keys(user).forEach((each) => {
      if (each === "otp" && isDirty.otp) {
        if (!user.otp.trim().length) {
          errors[each] = "*Required";
        } else if (
          user.otp.trim().length && (isNaN(user.otp) || user.otp.length!==6)        
        ) {
          errors[each] = "OTP shuld be a 6 digit Number";
        } else {
          delete errors[each];
          isDirty.otp = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  //reset password here
  _handleOnSubmit = (e) => {
    e.preventDefault();
    let isDirty = {
      otp: true
    }
    this.setState({ isDirty }, async() => {
      let errors = this._validateForm();
      if (!errors) {
        console.log('make api call :>> ');
        try {
         this.props.showLoader();
         let res = await verifyOTP({token: this.state.user.otp}) 
         console.log('res :>> ', res);
         this.props.updateUserData(res);    
         this.props.hideLoader();
         this.props.history.push("/dashboard");
        } catch (error) {
          this.props.hideLoader();
          showToast(error.reason, "error");
        }        
      }
    });
  }
  render() { 
    const { user,  errors } = deepClone(this.state);
    
    return ( 
      <div className="full-page login-page">

        <div className="authFormWrap">
          <Form onSubmit={(e) => this._handleOnSubmit(e)}>
            <Card className="card-white">
              <CardHeader>
                <img alt="EVOS Logo" className="companyLogo" src="../../../assets/img/evos-logo.png" style={{ marginBottom: 45 }} />

                <h1 className="card-title">Verify OTP</h1>
                <div className="otp-text">An OTP has been sent to your registered email address.</div>
              </CardHeader>
              <CardBody>
                <Label>OTP</Label>
                <FormGroup>
                  <i className="fa fa-lock formIcon"></i>
                  <Input
                    placeholder="Enter OTP"
                    type="otp"
                    value={user.otp}
                    onChange={(e) =>
                      this._handleOnChange("otp", e.target.value)
                    }
                  />
                  {errors && (
                    <div className="validation-error">
                      {errors.otp}
                    </div>
                  )}
                </FormGroup>

                <Button
                  className="btn themeBtn"
                  type="submit"
                >
                  Submit
                </Button>

                <div className="link createAccount cursor-pointer" onClick={()=>this._sendOTP()}>
                  <span>Resend OTP</span>
                </div>
              </CardBody>
            </Card>
          </Form>
        </div>

        <div className="authFooter">
          EVOS &#169; {new Date().getFullYear()}.

          <div style={{display:'none'}}>
            Powered By: <a href="https://www.logic-square.com/" target="_blank" rel="noreferrer">Logic Square</a>
          </div>
          <div>
            Powered By: <a href="http://www.gurooit.com" target="_blank" rel="noreferrer">GuROO</a>
          </div>
        </div>
      </div>
     );
  }
}
 
const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    loaderData: state.loaderData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserData: (userData) => dispatch(updateUserData(userData)),
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTP);
// export default VerifyOTP;