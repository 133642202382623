const config = {
  // ! Local Changes
  // baseUrl: 'https://c4.gurooevos.com/api/v1/',
  // mainServerUrl: 'https://c4.gurooevos.com/api/v1/',
  // socketUrl: 'https://c4.gurooevos.com/api/v1/',
  baseUrl: process.env.REACT_APP_BASE_URL || '/api/v1/',
  mainServerUrl: process.env.REACT_APP_MAIN_SERVER_URL || '/api/v1/',
  socketUrl: process.env.REACT_APP_SOCKET_URL || '/',
  // ! Local Changes

  // ~ Servre changes
  // socketUrl: '/',
  // baseUrl: '/api/v1/',
  // mainServerUrl: '/api/v1/',
  // ~ Server changes

  actionMenu: [
    {
      icon: 'fa fa-desktop',
      name: 'Virtual Machine',
      key: 'vm'
    },
    {
      icon: 'fa fa-sitemap',
      name: 'Virtual Network',
      key: 'vn'
    },
    // {
    // icon: require('../assets/img/docker-menu-icon.png').default,
    // icon: 'fa fa-circle',
    // name: 'Container',
    // key: 'container'
    // isImage: true
    // },
    {
      icon: '/assets/img/docker-menu-icon.png',
      name: 'Container',
      key: 'container',
      isImage: true
    },
    {
      icon: 'fa fa-plus',
      name: 'Add Annotation',
      key: 'add-object'
    },
    // {
    //   icon: "fa fa-gear",
    //   name: "Device Configurations",
    // },
    {
      icon: 'fa fa-server',
      name: 'All Nodes',
      key: 'nodes'
    },
    {
      // icon: "tim-icons icon-coins",
      icon: 'fa fa-cloud',
      name: 'All Networks',
      key: 'networks'
    },
    {
      // icon: "fa fa-file-text-o",
      icon: 'fa fa-object-group',
      name: 'All Annotations',
      key: 'annotations'
    },
    {
      // icon: require("../assets/img/all-annotations.png").default,
      icon: 'fa fa-pencil-square-o',
      name: 'Drawings',
      key: 'drawings',
      isImage: false
    },
    {
      icon: 'fa fa-folder',
      name: 'Lab Storage',
      key: 'lab-storage',
      isImage: false
    },
    {
      icon: 'fa fa-sign-out',
      name: 'Exit Lab',
      key: 'close'
    }
  ],
  // "internal", "private", "NAT",
  networkTypes: [
    { name: 'Private Network', value: 'bridge' },
    { name: 'Management (Cloud0)', value: 'Cloud0' },
    { name: 'Cloud1', value: 'Cloud1' },
    { name: 'Cloud2', value: 'Cloud2' },
    { name: 'Cloud3', value: 'Cloud3' },
    { name: 'Cloud4', value: 'Cloud4' },
    { name: 'Cloud5', value: 'Cloud5' },
    { name: 'Cloud6', value: 'Cloud6' },
    { name: 'Cloud7', value: 'Cloud7' },
    { name: 'Cloud8', value: 'Cloud8' },
    { name: 'Cloud9', value: 'Cloud9' }
  ],
  supportedFileFormats: ['image/jpeg', 'image/png'],
  consoleList: ['vnc', 'ssh', 'telnet', 'rdp'],
  vmKeyMap: {
    graphics: 'console',
    graphicsListen: 'rdpUsername',
    graphicsPassword: 'rdpPassword',
    cpuLimit: 'cpuLimit',
    cpu: 'cpu'
  },
  vmContextActionMenuOptions: [
    'Start',
    'Stop',
    'Edit',
    'Modify',
    'Connect(vnc)',
    'Connect(telnet)',
    'Delete',
    'Path Emulation'
  ],
  vnContextActionMenuOptions: ['Edit', 'Delete'],
  objectTypes: [
    { label: 'Picture', value: 'Picture', key: 'picture' },
    { label: 'Rectangle', value: 'Rectangle', key: 'rect' },
    { label: 'Circle', value: 'Circle', key: 'circle' },
    { label: 'Text', value: 'Text', key: 'text' },
    { label: 'Line', value: 'Line', key: 'line' }
  ],
  actionIcons: {
    Start: 'fa fa-play',
    Stop: 'fa fa-stop',
    Edit: 'fa fa-edit',
    Modify: 'fa fa-gear',
    'Connect(vnc)': 'fa fa-desktop',
    'Connect(telnet)': 'fa fa-terminal',
    Delete: 'fa fa-trash',
    'Horizontal Align': 'fa fa-arrows-h',
    'Vertical Align': 'fa fa-arrows-v',
    'Send To Back': 'fa fa-arrow-down',
    'Send To Front': 'fa fa-arrow-up',
    'Path Emulation': require('../assets/img/pulse.png').default,
    Duplicate: 'fa fa-copy',
    'Capture Packets': 'fa fa-video-camera',
    'View Live Capture': require('../assets/img/wireshark.png').default
  },
  guacamoleScaleValues: [
    {
      label: '10%',
      value: 10
    },
    { label: '20%', value: 20 },
    { label: '25%', value: 25 },
    { label: '50%', value: 50 },
    { label: '75%', value: 75 },
    { label: '100%', value: 100 }
    // { label: '200%', value: 200, },
    // { label: '300%', value: 300, },
    // { label: '400%', value: 400, },
    // {
    //   label: '500%', value: 500,
    // }
  ],
  shapeContextActionMenuOptions: [
    'Edit',
    'Delete',
    'Horizontal Align',
    'Vertical Align',
    'Send To Back',
    'Send To Front',
    'Duplicate'
  ],
  connectionLineTypes: [
    {
      label: 'Straight Line with stub',
      value: 'waypoints'
    },
    {
      label: 'Straight Line',
      value: 'line'
    },
    {
      label: 'Bezier Curve',
      value: 'bezier'
    },
    {
      label: 'Flowchart with corner radius',
      value: 'flowchart'
    }
  ],
  connectionColors: [
    {
      label: 'Grey',
      value: '#c5c5c5'
    },
    {
      label: 'Blue',
      value: '#007FFF'
    },
    {
      label: 'Red',
      value: '#FF0000'
    },
    {
      label: 'Black',
      value: '#000000'
    },
    {
      label: 'Orange',
      value: '#FF9900'
    },
    {
      label: 'Green',
      value: '#1AA260'
    },
    {
      label: 'Light Blue',
      value: '#00BCEB'
    }
  ],
  canvasMenuActions: [
    {
      icon: 'fa fa-desktop',
      name: 'Virtual Machine',
      key: 'vm'
    },
    {
      icon: 'fa fa-sitemap',
      name: 'Virtual Network',
      key: 'vn'
    },
    {
      icon: require('../assets/img/docker-menu-black-icon.png').default,
      name: 'Container',
      key: 'container',
      isImage: true
    },
    {
      icon: 'fa fa-plus',
      name: 'Add Annotation',
      key: 'add-object'
    },
    {
      icon: 'fa fa-image',
      name: 'Change Background',
      key: 'canvas-bg'
    }
  ],
  emulationProfiles: [
    {
      name: '4G LTE Good',
      icon: 'broadcast.png',
      activeIcon: 'broadcast-active.png',
      latency: '80',
      url: '/assets/img/addtc.png',
      jitter: '1',
      rate: '13000',
      packetLoss: '0'
    },
    {
      name: '4G LTE Poor',
      icon: 'broadcast.png',
      activeIcon: 'broadcast-active.png',
      latency: '120',
      url: '/assets/img/addtc.png',
      jitter: '1',
      rate: '5000',
      packetLoss: '1'
    },
    {
      name: '5G LTE',
      icon: 'broadcast.png',
      activeIcon: 'broadcast-active.png',
      url: '/assets/img/addtc.png',
      latency: '30',
      jitter: '1',
      rate: '50900',
      packetLoss: '0.2'
    },
    {
      name: 'SatCom',
      url: '/assets/img/addtc.png',
      icon: 'satellite.png',
      activeIcon: 'satellite-active.png',
      latency: '550',
      jitter: '1',
      rate: '1000',
      packetLoss: '0.2'
    },
    {
      name: 'Wifi',
      icon: 'wifi.png',
      url: '/assets/img/addtc.png',
      activeIcon: 'wifi-active.png',
      latency: '40',
      jitter: '1',
      rate: '30000',
      packetLoss: '0.2'
    },
    {
      name: 'DSL',
      icon: 'ethernet.png',
      url: '/assets/img/addtc.png',
      activeIcon: 'ethernet-active.png',
      latency: '40',
      jitter: '1',
      rate: '8000',
      packetLoss: '0.5'
    },
    {
      name: 'Custom',
      icon: 'ethernet.png',
      thumbnailImage: '/assets/img/addtc.png',
      activeIcon: 'ethernet-active.png',
      latency: '',
      jitter: '',
      rate: '',
      packetLoss: ''
    }
  ],
  vmTemplateFields: [
    {
      type: ['virsh'],
      fieldName: 'connect',
      dataType: 'String',
      variants: ['qemu:///system']
    },
    {
      type: ['virsh'],
      fieldName: 'cpu',
      dataType: 'String',
      variants: ['host']
    },
    {
      type: ['virsh'],
      fieldName: 'vcpus',
      dataType: 'Number'
    },
    {
      type: ['virsh'],
      fieldName: 'vcpusSockets',
      dataType: 'Number'
    },
    {
      type: ['virsh'],
      fieldName: 'vcpusCores',
      dataType: 'Number'
    },
    {
      type: ['virsh'],
      fieldName: 'vcpusThreads',
      dataType: 'Number'
    },
    {
      type: ['virsh'],
      fieldName: 'ram',
      dataType: 'Number'
    },
    {
      type: ['virsh'],
      fieldName: 'diskBus',
      dataType: 'String'
    },
    {
      type: ['virsh'],
      fieldName: 'diskDevice',
      dataType: 'String',
      variants: ['disk']
    },
    {
      type: ['virsh'],
      fieldName: 'diskFormat',
      dataType: 'String',
      variants: ['qcow2', 'img']
    },
    {
      type: ['virsh'],
      fieldName: 'diskSize',
      dataType: 'Number'
    },
    {
      type: ['virsh'],
      fieldName: 'diskSparse',
      dataType: 'String'
    },
    {
      type: ['virsh'],
      fieldName: 'diskCache',
      dataType: 'String',
      variants: ['none']
    },
    {
      type: ['virsh'],
      fieldName: 'secondaryDiskBus',
      dataType: 'String'
    },
    {
      type: ['virsh'],
      fieldName: 'secondaryDiskDevice',
      dataType: 'String',
      variants: ['disk']
    },
    {
      type: ['virsh'],
      fieldName: 'secondaryDiskFormat',
      dataType: 'String',
      variants: ['qcow2', 'img']
    },
    {
      type: ['virsh'],
      fieldName: 'secondaryDiskSize',
      dataType: 'Number'
    },
    {
      type: ['virsh'],
      fieldName: 'video',
      dataType: 'String',
      variants: ['cirrus', 'vga', 'qxl', 'vmvga']
    },
    {
      type: ['virsh'],
      fieldName: 'graphics',
      dataType: 'String',
      variants: ['vnc', 'none']
    },
    {
      type: ['virsh'],
      fieldName: 'graphicsPassword',
      dataType: 'String'
    },
    {
      type: ['virsh'],
      fieldName: 'graphicsListen',
      dataType: 'String'
    },
    {
      type: ['virsh'],
      fieldName: 'qemuCommandline',
      dataType: 'String'
    },
    {
      type: ['virsh'],
      fieldName: 'osType',
      dataType: 'String'
    },
    {
      type: ['virsh'],
      fieldName: 'osVariant',
      dataType: 'String'
    },
    {
      type: ['virsh'],
      fieldName: 'arch',
      dataType: 'String',
      variants: ['x86_64']
    },
    {
      type: ['virsh'],
      fieldName: 'hvmFlag',
      dataType: 'Boolean'
    },
    {
      type: ['virsh'],
      fieldName: 'importFlag',
      dataType: 'Boolean'
    },
    {
      type: ['virsh'],
      fieldName: 'noRebootFlag',
      dataType: 'Boolean'
    },
    {
      type: ['virsh'],
      fieldName: 'noAutoConsoleFlag',
      dataType: 'Boolean'
    },
    {
      type: ['virsh'],
      fieldName: 'accelerateFlag',
      dataType: 'Boolean'
    },
    {
      type: ['virsh'],
      fieldName: 'rng',
      dataType: 'String'
    },
    {
      type: ['virsh'],
      fieldName: 'virtType',
      dataType: 'String'
    },
    {
      type: ['virsh', 'docker'],
      fieldName: 'ethernets',
      dataType: 'Number'
    },
    {
      type: ['virsh'],
      fieldName: 'delay',
      dataType: 'Number'
    },
    {
      type: ['virsh'],
      fieldName: 'boot',
      dataType: 'String'
    },
    {
      type: ['virsh'],
      fieldName: 'defaultNetworkModel',
      dataType: 'String',
      variants: [
        'virtio',
        'e1000',
        'e1000',
        'e1000-82544gc',
        'e1000-82545em',
        'e1000e',
        'i82550',
        'i82551',
        'i82557a',
        'i82557b',
        'i82557c',
        'i82558a',
        'i82558b',
        'i82559a',
        'i82559b',
        'i82559c',
        'i82559er',
        'i82562',
        'i82801',
        'ne2k_pci',
        'pcnet',
        'pvrdma',
        'rtl8139',
        'tulip',
        'virtio-net-pci',
        'virtio-net-pci-non-transitional',
        'virtio-net-pci-transitional',
        'vmxnet3',
        'virtio',
        'virtio-pci'
      ]
    },
    {
      type: ['virsh'],
      fieldName: 'ethernetNames',
      dataType: 'Array'
    },
    {
      type: ['docker'],
      fieldName: 'detachFlag',
      dataType: 'Boolean'
    },
    {
      type: ['docker'],
      fieldName: 'interactiveFlag',
      dataType: 'Boolean'
    },
    {
      type: ['docker'],
      fieldName: 'ttyFlag',
      dataType: 'Boolean'
    },
    {
      type: ['docker'],
      fieldName: 'privilegedFlag',
      dataType: 'Boolean'
    },
    {
      type: ['docker'],
      fieldName: 'env',
      dataType: 'String'
    },
    {
      type: ['docker'],
      fieldName: 'startupCommand',
      dataType: 'String'
    }
  ]
};

export const osVariant_AcceptedValues = [
  'almalinux8',
  'alpinelinux3.10',
  'alpinelinux3.11',
  'alpinelinux3.12',
  'alpinelinux3.13',
  'alpinelinux3.14',
  'alpinelinux3.15',
  'alpinelinux3.5',
  'alpinelinux3.6',
  'alpinelinux3.7',
  'alpinelinux3.8',
  'alpinelinux3.9',
  'alt.p10',
  'alt.p8',
  'alt.p9',
  'alt.sisyphus',
  'alt10.0',
  'alt8.0',
  'alt8.1',
  'alt8.2',
  'alt9.0',
  'alt9.1',
  'alt9.2',
  'altlinux1.0',
  'altlinux2.0',
  'altlinux2.2',
  'altlinux2.4',
  'altlinux3.0',
  'altlinux4.0',
  'altlinux4.1',
  'altlinux5.0',
  'altlinux6.0',
  'altlinux7.0',
  'android-x86-8.1',
  'android-x86-9.0',
  'archlinux',
  'asianux-unknown',
  'asianux4.6',
  'asianux4.7',
  'asianux7.0',
  'asianux7.1',
  'asianux7.2',
  'asianux7.3',
  'asianux8.0',
  'caasp-unknown',
  'caasp1.0',
  'caasp2.0',
  'caasp3.0',
  'centos-stream8',
  'centos-stream9',
  'centos5.0',
  'centos5.1',
  'centos5.10',
  'centos5.11',
  'centos5.2',
  'centos5.3',
  'centos5.4',
  'centos5.5',
  'centos5.6',
  'centos5.7',
  'centos5.8',
  'centos5.9',
  'centos6.0',
  'centos6.1',
  'centos6.10',
  'centos6.2',
  'centos6.3',
  'centos6.4',
  'centos6.5',
  'centos6.6',
  'centos6.7',
  'centos6.8',
  'centos6.9',
  'centos7.0',
  'centos8',
  'circle-unknown',
  'circle8-unknown',
  'circle8.4',
  'circle8.5',
  'circle9-unknown',
  'circle9.0',
  'cirros0.3.0',
  'cirros0.3.1',
  'cirros0.3.2',
  'cirros0.3.3',
  'cirros0.3.4',
  'cirros0.3.5',
  'cirros0.4.0',
  'cirros0.5.0',
  'cirros0.5.1',
  'cirros0.5.2',
  'clearlinux',
  'debian1.1',
  'debian1.2',
  'debian1.3',
  'debian10',
  'debian11',
  'debian2.0',
  'debian2.1',
  'debian2.2',
  'debian3',
  'debian3.1',
  'debian4',
  'debian5',
  'debian6',
  'debian7',
  'debian8',
  'debian9',
  'debiantesting',
  'dragonflybsd1.0',
  'dragonflybsd1.0A',
  'dragonflybsd1.10.0',
  'dragonflybsd1.10.1',
  'dragonflybsd1.12.0',
  'dragonflybsd1.12.1',
  'dragonflybsd1.12.2',
  'dragonflybsd1.2.0',
  'dragonflybsd1.2.1',
  'dragonflybsd1.2.2',
  'dragonflybsd1.2.3',
  'dragonflybsd1.2.4',
  'dragonflybsd1.2.5',
  'dragonflybsd1.2.6',
  'dragonflybsd1.4.0',
  'dragonflybsd1.4.4',
  'dragonflybsd1.6.0',
  'dragonflybsd1.8.0',
  'dragonflybsd1.8.1',
  'dragonflybsd2.0.0',
  'dragonflybsd2.0.1',
  'dragonflybsd2.10.1',
  'dragonflybsd2.2.0',
  'dragonflybsd2.2.1',
  'dragonflybsd2.4.0',
  'dragonflybsd2.4.1',
  'dragonflybsd2.6.1',
  'dragonflybsd2.6.2',
  'dragonflybsd2.6.3',
  'dragonflybsd2.8.2',
  'dragonflybsd3.0.1',
  'dragonflybsd3.2.1',
  'dragonflybsd3.4.1',
  'dragonflybsd3.4.2',
  'dragonflybsd3.4.3',
  'dragonflybsd3.6.0',
  'dragonflybsd3.6.1',
  'dragonflybsd3.6.2',
  'dragonflybsd3.8.0',
  'dragonflybsd3.8.1',
  'dragonflybsd3.8.2',
  'dragonflybsd4.0.0',
  'dragonflybsd4.0.1',
  'dragonflybsd4.2.0',
  'dragonflybsd4.2.1',
  'dragonflybsd4.2.3',
  'dragonflybsd4.2.4',
  'dragonflybsd4.4.1',
  'dragonflybsd4.4.2',
  'dragonflybsd4.4.3',
  'dragonflybsd4.6.0',
  'dragonflybsd4.6.1',
  'dragonflybsd4.6.2',
  'dragonflybsd4.8.0',
  'dragonflybsd4.8.1',
  'dragonflybsd5.0.0',
  'dragonflybsd5.0.1',
  'dragonflybsd5.0.2',
  'dragonflybsd5.2.0',
  'dragonflybsd5.2.1',
  'dragonflybsd5.2.2',
  'dragonflybsd5.4.0',
  'dragonflybsd5.4.1',
  'dragonflybsd5.4.2',
  'dragonflybsd5.4.3',
  'dragonflybsd5.6',
  'elementary5.0',
  'eos3.1',
  'eos3.10',
  'eos3.2',
  'eos3.3',
  'eos3.4',
  'eos3.5',
  'eos3.6',
  'eos3.7',
  'eos3.8',
  'eos3.9',
  'eos4.0',
  'fedora-coreos-next',
  'fedora-coreos-stable',
  'fedora-coreos-testing',
  'fedora-rawhide',
  'fedora-unknown',
  'fedora1',
  'fedora10',
  'fedora11',
  'fedora12',
  'fedora13',
  'fedora14',
  'fedora15',
  'fedora16',
  'fedora17',
  'fedora18',
  'fedora19',
  'fedora2',
  'fedora20',
  'fedora21',
  'fedora22',
  'fedora23',
  'fedora24',
  'fedora25',
  'fedora26',
  'fedora27',
  'fedora28',
  'fedora29',
  'fedora3',
  'fedora30',
  'fedora31',
  'fedora32',
  'fedora33',
  'fedora34',
  'fedora35',
  'fedora4',
  'fedora5',
  'fedora6',
  'fedora7',
  'fedora8',
  'fedora9',
  'freebsd1.0',
  'freebsd10.0',
  'freebsd10.1',
  'freebsd10.2',
  'freebsd10.3',
  'freebsd10.4',
  'freebsd11.0',
  'freebsd11.1',
  'freebsd11.2',
  'freebsd11.3',
  'freebsd11.4',
  'freebsd12.0',
  'freebsd12.1',
  'freebsd12.2',
  'freebsd12.3',
  'freebsd13.0',
  'freebsd2.0',
  'freebsd2.0.5',
  'freebsd2.2.8',
  'freebsd2.2.9',
  'freebsd3.0',
  'freebsd3.2',
  'freebsd4.0',
  'freebsd4.1',
  'freebsd4.10',
  'freebsd4.11',
  'freebsd4.2',
  'freebsd4.3',
  'freebsd4.4',
  'freebsd4.5',
  'freebsd4.6',
  'freebsd4.7',
  'freebsd4.8',
  'freebsd4.9',
  'freebsd5.0',
  'freebsd5.1',
  'freebsd5.2',
  'freebsd5.2.1',
  'freebsd5.3',
  'freebsd5.4',
  'freebsd5.5',
  'freebsd6.0',
  'freebsd6.1',
  'freebsd6.2',
  'freebsd6.3',
  'freebsd6.4',
  'freebsd7.0',
  'freebsd7.1',
  'freebsd7.2',
  'freebsd7.3',
  'freebsd7.4',
  'freebsd8.0',
  'freebsd8.1',
  'freebsd8.2',
  'freebsd8.3',
  'freebsd8.4',
  'freebsd9.0',
  'freebsd9.1',
  'freebsd9.2',
  'freebsd9.3',
  'freedos1.2',
  'freenix14.2',
  'gentoo',
  'gnome-continuous-3.10',
  'gnome-continuous-3.12',
  'gnome-continuous-3.14',
  'gnome3.6',
  'gnome3.8',
  'guix-1.1',
  'guix-1.3',
  'guix-hurd-latest',
  'guix-latest',
  'haikunightly',
  'haikur1alpha1',
  'haikur1alpha2',
  'haikur1alpha3',
  'haikur1alpha4.1',
  'haikur1beta1',
  'haikur1beta2',
  'haikur1beta3',
  'hyperbola03',
  'linux2016',
  'linux2018',
  'linux2020',
  'macosx10.0',
  'macosx10.1',
  'macosx10.2',
  'macosx10.3',
  'macosx10.4',
  'macosx10.5',
  'macosx10.6',
  'macosx10.7',
  'mageia1',
  'mageia2',
  'mageia3',
  'mageia4',
  'mageia5',
  'mageia6',
  'mageia7',
  'mageia8',
  'mandrake10.0',
  'mandrake10.1',
  'mandrake10.2',
  'mandrake5.1',
  'mandrake5.2',
  'mandrake5.3',
  'mandrake6.0',
  'mandrake6.1',
  'mandrake7.0',
  'mandrake7.1',
  'mandrake7.2',
  'mandrake8.0',
  'mandrake8.1',
  'mandrake8.2',
  'mandrake9.0',
  'mandrake9.1',
  'mandrake9.2',
  'mandriva2006.0',
  'mandriva2007',
  'mandriva2007.1',
  'mandriva2008.0',
  'mandriva2008.1',
  'mandriva2009.0',
  'mandriva2009.1',
  'mandriva2010.0',
  'mandriva2010.1',
  'mandriva2010.2',
  'mandriva2011',
  'manjaro',
  'mbs1.0',
  'mes5',
  'mes5.1',
  'miraclelinux8.4',
  'msdos6.22',
  'netbsd0.8',
  'netbsd0.9',
  'netbsd1.0',
  'netbsd1.1',
  'netbsd1.2',
  'netbsd1.3',
  'netbsd1.4',
  'netbsd1.5',
  'netbsd1.6',
  'netbsd2.0',
  'netbsd3.0',
  'netbsd4.0',
  'netbsd5.0',
  'netbsd5.1',
  'netbsd6.0',
  'netbsd6.1',
  'netbsd7.0',
  'netbsd7.1',
  'netbsd7.1.1',
  'netbsd7.1.2',
  'netbsd7.2',
  'netbsd8.0',
  'netbsd8.1',
  'netbsd8.2',
  'netbsd9.0',
  'netware4',
  'netware5',
  'netware6',
  'nixos-20.03',
  'nixos-20.09',
  'nixos-21.05',
  'nixos-unknown',
  'nixos-unstable',
  'oel4.4',
  'oel4.5',
  'oel4.6',
  'oel4.7',
  'oel4.8',
  'oel4.9',
  'oel5.0',
  'oel5.1',
  'oel5.2',
  'oel5.3',
  'oel5.4',
  'ol5.10',
  'ol5.11',
  'ol5.5',
  'ol5.6',
  'ol5.7',
  'ol5.8',
  'ol5.9',
  'ol6.0',
  'ol6.1',
  'ol6.10',
  'ol6.2',
  'ol6.3',
  'ol6.4',
  'ol6.5',
  'ol6.6',
  'ol6.7',
  'ol6.8',
  'ol6.9',
  'ol7.0',
  'ol7.1',
  'ol7.2',
  'ol7.3',
  'ol7.4',
  'ol7.5',
  'ol7.6',
  'ol7.7',
  'ol7.8',
  'ol7.9',
  'ol8.0',
  'ol8.1',
  'ol8.2',
  'ol8.3',
  'ol8.4',
  'ol8.5',
  'openbsd4.2',
  'openbsd4.3',
  'openbsd4.4',
  'openbsd4.5',
  'openbsd4.8',
  'openbsd4.9',
  'openbsd5.0',
  'openbsd5.1',
  'openbsd5.2',
  'openbsd5.3',
  'openbsd5.4',
  'openbsd5.5',
  'openbsd5.6',
  'openbsd5.7',
  'openbsd5.8',
  'openbsd5.9',
  'openbsd6.0',
  'openbsd6.1',
  'openbsd6.2',
  'openbsd6.3',
  'openbsd6.4',
  'openbsd6.5',
  'openbsd6.6',
  'openbsd6.7',
  'openbsd6.8',
  'openbsd6.9',
  'openbsd7.0',
  'opensolaris2009.06',
  'opensuse-factory',
  'opensuse-unknown',
  'opensuse10.2',
  'opensuse10.3',
  'opensuse11.0',
  'opensuse11.1',
  'opensuse11.2',
  'opensuse11.3',
  'opensuse11.4',
  'opensuse12.1',
  'opensuse12.2',
  'opensuse12.3',
  'opensuse13.1',
  'opensuse13.2',
  'opensuse15.0',
  'opensuse15.1',
  'opensuse15.2',
  'opensuse15.3',
  'opensuse42.1',
  'opensuse42.2',
  'opensuse42.3',
  'opensusetumbleweed',
  'popos17.10',
  'popos18.04',
  'popos18.10',
  'popos19.04',
  'popos19.10',
  'popos20.04',
  'popos20.10',
  'pureos10',
  'pureos8',
  'pureos9',
  'rhel-atomic-7.0',
  'rhel-atomic-7.1',
  'rhel-atomic-7.2',
  'rhel-atomic-7.3',
  'rhel-atomic-7.4',
  'rhel-unknown',
  'rhel2.1',
  'rhel2.1.1',
  'rhel2.1.2',
  'rhel2.1.3',
  'rhel2.1.4',
  'rhel2.1.5',
  'rhel2.1.6',
  'rhel2.1.7',
  'rhel3',
  'rhel3.1',
  'rhel3.2',
  'rhel3.3',
  'rhel3.4',
  'rhel3.5',
  'rhel3.6',
  'rhel3.7',
  'rhel3.8',
  'rhel3.9',
  'rhel4.0',
  'rhel4.1',
  'rhel4.2',
  'rhel4.3',
  'rhel4.4',
  'rhel4.5',
  'rhel4.6',
  'rhel4.7',
  'rhel4.8',
  'rhel4.9',
  'rhel5.0',
  'rhel5.1',
  'rhel5.10',
  'rhel5.11',
  'rhel5.2',
  'rhel5.3',
  'rhel5.4',
  'rhel5.5',
  'rhel5.6',
  'rhel5.7',
  'rhel5.8',
  'rhel5.9',
  'rhel6-unknown',
  'rhel6.0',
  'rhel6.1',
  'rhel6.10',
  'rhel6.2',
  'rhel6.3',
  'rhel6.4',
  'rhel6.5',
  'rhel6.6',
  'rhel6.7',
  'rhel6.8',
  'rhel6.9',
  'rhel7-unknown',
  'rhel7.0',
  'rhel7.1',
  'rhel7.2',
  'rhel7.3',
  'rhel7.4',
  'rhel7.5',
  'rhel7.6',
  'rhel7.7',
  'rhel7.8',
  'rhel7.9',
  'rhel8-unknown',
  'rhel8.0',
  'rhel8.1',
  'rhel8.2',
  'rhel8.3',
  'rhel8.4',
  'rhel8.5',
  'rhel9-unknown',
  'rhel9.0',
  'rhl1.0',
  'rhl1.1',
  'rhl2.0',
  'rhl2.1',
  'rhl3.0.3',
  'rhl4.0',
  'rhl4.1',
  'rhl4.2',
  'rhl5.0',
  'rhl5.1',
  'rhl5.2',
  'rhl6.0',
  'rhl6.1',
  'rhl6.2',
  'rhl7',
  'rhl7.1',
  'rhl7.2',
  'rhl7.3',
  'rhl8.0',
  'rhl9',
  'rocky-unknown',
  'rocky8-unknown',
  'rocky8.4',
  'rocky8.5',
  'rocky8.6',
  'rocky9-unknown',
  'rocky9.0',
  'scientificlinux5.0',
  'scientificlinux5.1',
  'scientificlinux5.10',
  'scientificlinux5.11',
  'scientificlinux5.2',
  'scientificlinux5.3',
  'scientificlinux5.4',
  'scientificlinux5.5',
  'scientificlinux5.6',
  'scientificlinux5.7',
  'scientificlinux5.8',
  'scientificlinux5.9',
  'scientificlinux6.0',
  'scientificlinux6.1',
  'scientificlinux6.10',
  'scientificlinux6.2',
  'scientificlinux6.3',
  'scientificlinux6.4',
  'scientificlinux6.5',
  'scientificlinux6.6',
  'scientificlinux6.7',
  'scientificlinux6.8',
  'scientificlinux6.9',
  'scientificlinux7-unknown',
  'scientificlinux7.0',
  'scientificlinux7.1',
  'scientificlinux7.2',
  'scientificlinux7.3',
  'scientificlinux7.4',
  'scientificlinux7.5',
  'scientificlinux7.6',
  'silverblue-rawhide',
  'silverblue-unknown',
  'silverblue28',
  'silverblue29',
  'silverblue30',
  'silverblue31',
  'silverblue32',
  'silverblue33',
  'silverblue34',
  'silverblue35',
  'slackware-current',
  'slackware14.2',
  'sle-unknown',
  'sle15',
  'sle15-unknown',
  'sle15sp1',
  'sle15sp2',
  'sle15sp3',
  'sled10',
  'sled10sp1',
  'sled10sp2',
  'sled10sp3',
  'sled10sp4',
  'sled11',
  'sled11sp1',
  'sled11sp2',
  'sled11sp3',
  'sled11sp4',
  'sled12',
  'sled12-unknown',
  'sled12sp1',
  'sled12sp2',
  'sled12sp3',
  'sled12sp4',
  'sled12sp5',
  'sled9',
  'slem5.0',
  'sles10',
  'sles10sp1',
  'sles10sp2',
  'sles10sp3',
  'sles10sp4',
  'sles11',
  'sles11sp1',
  'sles11sp2',
  'sles11sp3',
  'sles11sp4',
  'sles12',
  'sles12-unknown',
  'sles12sp1',
  'sles12sp2',
  'sles12sp3',
  'sles12sp4',
  'sles12sp5',
  'sles9',
  'solaris10',
  'solaris11',
  'solaris9',
  'trisquel9',
  'ubuntu10.04',
  'ubuntu10.10',
  'ubuntu11.04',
  'ubuntu11.10',
  'ubuntu12.04',
  'ubuntu12.10',
  'ubuntu13.04',
  'ubuntu13.10',
  'ubuntu14.04',
  'ubuntu14.10',
  'ubuntu15.04',
  'ubuntu15.10',
  'ubuntu16.04',
  'ubuntu16.10',
  'ubuntu17.04',
  'ubuntu17.10',
  'ubuntu18.04',
  'ubuntu18.10',
  'ubuntu19.04',
  'ubuntu19.10',
  'ubuntu20.04',
  'ubuntu20.10',
  'ubuntu21.04',
  'ubuntu21.10',
  'ubuntu22.04',
  'ubuntu4.10',
  'ubuntu5.04',
  'ubuntu5.10',
  'ubuntu6.06',
  'ubuntu6.10',
  'ubuntu7.04',
  'ubuntu7.10',
  'ubuntu8.04',
  'ubuntu8.10',
  'ubuntu9.04',
  'ubuntu9.10',
  'unknown',
  'voidlinux',
  'win1.0',
  'win10',
  'win2.0',
  'win2.1',
  'win2k',
  'win2k12',
  'win2k12r2',
  'win2k16',
  'win2k19',
  'win2k22',
  'win2k3',
  'win2k3r2',
  'win2k8',
  'win2k8r2',
  'win3.1',
  'win7',
  'win8',
  'win8.1',
  'win95',
  'win98',
  'winme',
  'winnt3.1',
  'winnt3.5',
  'winnt3.51',
  'winnt4.0',
  'winvista',
  'winxp'
];

// ! Default Icons Array
export const defaultIcons = [
  // 'APIC-Black_1-1.png',
  // 'APIC-Black_1-2.png',
  // 'APIC-Blue_1-1.png',
  // 'APIC-Blue_1-2.png',
  // 'APIC-Green_1-1.png',
  // 'APIC-Green_1-2.png',
  // 'APIC-Grey_1-1.png',
  // 'APIC-Grey_1-2.png',
  // 'APIC-Red_1-1.png',
  // 'APIC-Red_1-2.png'
  // 'APIC-Sea-Blue_1-1.png',
  // 'APIC-Sea-Blue_1-2.png',
  // 'APIC-White_1-1.png',
  // 'APIC-White_1-2.png',
  // 'ASA-Black_1-1.png',
  // 'ASA-Black_1-2.png',
  // 'ASA-Blue_1-1.png',
  // 'ASA-Blue_1-2.png',
  // 'ASA-Green_1-1.png',
  // 'ASA-Green_1-2.png',
  // 'ASA-Grey_1-1.png',
  // 'ASA-Grey_1-2.png',
  // 'ASA-Red_1-1.png',
  // 'ASA-Red_1-2.png',
  // 'ASA-Sea-Blue_1-1.png',
  // 'ASA-Sea-Blue_1-2.png',
  // 'ASA-White_1-1.png',
  // 'ASA-White_1-2.png',
  // 'ASR-Black.png',
  // 'ASR-Blue.png',
  // 'ASR-Green.png',
  // 'ASR-Grey.png',
  // 'ASR-Red.png',
  // 'ASR-Sea-Blue.png',
  // 'ASR-White.png',
  // 'BB-Router-Black.png',
  // 'BB-Router-Blue.png',
  // 'BB-Router-Green.png',
  // 'BB-Router-Grey.png',
  // 'BB-Router-Red.png',
  // 'BB-Router-Sea-Blue.png',
  // 'BB-Router-White.png',
  // 'Cisco Unified Contact Center-Black_1-1.png',
  // 'Cisco Unified Contact Center-Black_1-2.png',
  // 'Cisco Unified Contact Center-Blue_1-1.png',
  // 'Cisco Unified Contact Center-Blue_1-2.png',
  // 'Cisco Unified Contact Center-Green_1-1.png',
  // 'Cisco Unified Contact Center-Green_1-2.png',
  // 'Cisco Unified Contact Center-Grey_1-1.png',
  // 'Cisco Unified Contact Center-Grey_1-2.png',
  // 'Cisco Unified Contact Center-Red_1-1.png',
  // 'Cisco Unified Contact Center-Red_1-2.png',
  // 'Cisco Unified Contact Center-Sea-Blue_1-1.png',
  // 'Cisco Unified Contact Center-Sea-Blue_1-2.png',
  // 'Cisco Unified Contact Center-White_1-1.png',
  // 'Cisco Unified Contact Center-White_1-2.png',
  // 'Cisco Video Communications Server Control-Black1-1.png',
  // 'Cisco Video Communications Server Control-Black1-2.png',
  // 'Cisco Video Communications Server Control-Blue1-1.png',
  // 'Cisco Video Communications Server Control-Blue1-2.png',
  // 'Cisco Video Communications Server Control-Green1-1.png',
  // 'Cisco Video Communications Server Control-Green1-2.png',
  // 'Cisco Video Communications Server Control-Grey1-1.png',
  // 'Cisco Video Communications Server Control-Grey1-2.png',
  // 'Cisco Video Communications Server Control-Red1-1.png',
  // 'Cisco Video Communications Server Control-Red1-2.png',
  // 'Cisco Video Communications Server Control-Sea-Blue1-1.png',
  // 'Cisco Video Communications Server Control-Sea-Blue1-2.png',
  // 'Cisco Video Communications Server Control-White1-1.png',
  // 'Cisco Video Communications Server Control-White1-2.png',
  // 'Cisco Video Communications Server Expressway-Black-1-1.png',
  // 'Cisco Video Communications Server Expressway-Black-1-2.png',
  // 'Cisco Video Communications Server Expressway-Blue-1-1.png',
  // 'Cisco Video Communications Server Expressway-Blue-1-2.png',
  // 'Cisco Video Communications Server Expressway-Green-1-1.png',
  // 'Cisco Video Communications Server Expressway-Green-1-2.png',
  // 'Cisco Video Communications Server Expressway-Grey-1-1.png',
  // 'Cisco Video Communications Server Expressway-Grey-1-2.png',
  // 'Cisco Video Communications Server Expressway-Red-1-1.png',
  // 'Cisco Video Communications Server Expressway-Red-1-2.png',
  // 'Cisco Video Communications Server Expressway-Sea-Blue-1-1.png',
  // 'Cisco Video Communications Server Expressway-Sea-Blue-1-2.png',
  // 'Cisco Video Communications Server Expressway-White-1-1.png',
  // 'Cisco Video Communications Server Expressway-White-1-2.png',
  // 'Cisco Webex Meeting-Black_1-1_WS.png',
  // 'Cisco Webex Meeting-Black_1-2_WS.png',
  // 'Cisco Webex Meeting-Blue_1-1_WS.png',
  // 'Cisco Webex Meeting-Blue_1-2_WS.png',
  // 'Cisco Webex Meeting-Green_1-1_WS.png',
  // 'Cisco Webex Meeting-Green_1-2_WS.png',
  // 'Cisco Webex Meeting-Grey_1-1_WS.png',
  // 'Cisco Webex Meeting-Grey_1-2_WS.png',
  // 'Cisco Webex Meeting-Red_1-1_WS.png',
  // 'Cisco Webex Meeting-Red_1-2_WS.png',
  // 'Cisco Webex Meeting-Sea-Blue_1-1_WS.png',
  // 'Cisco Webex Meeting-Sea-Blue_1-2_WS.png',
  // 'Cisco Webex Meeting-White_1-1_WS.png',
  // 'Cisco Webex Meeting-White_1-2_WS.png',
  // 'cloud.png',
  // 'CUCM-Black_1-1.png',
  // 'CUCM-Black_1-2.png',
  // 'CUCM-Blue_1-1.png',
  // 'CUCM-Blue_1-2.png',
  // 'CUCM-Green_1-1.png',
  // 'CUCM-Green_1-2.png',
  // 'CUCM-Grey_1-1.png',
  // 'CUCM-Grey_1-2.png',
  // 'CUCM-Red_1-1.png',
  // 'CUCM-Red_1-2.png',
  // 'CUCM-Sea-Blue_1-1.png',
  // 'CUCM-Sea-Blue_1-2.png',
  // 'CUCM-White_1-1.png',
  // 'CUCM-White_1-2.png',
  // 'DNAC.png',
  // 'F.I-Black_1-1.png',
  // 'F.I-Black_1-2.png',
  // 'F.I-Blue_1-1.png',
  // 'F.I-Blue_1-2.png',
  // 'F.I-Green_1-1.png',
  // 'F.I-Green_1-2.png',
  // 'F.I-Grey_1-1.png',
  // 'F.I-Grey_1-2.png',
  // 'F.I-Red_1-1.png',
  // 'F.I-Red_1-2.png',
  // 'F.I-Sea-Blue_1-1.png',
  // 'F.I-Sea-Blue_1-2.png',
  // 'F.I-White_1-1.png',
  // 'F.I-White_1-2.png',
  // 'FC-Director-Black_1-1.png',
  // 'FC-Director-Black_1-2.png',
  // 'FC-Director-Blue_1-1.png',
  // 'FC-Director-Blue_1-2.png',
  // 'FC-Director-Green_1-1.png',
  // 'FC-Director-Green_1-2.png',
  // 'FC-Director-Grey_1-1.png',
  // 'FC-Director-Grey_1-2.png',
  // 'FC-Director-Red_1-1.png',
  // 'FC-Director-Red_1-2.png',
  // 'FC-Director-Sea-Blue_1-1.png',
  // 'FC-Director-Sea-Blue_1-2.png',
  // 'FC-Director-White_1-1.png',
  // 'FC-Director-White_1-2.png',
  // 'FC-Disk-Black_1-1.png',
  // 'FC-Disk-Black_1-2.png',
  // 'FC-Disk-Blue_1-1 copy.png',
  // 'FC-Disk-Blue_1-2.png',
  // 'FC-Disk-Green_1-1.png',
  // 'FC-Disk-Green_1-2.png',
  // 'FC-Disk-Grey_1-1 copy.png',
  // 'FC-Disk-Grey_1-2.png',
  // 'FC-Disk-Sea-Blue_1-1.png',
  // 'FC-Disk-Sea-Blue_1-2.png',
  // 'FC-Disk-White_1-1.png',
  // 'FC-Disk-White_1-2.png',
  // 'FC-DiskRed_1-1.png',
  // 'FC-DiskRed_1-2.png',
  // 'Fibre-Channel-Storage-Black_1-1.png',
  // 'Fibre-Channel-Storage-Black_1-2.png',
  // 'Fibre-Channel-Storage-Blue_1-1.png',
  // 'Fibre-Channel-Storage-Blue_1-2.png',
  // 'Fibre-Channel-Storage-Green_1-1.png',
  // 'Fibre-Channel-Storage-Green_1-2.png',
  // 'Fibre-Channel-Storage-Grey_1-1.png',
  // 'Fibre-Channel-Storage-Grey_1-2.png',
  // 'Fibre-Channel-Storage-Red_1-1.png',
  // 'Fibre-Channel-Storage-Red_1-2.png',
  // 'Fibre-Channel-Storage-Sea-Blue_1-1.png',
  // 'Fibre-Channel-Storage-Sea-Blue_1-2.png',
  // 'Fibre-Channel-Storage-White_1-1.png',
  // 'Fibre-Channel-Storage-White_1-2.png',
  // 'Fibre-Channel-Switch-Black_1-1.png',
  // 'Fibre-Channel-Switch-Black_1-2.png',
  // 'Fibre-Channel-Switch-Blue_1-1.png',
  // 'Fibre-Channel-Switch-Blue_1-2.png',
  // 'Fibre-Channel-Switch-Green_1-1.png',
  // 'Fibre-Channel-Switch-Green_1-2.png',
  // 'Fibre-Channel-Switch-Grey_1-1.png',
  // 'Fibre-Channel-Switch-Grey_1-2.png',
  // 'Fibre-Channel-Switch-Red_1-1.png',
  // 'Fibre-Channel-Switch-Red_1-2.png',
  // 'Fibre-Channel-Switch-Sea-Blue_1-1.png',
  // 'Fibre-Channel-Switch-Sea-Blue_1-2.png',
  // 'Fibre-Channel-Switch-White_1-1.png',
  // 'Fibre-Channel-Switch-White_1-2.png',
  // 'Flow-Collector.png',
  // 'ISE_3D LDAP-AD.png',
  // 'ISE_ISE.png',
  // 'ISE_MDM.png',
  // 'ISE_MnT.png',
  // 'ISE_PAN.png',
  // 'ISE_PSN.png',
  // 'ISE_pXGRID.png',
  // 'ISE_Security-Services3D.png',
  // 'ISE_SXP.png',
  // 'ISE_VPN-Gateway3D.png',
  // 'ISE_VPN.png',
  // 'ISE_Wired.png',
  // 'ISE_Wireless.png',
  // 'Kali Linux Laptop.png',
  // 'L3 Switch-Black_1-1.png',
  // 'L3 Switch-Black_1-2.png',
  // 'L3 Switch-Blue_1-1.png',
  // 'L3 Switch-Blue_1-2.png',
  // 'L3 Switch-Green_1-1.png',
  // 'L3 Switch-Green_1-2.png',
  // 'L3 Switch-Grey_1-1.png',
  // 'L3 Switch-Grey_1-2.png',
  // 'L3 Switch-Red_1-1.png',
  // 'L3 Switch-Red_1-2.png',
  // 'L3 Switch-Sea-Blue_1-1.png',
  // 'L3 Switch-Sea-Blue_1-2.png',
  // 'L3 Switch-White_1-1.png',
  // 'L3 Switch-White_1-2.png',
  // 'laptop.png',
  // 'Management-Console3D.png',
  // 'N9300-Black_1-1.png',
  // 'N9300-Black_1-2.png',
  // 'N9300-Blue_1-1.png',
  // 'N9300-Blue_1-2.png',
  // 'N9300-Green_1-1.png',
  // 'N9300-Green_1-2.png',
  // 'N9300-Grey_1-1.png',
  // 'N9300-Grey_1-2.png',
  // 'N9300-Red_1-1.png',
  // 'N9300-Red_1-2.png',
  // 'N9300-Sea-Blue_1-1.png',
  // 'N9300-Sea-Blue_1-2.png',
  // 'N9300-White_1-1.png',
  // 'N9300-White_1-2.png',
  // 'N9500-Black_1-1.png',
  // 'N9500-Black_1-2.png',
  // 'N9500-Blue_1-1.png',
  // 'N9500-Blue_1-2.png',
  // 'N9500-Green_1-1.png',
  // 'N9500-Green_1-2.png',
  // 'N9500-Grey_1-1.png',
  // 'N9500-Grey_1-2.png',
  // 'N9500-Red_1-1.png',
  // 'N9500-Red_1-2.png',
  // 'N9500-Sea-Blue_1-1.png',
  // 'N9500-Sea-Blue_1-2.png',
  // 'N9500-White_1-1.png',
  // 'N9500-White_1-2.png',
  // 'N9K-NXOS-Black_1-1.png',
  // 'N9K-NXOS-Black_1-2.png',
  // 'N9K-NXOS-Blue_1-1.png',
  // 'N9K-NXOS-Blue_1-2.png',
  // 'N9K-NXOS-Green_1-1.png',
  // 'N9K-NXOS-Green_1-2.png',
  // 'N9K-NXOS-Grey_1-1.png',
  // 'N9K-NXOS-Grey_1-2.png',
  // 'N9K-NXOS-Red_1-1.png',
  // 'N9K-NXOS-Red_1-2.png',
  // 'N9K-NXOS-Sea-Blue_1-1.png',
  // 'N9K-NXOS-Sea-Blue_1-2.png',
  // 'N9K-NXOS-White_1-1.png',
  // 'N9K-NXOS-White_1-2.png',
  // 'NCS-Black_1-1.png',
  // 'NCS-Black_1-2.png',
  // 'NCS-Blue_1-1.png',
  // 'NCS-Blue_1-2.png',
  // 'NCS-Green_1-1.png',
  // 'NCS-Green_1-2.png',
  // 'NCS-Grey_1-1.png',
  // 'NCS-Grey_1-2.png',
  // 'NCS-Red_1-1.png',
  // 'NCS-Red_1-2.png',
  // 'NCS-Sea-Blue_1-1.png',
  // 'NCS-Sea-Blue_1-2.png',
  // 'NCS-White_1-1.png',
  // 'NCS-White_1-2.png',
  // 'Nexus 2K-Black_1-1.png',
  // 'Nexus 2K-Black_1-2.png',
  // 'Nexus 2K-Blue_1-1.png',
  // 'Nexus 2K-Blue_1-2.png',
  // 'Nexus 2K-Green_1-1.png',
  // 'Nexus 2K-Green_1-2.png',
  // 'Nexus 2K-Grey_1-1.png',
  // 'Nexus 2K-Grey_1-2.png',
  // 'Nexus 2K-Red_1-1.png',
  // 'Nexus 2K-Red_1-2.png',
  // 'Nexus 2K-Sea-Blue_1-1.png',
  // 'Nexus 2K-Sea-Blue_1-2.png',
  // 'Nexus 2K-White_1-1.png',
  // 'Nexus 2K-White_1-2.png',
  // 'Nexus 5K-Black_1-1.png',
  // 'Nexus 5K-Black_1-2.png',
  // 'Nexus 5K-Blue_1-1.png',
  // 'Nexus 5K-Blue_1-2.png',
  // 'Nexus 5K-Green_1-1.png',
  // 'Nexus 5K-Green_1-2.png',
  // 'Nexus 5K-Grey_1-1.png',
  // 'Nexus 5K-Grey_1-2.png',
  // 'Nexus 5K-Red_1-1.png',
  // 'Nexus 5K-Red_1-2.png',
  // 'Nexus 5K-Sea-Blue_1-1.png',
  // 'Nexus 5K-Sea-Blue_1-2.png',
  // 'Nexus 5K-White_1-1.png',
  // 'Nexus 5K-White_1-2.png',
  // 'Nexus 7K-Black_1-1.png',
  // 'Nexus 7K-Black_1-2.png',
  // 'Nexus 7K-Blue_1-1.png',
  // 'Nexus 7K-Blue_1-2.png',
  // 'Nexus 7K-Green_1-1.png',
  // 'Nexus 7K-Green_1-2.png',
  // 'Nexus 7K-Grey_1-1.png',
  // 'Nexus 7K-Grey_1-2.png',
  // 'Nexus 7K-Red_1-1.png',
  // 'Nexus 7K-Red_1-2.png',
  // 'Nexus 7K-Sea-Blue_1-1.png',
  // 'Nexus 7K-Sea-Blue_1-2.png',
  // 'Nexus 7K-White_1-1.png',
  // 'Nexus 7K-White_1-2.png',
  // 'PIX_Black.png',
  // 'PIX_Blue.png',
  // 'PIX_Green.png',
  // 'PIX_Grey.png',
  // 'PIX_Red.png',
  // 'PIX_Sea-Blue.png',
  // 'PIX_White.png',
  // 'Prime-Server-1-1.png',
  // 'Prime-Server-1-2.png',
  // 'Prime-server2-1-1.png',
  // 'Prime-server2-1-2.png',
  // 'Router Silver.png',
  // 'Router-FW-Black.png',
  // 'Router-FW-Blue.png',
  // 'Router-FW-Green.png',
  // 'Router-FW-Grey.png',
  // 'Router-FW-Red.png',
  // 'Router-FW-Sea-Blue.png',
  // 'Router-FW-White.png',
  // 'Router_Black.png',
  // 'Router_Blue.png',
  // 'Router_Green.png',
  // 'Router_Grey.png',
  // 'Router_Red.png',
  // 'Router_Sea-Blue.png',
  // 'Router_White.png',
  // 'RSP-Black_1-1.png',
  // 'RSP-Black_1-2.png',
  // 'RSP-Blue_1-1.png',
  // 'RSP-Blue_1-2.png',
  // 'RSP-Green_1-1.png',
  // 'RSP-Green_1-2.png',
  // 'RSP-Grey_1-1.png',
  // 'RSP-Grey_1-2.png',
  // 'RSP-Red_1-1.png',
  // 'RSP-Red_1-2.png',
  // 'RSP-Sea-Blue_1-1.png',
  // 'RSP-Sea-Blue_1-2.png',
  // 'RSP-White_1-1.png',
  // 'RSP-White_1-2.png',
  // 'Security_AMP For End Points.png',
  // 'Security_Cisco-FirePower.png',
  // 'Security_Cisco-NGFW.png',
  // 'Security_Cisco-Threat-Grid.png',
  // 'Security_Email Security.png',
  // 'Security_WSA.png',
  // 'Server Farm.png',
  // 'Switch-Black_1-1.png',
  // 'Switch-Black_1-2.png',
  // 'Switch-Blue_1-1.png',
  // 'Switch-Blue_1-2.png',
  // 'Switch-Green_1-1.png',
  // 'Switch-Green_1-2.png',
  // 'Switch-Grey_1-1.png',
  // 'Switch-Grey_1-2.png',
  // 'Switch-Red_1-1.png',
  // 'Switch-Red_1-2.png',
  // 'Switch-Sea-Blue_1-1.png',
  // 'Switch-Sea-Blue_1-2.png',
  // 'Switch-White_1-1.png',
  // 'Switch-White_1-2.png',
  // 'Telepresence-1000-1-1.png',
  // 'Telepresence-1000-1-2.png',
  // 'Ubuntu Desktop.png',
  // 'UCS-Chassis-Off-1-1.png',
  // 'UCS-Chassis-Off-1-2.png',
  // 'UCS-Chassis-On-1-1.png',
  // 'UCS-Chassis-On-1-2.png',
  // 'vAnylatics.png',
  // 'vBond.png',
  // 'vEdge.png',
  // 'vManage.png',
  // 'vSmart.png',
  // 'VSS-Black_1-1.png',
  // 'VSS-Black_1-2.png',
  // 'VSS-Blue_1-1.png',
  // 'VSS-Blue_1-2.png',
  // 'VSS-Green_1-1.png',
  // 'VSS-Green_1-2.png',
  // 'VSS-Grey_1-1.png',
  // 'VSS-Grey_1-2.png',
  // 'VSS-Red_1-1.png',
  // 'VSS-Red_1-2.png',
  // 'VSS-Sea-Blue_1-1.png',
  // 'VSS-Sea-Blue_1-2.png',
  // 'VSS-White_1-1.png',
  // 'VSS-White_1-2.png',
  // 'Windows Laptop.png',
  // 'Wireless-AP-Black_1-1.png',
  // 'Wireless-AP-Black_1-2.png',
  // 'Wireless-AP-Blue_1-1.png',
  // 'Wireless-AP-Blue_1-2.png',
  // 'Wireless-AP-Green_1-1.png',
  // 'Wireless-AP-Green_1-2.png',
  // 'Wireless-AP-Grey_1-1.png',
  // 'Wireless-AP-Grey_1-2.png',
  // 'Wireless-AP-Red_1-1.png',
  // 'Wireless-AP-Red_1-2.png',
  // 'Wireless-AP-Sea-Blue_1-1.png',
  // 'Wireless-AP-Sea-Blue_1-2.png',
  // 'Wireless-AP-White_1-1.png',
  // 'Wireless-AP-White_1-2.png',
  // 'Wireless-Bridge-Black_1-1.png',
  // 'Wireless-Bridge-Black_1-2.png',
  // 'Wireless-Bridge-Blue_1-1.png',
  // 'Wireless-Bridge-Blue_1-2.png',
  // 'Wireless-Bridge-Green_1-1.png',
  // 'Wireless-Bridge-Green_1-2.png',
  // 'Wireless-Bridge-Grey_1-1.png',
  // 'Wireless-Bridge-Grey_1-2.png',
  // 'Wireless-Bridge-Red_1-1.png',
  // 'Wireless-Bridge-Red_1-2.png',
  // 'Wireless-Bridge-Sea-Blue_1-1.png',
  // 'Wireless-Bridge-Sea-Blue_1-2.png',
  // 'Wireless-Bridge-White_1-1.png',
  // 'Wireless-Bridge-White_1-2.png',
  // 'Wireless_Router-Black.png',
  // 'Wireless_Router-Black2.png',
  // 'Wireless_Router-Blue.png',
  // 'Wireless_Router-Blue2.png',
  // 'Wireless_Router-Green.png',
  // 'Wireless_Router-Green2.png',
  // 'Wireless_Router-Grey.png',
  // 'Wireless_Router-Grey2.png',
  // 'Wireless_Router-Red.png',
  // 'Wireless_Router-Red2.png',
  // 'Wireless_Router-Sea-Blue.png',
  // 'Wireless_Router-Sea-Blue2.png',
  // 'Wireless_Router-White.png',
  // 'Wireless_Router-White2.png',
  // 'WLC-Black_1-1.png',
  // 'WLC-Black_1-2.png',
  // 'WLC-Blue_1-1.png',
  // 'WLC-Blue_1-2.png',
  // 'WLC-Green_1-1.png',
  // 'WLC-Green_1-2.png',
  // 'WLC-Grey_1-1.png',
  // 'WLC-Grey_1-2.png',
  // 'WLC-Red_1-1.png',
  // 'WLC-Red_1-2.png',
  // 'WLC-Sea-Blue_1-1.png',
  // 'WLC-Sea-Blue_1-2.png',
  // 'WLC-White_1-1.png',
  // 'WLC-White_1-2.png'
];
// ! Default Icons Array
export default Object.seal(config);
