import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Input, Label, FormGroup } from 'reactstrap';
import { addAsset, editAsset } from '../../../http/http-calls';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { ToastsStore } from 'react-toasts';
import { deepClone, showToast } from '../../../helper-methods';

import swal from 'sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AddEditAssetModal extends Component {
  state = {
    asset: {
      name: '',
      description: '',
      isIcon: false
    },
    isDirty: {
      name: false,
      description: false
    },
    errors: {},
    selectedFile: null,
    previewImg: null
  };

  _closeModal = (action) => {
    console.log('in _closeModal');
  };

  componentDidMount() {
    console.log('componentDidMount', this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      let state = {
        asset: {
          name: this.props.data ? this.props.data.name : '',
          description: this.props.data ? this.props.data.description : '',
          isIcon: this.props.data?.isIcon || false
        },
        isDirty: {
          name: false,
          description: false
        },
        errors: {},
        selectedFile: null,
        previewImg: this.props.data ? this.props.data.url : ''
      };
      this.setState(state);
    }
  }

  _handleToggle = () => {
    this.setState({
      asset: {
        name: '',
        description: '',
        isIcon: false
      },
      isDirty: {
        name: false,
        description: false
      },
      errors: {},
      selectedFile: null,
      previewImg: null
    });
    this.props.toggle();
    this.props.reload();
  };

  _handleOnChange = (field, value) => {
    const { asset, isDirty } = this.state;
    if (field === 'selectedFile' && value.target.files?.[0]) {
      this.setState({
        selectedFile: value.target.files[0],
        previewImg: URL.createObjectURL(value.target.files[0])
      });
    } else if (field === 'isIcon') {
      asset[field] = value;
    } else {
      asset[field] = value;
      isDirty[field] = true;
    }
    this.setState({ asset, isDirty }, () => {
      this._validateField();
    });
  };

  _validateField = () => {
    const { asset, isDirty, errors } = this.state;
    Object.keys(asset).forEach((each) => {
      if (each === 'name' && isDirty.name) {
        if (!asset.name.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.name = false;
        }
      } else if (each === 'description' && isDirty.description) {
        if (!asset.description.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.description = false;
        }
      }
    });
    if (
      this.props.type === 'add' &&
      (!this.state.selectedFile ||
        !(
          this.state.selectedFile.name.split('.')[this.state.selectedFile.name.split('.').length - 1] === 'png' ||
          this.state.selectedFile.name.split('.')[this.state.selectedFile.name.split('.').length - 1] === 'gif' ||
          this.state.selectedFile.name.split('.')[this.state.selectedFile.name.split('.').length - 1] === 'jpeg' ||
          this.state.selectedFile.name.split('.')[this.state.selectedFile.name.split('.').length - 1] === 'jpg'
        ))
    ) {
      errors.selectedFile = 'Select an image file (.png, .gif, .jpg or .jpeg)';
    } else {
      delete errors.selectedFile;
    }
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  _handleOnSubmit = (e) => {
    e.preventDefault();
    let isDirty = {
      name: true,
      description: true
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateField();
      if (!errors) {
        if (this.props.type === 'add') {
          this._addAsset();
        } else {
          this._editAsset();
        }
      }
    });
  };

  _addAsset = () => {
    const assetData = new FormData();
    assetData.append('assetFile', this.state.selectedFile);
    assetData.append('name', this.state.asset.name);
    assetData.append('description', this.state.asset.description);
    assetData.append('isIcon', this.state.asset.isIcon);
    this.props.showLoader();
    addAsset(assetData)
      .then((resp) => {
        console.log('Add asset response here :- ', resp);
        this.props.hideLoader();
        // ToastsStore.success("Asset added successfully");
        setTimeout(() => {
          swal('Success!', 'Asset added successfully', 'success');
        }, 1500);
        this.props.toggle('save');
      })
      .catch((err) => {
        this.props.hideLoader();
        // ToastsStore.error('Adding asset failed :- ' + err.reason);
        setTimeout(() => {
          swal('Error!', `Adding asset failed:- ${err.reason}`, 'error');
        }, 1500);
      });
  };

  _editAsset = async () => {
    try {
      this.props.showLoader();
      let res = await editAsset(this.props.data.id, this.state.asset);
      console.log('res :>> ', res);
      this.props.hideLoader();
      this.props.toggle('save');
      showToast('Asset updated successfully', 'success');
    } catch (error) {
      this.props.hideLoader();
      console.log('error :>> ', error);
      showToast(error.reason, 'error');
    }
  };

  render() {
    const { asset, errors } = this.state;
    const { isOpen, type } = deepClone(this.props);

    return (
      <Modal isOpen={isOpen} toggle={() => this.props.toggle('cancel')}>
        <ModalHeader toggle={() => this.props.toggle('cancel')}>
          <p className='text-white'>{type === 'add' ? 'Add Asset' : 'Edit Asset'}</p>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type='text'
              placeholder='Enter name'
              value={asset.name}
              onChange={(e) => this._handleOnChange('name', e.target.value)}
            />
            {errors && <div className='validation-error'>{errors.name}</div>}
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <Input
              type='textarea'
              rows='3'
              placeholder='Enter description'
              value={asset.description}
              onChange={(e) => this._handleOnChange('description', e.target.value)}
            />
            {errors && <div className='validation-error'>{errors.description}</div>}
          </FormGroup>
          <FormGroup className='pl-20'>
            <Input
              type='checkbox'
              id='isIcon'
              rows='3'
              placeholder='Enter description'
              //   value={asset.isIcon}
              checked={asset.isIcon}
              onChange={(e) => this._handleOnChange('isIcon', e.target.checked)}
            />
            <Label for='isIcon' className='cursor-pointer'>
              Use as Icon
            </Label>
          </FormGroup>
          {type === 'add' ? (
            <FormGroup>
              <img
                alt='Asset Img'
                className='assetImg'
                src={
                  this.state.previewImg ? this.state.previewImg : 'https://via.placeholder.com/728x190.png?text=Image'
                }
              />
              <Label>Add File</Label>
              <Input
                type='file'
                accept='image/x-png,image/gif,image/jpeg'
                onChange={(e) => this._handleOnChange('selectedFile', e)}
              />
              {errors && <div className='validation-error'>{errors.selectedFile}</div>}
            </FormGroup>
          ) : (
            <FormGroup>
              <img src={this.props?.data?.url} alt='Asset Img' className='assetImg' />
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className='modalBtnCancel' onClick={() => this.props.toggle('cancel')}>
            Cancel
          </Button>
          <Button className='modalBtnSave' onClick={(e) => this._handleOnSubmit(e)}>
            {type === 'add' ? 'Create' : 'Update'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  console.log('state :>> ', state);
  return {
    loaderData: state.loaderData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAssetModal);
