// eslint-disable-next-line
import React, { Component } from "react";
import {
  Row,
  Col,
} from "reactstrap";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import { ToastsStore } from "react-toasts";
import moment from "moment";
import { deepClone } from "../../../../helper-methods";
import StepWizard from "react-step-wizard";
// import { Paginate } from "./Pagination";
import {
  getAllFilesList,
  getAllImagesList,
  createVmTemplate,
  getAllTemplates
} from "../../../../http/http-calls";
import config from "../../../../config";
import { template } from "lodash";
import styled from "styled-components";
import StepFirst from "../template-creation-vm";
import StepTwo from "../template-images-to-show";

const StyledOption = styled.span`
  padding: 0;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  transition: all 1s ease-in;

  span {
    display: none;
    transition: all 1s ease-in;
  }

  a {
    margin: 0 5px;
  }

  :hover {
    background: #f2f2f2;

    span {
      display: inline;
      margin: 0 0 0 5px;
      color: red;
    }
  }
`;
const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;

  :hover {
    background: #f2f2f2;
  }
`;
class TemplateCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 1,
      input: false,
      template: {
        name: "",
        description: "",
        baseBin: "virsh",
        vmimage: [],
        dockertext: "",
        dockerimage: [],
        textArea: "",
        defaulticon: "",
        defaultvmimage: "",
        requiresSecondaryDisk: false,
        pictureId: [],
        active: false,
      },
      tempDefaultvalue: "",
      fieldsToShow: [],
      vmTemplates: deepClone(config.vmTemplateFields),
      isDirtyTemplate: {
        name: false,
        baseBin: false,
        vmimage: false,
        textArea: false,
        pictureId: false,
      },
      errors: {},
      currentPage: 1,
      totalPages: "",
      searchItem: "",
      imageList: [],
      iconList: [],
      selectedFile: null,
      previewImg: null,
      selectedIcons: [],
      predefinedTemplates: []
    };
  }
  componentDidMount = () => {
    this._getAllImage();
    this._getAllIcons();
    this._getAvailableTemplates()
  };
  //changing format of createdAt
  _changeFormat = (data) => {
    return moment(data).format("MMM DD, hh:mm a");
  };

  _getAvailableTemplates = async () => {
    getAllTemplates()
      .then(response => {
        console.log("response of template", response)
        this.setState({ predefinedTemplates: response.templates })
      }).catch(err => console.log(err))
  }

  //for pagination
  _paginate = (pageNumber) => {
    this._getTeamsList(pageNumber);
  };

  // To get the Image assets
  _getAllImage = async () => {
    let res = await getAllImagesList();
    console.log("res :>> ", res);
    this.setState({ imageList: res.files });
  };

  // To get the icon assets
  _getAllIcons = async () => {
    let res = await getAllFilesList({ isIcon: true });
    console.log("res :>> ", res);
    this.setState({ iconList: res.files });
  };
  // handleChange
  handleChange = () => {
    let tempFieldsToShow = this.state.fieldsToShow.filter(
      (each) => each.isSelected !== undefined && each.isSelected !== false
    );
    // tempFieldsToShow.forEach(it=>delete it.type);
    console.log(tempFieldsToShow);
    this.setState(
      { input: !this.state.input, fieldsToShow: tempFieldsToShow },
      () => {
        // this._handleOnChange(
        //   "textArea",
        //   JSON.stringify(this.state.fieldsToShow)
        // )

        // console.log(!this.state.input,template.textArea=="[]")
        // if(!this.state.input&&template.textArea!==`[]`){
        //   console.log(JSON.parse(template.textArea))
        //   this.setState({
        //     fieldsToShow:JSON.parse(template.textArea)
        //   })
        // }
        if (!this.state.input && template.textArea !== `[]`) {
          this.fieldsToShowUpdating();
        } else {
          this._handleOnChange(
            "textArea",
            JSON.stringify(this.state.fieldsToShow, undefined, 2)
          );
        }
      }
    );
    //  this.setState({ fieldsToShow: tempFieldsToShow });
  };
  fieldsToShowUpdating = () => {
    let { vmTemplates } = this.state;
    console.log(JSON.parse(this.state.template.textArea));
    this.setState(
      {
        fieldsToShow: JSON.parse(this.state.template.textArea),
      },
      () => {
        let updatedVmTemplates = vmTemplates.map(
          (obj) =>
            JSON.parse(this.state.template.textArea).find(
              // eslint-disable-next-line
              (o) => o.fieldName == obj.fieldName
            ) || obj
        );
        this.setState({ vmTemplates: updatedVmTemplates });
      }
    );
  };
  //handling input here
  _handleOnChange = (field, value) => {
    console.log(field, value);
    const { template, isDirtyTemplate } = this.state;
    // template[field] = value;
    // isDirtyTemplate[field] = true;
    // this.setState({ template });
    // this.setState({ template }, () => {
    //   console.log(isDirtyTemplate[field], field);
    //   if (isDirtyTemplate[field]) this._validationTemplate(value);
    // });
    // eslint-disable-next-line
    if (field == "baseBin") {
      this.setState(
        {
          template: {
            ...this.state.template,
            [field]: value,
            defaultvmimage: "",
            vmimage: [],
            dockerimage: []
          },
          fieldsToShow: [],
          vmTemplates: deepClone(config.vmTemplateFields),
        },
        () => {
          console.log(isDirtyTemplate[field], field);
          if (isDirtyTemplate[field]) this._validationTemplate(value);
        }
      );
      // eslint-disable-next-line
    } else if (field == "vmimage" || field == "dockerimage") {
      this.setState(
        {
          template: {
            ...this.state.template,
            vmimage:
              // eslint-disable-next-line
              field == "vmimage"
                ? this.state.template.vmimage.concat([value])
                : [],
            dockerimage:
              // eslint-disable-next-line
              field == "dockerimage"
                ? [...new Set(this.state.template.dockerimage.concat([value]))]
                : [],
            dockertext: "",
          },
        },
        () => {
          console.log(isDirtyTemplate[field], field);
          if (isDirtyTemplate[field]) this._validationTemplate(value);
        }
      );
    } else {
      template[field] = value;
      this.setState({ template }, () => {
        console.log(isDirtyTemplate[field], field);
        if (isDirtyTemplate[field]) this._validationTemplate(value);
      });
    }
  };

  markDirty = (fieldName, fieldvalue) => {
    const { isDirtyTemplate } = this.state;
    console.log(fieldName);
    isDirtyTemplate[fieldName] = true;

    this.setState(
      { isDirtyTemplate },
      () => console.log(isDirtyTemplate),
      this._validationTemplate(fieldvalue)
    );
    // console.log(this.state.errors,this.state.isDirtyTemplate);
  };

  _validationTemplate = (value) => {
    const { template, errors, isDirtyTemplate } = this.state;
    console.log('object')
    Object.keys(template).forEach((each) => {
      if (each === "name" && isDirtyTemplate.name) {
        if (!template.name.trim().length) {
          errors.nameTemplate = "Name is Required";
        } else {
          delete errors["nameTemplate"];
          isDirtyTemplate.name = false;
        }
      } else if (each === "baseBin" && isDirtyTemplate.baseBin) {
        if (!template.baseBin.trim().length) {
          errors.baseBin = "VM Type is Required";
        } else {
          delete errors[each];
          isDirtyTemplate.baseBin = false;
        }
      } else if (each === "vmimage" && isDirtyTemplate.vmimage) {
        // eslint-disable-next-line
        if (!template.vmimage.length > 0 && template.baseBin == "virsh") {
          errors.vmimage = "VM Image is Required";
        } else {
          delete errors[each];
          isDirtyTemplate.vmimage = false;
        }
      } else if (each === "dockerimage" && isDirtyTemplate.dockerimage) {
        // eslint-disable-next-line
        if (!template.dockerimage.length > 0 && template.baseBin == "docker") {
          errors.dockerimage = "Docker Image is Required";
        } else {
          delete errors[each];
          isDirtyTemplate.dockerimage = false;
        }
      } else if (
        // eslint-disable-next-line
        each == "textArea" &&
        this.state.input &&
        isDirtyTemplate.textArea
      ) {
        console.log(value);
        //  let data= JSON.stringify(value, undefined, 0)
        try {
          JSON.parse(value);
          delete errors[each];
          isDirtyTemplate.textArea = false;
        } catch {
          console.log("error");
          errors.textArea = "Not a Valid Json";
        }
      }
    });
    if (
      isDirtyTemplate.selectedFile &&
      !this.state.selectedFile &&
      !this.state.selectedIcons.length
    ) {
      errors.selectedFile = "*Select OR Upload an Icon";
    } else if (
      isDirtyTemplate.selectedFile &&
      this.state.selectedFile &&
      !config.supportedFileFormats.includes(this.state.selectedFile.type)
    ) {
      errors.selectedFile = "Please select an image file";
    } else {
      delete errors.selectedFile;
    }
    this.setState({ errors });
    console.log("err", this.state.errors);
    return Object.keys(errors).length ? errors : {};
  };

  _Template = (fields) => {
    let isDirtyTemplate = {
      name: true,
      baseBin: true,
      vmimage: true,
      dockerimage: true,
    };
    this.setState({ isDirtyTemplate }, () => {
      // eslint-disable-next-line
      let arrayDataType = fields.filter(item => item.dataType == "Array")
        .map(each => each?.defaultValue !== undefined &&
          this.checkArrayHasDuplicate(each?.defaultValue.split(",")))
      let errors = this._validationTemplate()
      console.log(JSON.stringify(errors) === '{}' && !arrayDataType.includes(true))
      if (JSON.stringify(errors) === '{}' && !arrayDataType.includes(true)) {
        let { template } = this.state;

        let fieldstoSend = fields
          .filter(
            (each) => each.isSelected !== undefined && each.isSelected !== false
          )
          .map((item) => ({
            dataType: item.dataType,
            isEditable: item.isEditable,
            isMandatory: item.isMandatory,
            fieldName: item.fieldName,
            label: item.label,
            defaultValue:
              // eslint-disable-next-line
              item.dataType == "Number"
                ? Number(item.defaultValue)
                :
                // eslint-disable-next-line
                item.dataType == "Array" ? item.defaultValue.split(',') : item.defaultValue,
          }));
        let data = {
          name: template.name,
          description: template.description,
          baseBin: template.baseBin,
          imagesToShow:
            template.vmimage.length > 0 ? template.vmimage : undefined,
          dockerImagesToShow:
            template.dockerimage.length > 0 ? template.dockerimage : undefined,
          defaultIconId: template?.pictureId[0]?.id,
          defaultVMImageId:
            // eslint-disable-next-line
            template.baseBin == "virsh" ? template.defaultvmimage : undefined,
          defaultDockerImage:
            // eslint-disable-next-line
            template.baseBin == "docker" ? template.defaultvmimage : undefined,
          fieldsToShow: fieldstoSend.length > 0 ? fieldstoSend : undefined,
          isActive: template.active,
          requiresSecondaryDisk: template.requiresSecondaryDisk,
        };
        console.log(data);
        createVmTemplate(data)
          .then((resp) => {
            this.setState({
              template: {
                name: "",
                description: "",
                baseBin: "virsh",
                vmimage: [],
                dockerimage: [],
                textArea: "",
                defaulticon: "",
                defaultvmimage: "",
                requiresSecondaryDisk: false,
                pictureId: [],
                active: false,
              },
              isDirtyTemplate: {
                name: false,
                baseBin: false,
                vmimage: false,
                textArea: false,
                pictureId: false,
              },
            });
            this.props.hideLoader();
            ToastsStore.success("VM Template Created successfully");
          })
          .catch((error) => {
            console.log("error :", error);
            this.props.hideLoader();
            ToastsStore.error("VM Template Creation Error:- " + error.reason);
          });
      }
    });
  };

  _fieldOnChange = (fieldName, fieldValue, item) => {
    console.log(fieldName, fieldValue, item);
    const { fieldsToShow } = this.state;
    if (fieldsToShow.length > 0) {
      let objIndex = fieldsToShow.findIndex(
        (o) => o.fieldName === item.fieldName && o.dataType === item.dataType
      );
      if (objIndex >= 0) {
        // this.setState({fieldsToShow:[...this.state.fieldsToShow,{...fieldsToShow[a],[fieldName]:fieldValue}]})
        const updatedObj = {
          ...fieldsToShow[objIndex],
          [fieldName]: fieldValue,
        };
        const updatedfieldtoShow = [
          ...fieldsToShow.slice(0, objIndex),
          updatedObj,
          ...fieldsToShow.slice(objIndex + 1),
        ];
        console.log(updatedfieldtoShow);
        this.setState({ fieldsToShow: updatedfieldtoShow });
      } else {
        this.setState({
          fieldsToShow: [
            ...this.state.fieldsToShow,
            {
              ...item,
              [fieldName]: fieldValue,
              isMandatory: false,
              isEditable: false,
            },
          ],
        });
      }
    } else {
      this.setState({
        fieldsToShow: [
          ...this.state.fieldsToShow,
          {
            ...item,
            [fieldName]: fieldValue,
            isMandatory: false,
            isEditable: false,
          },
        ],
      });
    }
    this._updateTemplate(fieldName, fieldValue, item);
  };

  _updateTemplate = (fieldName, fieldValue, item) => {
    const { vmTemplates } = this.state;

    let objIndex = vmTemplates.findIndex(
      (o) => o.fieldName === item.fieldName && o.dataType === item.dataType
    );
    if (objIndex >= 0) {
      // this.setState({fieldsToShow:[...this.state.fieldsToShow,{...fieldsToShow[a],[fieldName]:fieldValue}]})
      const updatedObj = {
        ...vmTemplates[objIndex],
        [fieldName]: fieldValue,
      };
      const updatedfieldtoShow = [
        ...vmTemplates.slice(0, objIndex),
        updatedObj,
        ...vmTemplates.slice(objIndex + 1),
      ];
      this.setState({ vmTemplates: updatedfieldtoShow });
    }
  };

  _customOptionRenderer = ({ item, props, state, methods }) => {
    console.log(item);
    return (
      <StyledOption>
        <img src={item.url} width={30} alt={item.name} />
        &nbsp;
        {item.name}
        <span
          onClick={() => {
            methods.removeItem(null, item);
            this.setState({ selectedIcons: [] }, () => {
              this._validateFields();
            });
          }}
        >
          &times;
        </span>
      </StyledOption>
    );
  };
  _customItemRenderer = ({ item, itemIndex, props, state, methods }) => {
    const { selectedIcons } = deepClone(this.state);
    console.log(item);

    return (
      <StyledItem>
        <div
          onClick={() => {
            console.log("selectedIcons :>> ", item);
            methods.addItem(item);
            const { isDirtyTemplate } = deepClone(this.state);
            isDirtyTemplate.pictureId = true;
            const isAlreadyPresent =
              selectedIcons.length && selectedIcons[0].id === item.id;
            this.setState(
              {
                selectedIcons: isAlreadyPresent ? [] : [item],
                selectedFile: null,
                previewImg: null,
                isDirtyTemplate,
              },
              () => {
                this._validationTemplate();
              }
            );
          }}
        >
          <img src={item.url} width={30} alt={item.name} /> &nbsp;
          {item.name}
        </div>
      </StyledItem>
    );
  };

  _handleOnDelete = (value) => {
    console.log(value);
    let list = this.state.template.vmimage.filter((each) => each !== value);

    console.log(list);
    this.setState({
      template: {
        ...this.state.template,
        vmimage: list,
      },
    });
  };

  _handleOnDockerDelete = (value) => {
    let list = this.state.template.dockerimage.filter((each) => each !== value);
    console.log(list);
    this.setState({
      template: {
        ...this.state.template,
        dockerimage: list,
      },
    });
  };

  checkArrayHasDuplicate = (arr) => {
    console.log(arr);
    // eslint-disable-next-line
    let duplicates = arr.filter((item, index) => arr.indexOf(item) != index);
    return duplicates.length > 0 ? true : false;
  };
  // _fieldOnDefaultChange = (fieldName, fieldValue, item, index) => {
  //   console.log(fieldName, fieldValue, item);
  //   const { fieldsToShow } = this.state;
  //   let objIndex = fieldsToShow.findIndex(
  //     (o) => o.fieldName === item.fieldName && o.dataType === item.dataType
  //   );
  //   const updatedObj = {
  //     ...fieldsToShow[objIndex],
  //     [fieldName]: fieldsToShow[objIndex].defaultValue
  //       ? [...fieldsToShow[objIndex].defaultValue, fieldValue]
  //       : [fieldValue],
  //   };
  //   const updatedfieldtoShow = [
  //     ...fieldsToShow.slice(0, objIndex),
  //     updatedObj,
  //     ...fieldsToShow.slice(objIndex + 1),
  //   ];
  //   console.log(updatedfieldtoShow);
  //   this.setState(
  //     { fieldsToShow: updatedfieldtoShow, tempDefaultvalue: "" },
  //     () =>
  //       this._fieldOnDefaultChangeUpdateVmtemplate(
  //         fieldName,
  //         fieldValue,
  //         item,
  //         index
  //       )
  //   );
  // };

  // _fieldOnDefaultChangeUpdateVmtemplate = (
  //   fieldName,
  //   fieldValue,
  //   item,
  //   index
  // ) => {
  //   console.log(fieldName, fieldValue, item);
  //   const { vmTemplates } = this.state;
  //   let objIndex = vmTemplates.findIndex(
  //     (o) => o.fieldName === item.fieldName && o.dataType === item.dataType
  //   );
  //   const updatedObj = {
  //     ...vmTemplates[objIndex],
  //     [fieldName]: vmTemplates[objIndex].defaultValue
  //       ? [...vmTemplates[objIndex].defaultValue, fieldValue]
  //       : [fieldValue],
  //   };
  //   const updatedfieldtoShow = [
  //     ...vmTemplates.slice(0, objIndex),
  //     updatedObj,
  //     ...vmTemplates.slice(objIndex + 1),
  //   ];
  //   console.log(updatedfieldtoShow);
  //   this.setState({ vmTemplates: updatedfieldtoShow });
  // };

  // _handleOnDefaultvalueDelete = (item, each) => {
  //   console.log(item, each);
  //   const { fieldsToShow } = this.state;
  //   let objIndex = fieldsToShow.findIndex(
  //     (o) => o.fieldName === item.fieldName && o.dataType === item.dataType
  //   );
  //   const updatedObj = {
  //     ...fieldsToShow[objIndex],
  //     defaultValue: fieldsToShow[objIndex].defaultValue.filter(
  //       (item) => item !== each
  //     ),
  //   };
  //   const updatedfieldtoShow = [
  //     ...fieldsToShow.slice(0, objIndex),
  //     updatedObj,
  //     ...fieldsToShow.slice(objIndex + 1),
  //   ];
  //   this.setState({ fieldsToShow: updatedfieldtoShow }, () =>
  //     this._handleOnDefaultvalueDeleteVmtemplate(item, each)
  //   );
  // };

  // _handleOnDefaultvalueDeleteVmtemplate = (item, each) => {
  //   const { vmTemplates } = this.state;
  //   let objIndex = vmTemplates.findIndex(
  //     (o) => o.fieldName === item.fieldName && o.dataType === item.dataType
  //   );
  //   const updatedObj = {
  //     ...vmTemplates[objIndex],
  //     defaultValue: vmTemplates[objIndex].defaultValue.filter(
  //       (item) => item !== each
  //     ),
  //   };
  //   const updatedfieldtoShow = [
  //     ...vmTemplates.slice(0, objIndex),
  //     updatedObj,
  //     ...vmTemplates.slice(objIndex + 1),
  //   ];
  //   this.setState({ vmTemplates: updatedfieldtoShow });
  // };

  onDockerChange = (e) => {
    this.setState({
      template: {
        ...this.state.template,
        dockertext: e.target.value,
      },
    })
  }

  render() {
    console.log(this.state);
    const { errors, template } = deepClone(this.state);
    let VmTemplate = this.state.vmTemplates.filter(
      (i) =>
        // eslint-disable-next-line
        i.type[0] == this.state.template.baseBin ||
        // eslint-disable-next-line
        i.type[1] == this.state.template.baseBin
    );
    console.log(VmTemplate);
    let preView =
      this.state.template.vmimage &&
      this.state.imageList.filter((item) =>
        // eslint-disable-next-line
        this.state.template.vmimage.some((each) => item.id == each)
      );
    let dockerPreView =
      this.state.template.dockerimage &&
      this.state.imageList.filter((item) =>
        // eslint-disable-next-line
        this.state.template.dockerimage.some((each) => item.id == each)
      );
    console.log(dockerPreView);

    return (
      <>
        <Row className="innerForm innerCard">
          <Col xs="12">

            <StepWizard>
              <StepFirst template={this.state.template} imageList={this.state.imageList} _handleOnChange={this._handleOnChange} _handleOnDelete={this._handleOnDelete} iconList={this.state.iconList} onDockerChange={this.onDockerChange} _handleOnDockerDelete={this._handleOnDockerDelete} />
              <StepTwo handleChange={this.handleChange} input={this.state.input} template={this.state.template} _handleOnChange={this._handleOnChange} markDirty={this.markDirty} _fieldOnChange={this._fieldOnChange} _template={this._Template} />
            </StepWizard>
            
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateCreation);
