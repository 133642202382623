import React, { useEffect, useState } from 'react';
import { FormGroup } from 'reactstrap';

import { Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';
import { getUpdate, getSerialKey, getUpdatedData, postUpdate } from '../../../http/http-calls';
import NewLicenseModal from '../components/new-license-modal';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data.js';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { toast } from 'react-toastify';
import './toggle-button.css';
import ResizeDragModal from './resizeDragModal';
import axios from 'axios';

const AppInfo = (props) => {
  const [update, setUpdate] = useState(null);
  const [modal, setModal] = useState(false);
  const [license, setLicense] = useState(null);
  const [validity, setValidity] = useState(null);
  const [clientupdate, setClientupdate] = useState(null);
  const [availableUpdate, setAvailableUpdate] = useState(null);
  const [showLicense, setShowLicense] = useState(false);
  const [customerID, setCustomerID] = useState();
  const [licenceInfo, setLicenseInfo] = useState({});
  const [uploadedFile, setUploadedFile] = useState();
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({
    accessKey: false,
  });
  const handleApiVisiblity = async () => {
    var apiContainer = document.getElementById('api');
    var toggleButton = document.getElementById('toggle-switch');

    toggleButton.classList.toggle('active');
    apiContainer.classList.toggle('visibleToggle');
  };

  const fetchClientID = async () => {
    const id = await axios.get('/ronin/get-client-id/', {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Expires: '0',
      },
    });
    console.info('this is the id', id);
    setCustomerID(id.data);
  };

  const fetchLicenseInfo = async () => {
    const status = await axios.get('ronin/license-status/');
    if (status.data.license_created) {
      setUploadedFile('License file is valid');
      setLicenseInfo(status.data);
      return;
    } else if (!status.data.license_created) {
      setUploadedFile('License is not valid');
      setLicenseInfo({
        license_created: 'Expired License',
        license_expires: 'Expired License',
      });
      return;
    }
  };

  useEffect(() => {
    fetchClientID();
    fetchLicenseInfo();
    props.showLoader();
    addSocketListener();

    Promise.all([getUpdate(), getSerialKey(), getUpdatedData()])
      .then((responses) => {
        console.log(responses);
        setUpdate(responses[0].updates && responses[0].updates.length ? responses[0].updates[0] : null);
        setLicense(responses[1].license);
        setValidity(Math.ceil(responses[1].days));
        setClientupdate(responses[2].update ? responses[2].update : null);

        let clientUpdateTemp = responses[2].update ? responses[2].update : null;
        let updateTemp = responses[0].updates && responses[0].updates.length ? responses[0].updates[0] : null;
        console.log('client update', clientUpdateTemp, updateTemp);

        if (clientUpdateTemp !== null && updateTemp !== null) {
          if (clientUpdateTemp.updateId === updateTemp.uuid) {
            setAvailableUpdate(null);
          }
        }
        if (clientupdate === null && update !== null) {
          setAvailableUpdate(update);
        }

        props.hideLoader();
      })
      .catch((error) => {
        props.hideLoader();
        console.log(error);
      });
  }, []);

  const addSocketListener = () => {
    props.socket.on('update-confirmed', () => {
      props.hideLoader();
      window.location.reload();
    });
  };

  const handleToggle = () => {
    setLicense({
      accessKey: '',
    });

    setIsDirty({
      accessKey: false,
    });

    setErrors({});
    props.toggle();
  };

  const _onFileSelect = async (event) => {
    if (event.target.files) {
      console.info('event.target.files :>> ', event.target.files[0]);
      try {
        props.showLoader();
        const formData = new FormData();
        formData.append('fileupload', event.target.files[0]);
        console.info(event.target.files[0]);
        const res = await axios({
          method: 'POST',
          url: '/ronin/upload-license/',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
          transformRequest: (formData) => formData,
          data: formData,
        });
        setUploadedFile('License file is valid');
        fetchLicenseInfo();
        toast.success(`Successfully uploaded a new License file`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.hideLoader();
      } catch (error) {
        props.showLoader();
        // setUploadedFile('License is not valid')
        // setLicenseInfo({
        //   "license_created": "Expired License",
        //   "license_expires": "Expired License"
        // })

        toast.error(`Error while uploaing the license file`, {
          position: toast.POSITION.TOP_RIGHT,
        });

        props.hideLoader();
        // let res = await uploadFileMultiPart(formData);
        // 65.220.37.101:8051/ronin/upload-license/
        this.props.showLoader();
        this.props.hideLoader();
      }
    }
  };

  useEffect(() => {
    if (clientupdate === null && update !== null) {
      setAvailableUpdate(update);
    }
  }, [update]);

  const _update = () => {
    props.showLoader();
    postUpdate({ updateId: update.uuid })
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };

  return (
    <div className='content'>
      <Row>
        <Col md='6'>
          <Card className='userInfo appInfo'>
            <CardHeader className='d-flex justify-content-between align-items-center'>
              <span>License Info</span>
            </CardHeader>
            <CardBody className='license-body' style={{ paddingBottom: '2.3rem', paddingTop: '3rem' }}>
              <div className='license_modal' size={'lg'} isOpen={props.isOpen} toggle={handleToggle}>
                <div>
                  <FormGroup>
                    <span className='licenseDetails'>{uploadedFile ? uploadedFile : 'No File Added'}</span>
                    <Button className='viewBtn import-btn h-v_center'>
                      <input type='file' className='file-input' onChange={(e) => _onFileSelect(e)} />
                      <span>
                        {'Choose a new license file'} <b className='browse'>Browse</b>
                      </span>
                    </Button>
                    <br />
                  </FormGroup>
                  <div className='license-details'>
                    <p>
                      <span className='licenseDetails'>License Created On</span>{' '}
                      <span className='license_info'>{new Date(licenceInfo.license_created).toUTCString()}</span>
                    </p>
                    <p>
                      <span className='licenseDetails'> License Expires On</span>{' '}
                      <span className='license_info'>{new Date(licenceInfo.license_expires).toUTCString()}</span>
                    </p>
                  </div>
                </div>
                <div></div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md='6'>
          <Card className='userInfo appInfo'>
            <CardHeader className='d-flex justify-content-between align-items-center'>
              <span>Software Updates</span>
              {availableUpdate !== null && (
                <Button className='addBtn' onClick={_update}>
                  Update Available
                </Button>
              )}
            </CardHeader>
            <CardBody>
              {availableUpdate === null ? (
                <div className='uptodate'>
                  <img src='/assets/img/Uptodate.png' alt='up to date' />
                  {/* App is already up to date <br />
                  <span>No changes req</span> */}
                  <h2 style={{ marginBottom: '4.4rem', fontSize: '1rem' }}>App is already up to date</h2>
                  <span></span>
                </div>
              ) : (
                <div className=''>
                  <img src={require('../../../assets/img/Uptodate.png').default} alt='up to date' />
                  {`update available ${availableUpdate.uuid} \n ${availableUpdate.description}`}
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Col md='6' className='apiKeyCard'>
        <Card className='userInfo appInfo'>
          <CardHeader className='d-flex justify-content-between align-items-center'>
            <span>Customer ID</span>
            <button onClick={handleApiVisiblity} id='toggle-switch'></button>
          </CardHeader>
          <CardBody>
            <div className='api' id='api'>
              {`Your customer ID is: ${customerID}`}
            </div>
          </CardBody>
        </Card>
      </Col>
      <NewLicenseModal isOpen={modal} toggle={() => setModal((prev) => !prev)}></NewLicenseModal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(socketConnect(AppInfo));
