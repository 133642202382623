import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Input, Label, FormGroup, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../../redux/actions/loader-data';
import config from '../../../../config';
import { deepClone } from '../../../../helper-methods';
import { addAsset } from '../../../../http/http-calls';
import Select from 'react-dropdown-select';
import styled from 'styled-components';

const StyledOption = styled.span`
  padding: 0;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  transition: all 1s ease-in;

  span {
    display: none;
    transition: all 1s ease-in;
  }

  a {
    margin: 0 5px;
  }

  :hover {
    background: #f2f2f2;

    span {
      display: inline;
      margin: 0 0 0 5px;
      color: red;
    }
  }
`;

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;

  :hover {
    background: #f2f2f2;
  }
`;
class AddVirtualNetworkModal extends Component {
  state = {
    network: {
      name: '',
      description: '',
      iconId: '',
      networkType: '',
      labId: '',
      left: 100,
      top: 100,
      count: 1
    },
    isDirty: {
      name: false,
      description: false,
      networkType: false,
      selectedFile: false,
      left: false,
      top: false,
      count: false
    },
    errors: {},
    selectedFile: null,
    previewImg: null,
    selectedIcons: []
  };

  componentDidMount() {
    const { network } = deepClone(this.state);
    network.labId = this.props.match.params.id;
    this.setState({ network });
  }

  _handleOnChange = (field, value) => {
    const { network, isDirty } = this.state;
    if (field === 'selectedFile') {
      if (value.target.files && value.target.files[0]) {
        isDirty.selectedFile = true;
        this.setState(
          {
            selectedFile: value.target.files[0],
            previewImg: URL.createObjectURL(value.target.files[0]),
            selectedIcons: [],
            isDirty
          },
          () => {
            this._validateFields();
          }
        );
      }
    } else {
      network[field] = value;
      isDirty[field] = true;
    }
    this.setState({ network, isDirty }, () => {
      this._validateFields();
    });
  };

  _validateFields = () => {
    const { network, isDirty, errors } = this.state;
    Object.keys(network).forEach((each) => {
      if (each === 'name' && isDirty.name) {
        if (!network.name.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.name = false;
        }
      }
      // else if (each === "description" && isDirty.description) {
      //     if (!network.description.trim().length) {
      //         errors[each] = "*Required";
      //     } else {
      //         delete errors[each];
      //         isDirty.description = false;
      //     }
      // }
      else if (each === 'networkType' && isDirty.networkType) {
        if (!network.networkType.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.networkType = false;
        }
      } else if (each === 'left' && isDirty.left) {
        if (network.left.length && isNaN(network.left)) {
          errors[each] = 'Should be Number';
        } else {
          delete errors[each];
          isDirty.left = false;
        }
      } else if (each === 'top' && isDirty.top) {
        if (network.top.length && isNaN(network.top)) {
          errors[each] = 'Should be Number';
        } else {
          delete errors[each];
          isDirty.top = false;
        }
      } else if (each === 'count' && isDirty.count) {
        if (!network.count.toString().length) {
          errors[each] = '*Required';
        } else if (network.count.length && isNaN(network.count)) {
          errors[each] = 'Should be Number';
        } else if (network.count.length && !isNaN(network.count) && (network.count <= 0 || network.count > 10)) {
          errors[each] = 'Number should be between 1 to 10';
        } else {
          delete errors[each];
          isDirty.count = false;
        }
      }
    });
    if (isDirty.selectedFile && !this.state.selectedFile && !this.state.selectedIcons.length) {
      errors.selectedFile = '*Select OR Upload an Icon';
    } else if (
      isDirty.selectedFile &&
      this.state.selectedFile &&
      !config.supportedFileFormats.includes(this.state.selectedFile.type)
    ) {
      errors.selectedFile = 'Please select an image file';
    } else {
      delete errors.selectedFile;
    }
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  _handleToggle = () => {
    this.props.toggle('close');
  };

  _handleOnSubmit = () => {
    console.log('this.state.network :>> ', this.state.network);
    const { network, selectedIcons } = deepClone(this.state);
    let isDirty = {
      name: true,
      description: true,
      networkType: true,
      selectedFile: true,
      left: true,
      top: true,
      count: true
    };
    this.setState({ isDirty }, async () => {
      let errors = this._validateFields();
      if (!errors) {
        network.position = {
          x: network.left ? Number(network.left) : 100,
          y: network.top ? Number(network.top) : 100
        };
        if (!selectedIcons.length && this.state.selectedFile) {
          const formData = new FormData();
          formData.append('assetFile', this.state.selectedFile);
          formData.append('name', this.state.network.name);
          formData.append('description', this.state.network.description);
          formData.append('isIcon', true);
          let res = await addAsset(formData);
          console.log('res :>> ', res);
          network.iconId = res.file.id;
          network.iconURL = res.file.url || res.file.thumbnailImage;
        } else {
          network.iconId = selectedIcons[0].id;
          network.iconURL = selectedIcons[0].url;
        }
        this.setState({ network }, () => {
          console.log('network :>> ', network);
          this.props.toggle('save', network);
        });
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      let state = {
        network: {
          name: '',
          description: '',
          iconId: '',
          networkType: '',
          labId: this.props.match.params.id,
          left: 100,
          top: 100,
          count: 1
        },
        isDirty: {
          name: false,
          description: false,
          networkType: false,
          selectedFile: false,
          left: false,
          top: false,
          count: false
        },
        errors: {},
        selectedFile: null,
        previewImg: null,
        selectedIcons: []
      };
      this.setState(state);
    }
  }

  _customOptionRenderer = ({ item, props, state, methods }) => {
    return (
      <StyledOption>
        <img src={item.url} alt={item.name} width={30} />
        &nbsp;
        {item.name}
        <span
          onClick={() => {
            methods.removeItem(null, item);
            this.setState({ selectedIcons: [] }, () => {
              this._validateFields();
            });
          }}>
          &times;
        </span>
      </StyledOption>
    );
  };

  _customItemRenderer = ({ item, itemIndex, props, state, methods }) => {
    const { selectedIcons } = deepClone(this.state);

    return (
      <StyledItem>
        <div
          onClick={() => {
            console.log('selectedIcons :>> ', item);
            methods.addItem(item);
            const { isDirty } = deepClone(this.state);
            isDirty.selectedFile = true;
            const isAlreadyPresent = selectedIcons.length && selectedIcons[0].id === item.id;
            this.setState(
              {
                selectedIcons: isAlreadyPresent ? [] : [item],
                selectedFile: null,
                previewImg: null,
                isDirty
              },
              () => this._validateFields()
            );
          }}>
          {item.isFirstDefaultIcon && <div className='custom-select-section'>System Icons</div>}
          {item.isFirstIcon && <div className='custom-select-section'>Custom Icons</div>}
          <img src={item.url} alt={item.name} width={30} /> &nbsp;
          {item.name}
        </div>
      </StyledItem>
    );
  };

  render() {
    const { network, errors } = this.state;
    const { data } = deepClone(this.props);

    return (
      <Modal isOpen={this.props.isOpen} toggle={() => this._handleToggle()} centered>
        <ModalHeader toggle={() => this._handleToggle()}>
          <p className='text-white'>Add New Virtual Network</p>
        </ModalHeader>
        <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }} className='innerForm'>
          <FormGroup>
            <Label>Number of Networks to add</Label>
            <Input
              type='number'
              placeholder='Enter'
              min={1}
              max={10}
              value={network.count}
              onChange={(e) => this._handleOnChange('count', e.target.value)}
            />
            {errors && <div className='validation-error'>{errors.count}</div>}
          </FormGroup>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type='text'
              placeholder='Enter name'
              value={network.name}
              onChange={(e) => this._handleOnChange('name', e.target.value)}
            />
            {errors && <div className='validation-error'>{errors.name}</div>}
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <Input
              type='textarea'
              rows='3'
              placeholder='Enter description'
              value={network.description}
              onChange={(e) => this._handleOnChange('description', e.target.value)}
            />
            {errors && <div className='validation-error'>{errors.description}</div>}
          </FormGroup>
          <FormGroup>
            <Label>Type</Label>
            <Input
              type='select'
              rows='3'
              placeholder='Select Network Type'
              value={network.networkType}
              onChange={(e) => this._handleOnChange('networkType', e.target.value)}>
              <option value=''>Select Network Type</option>
              {React.Children.toArray(
                config.networkTypes.map((each) => <option value={each.value}>{each.name}</option>)
              )}
            </Input>
            {errors && <div className='validation-error'>{errors.networkType}</div>}
          </FormGroup>
          <FormGroup>
            <Label>Add Icon</Label>
            <div>
              <Select
                placeholder='Select Icon'
                multi={true}
                keepOpen={false}
                searchable={false}
                optionRenderer={this._customOptionRenderer}
                itemRenderer={this._customItemRenderer}
                onChange={(value) => this._handleOnChange('iconId', value)}
                values={this.state.selectedIcons}
                options={data?.iconList}
                labelField={'name'}
                valueField={'id'}
                closeOnSelect={true}
              />
            </div>
            <div className='or-text'>
              <p className='ext-center'>OR</p>
            </div>
            <div className='text-center'>
              <Button className='addBtn mr-3'>
                <Input
                  type='file'
                  accept='image/x-png,image/gif,image/jpeg'
                  onChange={(e) => this._handleOnChange('selectedFile', e)}
                />
                Select File
              </Button>
              {this.state.previewImg && <img alt='Asset Img' className='assetImg' src={this.state.previewImg} />}
            </div>
            {errors && <div className='validation-error'>{errors.selectedFile}</div>}
          </FormGroup>
          <FormGroup>
            <Label>Position</Label>
            <Row className='justify-content-between'>
              <Col md={6}>
                <Label>Left</Label>
                <Input
                  type='text'
                  placeholder='Enter Left'
                  value={network.left}
                  onChange={(e) => this._handleOnChange('left', e.target.value)}
                />
                {errors && <div className='validation-error'>{errors.left}</div>}
              </Col>
              <Col md={6}>
                <Label>Top</Label>
                <Input
                  type='text'
                  placeholder='Enter Top'
                  value={network.top}
                  onChange={(e) => this._handleOnChange('top', e.target.value)}
                />
                {errors && <div className='validation-error'>{errors.top}</div>}
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className='modalBtnCancel' onClick={() => this._handleToggle()}>
            Cancel
          </Button>
          <Button className='modalBtnSave' onClick={(e) => this._handleOnSubmit(e)}>
            Create
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  console.log('state :>> ', state);
  return {
    loaderData: state.loaderData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVirtualNetworkModal);
