import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className="content" style={{ color: "#676767" }}>
            <h1 style={{ color: "#fff" }}>EVOS Privacy Policy</h1>
            <p>GuRoo, LLC ("us", "we", "our" or “GuROO”) is the owner of the proprietary software known as EVOS™ (the "Software"). This page informs you of our policies regarding the collection, use, storing, and disclosure of Personal Information we receive from users of the Software, and your choices about privacy when you use the Software.

                The Software is not intended for children and we do not knowingly collect data relating to children. If we determine that we have received personal information from a person under the age of 18, we will delete it.

                This Policy is part of our terms of use and should be read in tandem with our End User License Agreement, which can be viewed at: [INSERT LINK TO TERMS OF USE].
            </p>
            <h2 style={{ color: "#fff" }}>
                1. INFORMATION WE COLLECT & LOG DATA
            </h2>
            <p>While using certain features on our Software, we will ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name, email address, and phone number ("Personal Information"). You agree that all information you provided will be accurate and complete, and you agree to update your information as appropriate.

                Personal Information does not include aggregated, non–personally identifying information.  We may use such information to operate, analyze, improve, and optimize our website and service.

                We collect login data concerning your account with us (“Log Data”).

                We may combine the various types of information we collect about you in order to improve our services. </p>

            <h2 style={{ color: "#fff" }}>2. HOW WE COLLECT YOUR INFORMATION</h2>
            <p>We may collect your Personal Information from any one of the following sources:</p>
            <ul>
                <li>Directly from you when you interact with us;</li>
                <li>Through our Website, GuROOit.com (the “Site”), when you send us an email or complete a form;</li>
            </ul>
            <h2 style={{ color: "#fff" }}>3. OUR USE OF YOUR INFORMATION</h2>
            <p>We use your Personal Information to communicate with you at your request.  We may use your Personal Information and Log Data for internal purposes, such as improving our Software and service offerings, security reasons, training purposes and for our legal documentation and compliance purpose. </p>
            <p>No Sale of Personal Information.  Under no circumstances do we sell, rent, or trade your Personal Information with third parties for their commercial purposes.  To enhance your experience we may provide you with interactive communications, using the e-mail address that you provide to us. Such interactive communications are provided only to those users who have expressly provided us with their permission to receive them. You may revoke our use of your email address for these purposes by clicking “unsubscribe.”

                Testimonials and Reviews:  We may display personal testimonials of satisfied customers on the Site in addition to other endorsements.  By submitting such information, you grant GUROO the right to post and publish such information at our sole discretion.  With your consent we may post your testimonial along with your name or company name, as applicable.

                Potential Newsletter.  If we add a newsletter for regular updates in the future, it will be an opt-in service and you will not be subscribed by default.
            </p>
            <h2 style={{color: "#fff"}}>4. OUR DISCLOSURE OF YOUR INFORMATION</h2>
            <p>We may share your Personal Information as follows:</p>
            <ul>
                <li>With our affiliates, who are bound to use your information in accordance with this policy.</li>
                <li>With our commercial partners who partner with us to deliver our services, including third party providers of multi-factor authentication services.</li>
                <li>In connection with a corporate transaction (e.g., merger, sale, joint venture, bankruptcy, or similar proceedings).</li>
                <li>With law enforcement to comply with legal process.</li>
                <li>At your direction.</li>
            </ul>
            <h2 style={{ color: "#fff" }}>5. COOKIES</h2>
            <p>The Software does not use any "cookies" to collect information.  Please refer to the Privacy Policy at our Site concerning the use of cookies at our Site.</p>
            <h2 style={{ color: "#fff" }}>6. DATA RETENTION</h2>
            <p>We generally will only store your information as long as necessary to fulfill the purposes for which the information is collected and processed or – where the applicable law provides for longer storage and retention periods – for the storage and retention period required by law. To dispose of Personal Information, we may anonymize it, delete it or take other appropriate steps. Data may persist in copies made for backup and business continuity purposes for additional time.</p>
            <p>In particular:</p>
            <ul>
                <li>If you withdraw your consent on which a processing of your Personal Information is based, GUROO will delete your Personal Information without undue delay to the extent that the collection and processing of the Personal Information was based on the withdrawn consent.</li>
                <li>If you exercise a right to object to the processing of your Personal Information, GUROO will delete your Personal Information that GUROO processed for the purpose to which you objected without undue delay, unless another legal basis for processing and retaining this data exists or unless applicable law requires us to retain the data.</li>
            </ul>
            <h2 style={{ color: "#fff" }}>7. YOUR DATA RIGHTS AND CHOICES</h2>
            <ul>
                <li>
                    How can I access the Personal Information you have about me?
                    <ul>

                        <li>If you would like to submit a data access request, you can email support@gurooit.com with the subject line “Personal Information Request.” We will then start the process and provide you access to the Personal Information that GUROO has on you within 30 days.</li>
                    </ul>
                </li>
                <li>How do I correct, update, amend, or delete the Personal Information you have about me?
                    <ul>
                        <li>In addition to the functionality available through the “Settings” once in your account, in which you can correct, update, amend, or delete certain Personal Information, you can also request other modifications from us directly. Please email support@gurooit.com with the subject line “Personal Information Request” in the subject or body of your message, along with an explanation of what data subject right you are seeking to exercise. For your protection, we may take steps to verify identity before responding to your request.</li>
                    </ul>
                </li>
                <li>
                    How do I object or restrict the manner in which GUROO processes my Personal Information?
                    <ul>
                        <li>You have a right to ask us to stop using or limit our use of your Personal Information in certain circumstances—for example, if we have no lawful basis to keep using your data, or if you think your Personal Information is inaccurate.</li>
                        <li>
                            In addition to those rights, you have the right to lodge a complaint with the relevant supervisory authority. However, we encourage you to contact us first, and we will do our very best to resolve your concern.
                        </li>
                    </ul>
                </li>
            </ul>
            <h2 style={{ color: "#fff" }}>8. SECURITY</h2>
            <p>We care about the protection of your user information and use industry standard safeguards to do so. Of course, it's important to keep in mind that no data transmission on the Internet is guaranteed to be 100% secure. Your information is stored on servers located in the United States. We use industry standard physical, electronic, and organizational safeguards to protect your user information against loss or theft as well as unauthorized access and disclosure to protect your privacy. We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by users inside and outside the company. However, "perfect security" does not exist on the Internet. If you are concerned that your privacy may have been breached, please contact us as provided below. </p>
            <h2 style={{ color: "#fff" }}>9. Third party links</h2>
            <p>The Software may, from time to time, contain links to and from the websites of third parties, including those of our affiliates and business partners.  Please note that these websites and any services that may be accessible through them have their own privacy policies and that we do not accept any responsibility or liability for these policies or for any Personal Information that may be collected through these websites or services, including Contact and Location Data. Please check these policies before you submit any Personal Information to these websites or use the services available through these websites.</p>
            <h2 style={{ color: "#fff" }}>10. CHANGES TO THIS PRIVACY POLICY</h2>
            <p>This Privacy Policy will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page, as evidenced in the change in date reference.</p>
            <p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Site after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>
            <h2 style={{ color: "#fff" }}>12. YOUR ACCEPTANCE OF THESE TERMS</h2>
            <p>By using the Software, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
            <h2 style={{ color: "#fff" }}>13. CONTACT US</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at support@gurooit.com.</p>
        </div>
    )
}

export default PrivacyPolicy