import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import { deepClone } from '../../../../helper-methods';

class ModifyVirtualMachineModal extends Component {
  state = { 
    machine:{
      cpu: '',
      ram : '',
      ethernets:''
    },
    isDirty:{
      cpu: false,
      ram : false,
      ethernets: false
    },
    errors:{}    
   }

   _handleOnChange = (field, value) => {
    let { machine, isDirty } = deepClone(this.state);
    machine[field] = value;
    isDirty[field] = true;    
    this.setState({ machine, isDirty }, () => {
      this._validateFields();
    });
  };

  _validateFields = () => {
    const { machine, isDirty, errors } = this.state;
    Object.keys(machine).forEach((each) => {
      if (each === "cpu" && isDirty.cpu) {
        if (!machine.cpu.length) {
          errors[each] = "*Required";
        } else if(isNaN(machine.cpu) || machine.cpu<1){
          errors[each] = "*Should have minimum one CPU";
        } else {
          delete errors[each];
          isDirty.cpu = false;
        }
      }
      if (each === "ram" && isDirty.ram) {
        if (!machine.ram.length) {
          errors[each] = "*Required";
        } else if(isNaN(machine.ram) || machine.ram<1){
          errors[each] = "*Should have minimum one RAM";
        } else {
          delete errors[each];
          isDirty.ram = false;
        }
      }
      if (each === "ethernets" && isDirty.ethernets) {
        if (!machine.ethernets.length) {
          errors[each] = "*Required";
        } else if(isNaN(machine.ethernets) || machine.ethernets<1){
          errors[each] = "*Should have minimum one ethernet";
        } else {
          delete errors[each];
          isDirty.ethernets = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  _handleOnSubmit = () => {
    const { machine, isDirty } = deepClone(this.state);
    isDirty.cpu = true;
    isDirty.ram = true;
    isDirty.ethernets = true;
    this.setState({ isDirty },()=>{
      let errors = this._validateFields();
      if(!errors){
        this.props.toggle('save', {
          cpu: Number(machine.cpu),
          ram: Number(machine.ram),
          ethernets: Number(machine.ethernets)
        })
      }
    })
  };


   componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.data) {
        let state = {
          machine: {
            cpu: this.props.data?.cpu.toString(),
            ram : this.props.data?.ram.toString(),
            ethernets:this.props.data?.ethernets.toString()
          },
          isDirty: {
            cpu: false,
            ram : false,
            ethernets: false
          },
          errors: {},
        };
        this.setState(state);
      }
    }
  }

  render() { 
    const { machine, errors } = deepClone(this.state);
    // const { data } = deepClone(this.props);
    
    return ( 
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle("cancel")}
      >
        <ModalHeader toggle={() => this.props.toggle("cancel")}>
          Modify Virtual Machine
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
        >
          <FormGroup>
            <Label>CPU(s)</Label>
            <Input
              type="number"
              placeholder="Enter CPU(s)"
              value={machine.cpu}
              onChange={(e) => this._handleOnChange("cpu", e.target.value)}
            />
            {errors && <div className="validation-error">{errors.cpu}</div>}
          </FormGroup>
          <FormGroup>
            <Label>RAM</Label>
            <Input
              type="number"
              placeholder="Enter RAM"
              value={machine.ram}
              onChange={(e) =>
                this._handleOnChange("ram", e.target.value)
              }
            />
            {errors && (
              <div className="validation-error">{errors.ram}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label>No. of Ethernet Ports</Label>
            <Input
              type="number"
              placeholder="Enter No. of Ethernet Ports"
              value={machine.ethernets}
              onChange={(e) =>
                this._handleOnChange("ethernets", e.target.value)
              }
            />
            {errors && (
              <div className="validation-error">{errors.ethernets}</div>
            )}
          </FormGroup>
          
        </ModalBody>
        <ModalFooter>
          <Button
            className="modalBtnCancel"
            onClick={() => this.props.toggle("cancel")}
          >
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={(e) => this._handleOnSubmit(e)}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
     );
  }
}
 
export default ModifyVirtualMachineModal;