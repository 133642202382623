import html2canvas from "html2canvas";
import React from 'react';

export const useScreenshot = () => {
    const [image, setImage] = React.useState(null)
    const [error, setError] = React.useState(null)

    const takeScreenShot = (node, conf) => {
        if (!node) {
            return
        }
        return html2canvas(node, conf).then(canvas => {
            const base64Image = canvas.toDataURL();
            setImage(base64Image);
            return base64Image;        
        })
            .catch(err => {
                console.log("image error ", err)
                setError(err)
            })
    }

    return [image, takeScreenShot, {
        error: error
    }]
}