import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Label } from 'reactstrap';
import { deleteStorageFile, downloadStorageFile, getAllLabDrawings, getLabFiles, addLabFiles } from '../../../../http/http-calls';
import axios from 'axios';
import config from '../../../../config/'

const StorageModal = ({ isOpen, toggle, onFileChange, id, onDelete }) => {
  const [files, setFiles] = useState([]);
  const [storage, setStorage] = useState([]);
  const [error, setError] = useState(null);

  const fetchDrawings = async () => {
    if (isOpen) {
      try {
        const all_files = await getAllLabDrawings(id);
        const labFiles = await getLabFiles(id);
        const filteredFiles = all_files.labDrawings.filter(file => file._lab === id);
        setFiles(filteredFiles);
        setStorage(labFiles);
      } catch (err) {
        setError('Failed to fetch drawings');
      }
    }
  };


  const _onFileSelectFromStorage = async (event) => {
    let formData = new FormData();
    formData.append('labFile', event.target.files[0]);
    addLabFiles(id, formData)
      .then((resp) => {
        fetchDrawings()
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchDrawings();
  }, [isOpen, id]);

  const deleteFile = async (file, fileId, index) => {

    let cookies = document.cookie;
    let cookieArray = cookies.split('; ');
    let authToken;

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      let [name, value] = cookie.split('=');
      if (name === 'bearer') {
        authToken = value
      }
    }

    if (!file._drawing) {
      try {
        await deleteStorageFile(fileId);
        onDelete(index);
        fetchDrawings();
      } catch (err) {
        console.log(err);
        setError('Failed to delete file');
      }
    }
    if (file._drawing) {
      try {
        axios({
          method: "DELETE",
          url: `${config.baseUrl}labDrawing/${file._id}`,
          withCredentials: true,
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        }).then(res => {
          fetchDrawings();
        })
      } catch (err) {
        console.log("error")
      }
    }
  };

  const downloadFile = async (file) => {
    try {
      let url;
      let filename = ''
      if (file._drawing) {
        url = file._drawing.url
        filename = url.split('/')[url.split('/').length - 1]
      } else if (file.url?.split('.')[1] === 'pcap') {
        url = file.url
        filename = url.split('/')[url.split('/').length - 1]
      } else {
        url = '/files/' + file.url.split('/')[file.url.split('/').length - 2] + '/' + file.url.split('/')[file.url.split('/').length - 1]
        filename = url.split('/')[url.split('/').length - 1]
      }
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(error => console.log('There was a problem with the fetch operation:', error));
    } catch (err) {
      console.log(err);
      setError('Failed to download file');
    }
  };

  const fileToShow = files.concat(storage?.storage?._storage || []);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className='modal-lg'>
      <ModalHeader toggle={toggle}>Lab Files</ModalHeader>
      <ModalBody>
        {error && <div className="error">{error}</div>}
        <ul className='uploadfiles'>
          {fileToShow.length ? (
            fileToShow.map((el, index) => (
              <li key={index}>
                <div className='storage_file' style={{ cursor: 'pointer' }}>
                  {true ? (
                    <img
                      src={el.url?.split('.')[1] === 'pcap' ? 'https://cdn.icon-icons.com/icons2/2699/PNG/512/wireshark_logo_icon_169697.png' :
                        el._drawing?.url || `/files/${el.path?.split('/')[3]}/${el.path?.split('/')[4]}`}
                      onClick={() => downloadFile(el)}
                      alt='placeholder'
                    />
                  ) : el.mimetype === 'application/pdf' ? (
                    <i className='fa fa-file-pdf-o' onClick={() => downloadFile(el)}></i>
                  ) : (
                    <i className='fa fa-file' onClick={() => downloadFile(el)}></i>
                  )}
                  <i className='remove fa fa-times' onClick={(e) => deleteFile(el, el._id, index)}></i>
                </div>
                <div className='content'>
                  <p>{el.name || el.fileName || el.url.split('/')[el.url.split('/').length - 1]}</p>
                </div>
              </li>
            ))
          ) : (
            <li className='noData'>No data available</li>
          )}
          <li className='fileUploadButton'>
            <input type='file' id='chooseFile' onChange={(e) => _onFileSelectFromStorage(e)} hidden />
            <Label for='chooseFile' className='ChooseFile'>
              <i className='fa fa-plus'></i>
            </Label>
          </li>
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' className='modalBtnCancel' onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StorageModal;