import React, { Component } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import PublicRoute from './modules/general/components/public-route';
import { persistor, store } from './redux/store';
import Login from './modules/public/pages/login';
import ForgotPassword from './modules/public/pages/forgot-password';
import SignUp from './modules/public/pages/signup';
import VerifyOTP from './modules/public/pages/verify-otp.jsx';
import Layout from './modules/private/containers/layout';
import FullPageLoader from './modules/general/container/full-page-loader';
import { SocketProvider } from 'socket.io-react';
import io from 'socket.io-client';
import ProtectedRoute from './modules/general/components/protected-route';
import DesignlabLayout from './modules/private/containers/designlab-layout';
import './assets/css/jsplumbtoolkit.css';
import './assets/css/jsplumbtoolkit-syntax-highlighter.css';
import './assets/css/jsplumbtoolkit-demo-support.css';
import './assets/css/jsplumbtoolkit-editable-connectors.css';
import config from './config';
import ConsoleModal from './modules/private/components/ConsoleModal';
import { ToastContainer, toast } from 'react-toastify';
const socketUrl = (' ' + config.socketUrl).slice(1);
const socket = io.connect(socketUrl);

class App extends Component {
  componentWillUnmount() {
    console.log('here :>> ');
    socket.on('disconnect', () => {
      console.log('socket disconnect');
    });
  }
  render() {
    // Mute console.log and console.info
    console.log = () => { };
    console.info = () => { };
    return (
      <Provider store={store}>
        <ToastContainer />
        <PersistGate persistor={persistor}>
          <SocketProvider socket={socket}>
            <BrowserRouter>
              <FullPageLoader />
              <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
              <div className='App'>
                {/* <Navbar /> */}

                <Switch>
                  <PublicRoute path='/login' component={Login} redirectRoute={'/dashboard'} />
                  <PublicRoute path='/forgot-password' component={ForgotPassword} redirectRoute={'/dashboard'} />
                  <PublicRoute path='/signup' component={SignUp} redirectRoute={'/dashboard'} />
                  <ProtectedRoute path={'/design-lab/:id'} component={DesignlabLayout} redirectRoute={'/login'} />
                  <Route path={'/verify-otp'} component={VerifyOTP} />
                  <Route path='/' component={Layout} />

                  <Route path='*' render={() => <Redirect to='/' />} />
                  <ConsoleModal />
                </Switch>
              </div>
            </BrowserRouter>
          </SocketProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
