import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export const Paginate = ({ currentPage, totalPages, paginate }) => {

    return (
        <Pagination aria-label="Page navigation" style={{float: 'right'}}>
            <PaginationItem disabled={currentPage === 1 ? true : false}>
                <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
            </PaginationItem>
            {[...Array(totalPages)].map((each, index) => (
                <PaginationItem key={index} className={index+1===currentPage ? 'active' : ''}>
                    <PaginationLink onClick={() => paginate(index + 1)}>
                        {index + 1}
                    </PaginationLink>
                </PaginationItem>
            ))}
            <PaginationItem disabled={currentPage === totalPages ? true : false}>
                <PaginationLink next onClick={() => paginate(currentPage + 1)} />
            </PaginationItem>
        </Pagination>
    )
}

export default Paginate;