import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Table,
  FormGroup,
  Input,
} from "reactstrap";
import { deepClone } from "../../../../helper-methods";
import Switch from "react-switch";

class LabNodeListModal extends Component {
  state = {
    searchTerm: '',
    nodeList:[],
    nodeListBackup:[],
  };

  _handleOnSearch = (value)=>{
    this.setState({ searchTerm: value });
    const { nodeListBackup } = deepClone(this.state);
    let nodeList = deepClone(nodeListBackup);
    if(value.trim().length){
      nodeList = nodeList.filter((each)=>{
        return (
          each.name.toLowerCase().includes(value.toLowerCase()) ||
          each.description.toLowerCase().includes(value.toLowerCase()) ||
          each.vmState.toLowerCase().includes(value.toLowerCase())
        )
      })
      this.setState({ nodeList });
    } else {
      this.setState({ nodeList: nodeListBackup });
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.isOpen!==this.props.isOpen){
      if(this.props.data){
        this.setState({ 
          searchTerm: '',
          nodeList: this.props.data,
          nodeListBackup: this.props.data
         });
      }
    }
  }

  handleChange=(node)=>{
    const { nodeList } = deepClone(this.state);
    const nodeIndex = nodeList.findIndex((e)=>e.id===node.id);
    console.log('object :>> ', nodeIndex, nodeList[nodeIndex], node.id);
    if(nodeList[nodeIndex].vmState==='running'){
      console.log('need to stop :>> ');
      this.props.toogleNodeState('stop', node.id);
    } else {
      console.log('need to start :>> ');
      this.props.toogleNodeState('start', node.id);
    }
    nodeList[nodeIndex].vmState = nodeList[nodeIndex].vmState === 'stopped' ? 'running' : 'stopped';
    this.setState({ nodeList });
  }
  render() {
    const { nodeList, searchTerm } = deepClone(this.state);
    
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal-lg"
      >
        <ModalHeader toggle={() => this.props.toggle()}>
          Node List
        </ModalHeader>
        <ModalBody>
          <FormGroup className="d-flex justify-content-end">
            <Input type="text" placeholder="Search..." style={{ width: 240 }} value={searchTerm} onChange={(e)=>this._handleOnSearch(e.target.value)} />
          </FormGroup>

          {nodeList.length>0 ? <Table className="tablesorter tableInModal" responsive>
            <thead>
              <tr>
                <th>Node Name</th>
                <th>Description</th>
                <th>Template</th>
                <th>RAM, CPUs, ETH</th>
                {/* <th>Icon</th> */}
                <th>State</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                React.Children.toArray(nodeList.map((each, index)=>
                <tr>
                <td style={{whiteSpace:'nowrap'}}> <img src={each._icon?.url} alt={"icon-image"+index} width="20" className="mr-2"/>{each.name}</td>
                <td>{each.description || 'N/A'}</td>
                <td>{each._template.name}</td>
                <td style={{whiteSpace:'nowrap'}}> {each.ram} MB, &nbsp;{each.cpu}, &nbsp; {each.ethernets}</td>
                <td>{each.vmState}</td>
                <td>  
                <Switch onChange={()=>this.handleChange(each)} 
                checked={each.vmState==="running"?true:false} 
                uncheckedIcon={""} checkedIcon={""} height={20} width={40} className="switch-toggle-button" 
                onColor={"#F18F3C"}/>
                </td>
                {/* <td>
                  <Button className="viewBtn">Open</Button>
                  <Button className="stopBtn ml-3">Stop</Button>
                </td> */}
              </tr>
                )                               
                )
              }
            </tbody>
          </Table>:
          <p className="text-center">No Nodes Found.</p>}
        </ModalBody>
        <ModalFooter>
          <Button className="modalBtnSave" onClick={() => this.props.toggle()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default LabNodeListModal;
