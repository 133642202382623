import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import {
  assignTeamToLab,
  teamList,
  unassignTeam,
} from "../../../http/http-calls";
import { ToastsStore } from "react-toasts";

class AssignTeam extends Component {
  state = {
    teamId: [],
    teamIdBackup: [],
    selectedTeam: "",
    isDirty: {
      name: false,
    },
    TeamsData: [],
    errors: {},
  };

  //closing modal
  _closeModal = (action) => {
    console.log("in _closeModal");
  };

  componentDidMount() {
    console.log("componentDidMount", this.props);
  }

  _getTeamsList = () => {
    this.props.showLoader();
    teamList()
      .then((resp) => {
        this.setState({
          TeamsData: resp.teams,
        });
        this.props.hideLoader();
      })
      .catch((err) => {
        this.props.hideLoader();
        ToastsStore.error("Team list loading failed :- " + err.reason);
      });
  };
  componentDidUpdate(previousProps, previousState) {
    console.log("componentDidMount", this.props);
    if (this.props.data && previousProps.isOpen !== this.props.isOpen) {
      console.log("object :", this.props.data);
      this._getTeamsList();
      
      let ids = this.props.data._assignedTeams.map((item) => {return ({ isNew: false, id: item.id })});
      console.log(ids)
      this.setState({ teamId: ids, teamIdBackup: ids });
    }
  }

  //handling toggle of modal here
  _handleToggle = () => {
    this.setState({
      teamId: "",
      isDirty: {
        name: false,
      },
      errors: {},
    });
    this.props.toggle();
    // this.props.reload();
  };

  //handling input here
  _handleOnChange = (value) => {
    let data = { isNew: true, id: value };
    this.setState({
      teamId: [...this.state.teamId, data],
      selectedTeam: "",
    });
  };

  //handling delete here

  _handleOnDelete = (value) => {
    let list = this.state.teamId.filter((each) => each.id !== value);

    console.log(list);
    this.setState({
      teamId: list,
    });
  };

  //for validation
  _validateField = () => {
    const { team, isDirty, errors } = this.state;
    Object.keys(team).forEach((each) => {
      if (each === "name" && isDirty.name) {
        if (!team.name.trim().length) {
          errors[each] = "*Required";
        } else if (
          team.name.trim().length &&
          team.name.match(/^[a-zA-Z ]*$/) &&
          team.name.trim().length <= 2
        ) {
          errors[each] = "Name should be greater than two characters";
        } else {
          delete errors[each];
          isDirty.name = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  //add new Team here
  _assignTeam = (e) => {
    e.preventDefault();
    let newTeam = this.state.teamId
    // eslint-disable-next-line
    .filter((each) => each.isNew == true)
    .map((item) => item.id);

  console.log(newTeam);

    // if (this.state.teamId.length !== this.props.data._assignedTeams.length) {
      this.props.showLoader();
      let promiseArray = [];
      promiseArray = this.state.teamId.map((item) =>
        assignTeamToLab({ labId: this.props.data.id, teamId: item.id })
      );
      // }

      console.log(promiseArray);
      // assignTeamToLab(teamData)
      //   .then((resp) => {
      //     console.log(resp);
      //     this.props.hideLoader();
      //     ToastsStore.success("Team Assigned successfully");
      //     this._handleToggle();
      //   })
      //   .catch((err) => {
      //     this.props.hideLoader();
      //     ToastsStore.error("Assigning Team failed :- " + err.reason);
      //   });
      // }
      if (promiseArray.length > 0) {
        Promise.all(promiseArray).then(
          (resp) => {
            if (resp) {
              this.props.hideLoader();
              ToastsStore.success("Team Assigned successfully");
              this.props.isOpen === true && this._handleToggle();
              this.props.reload();
            }
          },
          (error) => {
            console.log("error :", error);
            this.props.hideLoader();
            ToastsStore.error("Assigning Team failed :- " + error.reason);
          }
        );
      }
    // }
    this._deleteTeam()
  };

  _deleteTeam = (id) => {
    console.log(id);
    // if (this.state.teamId.length <= this.state.teamIdBackup.length) {
    //   this.props.showLoader();
    //   let data = {
    //     labId: this.props.data.id,
    //     teamId: id,
    //   };
    //   unassignTeam(data)
    //     .then((resp) => {
    //       console.log("Team delete response :- ", resp);
    //       this.props.hideLoader();
    //       ToastsStore.success("Team deleted successfully");
    //       this._handleToggle();
    //       this.props.reload();
    //     })
    //     .catch((err) => {
    //       this.props.hideLoader();
    //       ToastsStore.error("Member deletion failed :- ", err.reason);
    //     });
    // } else {
    //   let dataList = this.state.teamId.filter((item) => item !== id);
    //   this.setState({ teamId: dataList });
    // }

    let unique = this.state.teamIdBackup
    .map((i) => i.id)
    // eslint-disable-next-line
    .filter((o) => this.state.teamId.map((i) => i.id).indexOf(o) == -1);

  console.log(unique);
  let promiseArray = [];
  promiseArray =
    unique.length &&
    unique.map((item) =>
      unassignTeam({
        labId: this.props.data.id,
        teamId: item,
      })
    );

  console.log(promiseArray);

  if (promiseArray.length > 0) {
    Promise.all(promiseArray).then(
      (resp) => {
        if (resp) {
          this.props.hideLoader();
          ToastsStore.success("Team Deleted successfully");
          this.props.isOpen === true && this._handleToggle();
          this.props.reload();
        }
      },
      (error) => {
        console.log("error :", error);
        this.props.hideLoader();
        ToastsStore.error("Team deletion failed :- " + error.reason);
      }
    );
  }
  };
  render() {
    console.log(this.state);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._handleToggle()}
        className="modal-lg"
      >
        <ModalHeader toggle={() => this._handleToggle()}>
          {" "}
          Assign Team
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Label>Team</Label>
                <Input
                  type="select"
                  placeholder="Enter Member"
                  value={this.state.selectedTeam}
                  onChange={(e) => this._handleOnChange(e.target.value)}
                >
                  <option>Select</option>

                  {this.state.TeamsData &&
                    this.state.TeamsData.map((item) => {
                      return (
                        <option
                          value={item.id}
                          disabled={
                            this.state.teamId &&
                            this.state.teamId.filter((each) => {
                              return each.id === item.id;
                            })[0]
                              ? true
                              : false
                          }
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </Input>
                {/* {this.state.teamId &&
                  this.state.TeamsData
                    .filter((item) => {
                      return this.state.teamId.includes(item.id);
                    })
                    .map((each) => {
                      return (
                        <Badge color="secondary">
                          {each.name}
                          <Button color="link">
                            <i
                              className="fa fa-times-circle text-danger"
                              onClick={() => this._deleteTeam(each.id)}
                            ></i>
                          </Button>
                        </Badge>
                      );
                    })} */}

                {this.state.teamId &&
                  this.state.TeamsData
                  .filter((each) =>
                    this.state.teamId.some((item) => item.id === each.id)
                  )
                  .map((each) => {
                    return (
                      <Badge color="secondary">
                        {each.name}
                        <Button color="link">
                          <i
                            className="fa fa-times-circle text-danger"
                            onClick={() => this._handleOnDelete(each.id)}
                          ></i>
                        </Button>
                      </Badge>
                    );
                  })}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            className="modalBtnCancel"
            onClick={() => this._handleToggle()}
          >
            Cancel
          </Button>
          <Button className="modalBtnSave" onClick={(e) => this._assignTeam(e)}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignTeam);
