/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import DialActionMenu from './DialActionMenu';

// reactstrap components
import { Nav, UncontrolledTooltip, NavLink as ReactstrapNavLink } from 'reactstrap';

var ps;

class DesignLabSidebar extends React.Component {
  state = {
    zoom: 0.7,
    isOpen: false
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    // return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    // if (navigator.platform.indexOf('Win') > -1) {
    //   ps = new PerfectScrollbar(this.refs.sidebar, {
    //     suppressScrollX: true,
    //     suppressScrollY: false
    //   });
    // }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps?.destroy();
    }
  }
  linkOnClick = () => {
    document.documentElement.classList.remove('nav-open');
  };

  _actionOnClick = (e, menu) => {
    console.log('menu :>> ', menu);
    e.preventDefault();
    console.log('here :>> ');
    this.props.toggleSidebar();
    if (this.state.isOpen) {
      document.getElementById('openSidebarMenu').click();
    }
    this.setState({ isOpen: false });

    this.props.onMenuAction(menu.key);
  };

  _onZoomChange = (value) => {
    // console.log('value :>> ', value);
    this.props.onUpdateZoom(Number(value));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.zoom !== this.props.zoom) {
      this.setState({ zoom: this.props.zoom });
    }
  }

  _toggleMenu = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { bgColor, routes, rtlActive, logo, actionMenu } = this.props;
    return (
      <>
        <DialActionMenu actionMenu={actionMenu} actionOnClick={this._actionOnClick} />
        {/* <div className='designLabSidebarWrapper'>
          <input type='checkbox' className='openSidebarMenu' id='openSidebarMenu' />
          <label htmlFor='openSidebarMenu' className='sidebarIconToggle' onClick={() => this._toggleMenu()}>
            <div className='spinner diagonal part-1'></div>
            <div className='spinner horizontal'></div>
            <div className='spinner diagonal part-2'></div>
          </label>

          <div className='sidebar designLabSidebar' data={bgColor} id='sidebarMenu'>
            <div className='sidebar-wrapper' ref='sidebar'>
              <Nav className='sidebarMenuInner'>
                {actionMenu.map((prop, key) => {
                  if (prop.redirect) return null;
                  return (
                    <li key={key} id={'UncontrolledTooltipExample' + key}>
                      <div onClick={(e) => this._actionOnClick(e, prop)} className='d-flex justify-content-end'>
                        {!prop.isImage ? (
                          <i className={prop.icon} />
                        ) : (
                          <img src={prop.icon} className='lab-menu-img'></img>
                        )}
                        <p style={{ marginLeft: '35px' }}>{rtlActive ? prop.rtlName : prop.name}</p>
                      </div>
                    </li>
                  );
                })}
              </Nav>
              <div className='zoom-slider-wrap'>
                Zoom&nbsp;
                <input
                  type='range'
                  value={this.state.zoom}
                  onChange={(e) => this._onZoomChange(e.target.value)}
                  step='0.10'
                  min='0.10'
                  max='2'
                />
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

DesignLabSidebar.defaultProps = {
  rtlActive: false,
  bgColor: 'primary',
  routes: [{}]
};

DesignLabSidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(['primary', 'blue', 'green']),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string
  })
};

export default DesignLabSidebar;
