import React, { useState } from "react";
import {
    Row,
    Col,
    //Card,
    Input,
    FormGroup,
    Button,
    Label,
    //Badge,
} from "reactstrap";
import Switch from "react-switch";
import Card from "reactstrap/lib/Card";
import config from "../../../config";
import { deepClone } from '../../../helper-methods'

const StepTwo = (props) => {
    const [fields, setFields] = useState([])
    // const [errors, setErros] = useState({})
    const [vmtemplatesFields, setVmtemplateFiedls] = useState(deepClone(config.vmTemplateFields))
    const [textArea, setTextArea] = useState("[]")
    const [templateMap, setTemplateMap] = useState({})

    const _addField = () => {
        setFields(prev => {
            prev.push({})
            return [...prev]
        })
    }

    const _onFieldNameChange = (e, i) => {
        const index = e.target.value
        templateMap[i] = index
        const fieldData = deepClone(vmtemplatesFields.filter((each) => each.type[0] === props.template.baseBin)[index])
        // if (fieldData.variants) {
        //     delete fieldData.variants
        // }
        console.log("field Name ===> ", fieldData)
        setFields(prev => {
            prev[i] = fieldData
            prev[i]["label"] = fieldData.fieldName
            return [...prev]
        })
    }

    const _fieldOnChange = (fieldType, value, index) => {
        setFields(prev => {
            console.log(prev[index])
            prev[index][fieldType] = value
            return [...prev]
        })
        setTextArea(JSON.stringify(fields, undefined, 2))
    }

    const _submit = () => {
        props._template(fields)
    }

    const ontextAreaChange = (e) => {
        setTextArea(e.target.value)
        setFields(JSON.parse(textArea))
    }

    return (
        <>
            <Row>
                <Col md="12">
                    <FormGroup>
                        <div className="d-flex justify-content-between">
                            <Label>Fields to Show</Label>
                            <div className="d-flex align-item-center">
                                <Label className="mr-2">Input</Label>
                                <Switch
                                    onChange={props.handleChange}
                                    checked={props.input}
                                    uncheckedIcon={""}
                                    checkedIcon={""}
                                    onColor={"#f18f3c"}
                                />
                                <Label className="ml-2">Raw</Label>
                            </div>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            {/* 
            <Card>
                
                <Row className="align-items-center ml-4">
                    {props.input === false && <>
                        {
                            
                        }
                        <Col md={4}>
                            <FormGroup>
                                <Label>Field Name</Label>
                                <Input
                                    type="select"
                                    placeholder="File Name"
                                //value={item.fieldName}
                                >
                                    <option>Select</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Data Type</Label>
                                <Input
                                    type="select"
                                    placeholder="File Name"
                                //value={item.fieldName}
                                >
                                    <option>Select</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Default Value</Label>
                                <Input
                                    type="text"
                                    placeholder="Default Value"
                                //value={item.fieldName}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Label</Label>
                                <Input
                                    type="select"
                                    placeholder="File Name"
                                //value={item.fieldName}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className="mt-3">
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        placeholder="active"
                                    //checked={template.active}

                                    />
                                    Mandatory
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className="mt-3">
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        placeholder="active"
                                    //checked={template.active}

                                    />
                                    Editable
                                </Label>
                            </FormGroup>
                        </Col>
                    </>}
                    {props.input === true && <Col md={12}>
                        <FormGroup>
                            <Input
                                type="textarea"
                                placeholder="Enter Valid JSON"
                                name="textArea"
                                // value={JSON.stringify(this.state.fieldsToShow)}
                                value={props.template.textArea}
                                onChange={(e) =>
                                    props._handleOnChange("textArea", e.target.value)
                                }
                                // onBlur={(e) => this._validJson(e.target.value)}
                                onBlur={(e) =>
                                    props.markDirty(e.target.name, e.target.value)
                                }
                                style={{ height: "200px", maxHeight: "200px" }}
                            />
                            
                        </FormGroup>
                    </Col>}
                    <Col md={12} className="text-right">
                        <Button className="addBtn" onClick={props.previousStep}>
                            Previous
                        </Button>
                        <Button className="addBtn">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Card> */}
            <Card>
                {!props.input && <Row>
                    {fields.map((each, i) => (
                        <>
                            <Col md="6">
                                <FormGroup>
                                    <Label>Field Name</Label>
                                    <Input type="select" onChange={(e) => _onFieldNameChange(e, i)} value={templateMap[i]} >
                                        <option>Select</option>
                                        {vmtemplatesFields.filter((each) => each.type[0] === props.template.baseBin).map((item, index) => (
                                            <option value={index} key={index}>{item.fieldName}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            {each.fieldName &&
                                <Col md="6">
                                    <Label>Default Value</Label>
                                    {
                                        each.variants && each.dataType !== "Boolean" ? (
                                            <Input type="select" onChange={(e) => _fieldOnChange("defaultValue", e.target.valuem, i)}>
                                                <option>Select</option>
                                                {each.variants.map((item, index) => (
                                                    <option value={item} key={index}>{item}</option>
                                                ))}
                                            </Input>

                                        ) :

                                            <>
                                                {each.dataType !== "Boolean" &&
                                                    each.dataType !== "Array" ? (
                                                    <>
                                                        <Input
                                                            type={
                                                                // eslint-disable-next-line
                                                                each.dataType == "Number"
                                                                    ? "number"
                                                                    : "text"
                                                            }
                                                            placeholder="Default Value"
                                                            value={each.defaultValue || ''}
                                                            onChange={(e) =>
                                                                _fieldOnChange(
                                                                    "defaultValue",
                                                                    e.target.value,
                                                                    i,
                                                                )
                                                            }
                                                        />
                                                    </>
                                                ) :
                                                    // eslint-disable-next-line
                                                    each.dataType == "Array" ? (
                                                        <>
                                                            <Input
                                                                type="text"
                                                                placeholder="Enter Comma Separated value"
                                                                // value={this.state.tempDefaultvalue}
                                                                value={each.defaultValue || ''}
                                                                onChange={
                                                                    (e) =>
                                                                        _fieldOnChange(
                                                                            "defaultValue",
                                                                            e.target.value,
                                                                            i
                                                                        )

                                                                }

                                                            />
                                                            {each.defaultValue !== undefined &&
                                                                this.checkArrayHasDuplicate(
                                                                    each?.defaultValue.split(",")
                                                                ) && (
                                                                    <div className="validation-error">
                                                                        Duplicate Value Exits
                                                                    </div>
                                                                )}

                                                        </>
                                                    ) : (
                                                        <div className="d-flex justify-content-center">
                                                            <div>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        placeholder="defaultValue"
                                                                        onChange={(e) =>
                                                                            _fieldOnChange(
                                                                                "defaultValue",
                                                                                e.target.checked,
                                                                                i
                                                                            )
                                                                        }
                                                                        checked={each?.defaultValue || ""}
                                                                    />{" "}
                                                                    {each?.defaultValue
                                                                        ? "True"
                                                                        : "false"}
                                                                </Label>
                                                            </div>

                                                        </div>
                                                    )}
                                            </>}
                                </Col>
                            }
                            {/* {each.fieldName && <Col md="4">
                                <FormGroup>
                                    <Label> Label</Label>
                                    <Input
                                        type="text"
                                        placeholder="Label"
                                        // value={item.label}
                                        value={each.label || ''}
                                        onChange={(e) =>
                                            _fieldOnChange(
                                                "label",
                                                e.target.value,
                                                i
                                            )
                                        }
                                    />
                                </FormGroup>
                            </Col>} */}
                        </>
                    ))}
                </Row>}
                {!props.input && <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button className="addBtn" onClick={_addField}>Add</Button>
                </Row>}
                {/* {!props.input ? (
                    vmtemplatesFields.map((item, index) => (
                        <CardBody>
                            <Input
                                type="checkbox"
                                placeholder="Selected"
                                className="checkBox"
                                onClick={(e) =>
                                    props._fieldOnChange(
                                        "isSelected",
                                        e.target.checked,
                                        item,
                                        index
                                    )
                                }
                                checked={item?.isSelected || ""}
                            />

                            <Row style={{ marginLeft: '0px' }}>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Field Name</Label>
                                        <Input
                                            type="text"
                                            placeholder="File Name"
                                            value={item.fieldName}
                                            onChange={(e) =>
                                                props._fieldOnChange(
                                                    "fieldName",
                                                    e.target.value,
                                                    item,
                                                    index
                                                )
                                            }
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label> Data Type</Label>
                                        <Input
                                            type="select"
                                            placeholder="Data Type"
                                            value={item.dataType || ''}
                                            onChange={(e) =>
                                                props._fieldOnChange(
                                                    "dataType",
                                                    e.target.value,
                                                    item,
                                                    index
                                                )
                                            }
                                            readOnly
                                        >
                                            <option>Select</option>
                                            <option value="Number">Number</option>
                                            <option value="String">String</option>
                                            <option value="Boolean">Boolean</option>
                                            <option value="Array">Array</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label> Default Value</Label>
                                        {item.dataType !== "Boolean" &&
                                            item.dataType !== "Array" ? (
                                            <>
                                                <Input
                                                    type={
                                                        // eslint-disable-next-line
                                                        item.dataType == "Number"
                                                            ? "number"
                                                            : "text"
                                                    }
                                                    placeholder="Default Value"
                                                    value={item.defaultValue || ''}
                                                    onChange={(e) =>
                                                        props._fieldOnChange(
                                                            "defaultValue",
                                                            e.target.value,
                                                            item,
                                                            index
                                                        )
                                                    }
                                                />
                                            </>
                                        ) :
                                            // eslint-disable-next-line
                                            item.dataType == "Array" ? (
                                                <>
                                                    <Input
                                                        type="text"
                                                        placeholder="Enter Comma Separated value"
                                                        // value={this.state.tempDefaultvalue}
                                                        value={item.defaultValue || ''}
                                                        onChange={
                                                            (e) =>
                                                                props._fieldOnChange(
                                                                    "defaultValue",
                                                                    e.target.value,
                                                                    item,
                                                                    index
                                                                )
                                                            // console.log(e.target.value.split(','))
                                                        }
                                                    
                                                    />
                                                    {item.defaultValue !== undefined &&
                                                        this.checkArrayHasDuplicate(
                                                            item?.defaultValue.split(",")
                                                        ) && (
                                                            <div className="validation-error">
                                                                Duplicate Value Exits
                                                            </div>
                                                        )}
                                                    
                                                </>
                                            ) : (
                                                <div className="d-flex justify-content-center">
                                                    <div>
                                                        <Label check>
                                                            <Input
                                                                type="checkbox"
                                                                placeholder="defaultValue"
                                                                onChange={(e) =>
                                                                    props._fieldOnChange(
                                                                        "defaultValue",
                                                                        e.target.checked,
                                                                        item,
                                                                        index
                                                                    )
                                                                }
                                                                checked={item?.defaultValue || ""}
                                                            />{" "}
                                                            {item?.defaultValue
                                                                ? "True"
                                                                : "false"}
                                                        </Label>
                                                    </div>
                                                    
                                                </div>
                                            )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ marginLeft: '0px' }}>
                                <Col md="4">
                                    <FormGroup>
                                        <Label> Label</Label>
                                        <Input
                                            type="text"
                                            placeholder="Label"
                                            // value={item.label}
                                            value={item.label || ''}
                                            onChange={(e) =>
                                                props._fieldOnChange(
                                                    "label",
                                                    e.target.value,
                                                    item,
                                                    index
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <div className="mt-4 ml-4">
                                        {" "}
                                        <FormGroup>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    placeholder="Mandatory"
                                                    onChange={(e) =>
                                                        props._fieldOnChange(
                                                            "isMandatory",
                                                            e.target.checked,
                                                            item,
                                                            index
                                                        )
                                                    }
                                                    checked={item?.isMandatory || ""}
                                                />{" "}
                                                Mandatory
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="mt-4 ml-4">
                                        {" "}
                                        <FormGroup>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    placeholder="Editable"
                                                    onChange={(e) =>
                                                        props._fieldOnChange(
                                                            "isEditable",
                                                            e.target.checked,
                                                            item,
                                                            index
                                                        )
                                                    }
                                                    checked={item?.isEditable || ""}
                                                />{" "}
                                                Editable
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    ))
                ) : (
                )} */}
                {props.input && <FormGroup>
                    <Input
                        type="textarea"
                        placeholder="Enter Valid JSON"
                        name="textArea"
                        // value={JSON.stringify(this.state.fieldsToShow)}
                        value={textArea}
                        className="rawfields"
                        onChange={ontextAreaChange}
                        // onBlur={(e) => this._validJson(e.target.value)}
                        onBlur={(e) =>
                            props.markDirty(e.target.name, e.target.value)
                        }
                        style={{ height: "200px", maxHeight: "200px" }}
                    />

                </FormGroup>}
                <br />
                <br />
                <Col md={12} className="text-right">
                    <Button className="addBtn" onClick={props.previousStep}>
                        Previous
                    </Button>
                    {fields.length !== 0 && <Button className="addBtn" onClick={_submit}>
                        Submit
                    </Button>}
                </Col>
            </Card>

        </>
    )
}

export default StepTwo
