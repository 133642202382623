import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../../../http/http-calls';
import { Card, CardHeader, CardBody, FormGroup, Form, Input, Label, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { updateUserData } from '../../../redux/actions/user-data';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import jwt_decode from 'jwt-decode';
// import swal from 'sweetalert';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './custom.css';

class Login extends Component {
  state = {
    user: {
      userName: '',
      password: '',
    },
    isDirty: {
      userName: false,
      password: false,
    },
    errors: {},
    isVisible: false,
  };

  toastMessage = (error, msg) => {};

  //handling visibility of password
  _handleVisibilityToggle = () => {
    let { isVisible } = this.state;
    isVisible = !isVisible;
    this.setState({ isVisible });
  };

  //handling login input here
  _handleOnChange = (field, value) => {
    const { user, isDirty } = this.state;
    user[field] = value;
    isDirty[field] = true;
    this.setState({ user, isDirty }, () => {
      this._validateForm();
    });
  };

  //for validation
  _validateForm() {
    const { user, errors, isDirty } = this.state;
    Object.keys(user).forEach((each) => {
      if (each === 'password' && isDirty.password) {
        if (!user.password.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.password = false;
        }
      } else if (each === 'userName' && isDirty.userName) {
        if (!user.userName.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.userName = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  //login here
  _login = (e) => {
    console.log('login ===> ');
    e.preventDefault();
    let isDirty = {
      userName: true,
      password: true,
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateForm();
      if (!errors) {
        let loginData = {
          handle: this.state.user.userName,
          password: this.state.user.password,
        };
        this.props.showLoader();
        login(loginData)
          .then((resp) => {
            console.info('THIS IS THE RESPONSE FROM REQUEST', resp);
            localStorage.setItem('isAdmin', true);

            // This code is for setting the token, so that auto login happens for guacamole auto login
            function getCookie(cookieName) {
              let cookies = document.cookie;
              let cookieArray = cookies.split('; ');

              for (let i = 0; i < cookieArray.length; i++) {
                let cookie = cookieArray[i];
                let [name, value] = cookie.split('=');
                if (name === cookieName) {
                  return decodeURIComponent(value);
                }
              }
              return null;
            }
            const guac_token = {
              authToken: '',
              username: 'guacadmin',
              dataSource: 'postgresql',
              availableDataSources: ['postgresql', 'postgresql-shared'],
            };
            // let guac_cookie = getCookie('Guacamole-Token');
            // guac_token.authToken = guac_cookie;
            // localStorage.setItem('GUAC_AUTH', JSON.stringify(guac_token));
            // End for auto login set up for guacamole.

            this.props.updateUserData(resp);
            this.setState({
              user: {
                userName: '',
                password: '',
              },
              isDirty: {
                userName: false,
                password: false,
              },
              errors: {},
              isVisible: false,
            });
            const tokenData = jwt_decode(resp.token);
            console.log('tokenData :>> ', tokenData);
            this.props.hideLoader();
            if (tokenData.isMFAEnabled) {
              this.props.history.push('/verify-otp');
            } else {
              // ToastsStore.success('Successfully Logged-In');
              toast.success(`Successfully Logged-In`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              this.props.history.push('/dashboard');
            }
          })
          .catch((err) => {
            this.props.hideLoader();
            // ToastsStore.error('' + err.reason);
            toast.error(`Login failed: ${err.reason} `, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    });
  };

  render() {
    const { user, errors, isVisible } = this.state;

    return (
      <div className='loginForm full-page login-page'>
        <div className='authFormWrap'>
          <Form onSubmit={(e) => this._login(e)}>
            <Card className='card-black'>
              <img alt='EVOS Logo' className='companyLogo' src='../../../assets/img/evos-logo-1.png' />
              <CardHeader>
                <h1 style={{ margin: '0 auto' }} className='card-title'>
                  Login
                </h1>
                <div className='btn-holder'></div>
              </CardHeader>
              <CardBody>
                <Label>Username</Label>
                <FormGroup>
                  <i className='fa fa-user formIcon'></i>
                  <Input
                    placeholder='Enter'
                    type='text'
                    value={user.userName}
                    onChange={(e) => this._handleOnChange('userName', e.target.value)}
                  />
                  {errors && <div className='validation-error'>{errors.userName}</div>}
                </FormGroup>

                <Label>Password</Label>
                <FormGroup>
                  <i className='fa fa-lock formIcon lockIcon'></i>
                  <Input
                    placeholder='Enter'
                    type={isVisible ? 'text' : 'password'}
                    className='passwordField'
                    value={user.password}
                    onChange={(e) => this._handleOnChange('password', e.target.value)}
                  />
                  <i
                    className={isVisible ? 'fa fa-eye showPassword' : 'fa fa-eye-slash showPassword'}
                    onClick={() => this._handleVisibilityToggle()}></i>
                  {/* <i className="fa fa-eye showPassword d-none"></i> */}
                  {errors && <div className='validation-error'>{errors.password}</div>}
                </FormGroup>
                <div className='text-right'>
                  <Link className='link forgotPassword' to='/forgot-password'>
                    Forgot Password?
                  </Link>
                </div>

                <div className='btnLogin btn-4'>
                  <Button className='btnLogin btn-4 btn themeBtn' type='submit'>
                    <span className='loginBtnText'>Login</span>
                  </Button>
                </div>

                <Link className='link createAccount' to='/signup'>
                  New to EVOS? <span>Create Account</span>
                </Link>
              </CardBody>
            </Card>
          </Form>
        </div>

        <div className='authFooter'>
          EVOS &#169; {new Date().getFullYear()}.
          <div style={{ display: 'none' }}>
            Powered By:{' '}
            <a href='https://www.logic-square.com/' target='_blank' rel='noreferrer'>
              Logic Square
            </a>
          </div>
          <div>
            Powered By:{' '}
            <a href='http://www.gurooit.com' target='_blank' rel='noreferrer'>
              GuROO
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log('state :>> ', state);
  return {
    userData: state.userData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserData: (userData) => dispatch(updateUserData(userData)),
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
