import * as _deepClone from 'clone-deep';
import { ToastsStore } from 'react-toasts';
import moment from 'moment';
import { store } from '../redux/store';
import { clearUserData } from '../redux/actions/user-data';
import { startCase } from 'lodash';

export const deepClone = (data) => {
  return _deepClone(data);
};

export const showToast = (message, type = 'error', duration = 4000) => {
  ToastsStore[type](message, duration);
};

export const sleepTime = (n) => new Promise((r) => setTimeout(() => r(), n));

export const getPostedDateValue = (postedDate) => {
  if (moment().isSame(postedDate, 'year')) {
    return moment(postedDate).format('MMM DD');
  } else {
    return moment(postedDate).format('MMM DD, YYYY');
  }
};

export const sortedThreads = (arr, attr) => {
  return arr.sort((t1, t2) => {
    return new Date(t2[attr]) - new Date(t1[attr]);
  });
};

export const formatCurrencyValue = (data) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(data); /* $2,500.00 */
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getPhoneNumberFromBrackets = (number) => {
  return number.split(')')[1] ? number.split(')')[1] : number;
};

export const mouseX = (evt) => {
  if (evt.pageX) {
    return evt.pageX;
  } else if (evt.clientX) {
    return (
      evt.clientX +
      (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft)
    );
  } else {
    return null;
  }
};

export const mouseY = (evt) => {
  if (evt.pageY) {
    return evt.pageY;
  } else if (evt.clientY) {
    return (
      evt.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop)
    );
  } else {
    return null;
  }
};

export const logout = (navRef) => {
  store.dispatch(clearUserData());
  // Clear other reducers data also
  console.log('navRef :>> ', navRef);
  localStorage.removeItem('GUAC_AUTH');
  if (navRef) {
    navRef.replace('/login');
  } else {
    window.location.reload();
  }
};

export const formatLable = (label) => {
  return startCase(label);
};

export const getTextWidth = (textContent, fontSize) => {
  const text = document.createElement('span');
  document.body.appendChild(text);

  // text.style.font = "times new roman";
  text.style.fontSize = fontSize + 'px';
  text.style.height = 'auto';
  text.style.width = 'auto';
  text.style.position = 'absolute';
  text.style.whiteSpace = 'no-wrap';
  text.innerHTML = textContent;

  const width = Math.ceil(text.clientWidth);
  const formattedWidth = width + 'px';

  document.body.removeChild(text);
  return formattedWidth;
};

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  let byteCharacters = atob(b64Data);
  let byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);
    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
