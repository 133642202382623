import React, { useState, useRef } from 'react'
import { Resizable } from 're-resizable'
import { ModalHeader } from 'reactstrap'

const ResizableModal = (props) => {
    // const [ifDisabled, setIfDisabled] = useState(false)
    const [position, setPosition] = useState({ x: 500, y: 100 })

    const resizer = useRef()
    const _ref = useRef()
    const _relX = useRef()
    const _relY = useRef()

    const _onResizeStart = () => {
        // setIfDisabled(true)
    }

    const _onResizeStop = () => {
        // setIfDisabled(false)
    }

    const _onResize = (event, direction, refToElement, delta) => {
        props.onResize(event, direction, refToElement, delta)
        resizer.current.updateSize({ width: props.dimensions.width, height: props.dimensions.height })
    }

    const _onMouseDown = (e) => {
        if (e.button !== 0) {
            return
        }

        const { scrollLeft, scrollTop, clientLeft, clientTop } = document.body
        const { left, top } = _ref.current.getBoundingClientRect()
        _relX.current = e.pageX - (left + scrollLeft - clientLeft);
        _relY.current = e.pageY - (top + scrollTop - clientTop);
        document.addEventListener("mousemove", _onMouseMove)
        document.addEventListener("mouseup", _onMouseUp)
        e.preventDefault()
    }

    const _onMouseMove = (e) => {
        setPosition(prev => {
            prev.x = e.pageX - _relX.current
            prev.y = e.pageY - _relY.current
            return { ...prev }
        })
        e.preventDefault()
        props.onDrag()
    }

    const _onMouseUp = (e) => {
        document.removeEventListener("mousemove", _onMouseMove)
        document.removeEventListener("mouseup", _onMouseUp)
    }

    return (
        <div className="modal-2" ref={_ref} style={{ display: "table", position: "absolute", top: position.y, left: position.x, zIndex: props.isActive ? 1060 : 1050 }}>
            <div className={"draggable"} >
                <div className="modal-content">
                    <Resizable ref={resizer} onResize={_onResize} onResizeStart={_onResizeStart} onResizeStop={_onResizeStop} size={{ width: props.dimensions.width, height: props.dimensions.height }} bounds="window">
                        <div>
                            <ModalHeader onMouseDown={_onMouseDown} style={{ cursor: "all-scroll" }} onClick={props._makeModalActive}>
                                {props.data?.properties?.name}{`(${props.type})`}
                                <div className="adjust-minimize-icon" style={{ display: "inline-block" }}>
                                    {props.isActive && <i style={{ fontSize: "20px" }} className="fa fa-keyboard-o" title="Keyboard is active on this window"></i>}
                                    <i className="fa fa-minus" onClick={props._onMinimize} style={{ margin: "0 20px", fontSize: "20px" }}></i>
                                    <i className="fa fa-times-circle" onClick={(e) => props._onClose(e)} style={{ fontSize: "20px" }}></i>
                                </div>
                            </ModalHeader>
                            {props.children}
                        </div>
                    </Resizable>
                </div>
            </div>
        </div>
    )
}

export default ResizableModal