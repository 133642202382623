import React, { useEffect } from 'react'

export default function SVGComponent({ color, ctx }) {

    // eslint-disable-next-line
    const { vertex, surface, toolkit } = ctx;
    const data = vertex.data;

    // This `useEffect` will cause the node to be repainted if its label has changed. This will run after the label has
    // been changed and React has repainted what it needs to, so it's the right time to tell the renderer, since the new
    // size of the element is known.
    useEffect(() => {
        surface.repaint(vertex);
        // eslint-disable-next-line
    }, [data]);

    const getTextWidth=(textContent, fontSize)=> { 
  
      const text = document.createElement("span"); 
      document.body.appendChild(text); 

      // text.style.font = "times new roman"; 
      text.style.fontSize = fontSize + "px"; 
      text.style.height = 'auto'; 
      text.style.width = 'auto'; 
      text.style.position = 'absolute'; 
      text.style.whiteSpace = 'no-wrap'; 
      text.innerHTML = textContent;

      const width = Math.ceil(text.clientWidth); 
      const formattedWidth = width + "px"; 

      document.body.removeChild(text);
      return formattedWidth;
  } 

    const renderShape = (data)=>{
      // console.log('data.shapeData?.zIndex :>> ', data.shapeData.zIndex);
      let parent = document.querySelectorAll(`[data-jtk-node-id="${data.id}"]`)
      if(parent?.[0]){
        parent[0].style.zIndex=data.shapeData.zIndex;
      }
      switch(data.shapeType){
        case 'rect':
          return <svg style={{ width:data.w + 'px', height:data.h + 'px'}}  type="jtk"
          id={data?.shapeData?.id}>
            <rect x={0} y={0} width={data.w} height={data.h} 
            fill={data.shapeData?.color || '#efefef'} rx={data.shapeData?.borderRadius} ry={data.shapeData?.borderRadius}
            fillOpacity="0.8"></rect>;
          </svg>
        case 'circle':
          return <svg style={{ width:data.w + 'px', height:data.w + 'px'}}>
              <circle cx={data.w/2} cy={data.w/2} r={data.w/2} fill={data.shapeData?.color || '#efefef'}
              fillOpacity="0.8" stroke={data.shapeData?.color} />;
          </svg>
        case 'line':
          return <svg style={{ width:data.w + 'px', height:data.h + 'px'}}>
          <line x1={0} y1={0} x2={data.w} y2={data.h} strokeOpacity={data.shapeData.strokeOpacity/100}
          strokeDasharray={data.shapeData.lineType && data.shapeData.lineType==="dashed" ? '4,4' : '0,0'}
          style={{stroke:data.shapeData.color,strokeWidth: data.shapeData.strokeWidth || 2}} />
        </svg>
        case 'text':
          return <svg style={{ width: getTextWidth(data.shapeData.textContent, data.h/2), height: data.h + 'px'}}>
            <text x={0} y={data.h/2} fontSize={data.h/2} fill={data.shapeData.color}>{data.shapeData.textContent || 'Hello World'}</text>
          </svg>
        default :
          return <div>{data.name}</div>

      }
    }

    return (
      // <svg style={{ width:data.w + 'px', height:data.h/2 + 'px'}}>
        renderShape(data)
        // {/* l-r */}
        // {/* <line x1={0} y1={0} x2={data.w} y2={data.h} style={{stroke:'rgb(255,0,0)',strokeWidth:2}} /> */}
        // {/* r-l */}
        // {/* <line x1={data.w} y1={0} x2={0} y2={data.h} style={{stroke:'rgb(255,0,0)',strokeWidth:2}} /> */}
        // {/* horizontal */}
        // {/* <line x1={0} y1={data.h/2} x2={data.w} y2={data.h/2} style={{stroke:'rgb(255,0,0)',strokeWidth:2}} /> */}
        // {/* verticle */}
        // {/* <line x1={data.w/2} y1={0} x2={data.w/2} y2={data.h} style={{stroke:'rgb(255,0,0)',strokeWidth:2}} />  */}
        // {/* <text x={10} y={data.h/2} fontSize={data.h/2}>Hello World</text> */}
      // </svg>
    );
}