import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
// import config from "../../../../config";
import { deepClone, showToast } from "../../../../helper-methods";
import {
  addPathEmulation,
  createEmulationProfiles,
  deleteEmulationProfiles,
  getEmulationProfiles,
  getPortsAndExistingEmulations,
  removePathEmulation
} from "../../../../http/http-calls";
const customProfileOption = {
  name:'Custom',
  icon:'ethernet-add',
  activeIcon:'ethernet-add-active',
  latency:'',
  jitter:'',
  rate:'',
  packetLoss:'',
  toCreateNew: true
}
class PathEmulationModal extends Component {
  state = {
    portList: [],
    existingEmulations: [],
    emulationProfiles: [],
    emulationProfilesBackup:[],
    newEmulation: {
      nodeId: "",
      vmPort: "",
      latency: "",
      jitter: "",
      rate: "",
      packetLoss: "",
      _emulationProfile: "",
      profileName: "",
      icon: ""
    },
    errors: {},
    isDirty:{
      vmPort: false,
      latency: false,
      jitter: false,
      rate: false,
      packetLoss: false,
      newProfileName: false,
      profile: false
    },
    isProfileSelected: false,
    isEmulationApplied: false,
    isCustomProfile: false,
    isSaveEnabled: false,
    newProfileName: ''
  };

  _init = async (nodeId) => {
    try {
      this.props.showLoader();
      let res = await Promise.all([getPortsAndExistingEmulations(nodeId), getEmulationProfiles()])
      this.setState({
        portList: res[0].portInterfaceMapping,
        existingEmulations: res[0].existingEmulations,
        emulationProfiles: res[1].pathEmulationProfiles.concat([customProfileOption]),
        emulationProfilesBackup: res[1].pathEmulationProfiles.concat([customProfileOption]),
      },()=>{
        console.log('emulationProfiles :>> ', this.state.emulationProfiles);
      });
      this.props.hideLoader();
    } catch (error) {
      console.log("error :>> ", error);
      showToast(error.reason,'error');
      this.props.hideLoader();
    }
  };

  _handleOnChange = (key, value) => {
    const { newEmulation, existingEmulations, emulationProfiles, emulationProfilesBackup, isDirty } = deepClone(this.state);
    newEmulation[key] = value;  
    isDirty[key] = true;      
    isDirty.profile = false;
    this.setState({ newEmulation, emulationProfiles: deepClone(emulationProfilesBackup), isSaveEnabled: false,
      newProfileName: '', isDirty },()=>{
        console.log('isDirty :>> ', this.state.isDirty.vmPort);   
      }); 
    if(key==='vmPort'){
      let emulationData = existingEmulations.find((e)=>e.vmPort===Number(value));
      console.log('emulationData :>> ', emulationData);
      if(emulationData){
        let profileData = emulationProfiles.find((e)=>e.name===emulationData.profileName)
        let data =  {
          latency: emulationData.latency,
          jitter: emulationData.jitter,
          rate: emulationData.rate,
          packetLoss: emulationData.packetLoss,
          // _emulationProfile: "",
          profileName: emulationData.profileName,
          icon: profileData ? profileData.icon : 'ethernet'
        }
        this.setState({ newEmulation:{...newEmulation, ...data}, isEmulationApplied: true, isProfileSelected: false, isCustomProfile: false, isDirty, errors: {}, },()=>{
          this._validateForm();
        });
      } else {
        this.setState({ newEmulation: {
          nodeId: this.props.data?.id,
          vmPort: value,
          latency: "",
          jitter: "",
          rate: "",
          packetLoss: "",
          _emulationProfile: "",
          profileName: "",
        }, isEmulationApplied: false, isProfileSelected: false, isCustomProfile: false, isDirty, errors: {} },()=>{
          this._validateForm();
        })
      }
    }
  };


  _handleOnFieldChange = (key, value)=>{
    const { newEmulation, emulationProfiles, isDirty } = deepClone(this.state);
    newEmulation[key] = value;   
    isDirty[key] = true;    
    if(newEmulation._emulationProfile){
      let index = emulationProfiles.findIndex((e) => e.toCreateNew);
      console.log('index :>> ', index);
      emulationProfiles.forEach((e) => (e.isSelected = false));
      emulationProfiles[index].isSelected = true;
      newEmulation.profileName = '';
      newEmulation._emulationProfile = undefined;
      this.setState({ emulationProfiles, isCustomProfile: true, isDirty },()=>{
        this._validateForm();
      });
    } else {
      this.setState({ isCustomProfile: true, isDirty },()=>{
        this._validateForm();
      });
    }
    this.setState({ newEmulation, isDirty }); 
  }

  resetState = () => {
    let newState = {
      portList: [],
      existingEmulations: [],
      emulationProfiles: [],
      emulationProfilesBackup:[],
      newEmulation: {
        nodeId: "",
        vmPort: "",
        latency: "",
        jitter: "",
        rate: "",
        packetLoss: "",
        _emulationProfile: "",
        profileName: "",
        icon: ""
      },
      errors: {},
      isDirty:{
        vmPort: false,
        latency: false,
        jitter: false,
        rate: false,
        packetLoss: false,
        newProfileName: false,
        profile: false
      },
      isProfileSelected: false,
      isEmulationApplied: false,
      isCustomProfile: false,
      isSaveEnabled: false,
      newProfileName: ''
   };
    this.setState(newState);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen && this.props.data) {
        console.log("this.props.data :>> ", this.props.data);
        const { newEmulation } = deepClone(this.state);
        newEmulation.nodeId = this.props.data?.id;
        this.setState({ newEmulation });
        this._init(this.props.data?.id);
      } else {
        this.resetState();
      }
    }
  }

  _selectProfile = (index) => {
    let { emulationProfiles, newEmulation, isCustomProfile, isDirty, newProfileName } = deepClone(this.state);
    emulationProfiles.forEach((e) => (e.isSelected = false));
    emulationProfiles[index].isSelected = true;
    isDirty.profile = true;
    this.setState({ emulationProfiles });
    newEmulation.latency = emulationProfiles[index].latency || '';
    newEmulation.jitter = emulationProfiles[index].jitter || '';
    newEmulation.rate = emulationProfiles[index].rate || '';
    newEmulation.packetLoss = emulationProfiles[index].packetLoss || '';
    newEmulation.profileName = emulationProfiles[index].name || '';
    newEmulation._emulationProfile = emulationProfiles[index].id;
    newEmulation.icon  = emulationProfiles[index].icon || 'ethernet';
    if(emulationProfiles[index].toCreateNew){
      isCustomProfile = true;
      newProfileName = ''
      isDirty.newProfileName = false;
    } else {
      isCustomProfile = false;
    }
    this.setState({ newEmulation, isProfileSelected: true, isCustomProfile, isDirty, newProfileName },()=>{
      this._validateForm();
    });
  };

  _validateForm = ()=>{
    const { newEmulation, errors, isDirty, isProfileSelected, isCustomProfile, newProfileName } = this.state;
    Object.keys(newEmulation).forEach((each) => {
      if (each === "vmPort" && isDirty.vmPort) {
        if (!newEmulation.vmPort.trim().length) {
          errors[each] = "*Select VM Interface";
        } else {
          delete errors[each];
          isDirty.vmPort = false;
        }
      }
      if(isProfileSelected){        
        errors.profile && delete errors.profile;
        if (each === "latency" && isDirty.latency) {
          if(newEmulation.latency.length && isNaN(newEmulation.latency)){
            errors[each] = "Should be Number";
          } else if (newEmulation.latency.length && !isNaN(newEmulation.latency) && newEmulation.latency<0) {
            errors[each] = "Should be positive Number";
          } else {
            delete errors[each];
            // isDirty.latency = false;
          }
        }

        if (each === "jitter" && isDirty.jitter) {
          if(newEmulation.jitter.length && isNaN(newEmulation.jitter)){
            errors[each] = "Should be Number";
          } else if (newEmulation.jitter.length && !isNaN(newEmulation.jitter) && newEmulation.jitter<0) {
            errors[each] = "Should be positive Number";
          } else {
            delete errors[each];
            // isDirty.jitter = false;
          }
        }

        if (each === "rate" && isDirty.rate) {
          if(newEmulation.rate.length && isNaN(newEmulation.rate)){
            errors[each] = "Should be positive Number";
          } else if (newEmulation.rate.length && !isNaN(newEmulation.rate) && newEmulation.rate<0) {
            errors[each] = "Should be positive Number";
          } else {
            delete errors[each];
            isDirty.rate = false;
          }
        }

        if (each === "packetLoss" && isDirty.packetLoss) {
          if(newEmulation.packetLoss.length && isNaN(newEmulation.packetLoss)){
            errors[each] = "Should be positive Number";
          } else if (newEmulation.packetLoss.length && !isNaN(newEmulation.packetLoss) && newEmulation.packetLoss<0) {
            errors[each] = "Should be positive Number";
          } else {
            delete errors[each];
            isDirty.packetLoss = false;
          }
        }

        if(isDirty.jitter && newEmulation.jitter && newEmulation.latency===''){
          errors.latency = 'Jitter can not be applied without latency'
        }

        if(newEmulation.latency==='' && newEmulation.jitter==='' && newEmulation.rate==='' && newEmulation.packetLoss===''){
          errors.emulation = 'Please provide atleast one value'; 
        } else{
          errors.emulation && delete errors.emulation;
        }

        if(isCustomProfile){
          if(!newProfileName.trim().length && isDirty.newProfileName){
            errors.newProfileName = '*Required'; 
          } else {
            delete errors.newProfileName;
          }
        }
      } else if(isDirty.profile){
        errors.profile = 'Select a Profile or create New';
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  _handleOnSubmit = async () => {
    let { newEmulation, isCustomProfile, newProfileName, isDirty, isSaveEnabled } = deepClone(this.state);
    Object.keys(isDirty).forEach((key)=>{
      isDirty[key] = true
    });
    this.setState({ isDirty },async()=>{
      let errors = this._validateForm();
      if(!errors){
        newEmulation.vmPort = Number(newEmulation.vmPort);
        newEmulation.profileName = isCustomProfile ? newProfileName : newEmulation.profileName;
        newEmulation.latency==='' && delete newEmulation.latency;
        newEmulation.jitter==='' && delete newEmulation.jitter;
        newEmulation.rate==='' && delete newEmulation.rate;
        newEmulation.packetLoss==='' && delete newEmulation.packetLoss;
        delete newEmulation.icon;
        console.log("newEmulation :>> ", newEmulation);
        try {
          this.props.showLoader();
          if(isSaveEnabled){
            let data = {
              name: newEmulation.profileName,
              icon: 'ethernet',
              latency: newEmulation.latency,
              jitter: newEmulation.jitter,
              rate: newEmulation.rate,
              packetLoss: newEmulation.packetLoss
            }
            let res = await createEmulationProfiles(data);
            newEmulation._emulationProfile = res.pathEmulationProfile._id;
          }           
            let res = await addPathEmulation(newEmulation);
            console.log("res :>> ", res);
            this.props.hideLoader();
            showToast('Emulation added successfully', 'success');
            this.props.toggle("close");
        } catch (error) {
          console.log("error :>> ", error);
          this.props.hideLoader();
          showToast(error.reason,'error');
        }
      } else {
        console.log('errors :>> ', errors);
      }
    });
  };

  _handleOnRemoveEmulation  = async()=>{
    let { newEmulation, existingEmulations } = deepClone(this.state);    
    console.log('newEmulation :>> ', newEmulation);
    try {
      this.props.showLoader();
      await removePathEmulation({nodeId:newEmulation.nodeId, vmPort: Number(newEmulation.vmPort)});
      this.props.hideLoader();      
      showToast('Emulation removed successfully', 'success');
      let index = existingEmulations.findIndex((e)=>e.vmPort===Number(newEmulation.vmPort));
      existingEmulations.splice(index, 1);
      this.setState({ newEmulation: {
        nodeId: this.props.data?.id,
        vmPort: newEmulation.vmPort,
        latency: "",
        jitter: "",
        rate: "",
        packetLoss: "",
        _emulationProfile: "",
        profileName: "",
      }, existingEmulations, isEmulationApplied: false, isProfileSelected: false, isCustomProfile: false })
    } catch (error) {
      console.log('error :>> ', error);
      showToast(error.reason,'error');
      this.props.hideLoader();      
    }
  }

  _onSaveEnableChange = ()=>{
    let { isSaveEnabled } = deepClone(this.state);
    isSaveEnabled = !isSaveEnabled;
    this.setState({ isSaveEnabled });
  }

  _handleOnProfileNameChange = (key, value)=>{
    let { newProfileName, isDirty } = deepClone(this.state);
    newProfileName = value;
    isDirty.newProfileName = true;
    this.setState({ newProfileName, isDirty },()=>{
      this._validateForm();
    });
  }

  _onRemoveProfile = async(e, profile)=>{
    e.stopPropagation();
    console.log('profile :>> ', profile);
    let { emulationProfiles, emulationProfilesBackup } = deepClone(this.state);
    
    if(profile.isSelected){
      showToast('You can not delete a selected profile','error');
      return;
    } else {
      try {
        this.props.showLoader();
        await deleteEmulationProfiles(profile._id);
        let index = emulationProfiles.findIndex((e)=>e._id===profile._id);
        emulationProfiles.splice(index, 1);
        emulationProfilesBackup.splice(index, 1);
        this.setState({ emulationProfiles, emulationProfilesBackup });
        showToast('Profile deleted successfully','success');
        this.props.hideLoader();
      } catch (error) {
        console.log('error :>> ', error);
        showToast(error.reason, 'error');
        this.props.hideLoader();
      }
    }
  }

  render() {
    const {
      portList,
      newEmulation,
      errors,
      emulationProfiles,
      isProfileSelected,
      isEmulationApplied, isCustomProfile, isSaveEnabled, newProfileName
    } = deepClone(this.state);
    return (
      <Modal
        size={"md"}
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle("close")}
      >
        <ModalHeader toggle={() => this.props.toggle("close")}>
          Path Emulation
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
        >
          <FormGroup>
            <Label>VM Interface</Label>
            <Input
              type="select"
              rows="3"
              placeholder="Select VM Interface"
              value={newEmulation.vmPort}
              onChange={(e) => this._handleOnChange("vmPort", e.target.value)}
            >
              <option value="">Select VM Interface</option>
              {React.Children.toArray(
                portList.map((each) => {
                  if (each.type === "bridge") {
                    return (
                      <option value={each.vmPort}>Port {each.vmPort}</option>
                    );
                  } else {
                    return null;
                  }
                })
              )}
            </Input>
            {errors && <div className="validation-error">{errors.vmPort}</div>}
          </FormGroup>
          {newEmulation.vmPort && !isEmulationApplied && (
            <div>
              <Label>Select Profile</Label>
              <div className="row d-flex profile-item-wrap">
                {React.Children.toArray(
                  emulationProfiles.map((each, index) => (
                    <div
                      className={
                        !each.isSelected
                          ? "profile-item"
                          : "profile-item active-profile-item"
                      }
                      onClick={() => this._selectProfile(index)}
                    >
                      <div>
                        <img
                          width={25}
                          src={
                            require(`../../../../assets/img/emulation/${
                              each.isSelected ? each.icon +'-active.png' : each.icon +'.png'
                            }`).default
                          }
                          alt={"icon" + index}
                        />
                      </div>
                      <div className="profile-name">{each.name}</div>
                      {/* {!each.isSystemDefined && !each.toCreateNew && <img className="custom-tag" src={require('../../../../assets/img/bookmark-tag.png').default} alt="tag"/>} */}
                      {!each.isSystemDefined && !each.toCreateNew && <i className="fa fa-times-circle remove-profile-icon" onClick={(e)=>this._onRemoveProfile(e, each)}></i>}
                    </div>
                  ))
                )}
              </div>
              {errors && <div className="validation-error">{errors.profile}</div>}
            </div>
          )}
          {isProfileSelected && (
            <>
              {isCustomProfile && <FormGroup>
                  <Label>Profile Name</Label>
                  <Input
                    type="text"
                    placeholder="Profile Name"
                    value={newProfileName}
                    onChange={(e) =>
                      this._handleOnProfileNameChange("newProfileName", e.target.value)
                    }
                  ></Input>
                  {errors && <div className="validation-error">{errors.newProfileName}</div>}
                </FormGroup>}
              <div className="emulation-fields">
                <FormGroup>
                  <Label>Latency (ms)</Label>
                  <Input
                    type="text"
                    placeholder="latency"
                    value={newEmulation.latency}
                    onChange={(e) =>
                      this._handleOnFieldChange("latency", e.target.value)
                    }
                  ></Input>
                  {errors && <div className="validation-error">{errors.latency}</div>}
                </FormGroup>
                <FormGroup>
                  <Label>Jitter (ms)</Label>
                  <Input
                    type="text"
                    placeholder="jitter"
                    value={newEmulation.jitter}
                    onChange={(e) =>
                      this._handleOnFieldChange("jitter", e.target.value)
                    }
                  ></Input>
                  {errors && <div className="validation-error">{errors.jitter}</div>}
                </FormGroup>
              </div>
              <div className="emulation-fields">
                <FormGroup>
                  <Label>Rate (Kbps)</Label>
                  <Input
                    type="text"
                    placeholder="rate"
                    value={newEmulation.rate}
                    onChange={(e) =>
                      this._handleOnFieldChange("rate", e.target.value)
                    }
                  ></Input>
                  {errors && <div className="validation-error">{errors.rate}</div>}
                </FormGroup>
                <FormGroup>
                  <Label>Packet Loss (%)</Label>
                  <Input
                    type="text"
                    placeholder="Packet Loss"
                    value={newEmulation.packetLoss}
                    onChange={(e) =>
                      this._handleOnFieldChange("packetLoss", e.target.value)
                    }
                  ></Input>
                  {errors && <div className="validation-error">{errors.packetLoss}</div>}
                </FormGroup>                
              </div>              
            </>
          )}

          {
            isEmulationApplied &&
            <div className="emulation-item-wrap">
               <div className="emulation-items">
                <div>
                  <img
                    width={25}
                    src={
                      require(`../../../../assets/img/emulation/${
                        newEmulation.icon + '.png' || 'ethernet.png'
                      }`).default
                    }
                    alt={"icon"}
                  />
                </div>
                <div className="profile-name">{newEmulation.profileName}</div>
              </div>
              <div className="emulation-items">
                <div>Latency</div>
                <div>{newEmulation.latency!==undefined ?  newEmulation.latency :  '-'}</div>
              </div>
              <div className="emulation-items">
                <div>Jitter</div>
                <div>{newEmulation.jitter!==undefined ? newEmulation.jitter : '-'}</div>
              </div>
              <div className="emulation-items">
                <div>Rate</div>
                <div>{newEmulation.rate!==undefined ? newEmulation.rate : '-'}</div>
              </div>
              <div className="emulation-items">
                <div>Packet Loss</div>
                <div>{newEmulation.packetLoss!==undefined ? newEmulation.packetLoss : '-'}</div>
              </div>
              <div className="emulation-items emulation-del-icon" onClick={()=>this._handleOnRemoveEmulation()}>
                <i className="fa fa-trash"></i>
              </div>
            </div>           
          }
          {errors && <div className="validation-error">{errors.emulation}</div>}
        </ModalBody>
        <ModalFooter>
         { isCustomProfile && <> <input type="checkbox" checked={isSaveEnabled} onChange={()=>this._onSaveEnableChange()} /> <span onClick={()=>this._onSaveEnableChange()}>Save as New Profile</span> </>}
          <Button
            className="modalBtnCancel"
            onClick={() => this.props.toggle("close")}
          >
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={(e) => this._handleOnSubmit(e)}
            disabled={isEmulationApplied}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default PathEmulationModal;
