import React, { useEffect, useState, useRef } from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { Terminal } from "xterm"
import ResizableModal from './design-lab/resizableModal';
import "xterm/css/xterm.css";

const TerminalModal = (props) => {
    const [dimensions, setDimensions] = useState({
        width: 750,
        height: 670
    })
    const [command, setCommand] = useState("");

    const cmd = useRef();

    useEffect(() => {
        if (props.open) {
        }
        cmd.current = "";
    }, [props.open])

    const openTerminal = () => {
        let terminal = new Terminal({
            convertEol: true
        })
        let elem = document.getElementById("terminal")
        terminal.open(elem)
        terminal.write("$> ")
        terminal.onKey((key, ev) => {
            console.log(key)

            if (key.key === '\r') {
                cmd.current = "";
                terminal.write("\r\n");
                terminal.write("$> ");
            } else if (key.key === '\x7F') {
                let str = cmd.current;
                console.log("string ===> ", str);
                if (cmd.current.length !== 0) {
                    terminal.write('\b \b');
                }
                cmd.current = str.substring(0, str.length - 1);
            } else {
                cmd.current += key.key;
                terminal.write(key.key);
            }
        })
    }

    return (
        <Modal isOpen={props.open} onOpened={openTerminal}>
            <ModalHeader toggle={props.toggle}>
                Terminal
            </ModalHeader>
            <ModalBody>

                <div style={{ display: "flex" }}>
                    <div id="terminal"></div>
                </div>
            </ModalBody>
        </Modal>

    )
}

export default TerminalModal;