import React, { Component } from 'react';
import { jsPlumbToolkit } from 'jsplumbtoolkit';
import { JsPlumbToolkitSurfaceComponent } from 'jsplumbtoolkit-react';
import NodeComponent from './node-component';
import SVGComponent from './svg-component';
import 'jsplumbtoolkit-editable-connectors';
import ImageComponent from './img-component';
import ChatGPTModal from '../../components/design-lab/chat-gpt-modal';

// import { isEqual } from "lodash";
// import CustomModal from "./components/modal";

const randomColor = () => {
  let colors = ['#59bb59', '#c7a76c', '#8181b7', '#703a82', '#cc8080'];
  return colors[Math.floor(Math.random() * colors.length)];
};

let overlayAdded = false;
class TopologyEditor extends Component {
  state = {
    color: randomColor(),
    view: {
      nodes: {
        selectable: {
          events: {
            tap: (params) => {
              // this.toolkit.toggleSelection(params);
              this._selectNode(params);
            }
          }
        },
        common: {
          events: {
            tap: (params) => {
              console.log('comes in common  ');
              this._selectNode(params);
            }
          }
        },
        node: {
          parent: 'common',
          jsx: (ctx) => {
            return <NodeComponent color={ctx.props.color} ctx={ctx} />;
          },
          // template: "tmplNode",
          events: {
            click: (event) => {
              console.log('node click:>> ', event);
            },
            dblclick: (event) => {
              console.log('node dblclick :>> ', event);
              event.e.preventDefault();
              event.e.stopPropagation();
              alert('dblclick');
            },
            contextmenu: (event) => {
              event.e.preventDefault();
              event.e.stopPropagation();
              console.log('node right-click:>> ', event.node.data);
              this._onNodeRightClick(event, 'onNodeRightClick');
            },
            mouseover: (event) => {
              // console.log('event :>> ', event);
            }
          }
        },
        shape: {
          parent: 'selectable',
          jsx: (ctx) => {
            return <SVGComponent color={ctx.props.color} ctx={ctx} />;
          },
          events: {
            click: (event) => {
              console.log('shape click:>> ', event);
              this._onShapeClick(event);
            },
            contextmenu: (event) => {
              event.e.preventDefault();
              event.e.stopPropagation();
              console.log('shape right-click:>> ', event);
              this._onNodeRightClick(event, 'onShapeRightClick');
            }
          }
        },
        image: {
          parent: 'selectable',
          jsx: (ctx) => {
            return <ImageComponent color={ctx.props.color} ctx={ctx} />;
          },
          events: {
            click: (event) => {
              console.log('image click:>> ', event);
              this._onShapeClick(event);
            },
            contextmenu: (event) => {
              event.e.preventDefault();
              event.e.stopPropagation();
              console.log('image right-click:>> ', event);
              this._onNodeRightClick(event, 'onShapeRightClick');
              // this._hideNodeMenu();
              // const { showShapeMenu, showCanvasMenu } = this.state;
              // showCanvasMenu.visible = false;
              // showShapeMenu.x = event.e.x;
              // showShapeMenu.y = event.e.y;
              // showShapeMenu.node = event;
              // this.setState({ nodeRightClick : true, showShapeMenu, showCanvasMenu });
            }
          }
        }
      },
      edges: {
        default: {
          connector: ['Bezier', { curviness: 150 }],
          anchor: 'Continuous',
          overlays: [
            // ["Label", { location: 0.5, label:"${label}" }],
            // ["Arrow", { location: 1 }],
            // [ "Custom", {
            // create:function(component) {
            // console.log('component :>> ', component)
            // if(component.edge.data.status === 'pending'){
            // var el = document.createElement('i')
            // el.class="fa fa-spinner fa-spin"
            //   return el;
            // } else {
            //   return '';
            // }
            // return el
            // },
            // location: 0.4}],
            // ["Arrow", { location: 0, direction: -1 }],
          ],

          paintStyle: { strokeWidth: 4, stroke: '#89bcde' }, //'#89bcde'
          hoverPaintStyle: { strokeWidth: 10, stroke: 'orange' },
          endpoint: 'Blank',
          // endpoint:[ "Dot", { radius:5 }],
          events: {
            mouseover: (event) => {
              console.log('link click:>> ', event);
              // this._onEdgeClick(event);
              // this._deleteConnection(event.edge);
            },
            contextmenu: (event) => {
              event.e.preventDefault();
              console.log('link right-click:>> ', event);
              this._onEdgeClick(event);
            },
            // dblclick: (event) => {
            //   console.log('mouseover :>> ', event);
            // event.connection.setPaintStyle({strokeWidth : 10, stroke: randomColor(), dropShadow:'8px 12px 7px rgba(255,165,0,1)', style:{
            // filter: 'drop-shadow(8px 12px 7px rgba(255,165,0,1))' // To add shadow o hover
            // }});

            // event.connection.addOverlay(["Label", { label:"Hello", location:0.5, id: "connLabel"} ]);

            // event.connection.addOverlay([
            //   'Label',
            //   {
            //     label: () => {
            //       return this._generateLabel(event.edge.data, 'from');
            //     },
            //     location: 0.2,
            //     id: 'connLabel1'
            //   }
            // ]);
            // event.connection.addOverlay([
            //   'Label',
            //   {
            //     label: () => {
            //       return this._generateLabel(event.edge.data, 'to');
            //     },
            //     location: 0.8,
            //     id: 'connLabel2'
            //   }
            // ]);
            // },

            click: (event) => {
              if (overlayAdded) {
                event.connection.removeOverlay('connLabel1');
                event.connection.removeOverlay('connLabel2');
                overlayAdded = false;
              } else {
                event.connection.addOverlay([
                  'Label',
                  {
                    label: () => {
                      return this._generateLabel(event.edge.data, 'from');
                    },
                    location: 0.2,
                    id: 'connLabel1'
                  }
                ]);
                event.connection.addOverlay([
                  'Label',
                  {
                    label: () => {
                      return this._generateLabel(event.edge.data, 'to');
                    },
                    location: 0.8,
                    id: 'connLabel2'
                  }
                ]);
                overlayAdded = true;
              }
            },
            // dblclick: (event) => {
            //   this._onEdgeClick(event);
            // },
            // click: (event) => {
            //   event.connection.removeOverlay('connLabel1');
            //   event.connection.removeOverlay('connLabel2');
            //   // event.connection.setPaintStyle({ strokeWidth: 4 });
            // },
            zoom: (event) => {
              console.log('event :>> ', event);
            }
          }
        },
        bezier: {
          parent: 'default',
          connector: ['Bezier', { curviness: 150 }],
          // eslint-disable-next-line
          paintStyle: { strokeWidth: 4, stroke: '${color}', 'stroke-dasharray': '${strokeType}' }
        },
        line: {
          parent: 'default',
          connector: 'Straight',
          // eslint-disable-next-line
          paintStyle: { strokeWidth: 4, stroke: '${color}', 'stroke-dasharray': '${strokeType}' }
        },
        waypoints: {
          parent: 'default',
          connector: ['Straight', { stub: 25, gap: 0 }],
          // eslint-disable-next-line
          paintStyle: { strokeWidth: 4, stroke: '${color}', 'stroke-dasharray': '${strokeType}' }
        },
        flowchart: {
          parent: 'default',
          connector: ['Flowchart', { stub: 25, gap: 0, cornerRadius: 5 }],
          // eslint-disable-next-line
          paintStyle: { strokeWidth: 4, stroke: '${color}', 'stroke-dasharray': '${strokeType}' }
        }
      }
    },
    renderParams: {
      layout: {
        type: 'Spring'
        // type: "Absolute",
      },
      zoomToFit: true,
      consumeRightClick: false,
      lassoInvert: false,
      dragOptions: {
        filter: '.jtk-draw-handle, .node-action, .node-action i',
        start: (event) => {
          console.log('start :>> ', event);
        },
        stop: (event) => {
          console.log('end :>> ', event);
          this._onDragEnd(event);
          // event.selection.forEach((each)=>{
          //   let jtkNodeId = each[0].dataset.jtkNodeId;
          //   document.querySelectorAll(`[data-jtk-node-id="${jtkNodeId}"]`)[0].style.left=event.finalPos[0]+'px'; // vertical
          //   // document.querySelectorAll(`[data-jtk-node-id="${jtkNodeId}"]`)[0].style.top=event.finalPos[1]+'px'; // horizontal
          //   this.state.surface.repaint(document.querySelectorAll(`[data-jtk-node-id="${jtkNodeId}"]`)[0]);
          // })
        }
      },
      events: {
        canvasClick: (e) => {
          console.log('canvas click :>> ', e);
          this._onCanvasClick();
        },
        // beforeDrag:()=>{
        //   console.log('1 :>> ', 1);
        //   return true;
        // },
        // beforeDrop: (ci)=>{
        //   console.log('2 :>> ', 2);
        //   return true; // true for establishing new connection
        // },
        // beforeConnect:()=>{
        //   console.log('11 :>> ', 11);
        // },
        connection: (event) => {
          console.log('connection :>> ', event);
          this._onCompleteConnection(event.connection);
        },
        modeChanged: (mode) => {
          console.log('mode :>> ', mode);
          this.setState({ currentMode: mode });
          // if(mode==='select'){
          //   // document.getElementsByClassName('jtk-surface')[0].classList.add('select-mode');
          // } else {
          //   // document.getElementsByClassName('jtk-surface')[0].classList.remove('select-mode');
          // }
        }
      }
    },
    toolkit: null,
    zoom: 0.2,
    isLoading: false,
    showCanvasMenu: {
      visible: false,
      x: 0,
      y: 0
    },
    showShapeMenu: {
      visible: false,
      x: 0,
      y: 0,
      node: null
    },
    showNodeMenu: {
      visible: false,
      x: 0,
      y: 0,
      node: null
    },
    canvasRightClick: false,
    nodeRightClick: false,
    openedModalList: [],
    currentMode: 'select',
    hideControls: false
  };

  _generateLabel = (data, type) => {
    console.log('data :>> ', data);
    return data.connectionInfo[type].portName || data.connectionInfo[type].portId;
  };

  componentDidMount() {
    // this._addContextMenuListener();
    jsPlumbToolkit.ready(() => {
      console.log('jsPlumbToolkit ready :>> ', jsPlumbToolkit);
      const toolkit = jsPlumbToolkit.newInstance({ Container: 'drawing' });
      console.log('toolkit :>> ', toolkit);

      this.setState({ toolkit }, () => {
        setTimeout(() => {
          new jsPlumbToolkit.DrawingTools({
            renderer: this.state.surface
          });
          this.state.toolkit.load({
            data: this.props.chartData
          });
          this.state.surface.setMode('select');
          this.props.onUpdate({}, {}, 'onLoadTopology', this.state.toolkit, this.state.surface);
        });
        this._onZoomChange(this.props.chartData.scale);
        // this._onZoomChange(0.6);
      });
      // window.setZoom = function(zoom, instance, transformOrigin, el) {
      //   transformOrigin = transformOrigin || [ 0.5, 0.5 ];
      //   instance = instance || window.jsPlumb;
      //   el = el || instance.getContainer();
      //   var p = [ "webkit", "moz", "ms", "o" ],
      //       s = "scale(" + zoom + ")",
      //       oString = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";

      //   for (var i = 0; i < p.length; i++) {
      //     el.style[p[i] + "Transform"] = s;
      //     el.style[p[i] + "TransformOrigin"] = oString;
      //   }

      //   el.style["transform"] = s;
      //   el.style["transformOrigin"] = oString;

      //   instance.setZoom(zoom);
      // };
    });
  }

  componentDidUpdate(prevProps) {
    console.log('prevProps.chartData :>> ', prevProps.chartData.scale, this.props.chartData.scale);
    if (prevProps.chartData.scale !== this.props.chartData.scale) {
      this._onZoomChange(this.props.chartData.scale);
    }
  }

  // To add right click listener
  // _addContextMenuListener = () => {
  //   document.addEventListener("contextmenu", (event) => {
  //     console.log("event :>> ", event.target);
  //     event.preventDefault();
  //     if (event.target?.className && event.target?.className.includes && event.target?.className.includes('jtk-surface')) {
  //       console.log("canvas-right-click :>> ", event);
  //       event.preventDefault();
  //       const clickX = event.x;
  //       const clickY = event.y;
  //       const showCanvasMenu = {
  //         visible: false, x: clickX, y: clickY
  //       }
  //       this.setState({ showCanvasMenu, canvasRightClick: true });
  //     }
  //   });
  // };

  _addNode = () => {
    // let id = new Date().getTime().toString();
    setTimeout(() => {
      // let res =
      this.state.toolkit.addNode({
        // "id": id, "name":"New Machine",
        icon: require('../../../../assets/img/computer.png').default,
        left: -200,
        top: 100,
        // "portId": id,
        type: 'node',
        name: 'New Machine'
      });
    });
    // console.log('res :>> ', res);
    // this.state.surface.repaint(res);
  };

  _updateNode = () => {
    this.state.toolkit.updateNode('1', { vmState: 'running', left: -347 });
    // console.log('surface :>> ', this.state.toolkit.getRenderers());
    // let surfaceObj = this.state.toolkit.getRenderers();
    // let res = this.state.toolkit.getSelection();
    // console.log('res :>> ', res);
    // let surface = surfaceObj[Object.keys(surfaceObj)[0]];
    // let vertex = res.getNodes()[0];
    // setTimeout(()=>{
    //   surface.setPosition(1, -347, -510);
    //   surface.repaint(vertex);
    // }, 100)
  };

  _onZoomChange = (value) => {
    console.log('value :>> ', value);
    this.setState({ zoom: value }, () => {
      this.state.surface.setZoom(value);
      // this.state.surface.zoomToFitIfNecessary();
    });
    // window.setZoom(value, window.jsPlumb, null, document.getElementsByClassName('jtk-surface')[0]);
    // jsPlumbToolkit.setZoom(value);
    // window.jsPlumb.setZoom(value, jsPlumbToolkit);
    // jsPlumbToolkit.Surface.setZoom(value);
  };

  _deleteNode = () => {
    this.state.toolkit.removeNode('1');
  };

  _onEdgeClick = (params) => {
    console.log('edge :>> ', params.edge.getId());
    // this.state.toolkit.removeEdge(edge);
    let res = this.state.toolkit.exportData();
    this.props.onUpdate(res, params, 'onLinkClick', this.state.toolkit);
  };

  _selectNode = (params) => {
    console.log('params :>> ', params);
    let res = this.state.toolkit.exportData();
    this.props.onUpdate(res, params, 'onSelectNode', this.state.toolkit);
  };

  _onCanvasClick = () => {
    this.state.toolkit.clearSelection();
    this.state.surface.setMode('select');
    let res = this.state.toolkit.exportData();
    this.props.onUpdate(res, {}, 'onCanvasClick');
    document.getElementsByTagName('body')[0].classList.add('sidebar-mini');
    // let { canvasRightClick, showCanvasMenu } = this.state;
    // console.log('showCanvasMenu :>> ', document.getElementById('right-click-menu'));
    // this._hideShapeMenu();
    // this._hideNodeMenu();
    // if(canvasRightClick){
    //   showCanvasMenu.visible = true;
    //   this.setState({ showCanvasMenu, canvasRightClick: false, nodeRightClick: false });
    // } else {
    //   showCanvasMenu.visible = false;
    //   showCanvasMenu.x = 0;
    //   showCanvasMenu.y = 0;
    //   this.setState({ showCanvasMenu, canvasRightClick: false, nodeRightClick: false });
    // }
  };

  _hideShapeMenu = () => {
    const { showShapeMenu } = this.state;
    showShapeMenu.visible = false;
    showShapeMenu.x = 0;
    showShapeMenu.y = 0;
    showShapeMenu.node = null;
    this.setState({ showShapeMenu });
  };

  _hideNodeMenu = () => {
    const { showNodeMenu } = this.state;
    showNodeMenu.visible = false;
    showNodeMenu.x = 0;
    showNodeMenu.y = 0;
    showNodeMenu.node = null;
    this.setState({ showNodeMenu });
  };

  _enableSelectMode = () => {
    setTimeout(() => {
      this.state.surface.setMode('select');
    }, 100);
  };

  _updateLink = () => {
    this.state.toolkit.updateEdge('1233', { status: 'connected', type: 'flowchart' });
    this._getUpdatedDataset();
  };

  _onCompleteConnection = (newConnection) => {
    newConnection.addOverlay([
      'Custom',
      {
        create: function (component) {
          var el = document.createElement('i');
          el.className = 'fa fa-spinner fa-spin';
          return el;
        },
        location: 0.4,
        id: 'loadingLabel'
      }
    ]);
    setTimeout(() => {
      console.log('newConnection :>> ', newConnection.edge);
      let res = this.state.toolkit.exportData();
      this.props.onUpdate(res, newConnection, 'onLinkComplete', this.state.toolkit);
      // this.state.toolkit.updateEdge(newConnection.edge.data.id,{ "type":"link","label":"Connection Label", "status" : 'connected'})
      // newConnection.removeOverlay("loadingLabel");
      // newConnection._jsPlumb.paintStyle.stroke ='blue';
    }, 1500);
  };

  _getUpdatedDataset = () => {
    let res = this.state.toolkit.exportData();
    console.log('res :>> ', res);
  };

  _alignSelectedNodes = (mode) => {
    console.log('mode :>> ', mode);
    const { showNodeMenu } = this.state;
    let res = this.state.toolkit.getSelection();
    console.log('res :>> ', res.getNodes());
    // const currentSelectedElements = document.getElementsByClassName('jtk-surface-selected-element'); // for raw
    const currentSelectedElements = res.getNodes();
    currentSelectedElements.forEach((each) => {
      let jtkNodeId = each.data.id;
      console.log('jtkNodeId :>> ', jtkNodeId);
      if (mode === 'v') {
        showNodeMenu.node.renderer.setPosition(jtkNodeId, showNodeMenu.node.node.data.left, each.data.top);
        // each.style.left = showNodeMenu.node.node.data.left +'px'; // vertical
      } else if (mode === 'h') {
        showNodeMenu.node.renderer.setPosition(jtkNodeId, each.data.left, showNodeMenu.node.node.data.top);
        // each.style.top = showNodeMenu.node.node.data.top +'px'; // horizontal
      }
      // setTimeout(()=>{
      showNodeMenu.node.renderer.repaint(each);
      // }, 1000)
    });
    this._hideNodeMenu();
  };

  _updateShape = () => {
    const { showShapeMenu } = this.state;
    this.state.toolkit.updateNode(showShapeMenu.node.node.data.id, { color: randomColor() }); //edit
    // this.state.toolkit.removeNode(showShapeMenu.node.node.data.id); // delete
    this._hideShapeMenu();
  };

  _openCustomModal = () => {
    const { openedModalList, showNodeMenu } = this.state;
    let customModal = {
      isOpen: true,
      data: showNodeMenu.node.node,
      isMinimised: false
    };
    openedModalList.push(customModal);
    this.setState({ openedModalList });
    this._hideNodeMenu();
  };

  _onCloseCustomModal = (index) => {
    const { openedModalList } = this.state;
    openedModalList.splice(index, 1);
    this.setState({ openedModalList });
  };

  _onMinimizeModal = (index) => {
    const { openedModalList } = this.state;
    openedModalList[index].isOpen = false;
    openedModalList[index].isMinimised = true;
    this.setState({ openedModalList });
  };

  _onOpenMinimisedModal = (data) => {
    const { openedModalList } = this.state;
    let index = openedModalList.findIndex((e) => e.data.data.id === data.id);
    openedModalList[index].isOpen = true;
    openedModalList[index].isMinimised = false;
    this.setState({ openedModalList });
  };

  _renderMinimisedModals = () => {
    const minimisedModals = this.state.openedModalList.filter((e) => e.isMinimised);
    return minimisedModals.length ? (
      <div className='minimised-modal-list'>
        {React.Children.toArray(
          minimisedModals.map((each, index) => (
            <div className='minimised-modal-item' onClick={() => this._onOpenMinimisedModal(each.data?.data)}>
              {each.data?.data?.name}
            </div>
          ))
        )}
      </div>
    ) : (
      <div></div>
    );
  };

  _onDragEnd = (params) => {
    let res = this.state.toolkit.exportData();
    if (params.selection.length === 1) {
      let data = params.el.jtk.node.data;
      this.props.onUpdate(res, [data], 'onDragNodeEnd');
    } else if (params.selection.length > 1) {
      let data = params.selection.map((each) => {
        return each[0].jtk.node.data;
      });
      this.props.onUpdate(res, data, 'onDragNodeEnd');
    }
  };

  _onShapeClick = (params) => {
    let res = this.state.toolkit.exportData();
    this.props.onUpdate(res, params.node.data, 'onShapeClick');
  };

  // shape or node right click
  _onNodeRightClick = (params, eventName) => {
    let res = this.state.toolkit.getSelection();
    // const currentSelectedElements = document.getElementsByClassName('jtk-surface-selected-element'); // for raw
    const currentSelectedElements = res.getNodes();
    if (currentSelectedElements.length > 1) {
      this.props.onUpdate(currentSelectedElements, params, eventName, this.state.toolkit);
    } else {
      this.props.onUpdate([], params, eventName, this.state.toolkit);
    }
    // currentSelectedElements.forEach((each)=>{
    //   let jtkNodeId = each.data.id;
    //   console.log('jtkNodeId :>> ', jtkNodeId);
    //   if(mode==='v'){
    //     showNodeMenu.node.renderer.setPosition(jtkNodeId, showNodeMenu.node.node.data.left, each.data.top);
    //     // each.style.left = showNodeMenu.node.node.data.left +'px'; // vertical
    //   } else if(mode==='h'){
    //     showNodeMenu.node.renderer.setPosition(jtkNodeId, each.data.left, showNodeMenu.node.node.data.top,);
    //     // each.style.top = showNodeMenu.node.node.data.top +'px'; // horizontal
    //   }
    //   // setTimeout(()=>{
    //     showNodeMenu.node.renderer.repaint(each);
    //   // }, 1000)
    // })
  };

  _onModeChange = (mode) => {
    this.state.surface.setMode(mode);
  };

  _takeImage = () => {
    this.setState({ hideControls: true });
    this.props.onUpdate(null, null, 'onSnapShot');
    setTimeout(() => {
      this.setState({ hideControls: false });
    }, 2000);
  };

  render() {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex' }}>
        {/* <button
          data-node-type="node"
            onClick={this._addNode}
            style={{ backgroundColor: this.state.color }}
            className="colorButton"
          >
            Add Node
          </button>
          <button
            onClick={this._updateNode}
            style={{ backgroundColor: this.state.color }}
            className="colorButton editBtn"
          >
            Update Machine 1
          </button>

          <button
            onClick={this._deleteNode}
            style={{ backgroundColor: this.state.color }}
            className="colorButton deleteBtn"
          >
            Delete Machine 1
          </button>

          <button
            onClick={this._enableSelectMode}
            style={{ backgroundColor: this.state.color }}
            className="colorButton selectModeBtn"
          >
            Multi Select Mode
          </button>

          <button
            onClick={this._updateLink}
            style={{ backgroundColor: this.state.color }}
            className="colorButton updateLinkBtn"
          >
            Update Link
          </button>
          <input type="range" className="zoom-range" value={this.state.zoom} step="0.10" min="0.10" max="2" onChange={(e)=>this._onZoomChange(e.target.value)}/> */}
        {this.state.toolkit && !this.state.isLoading && (
          <div style={{ width: '100%', height: '100%', display: 'flex' }} id='drawing'>
            {/* <ChatGPTModal /> */}
            {!this.state.hideControls && (
              <div className='controls'>
                <i
                  className={this.state.currentMode === 'pan' ? 'fa fa-arrows selected-mode' : 'fa fa-arrows'}
                  title='Pan Mode'
                  onClick={() => this._onModeChange('pan')}></i>
                <i
                  className={this.state.currentMode === 'select' ? 'fa fa-pencil selected-mode' : 'fa fa-pencil'}
                  title='Select Mode'
                  onClick={() => this._onModeChange('select')}></i>
                <i className={'fa fa-camera'} title='Select Mode' onClick={() => this._takeImage('select')}></i>
              </div>
            )}
            <JsPlumbToolkitSurfaceComponent
              renderParams={this.state.renderParams}
              toolkit={this.state.toolkit}
              view={this.state.view}
              ref={(c) => {
                if (c != null && !this.state.surface) this.setState({ surface: c.surface });
              }}
            />
            <ChatGPTModal />
          </div>
        )}
        {this.state.showCanvasMenu.visible && (
          <div
            id='right-click-menu'
            style={{
              position: 'fixed',
              top: this.state.showCanvasMenu.y,
              left: this.state.showCanvasMenu.x,
              background: '#fff',
              height: 200,
              border: '1px solid #ccc',
              borderRadius: 4,
              width: 170
            }}>
            <ul className='custom-context-menu'>
              <li>Menu One</li>
              <li>Menu Two</li>
            </ul>
          </div>
        )}
        {this.state.showShapeMenu.visible && (
          <div
            id='right-click-menu'
            style={{
              position: 'fixed',
              top: this.state.showShapeMenu.y,
              left: this.state.showShapeMenu.x,
              background: '#fff',
              height: 200,
              border: '1px solid #ccc',
              borderRadius: 4,
              width: 170
            }}>
            <ul className='custom-context-menu'>
              <li onClick={this._updateShape}>Edit</li>
              <li>Test Two</li>
            </ul>
          </div>
        )}

        {this.state.showNodeMenu.visible && (
          <div
            id='right-click-menu'
            style={{
              position: 'fixed',
              top: this.state.showNodeMenu.y,
              left: this.state.showNodeMenu.x,
              background: '#fff',
              height: 200,
              border: '1px solid #ccc',
              borderRadius: 4,
              width: 170
            }}>
            <ul className='custom-context-menu'>
              <li onClick={() => this._alignSelectedNodes('h')}>Horizontal Align</li>
              <li onClick={() => this._alignSelectedNodes('v')}>Vertical Align</li>
              {/* <li onClick={()=>this._openCustomModal()}>Connect</li> */}
            </ul>
          </div>
        )}
        {
          // React.Children.toArray(this.state.openedModalList.map((each, index)=>
          //   <CustomModal
          //     isOpen={each.isOpen}
          //     data={each.data}
          //     toggle={()=>this._onCloseCustomModal(index)}
          //     minimizeModal={()=>this._onMinimizeModal(index)}
          //   ></CustomModal>
          // ))
        }
        {this._renderMinimisedModals()}
      </div>
    );
  }
}

export default TopologyEditor;
