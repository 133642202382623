import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Table,
  FormGroup,
  Input,
} from "reactstrap";
import { deepClone } from "../../../helper-methods";
import { getResourceList } from "../../../http/http-calls";
import { Paginate } from "../pages/Pagination";

class DashboardDockerListModal extends Component {
  state = {
    searchTerm: "",
    nodeList: [],
    currentPage: 1,
    totalPages: 0,
  };

  componentDidMount() {
    console.log("componentDidMount", this.props);
  }

  _getNodeList = async (pageNumber) => {
    const { searchTerm } = deepClone(this.state);
    let data = {
      category: "docker",
      page: pageNumber,
    };
    if (searchTerm.trim().length) {
      data.search = searchTerm;
    }
    try {
      let res = await getResourceList(data);
      console.log("res; :>> ", res);
      this.setState({
        nodeList: res.docs,
        totalPages: res.totalPages,
        currentPage: res.page,
      });
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  _handleOnSearch = (value) => {
    this.setState({ searchTerm: value, currentPage: 1 }, () => {
      this._getNodeList(1);
    });
  };

  _handleOnPaginate = (pageNumber) => {
    this._getNodeList(pageNumber);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.data) {
        this.setState({
          searchTerm: "",
          nodeList: this.props.data.nodeList,
          currentPage: 1,
          totalPages: this.props.data.totalPages,
        });
        console.log("node List ====>", this.props.data.nodeList)
      }
    }
  }

  _stopNode = (node) => {
    this.props.onStopNode(node)
  }

  render() {
    // console.log('from modal', this.props);
    const { nodeList, searchTerm } = deepClone(this.state);

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle(null)}
        className="modal-lg"
      >
        <ModalHeader toggle={() => this.props.toggle(null)}>
          Docker Container List
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
        >
          <FormGroup className="d-flex justify-content-end">
            <Input
              type="text"
              placeholder="Search..."
              style={{ width: 240 }}
              value={searchTerm}
              onChange={(e) => this._handleOnSearch(e.target.value)}
            />
          </FormGroup>
          {nodeList.length > 0 ? (
            <div>
              <Table className="tablesorter tableInModal" responsive>
                <thead>
                  <tr>
                    <th>Lab Name</th>
                    <th>Created By</th>
                    <th>Node Name</th>
                    <th>Description</th>
                    <th>RAM, CPUs, ETH</th>
                    <th width="16%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {React.Children.toArray(
                    nodeList.map((each, index) => (
                      <tr>
                        <td>{each._lab?.name}</td>
                        <td>{each._createdBy?.name?.full}</td>
                        <td>
                          {" "}
                          <img
                            src={each._icon.url}
                            alt={"icon-image" + index}
                            width="20"
                            className="mr-2"
                          />
                          {each.name}
                        </td>
                        <td>{each.description || "N/A"}</td>
                        <td>
                          {each.ram || '-'} MB, &nbsp;{each.cpu || '-'}, &nbsp;{" "}
                          {each.ethernets || '-'}
                        </td>
                        <td>
                          <Button className="stopBtn ml-3" onClick={() => this._stopNode(each)}>Stop</Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              <Paginate
                totalPages={this.state.totalPages}
                currentPage={this.state.currentPage}
                paginate={this._handleOnPaginate}
              />
            </div>
          ) : (
            <p className="text-center">No Docker Containers Found.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="modalBtnSave" onClick={() => this.props.toggle()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DashboardDockerListModal;
