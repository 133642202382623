import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Table, FormGroup, Input } from 'reactstrap';
import { deepClone } from '../../../../helper-methods';

class LabObjectListModal extends Component {
  state = {
    searchTerm: '',
    objectList: [],
    objectListBackup: []
  };

  _handleOnSearch = (value) => {
    this.setState({ searchTerm: value });
    const { objectListBackup } = deepClone(this.state);
    let objectList = deepClone(objectListBackup);
    if (value.trim().length) {
      objectList = objectList.filter((each) => {
        return (
          each.type.toLowerCase().includes(value.toLowerCase()) ||
          each.data.shape.toLowerCase().includes(value.toLowerCase())
        );
      });
      this.setState({ objectList });
    } else {
      this.setState({ objectList: objectListBackup });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.data) {
        this.setState({
          searchTerm: '',
          objectList: this.props.data,
          objectListBackup: this.props.data
        });
      }
    }
  }

  _showShapaeData = (each) => {
    switch (each.data.shape) {
      case 'rect':
        return <p>{`w x h : ${Number(each.data.width).toFixed(2)} x ${Number(each.data.height).toFixed(2)}`}</p>;
      case 'text':
        return <p>Text : {each.data.textContent}</p>;
      case 'image':
        return <img src={each.data.url} alt={each.id} width='20' />;
      case 'circle':
        return <p>Radius : {Math.round(each.data.radius)}</p>;
      case 'line':
        return (
          <div>
            Stroke Width : {each.data.strokeWidth}, Color :{' '}
            <div
              style={{
                backgroundColor: each.data.color || '#222',
                display: 'inline-block',
                width: 10,
                height: 10,
                border: '1px solid #222'
              }}></div>
          </div>
        );
      default:
        break;
    }
  };

  _onDeleteObject = (labObject) => {
    this.props.openDeleteModal(labObject);
  };

  render() {
    const { objectList, searchTerm } = deepClone(this.state);

    return (
      <Modal isOpen={this.props.isOpen} toggle={() => this.props.toggle()} className='modal-md'>
        <ModalHeader toggle={() => this.props.toggle()}>All Annotations</ModalHeader>
        <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <FormGroup className='d-flex justify-content-end'>
            <Input
              type='text'
              placeholder='Search...'
              style={{ width: 240 }}
              value={searchTerm}
              onChange={(e) => this._handleOnSearch(e.target.value)}
            />
          </FormGroup>

          {objectList.length > 0 ? (
            <Table className='tablesorter tableInModal' responsive>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Related Data</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {React.Children.toArray(
                  objectList.map((each, index) => (
                    <tr>
                      <td style={{ textTransform: 'capitalize' }}>
                        {each.data.shape === 'rect' ? 'Rectangle' : each.data.shape}
                      </td>
                      <td>{this._showShapaeData(each)}</td>
                      <td>
                        <Button className='stopBtn' onClick={() => this._onDeleteObject(each)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          ) : (
            <p className='text-center'>No Annotations Found.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className='modalBtnSave' onClick={() => this.props.toggle()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default LabObjectListModal;
