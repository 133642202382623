import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  CardBody,
  CardFooter,
  Button,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Label,
} from 'reactstrap';

import { profileDetails, profileUpdate, addLicenseProfile, addMfa, getMfa } from '../../../http/http-calls';

import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { ToastsStore } from 'react-toasts';
import Password from '../components/password';
import Switch from 'react-switch';

import { logout } from '../../../helper-methods';

class MyProfile extends Component {
  state = {
    user: {
      name: '',
      email: '',
      phone: '',
      profilePicUrl: '',
      isMFAEnabled: false,
    },
    mailServer: {
      smtpHost: '',
      smtpPort: '',
      smtpAddress: '',
      smtpUser: '',
      smtpPass: '',
    },
    mailServerDirty: {
      smtpHost: false,
      smtpPort: false,
      smtpAddress: false,
      smtpUser: false,
      smtpPass: false,
    },
    isDirty: {
      name: false,
      email: false,
      phone: false,
    },
    errors: {},
    mailServerError: {},
    licenseList: [],
    licenseKey: '',
    isMailServerDefault: true,
  };

  componentDidMount() {
    this._getProfileDetails();
    this._getMfa();
  }

  //getting my-profile details here
  _getProfileDetails = () => {
    this.props.showLoader();
    profileDetails()
      .then((resp) => {
        console.log('My profile here :- ', resp);
        let user = {
          name: resp.user.name.full,
          email: resp.user.email,
          phone: resp.user.phone,
          profilePicUrl: resp.user.profilePicUrl,
          isMFAEnabled: resp.user.isMFAEnabled,
        };
        let licenseList = resp.user.licenseStack;
        this.setState({ user, licenseList });
        this.props.hideLoader();
      })
      .catch((err) => {
        this.props.hideLoader();
        ToastsStore.error('Getting User details failed, login again :- ' + err.reason);
        setTimeout(() => {
          logout();
        }, 3000);
      });
  };

  // Get Mfa
  _getMfa = () => {
    this.props.showLoader();
    getMfa()
      .then((res) => {
        console.log('get Mfa here :- ', res);
        let mailServer = {
          smtpHost: res.mfa?.smtpHost || '',
          smtpPort: res.mfa?.smtpPort || '',
          smtpAddress: res.mfa?.smtpAddress || '',
          smtpUser: res.mfa?.smtpUser || '',
          smtpPass: res.mfa?.smtpPass || '',
        };

        let isMailServerDefault = res.mfa === null ? true : false;

        this.setState({ mailServer, isMailServerDefault });
        this.props.hideLoader();
      })
      .catch((err) => {
        this.props.hideLoader();
        ToastsStore.error('Getting User details failed :- ' + err.reason);
      });
  };

  //handling input here
  _handleOnChange = (field, value) => {
    const { user, isDirty } = this.state;
    user[field] = value;
    isDirty[field] = true;
    this.setState({ user, isDirty }, () => {
      this._validateField();
    });
  };

  //handling input in Mail Server here
  _handleOnChangeMailServer = (field, value) => {
    const { mailServer, mailServerDirty } = this.state;
    mailServer[field] = value;
    mailServerDirty[field] = true;
    this.setState({ mailServer, mailServerDirty }, () => {
      this._validateMailServerField();
    });
  };

  // for validation mailServer
  _validateMailServerField = () => {
    const { mailServer, mailServerDirty, mailServerError } = this.state;

    Object.keys(mailServer).forEach((each) => {
      if (each === 'smtpHost' && mailServerDirty.smtpHost) {
        if (!mailServer.smtpHost.trim().length) {
          mailServerError[each] = '*Required';
        } else {
          delete mailServerError[each];
          mailServerDirty.smtpHost = false;
        }
      } else if (each === 'smtpPort' && mailServerDirty.smtpPort) {
        if (!mailServer.smtpPort.trim().length) {
          mailServerError[each] = '*Required';
        } else {
          delete mailServerError[each];
          mailServerDirty.smtpPort = false;
        }
      } else if (each === 'smtpAddress' && mailServerDirty.smtpAddress) {
        if (!mailServer.smtpAddress.trim().length) {
          mailServerError[each] = '*Required';
        } else {
          delete mailServerError[each];
          mailServerDirty.smtpAddress = false;
        }
      } else if (each === 'smtpUser' && mailServerDirty.smtpUser) {
        if (!mailServer.smtpAddress.trim().length) {
          mailServerError[each] = '*Required';
        } else {
          delete mailServerError[each];
          mailServerDirty.smtpUser = false;
        }
      } else if (each === 'smtpPass' && mailServerDirty.smtpPass) {
        if (!mailServer.smtpPass.trim().length) {
          mailServerError[each] = '*Required';
        } else {
          delete mailServerError[each];
          mailServerDirty.smtpPass = false;
        }
      }
    });
    this.setState({ mailServerError });
    return Object.keys(mailServerError).length ? mailServerError : null;
  };

  //for validation
  _validateField = () => {
    const { user, isDirty, errors } = this.state;
    Object.keys(user).forEach((each) => {
      if (each === 'name' && isDirty.name) {
        if (!user.name.trim().length) {
          errors[each] = '*Required';
        } else if (user.name.trim().length && !user.name.match(/^[a-zA-Z ]*$/)) {
          errors[each] = 'Invalid Name format';
        } else if (user.name.trim().length && user.name.match(/^[a-zA-Z ]*$/) && user.name.trim().length <= 2) {
          errors[each] = 'Name should be greater than two characters';
        } else {
          delete errors[each];
          isDirty.name = false;
        }
      } else if (each === 'email' && isDirty.email) {
        if (!user.email.trim().length) {
          errors[each] = '*Required';
        } else if (
          user.email.trim().length &&
          !new RegExp('^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$').test(user.email)
        ) {
          errors[each] = 'Enter a valid email ID';
        } else {
          delete errors[each];
          isDirty.email = false;
        }
      } else if (each === 'phone' && isDirty.phone) {
        if (!user.phone.trim().length) {
          errors[each] = '*Required';
        } else if (user.phone.trim().length && !user.phone.match(/^[0-9]{10}$/)) {
          errors[each] = 'Enter valid phone number';
        } else {
          delete errors[each];
          isDirty.phone = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  //update profile here
  _updateProfile = (e) => {
    e.preventDefault();
    let isDirty = {
      name: true,
      email: true,
      phone: true,
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateField();
      if (!errors) {
        let userData = {
          email: this.state.user.email,
          phone: this.state.user.phone,
          name: {
            first: this.state.user.name.split(' ')[0],
            last: this.state.user.name.split(' ')[1] ? this.state.user.name.split(' ')[1] : '',
          },
          isMFAEnabled: this.state.user.isMFAEnabled,
        };
        this.props.showLoader();
        profileUpdate(userData)
          .then((resp) => {
            console.log('Update profile resp here :- ', resp);
            this.props.hideLoader();
            ToastsStore.success('Profile updated successfully');
            this._getProfileDetails();
          })
          .catch((err) => {
            this.props.hideLoader();
            ToastsStore.error('Profile updation failed :- ', err.reason);
          });
      } else {
        ToastsStore.error('Something went wrong!!!');
      }
    });
  };

  //get list of licences
  _getLicensesList = () => {
    let licensesList = '';
    const { licenseList } = this.state;
    if (licenseList.length) {
      licensesList = licenseList.map((license) => {
        return (
          <ListGroupItem>
            <div>{'XXXX-XXXX-XXXX-XXXX-' + license._license.licenseKey.split('-')[3]}</div>
            <div>
              Validity Days: <span>{license._license.validityInDays}</span>
            </div>
          </ListGroupItem>
        );
      });
    } else {
      licensesList += 'No License found';
    }
    return licensesList;
  };

  //handling licensceKey input here
  _handleSerialChange = (value) => {
    let { licenseKey } = this.state;
    licenseKey = value;
    this.setState({ licenseKey });
  };

  //adding new license here
  _addLicense = (e) => {
    e.preventDefault();
    if (!this.state.licenseKey.trim().length) {
      ToastsStore.error('Serial number is empty');
    } else {
      let licenseData = {
        licenseKey: this.state.licenseKey,
      };
      this.props.showLoader();
      addLicenseProfile(licenseData)
        .then((resp) => {
          console.log('Add license response here :- ', resp);
          this.props.hideLoader();
          ToastsStore.success('License added successfully');
          this.setState({ licenseKey: '' });
          this._getProfileDetails();
        })
        .catch((err) => {
          this.props.hideLoader();
          ToastsStore.error('Adding new license failed :- ' + err.reason);
        });
    }
  };

  // toggle Mail Server
  _toggleMailServer = (fieldName, value) => {
    this.setState({ [fieldName]: !value }, () => {});
  };

  // _addMfa = (e) => {
  //   e.preventDefault();

  //   let licenseData = {
  //     licenseKey: this.state.licenseKey,
  //   };
  //   this.props.showLoader();
  //   mfa(licenseData)
  //     .then((resp) => {
  //       console.log("Add mfa response here :- ", resp);
  //       this.props.hideLoader();
  //       ToastsStore.success("mfa added successfully");
  //       this.setState({ licenseKey: "" });
  //       this._getProfileDetails();
  //     })
  //     .catch((err) => {
  //       this.props.hideLoader();
  //       ToastsStore.error("Adding new mfa failed :- " + err.reason);
  //     });
  // };

  _addMfa = (e) => {
    e.preventDefault();
    const { mailServer } = this.state;

    let mailServerDirty = {
      smtpHost: true,
      smtpPort: true,
      smtpAddress: true,
      smtpUser: true,
      smtpPass: true,
    };

    this.setState({ mailServerDirty }, () => {
      let errors = this._validateMailServerField();
      if (!errors) {
        let payload = {
          smtpHost: mailServer.smtpHost,
          smtpPort: mailServer.smtpPort,
          smtpAddress: mailServer.smtpAddress,
          smtpUser: mailServer.smtpUser,
          smtpPass: mailServer.smtpPass,
        };
        this.props.showLoader();
        addMfa(payload)
          .then((resp) => {
            console.log('Update profile resp here :- ', resp);
            this.props.hideLoader();
            ToastsStore.success('mfa added successfully');

            this._getMfa();
          })
          .catch((err) => {
            this.props.hideLoader();
            ToastsStore.error('Adding new mfa failed :- ' + err.reason);
          });
      } else {
        ToastsStore.error('Something went wrong!!!');
      }
    });
  };

  render() {
    const { user, errors, licenseKey, isMailServerDefault, mailServer, mailServerError } = this.state;
    // console.log("this.props", this.props.location.pathname);
    return (
      <div className='content'>
        <Row>
          <Col xl={7} lg={6} md={12}>
            <Card className='userInfo'>
              <CardHeader>Licenses</CardHeader>
              <CardBody>
                <ListGroup className='licensesList'>{this._getLicensesList()}</ListGroup>
              </CardBody>
              <CardFooter className='innerForm innerCard'>
                <FormGroup className='mb-0 w-100'>
                  <Input
                    type='text'
                    placeholder='Serial Number'
                    value={licenseKey}
                    onChange={(e) => this._handleSerialChange(e.target.value)}
                  />
                </FormGroup>
                <Button className='addBtn' onClick={(e) => this._addLicense(e)}>
                  Add
                </Button>
              </CardFooter>
            </Card>
            <Password type={'my-profile'} />
          </Col>

          <Col xl={5} lg={6} md={12}>
            <Card className='userInfo'>
              <CardHeader>
                <span>{this.props.userData.handle}</span>
              </CardHeader>
              <CardBody className='innerForm innerCard'>
                <FormGroup>
                  <Label>Name:</Label>
                  <Input
                    type='text'
                    placeholder='Name'
                    value={user.name}
                    onChange={(e) => this._handleOnChange('name', e.target.value)}
                  />
                  {errors && <div className='validation-error'>{errors.name}</div>}
                </FormGroup>
                <FormGroup>
                  <Label>Email:</Label>
                  <Input
                    type='email'
                    placeholder='Email'
                    value={user.email}
                    onChange={(e) => this._handleOnChange('email', e.target.value)}
                  />
                  {errors && <div className='validation-error'>{errors.email}</div>}
                </FormGroup>
                <FormGroup>
                  <Label>Phone:</Label>
                  <Input
                    type='text'
                    placeholder='Phone'
                    value={user.phone}
                    onChange={(e) => this._handleOnChange('phone', e.target.value)}
                  />
                  {errors && <div className='validation-error'>{errors.phone}</div>}
                </FormGroup>
                {window.localStorage.getItem('isAdmin') ? (
                  <FormGroup style={{ display: 'flex', 'justify-content': 'space-between' }}>
                    <Label>Multi Factor Authentication:</Label>
                    {/* <div> */}
                    <Switch
                      onChange={(value) => this._handleOnChange('isMFAEnabled', value)}
                      checked={user.isMFAEnabled}
                      uncheckedIcon={''}
                      checkedIcon={''}
                      height={30}
                      width={60}
                      className='switch-toggle-button'
                      onColor={'#F18F3C'}
                    />
                    {/* </div> */}
                  </FormGroup>
                ) : (
                  ''
                )}
                <div className='text-center mt-2'>
                  <Button className='mx-2 cancelBtn' onClick={() => this._getProfileDetails()}>
                    Cancel
                  </Button>
                  <Button className='mx-2 saveBtn' onClick={(e) => this._updateProfile(e)}>
                    Update
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={5} lg={6} md={12}>
            <Card className='userInfo'>
              <CardHeader>Mail Server</CardHeader>
              <CardBody>
                <div style={{ display: 'flex', 'justify-content': 'space-between' }}>
                  {/* <Label>Default Mail Server:</Label> */}
                  <Label>Custom Mail Server:</Label>
                  <Switch
                    onChange={(value) => this._toggleMailServer('isMailServerDefault', value)}
                    checked={!isMailServerDefault}
                    uncheckedIcon={''}
                    checkedIcon={''}
                    height={30}
                    width={60}
                    className='switch-toggle-button'
                    onColor={'#F18F3C'}
                  />
                </div>
                {!isMailServerDefault ? (
                  <>
                    <FormGroup>
                      <Label>SMTP Host:</Label>
                      <Input
                        type='text'
                        placeholder='SMTP Host'
                        value={mailServer.smtpHost}
                        onChange={(e) => this._handleOnChangeMailServer('smtpHost', e.target.value)}
                      />
                      {mailServerError && <div className='validation-error'>{mailServerError.smtpHost}</div>}
                    </FormGroup>
                    <FormGroup>
                      <Label>SMTP Port:</Label>
                      <Input
                        type='text'
                        placeholder='SMTP Port'
                        value={mailServer.smtpPort}
                        onChange={(e) => this._handleOnChangeMailServer('smtpPort', e.target.value)}
                      />
                      {mailServerError && <div className='validation-error'>{mailServerError.smtpPort}</div>}
                    </FormGroup>
                    <FormGroup>
                      <Label>SMTP Address:</Label>
                      <Input
                        type='text'
                        placeholder='SMTP Address'
                        value={mailServer.smtpAddress}
                        onChange={(e) => this._handleOnChangeMailServer('smtpAddress', e.target.value)}
                      />
                      {mailServerError && <div className='validation-error'>{mailServerError.smtpAddress}</div>}
                    </FormGroup>
                    <FormGroup>
                      <Label>SMTP User:</Label>
                      <Input
                        type='text'
                        placeholder='SMTP User'
                        value={mailServer.smtpUser}
                        onChange={(e) => this._handleOnChangeMailServer('smtpUser', e.target.value)}
                      />
                      {mailServerError && <div className='validation-error'>{mailServerError.smtpUser}</div>}
                    </FormGroup>
                    <FormGroup>
                      <Label>SMTP Password:</Label>
                      <Input
                        type='text'
                        placeholder='SMTP Password'
                        value={mailServer.smtpPass}
                        onChange={(e) => this._handleOnChangeMailServer('smtpPass', e.target.value)}
                      />
                      {mailServerError && <div className='validation-error'>{mailServerError.smtpPass}</div>}
                    </FormGroup>
                  </>
                ) : null}
              </CardBody>
              <CardFooter className='innerForm innerCard'>
                {!isMailServerDefault ? (
                  <Button className='addBtn' onClick={(e) => this._addMfa(e)}>
                    Add
                  </Button>
                ) : null}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
