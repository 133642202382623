import axios from 'axios';
import React, { useState } from 'react';
import { Bars } from 'react-loader-spinner';
import QuesAnswer from './Qns';
import ScrollableFeed from 'react-scrollable-feed';

import './styles.css';

const api_key = process.env.REACT_APP_API_KEY;

const Terminal = () => {
  const [newQuestion, setNewQuestion] = useState('');
  const [storedValues, setStoredValues] = useState([]);
  const [loader, setLoader] = useState(false);
  const [question, setQuestion] = useState();

  const instance = axios.create({
    baseURL: 'https://api.openai.com/v1',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${api_key}`,
    },
  });

  const askGPT = async (prompt) => {
    const response = await instance.post('/chat/completions', {
      model: 'gpt-3.5-turbo',
      messages: [{ role: 'user', content: prompt }],
    });
    console.log('Answer to the Question is : ', response.data.choices[0].message.content);

    return response.data.choices[0].message.content; // this is the answer provided by chatgpt as per api
  };

  // const handleSubmit = async () => {
  //   setLoader(true);
  //   setQuestion(newQuestion);
  //   const response = await askGPT(newQuestion);
  //   const id = Date.now();
  //   setStoredValues([
  //     ...storedValues,
  //     {
  //       id,
  //       question: newQuestion,
  //       answer: response
  //     }
  //   ]);
  //   setLoader(false);
  //   setNewQuestion('');
  // };

  const handleSubmit = async () => {
    setLoader(true);
    setQuestion(newQuestion);
    const response = await askGPT(newQuestion);
    const id = Date.now();
    setStoredValues([
      ...storedValues,
      {
        id,
        question: newQuestion,
        answer: response,
      },
    ]);
    setLoader(false);
    setNewQuestion('');
  };

  const enterHandle = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      <div style={{ height: '80%' }} className='page-content page-container customChatContainer' id='page-content'>
        {/* <a href='#' class='modal__close'>
          &times;
        </a> */}

        <div className='chat-main'>
          <div className='row container d-flex centerChat'>
            {/* <h1>{api_key}</h1> */}
            <div className='col-md-12'>
              <div className='card card-chat card-bordered' style={{ border: 'none' }}>
                <div className='card-header'>
                  {/* <h2 className='card-title'>
                    <img className='align-center' src='/assets/img/evos-logo.png' width='100px' alt='...' />
                  </h2> */}
                </div>

                <div className='ps-container ps-theme-default ps-active-y overFLOW' id='chat-content'>
                  {/* <div className='media media-chat media-chat-width media-chat-reverse'>
                    <div className='media-body'>
                      <p>{newQuestion}</p>
                    </div>
                  </div> */}

                  <ScrollableFeed style={{ maxHeight: '100%', overflow: 'auto' }}>
                    {storedValues.map((value, idx) => {
                      return (
                        <>
                          <QuesAnswer ques={value.question} answer={value.answer} key={idx} />;
                        </>
                      );
                    })}
                    <div className='media media-chat media-chat-width media-chat-reverse'>
                      <div className='media-body'>{newQuestion && <p>{newQuestion}</p>}</div>
                    </div>
                  </ScrollableFeed>

                  <div className='ps-scrollbar-x-rail' style={{ left: '0px', bottom: '0px' }}>
                    <div className='ps-scrollbar-x' tabIndex='0' style={{ left: '0px', width: '0px' }}></div>
                  </div>
                  <div className='ps-scrollbar-y-rail' style={{ top: '0px', height: '0px', right: '2px' }}>
                    <div className='ps-scrollbar-y' tabIndex='0' style={{ top: '0px', height: '2px' }}></div>
                  </div>
                </div>
                {loader === true ? (
                  <div className='publisher border-light'>
                    <img className='' src='/assets/img/evos-logo-1.png' width='50px' alt='EVOS' />
                    <input
                      className='publisher-input'
                      type='text'
                      placeholder='Ask Me Anything'
                      value={newQuestion}
                      disabled
                    />
                    <Bars
                      height='36'
                      width='36'
                      color='#4fa94d'
                      ariaLabel='bars-loading'
                      wrapperStyle={{}}
                      wrapperClass=''
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className='publisher border-light'>
                    <img className='' src='/assets/img/evos-logo-1.png' width='50px' alt='...' />
                    <input
                      className='publisher-input'
                      type='text'
                      placeholder='Ask Me Anything...'
                      value={newQuestion}
                      onChange={(e) => {
                        setNewQuestion(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        enterHandle(e);
                      }}
                    />
                    <button
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      className='chatgpt-btn btn addBtn btn btn-secondary chat-btn'>
                      <i className='fa fa-paper-plane'></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terminal;
