import React, { useState } from 'react';
import { FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const WiresharkModal = (props) => {
    const [portList, setPortList] = useState([]);
    const [errors, setErrors] = useState({});
    const [vmPort, setVmPort] = useState("");
    const [vmInterface, setvmInterface] = useState("")

    console.log(props.portList);

    const _handleOnChange = (value) => {
        let json = JSON.parse(value);
        setvmInterface(value);
        setVmPort({ port: json.hostInterface, bridge: json.source });
    }

    return (
        <Modal
            isOpen={props.isOpen}
            toggle={() => props.toggle()}
            className="modal-lg"
        >
            <ModalHeader toggle={() => props.toggle()}>
                Wireshark Capture
            </ModalHeader>
            <ModalBody>
                {props.portList && <FormGroup>
                    <Label>VM Interface</Label>
                    <Input
                        type="select"
                        rows="3"
                        placeholder="Select VM Interface"
                        value={vmInterface}
                        onChange={(e) => _handleOnChange(e.target.value)}
                    >
                        <option value="">Select VM Interface</option>
                        {React.Children.toArray(
                            props.portList.map((each) => {
                                if (each.type === "bridge") {
                                    return (
                                        <option value={JSON.stringify(each)}>Port {each.vmPort}</option>
                                    );
                                } else {
                                    return null;
                                }
                            })
                        )}
                    </Input>
                    {errors && <div className="validation-error">{errors.vmPort}</div>}
                </FormGroup>}
            </ModalBody>
            <ModalFooter>

                <Button color="secondary" className="modalBtnCancel" onClick={props.toggle} >
                    Close
                </Button>
                <Button color="primary" className="modalBtnSave" onClick={() => props.onSubmit(vmPort)}>
                    Track
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default WiresharkModal;