import React, { Component } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';

import { b64toBlob, deepClone, getTextWidth, showToast } from '../../../helper-methods';
import TopologyEditor from '../components/topology-editor/topology-editor';
import { socketConnect } from 'socket.io-react';
import AdminNavbar from '../components/Navbars/AdminNavbar';
import DesignLabSidebar from '../components/Sidebar/DesignLabSidebar';
import VncList from '../components/vnclist';
import Footer from '../components/Footer/Footer';
import config from '../../../config';
import AddVirtualNetworkModal from '../components/design-lab/add-virtual-network-modal';
import TerminalModal from '../components/terminal';
import DialActionMenu from '../components/Sidebar/DialActionMenu';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import {
  getLabDetails,
  createNewNetwork,
  getAllFilesList,
  updateLabComponentPosition,
  getAllvmTemplates,
  createNewVirtualMachine,
  startVMNode,
  stopVMNode,
  wipeVMNode,
  deleteVMNode,
  deleteVirtualNetwork,
  connectNodes,
  disconnectNodes,
  getLabNodes,
  getAllLabObjects,
  addLabObject,
  editLabObject,
  deleteLabObject,
  getDefaultIcons,
  editVNodeBasicInfo,
  getVNodeDetails,
  modifyVMNode,
  editLab,
  addDrawing,
  addDrawingToLab,
  getAllLabDrawings,
  getLabFiles,
  addLabFiles,
  postWireshark,
  removeWiresharkDocker,
  getPortsAndExistingEmulations,
} from '../../../http/http-calls';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import CloseLabConfirmationModal from '../components/design-lab/close-lab-confirmation-modal';
import AddVirtualMachineModal from '../components/design-lab/add-virtual-machine-modal';
import DeleteNodeConfirmationModal from '../components/design-lab/delete-node-confirmation-modal';
import LabNodeListModal from '../components/design-lab/lab-node-list-modal';
import LabNetworkListModal from '../components/design-lab/lab-network-list-modal';
import AddLabObjectModal from '../components/design-lab/add-lab-object-modal';
import LabObjectListModal from '../components/design-lab/lab-object-list-modal';
import DeleteLabObjectConfirmationModal from '../components/design-lab/delete-lab-object-confirmation-modal';
import EditVNodeModal from '../components/design-lab/edit-vnode-modal';
import ModifyVirtualMachineModal from '../components/design-lab/modify-virtual-machine-modal';
import GuacamoleModal from '../components/design-lab/guacamole-modal';
import AddDockerContainerModal from '../components/design-lab/add-docker-container-moda';
import AddDockerConnectionModal from '../components/design-lab/add-docker-connection-modal';
import AddConnectionModal from '../components/design-lab/add-connection-modal';
import ConnectionInfoModal from '../components/design-lab/connection-information-modal';
import EditConnectionModal from '../components/design-lab/edit-connection-modal';
import EditLabObjectModal from '../components/design-lab/edit-lab-object-modal';
import PathEmulationModal from '../components/design-lab/path-emulation-modal';
import ChangeCanvasBgModal from '../components/design-lab/change-canvas-bg-modal';
import StorageModal from '../components/design-lab/storage-modal';
import * as htmlToImage from 'html-to-image';
import DrawingModal from '../components/design-lab/drawing-modal';
import DrawingListModal from '../components/design-lab/drawing-list-modal';
// import ChatGPTModal from '../components/design-lab/chat-gpt-modal';
import WiresharkModal from '../components/design-lab/wireshark-modal';
// import ChatGPTModal from './chatGPTModal';

import './chatButton.css';
// import MyComp from '../pages/my-comp';
var ps;

class DesignLabLayout extends Component {
  state = {
    backgroundColor: 'blue',
    sidebarOpened: document.documentElement.className.indexOf('nav-open') !== -1,
    chartData: {
      nodes: [],
      edges: [],
      scale: 0.5,
    },
    addVirtualNetworkModal: {
      isOpen: false,
      data: null,
    },
    activeJobs: [],
    iconList: [],
    vmTemplates: [],
    isLoading: false,
    closeLabConfirmationModal: {
      isOpen: false,
      data: null,
    },
    addVirtualMachineModal: {
      isOpen: false,
      data: null,
    },
    selectedNode: '',
    deleteNodeConfirmationModal: {
      isOpen: false,
      type: '',
      data: null,
    },
    labNodeListModal: {
      isOpen: false,
      data: null,
    },
    labNetworkListModal: {
      isOpen: false,
      data: null,
    },
    labName: '',
    addLabObjectModal: {
      isOpen: false,
      data: null,
    },
    labObjectListModal: {
      isOpen: false,
      data: null,
    },
    deleteLabObjectConfirmationModal: {
      isOpen: false,
      data: null,
    },
    defaultIcons: [],
    editVNodeModal: {
      isOpen: false,
      data: null,
    },
    modifyVirtualMachineModal: {
      isOpen: false,
      data: null,
    },
    guacamoleModal: {
      isOpen: false,
      data: null,
      type: '',
    },
    addDockerContainerModal: {
      isOpen: false,
      data: null,
    },
    addDockerConnectionModal: {
      isOpen: false,
      data: null,
    },
    addConnectionModal: {
      isOpen: false,
      data: null,
    },
    connectionInfoModal: {
      isOpen: false,
      data: null,
    },
    showCanvasMenu: {
      visible: false,
      x: 0,
      y: 0,
    },
    canvasRightClick: false,
    showNodeMenu: {
      visible: false,
      x: 0,
      y: 0,
      node: null,
    },
    nodeRightClick: false,
    showShapeMenu: {
      visible: false,
      x: 0,
      y: 0,
      node: null,
    },
    shapeRightclick: false,
    selectedNodes: [],
    editConnectionModal: {
      isOpen: false,
      data: null,
    },
    editLabObjectModal: {
      isOpen: false,
      data: null,
    },
    toolkit: {},
    surface: {},
    pathEmulationModal: {
      isOpen: false,
      data: null,
    },
    changeCanvasBgModal: {
      isOpen: false,
      data: null,
    },
    bgImageUrl: '',
    openedGuacamoleModalList: [],
    multipleDelete: {
      enable: false,
      data: null,
    },
    drawingModal: {
      isOpen: false,
      data: null,
    },
    drawingListModal: {
      isOpen: false,
      data: null,
    },
    chatGPTModal: {
      isOpen: false,
      data: null,
    },
    storageModal: {
      isOpen: false,
      data: [],
    },
    minimizedModals: [],
    wireshark: {
      wiresharkSocketTunnel: null,
    },
    wiresharkModal: {
      isOpen: false,
      portList: null,
    },
    nodeMap: {},
    terminal: {
      open: false,
    },
  };

  // This is for automatic guacamole login
  setGuacToken = () => {
    // This code is for setting the token, so that auto login happens for guacamole auto login
    async function getCookie() {
      const BASE = config.baseUrl
      await axios.get(`${BASE}guacamole-login`);

      function decodeCookie(cookieName) {
        let cookies = document.cookie;
        let cookieArray = cookies.split('; ');

        for (let i = 0; i < cookieArray.length; i++) {
          let cookie = cookieArray[i];
          let [name, value] = cookie.split('=');
          if (name === cookieName) {
            return decodeURIComponent(value);
          }
        }
        return null;
      }

      let token = decodeCookie("Guacamole-Token")
      return token
    }


    const guac_token = {
      authToken: '',
      username: 'guacadmin',
      dataSource: 'postgresql',
      availableDataSources: ['postgresql', 'postgresql-shared'],
    };
    let guac_cookie;
    getCookie().then(res => {
      guac_cookie = res
      guac_token.authToken = guac_cookie;
      localStorage.setItem('GUAC_AUTH', JSON.stringify(guac_token));
    }
    )
    // End for auto login set up for guacamole.
  }

  // Wireshark
  createWireshark = ({ port, bridge }) => {
    this.props.showLoader();
    if (!this.state.wiresharkSocketTunnel) {
      const { showNodeMenu } = deepClone(this.state);
      let nodeData = showNodeMenu.node.node.data;
      postWireshark(this.props.match.params.id, nodeData.properties.id, port, bridge)
        .then((response) => {
          const { showNodeMenu, nodeMap } = deepClone(this.state);
          if (!nodeMap[showNodeMenu.node.node.data.id]) {
            nodeMap[showNodeMenu.node.node.data.id] = {};
          }

          if (!nodeMap[showNodeMenu.node.node.data.id].guacamoleWebSocketTunnel) {
            nodeMap[showNodeMenu.node.node.data.id].guacamoleWebSocketTunnel = {};
          }
          nodeMap[showNodeMenu.node.node.data.id].guacamoleWebSocketTunnel.wireshark = response.tunnel.vnc;
          this.setState({ nodeMap, wiresharkModal: { isOpen: false, portList: null } });
          window.open(response.tunnel.vnc, "_blank")
          // this.openWireshark(response.wiresharkSocketTunnel);
          setTimeout(() => {
            toast.success(`Successfully create wireshark`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 1000);
          this.props.hideLoader();
        })
        .catch((error) => {
          toast.error(`${error.reason ? error.reason : 'something went wrong'}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.props.hideLoader();
        });
    }
  };

  viewWireshark = () => {
    const { showNodeMenu } = deepClone(this.state);
    const { openedGuacamoleModalList } = deepClone(this.state);

    const data = {
      properties: {
        guacamoleWebSocketTunnel: {
          vnc: this.state.nodeMap[showNodeMenu.node.node.data.id].guacamoleWebSocketTunnel.wireshark,
        },
        isDockerNode: true,
        name: 'Wireshark',
        vmState: 'running',
      },
      type: 'vnc',
    };

    let nodeData = showNodeMenu.node.node.data;
    window.open(this.state.nodeMap[nodeData.id].guacamoleWebSocketTunnel.wireshark, "_blank")
    openedGuacamoleModalList.push({ data, isOpen: true, isMinimised: false, type: 'vnc' });
    this.setState({ openedGuacamoleModalList });
  };

  removeWireshark = () => {
    const { showNodeMenu } = deepClone(this.state);
    let nodeData = showNodeMenu.node.node.data;
    this.props.showLoader();
    removeWiresharkDocker(this.props.match.params.id, nodeData.properties.id)
      .then((response) => {
        const { showNodeMenu, nodeMap } = deepClone(this.state);
        nodeMap[showNodeMenu.node.node.data.id].guacamoleWebSocketTunnel.wireshark = undefined;
        this.setState({ nodeMap }, () => {
          console.log('Stop capture', this.state.chartData);
        });
        this.hideLoader();
      })
      .catch((error) => {
        console.log(error);
        this.props.hideLoader();
      });
  };

  openWireshark = (tunnelAddress) => {
    const { openedGuacamoleModalList } = deepClone(this.state);
    const data = {
      properties: {
        guacamoleWebSocketTunnel: {
          vnc: tunnelAddress || this.state.wireshark.wiresharkSocketTunnel,
        },
        isDockerNode: true,
        name: 'Wireshark',
        vmState: 'running',
      },
      type: 'vnc',
    };
    openedGuacamoleModalList.push({ data, isOpen: true, isMinimised: false, type: 'vnc' });
    this.setState({ openedGuacamoleModalList });
  };

  componentDidMount() {
    // console.log("window.location :>> ", window.location);
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll('.table-responsive');
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    // initiate Design lab
    this._initLab();
  }

  // initiate Design lab
  _initLab = async () => {
    this._addContextMenuListener();
    this._addJobStatusListener();
    this._getAllIcons();
    this._getDefaultIcons();
    this._getAllvmTemplates();
    this._getLabDetails(this.props.match.params.id);
    // this._getAllLabObjects(this.props.match.params.id);
  };

  // To add right click listener
  _addContextMenuListener = () => {
    document.addEventListener('contextmenu', (event) => {
      // console.log("event :>> ", event.target);
      event.preventDefault();
      if (
        event.target?.className &&
        event.target?.className.includes &&
        event.target?.className.includes('jtk-surface')
      ) {
        // console.log("canvas-right-click :>> ", event);
        event.preventDefault();
        const clickX = event.x;
        const clickY = event.y;
        const showCanvasMenu = {
          visible: false,
          x: clickX,
          y: clickY,
        };
        this.setState({ showCanvasMenu, canvasRightClick: true });
      }
    });
  };

  // To add job status listener with socket
  _addJobStatusListener = () => {
    // console.log("here CustomNodeInner:>> ", this.props.socket);
    this.props.socket.on('job-status-changed', async (data) => {
      // const { chartData } = deepClone(this.state);
      // console.log("data :>> ", data);
      if (data.jobCategory === 'create-network-node' || data.jobCategory === 'create-vm-node') {
        // console.log("here :>> ", data.jobId);
        let latestTopologyData = this.state.toolkit.exportData();
        let nodeData = latestTopologyData.nodes.find((e) => e.id === data.jobCode);
        if (nodeData) {
          // if node with jobCode exists
          nodeData.properties.status = data.jobStatus;
          if (data.jobStatus === 'successful') {
            nodeData.properties.uuid = data.jobOutput.vNode.uuid;
            nodeData.properties.vmState = data.jobOutput.vNode.vmState;
            nodeData.properties.id = data.jobOutput.vNode._id;
            nodeData.id = data.jobOutput.vNode._id;
            nodeData.ports.port1.properties.vmState = data.jobOutput.vNode.vmState;
            Object.keys(nodeData.properties.ports).forEach((key) => {
              nodeData.properties.ports[key].properties.vmState = data.jobOutput.vNode.vmState;
            });
            let newNode = deepClone(nodeData);
            this.state.toolkit.removeNode(data.jobCode);
            setTimeout(() => {
              // console.log('newNode :>> ', newNode);
              this.state.toolkit.addNode(newNode);
              this.state.surface.zoomToFit();
            });
            // delete nodeData;
          }
        } else {
          // console.log('No such Job Code :>>');
        }
      } else if (data.jobCategory === 'start-vm-node' || data.jobCategory === 'stop-vm-node') {
        if (data.jobStatus === 'successful') {
          let latestTopologyData = this.state.toolkit.exportData();
          const { multipleDelete } = deepClone(this.state);
          let nodeData = latestTopologyData.nodes.find((e) => e.id === data.jobOutput._vNode);
          if (nodeData) {
            try {
              let res = await getVNodeDetails(data.jobOutput._vNode);
              nodeData.properties.status = data.jobStatus;
              nodeData.properties.vmState = data.jobOutput.vmState;
              nodeData.properties.guacamoleWebSocketTunnel = res.vNode.guacamoleWebSocketTunnel || {};
              nodeData.ports.port1.properties.vmState = data.jobOutput.vmState;
              Object.keys(nodeData.properties.ports).forEach((key) => {
                nodeData.properties.ports[key].properties.vmState = data.jobOutput.vmState;
              });
              this.state.toolkit.updateNode(data.jobOutput._vNode, { properties: nodeData.properties });
              if (data.jobCategory === 'stop-vm-node') {
                if (multipleDelete.enable && multipleDelete.data?.connectedRunningNodes.length) {
                  let remainingNodes = this.state.multipleDelete.data.connectedRunningNodes.filter(
                    (e) => e.id !== data.jobOutput._vNode
                  );
                  multipleDelete.data.connectedRunningNodes = remainingNodes;
                  this.setState({ multipleDelete }, () => {
                    // console.log('object :>> ', this.state.multipleDelete);
                    if (!this.state.multipleDelete.data?.connectedRunningNodes.length) {
                      this._proceedMultipleDelete();
                    }
                  });
                } else if (multipleDelete.enable && !multipleDelete.data?.connectedRunningNodes.length) {
                  // console.log('all stopped :>> ', multipleDelete.data?.connectedRunningNodes);
                  this._proceedMultipleDelete();
                }
              }
            } catch (error) {
              // console.log('error :>> ', error);
              showToast(error.reason, 'error');
            }
          } else {
            // console.log('No such Node :>> ', data.jobOutput._vNode);
          }
        }
      } else if (data.jobCategory === 'delete-network-node' || data.jobCategory === 'delete-vm-node') {
        if (data.jobStatus === 'successful') {
          // console.log('data :>> ', data);
          let latestTopologyData = this.state.toolkit.exportData();
          let nodeData = latestTopologyData.nodes.find((e) => e.id === data.jobOutput._vNode);
          if (nodeData) {
            this.state.toolkit.removeNode(data.jobOutput._vNode);
          } else {
            // console.log('No such Node :>> ', data.jobOutput._vNode);
          }
        }
      } else if (data.jobCategory === 'connect-nodes') {
        // console.log('here :>> ',);
        if (data.jobStatus === 'successful') {
          const { addConnectionModal, addDockerConnectionModal } = deepClone(this.state);
          let toolkit = addConnectionModal.data?.toolkit || addDockerConnectionModal.data?.toolkit;
          if (toolkit) {
            let latestTopologyData = toolkit.exportData();
            // console.log('latestTopologyData :>> ', latestTopologyData);
            const edgeData = latestTopologyData.edges.find((e) => e.data.id === data.jobCode)?.data;
            // console.log('edgeData :>> ', edgeData);
            if (edgeData) {
              let fromNodeId = data.jobOutput.vConnection.from._vNode;
              let toNodeId = data.jobOutput.vConnection.to._vNode;
              let fromNode = latestTopologyData?.nodes.find((e) => e.id === fromNodeId);
              let toNode = latestTopologyData?.nodes.find((e) => e.id === toNodeId);

              // chartData.nodes[data.jobOutput.vConnection.from._vNode].ports['port1'].properties.linkColor = '#6495ed';
              fromNode.properties.ports['port' + data.jobOutput.vConnection.from.ethernetPortNo].properties.isUsed =
                data.jobOutput.vConnection.from.isNetworkNode ? false : true;
              toNode.properties.ports['port' + data.jobOutput.vConnection.to.ethernetPortNo].properties.isUsed = data
                .jobOutput.vConnection.to.isNetworkNode
                ? false
                : true;
              toolkit.updateNode(fromNodeId, { properties: fromNode.properties });
              toolkit.updateNode(toNode, { properties: toNode.properties });
              let edgeDataToUpdate = {
                source: data.jobOutput.vConnection.from._vNode,
                target: data.jobOutput.vConnection.to._vNode,
                data: {
                  uuid: data.jobOutput.vConnection.uuid,
                  id: data.jobOutput.vConnection._id,
                  label: 'Link',
                  status: 'connected',
                  type: data.jobOutput.vConnection.position?.lineType || 'default',
                  color: data.jobOutput.vConnection.position?.color || '#89bcde',
                  strokeType: data.jobOutput.vConnection.position?.strokeType === 'dashed' ? '4,4' : '0,0',
                  connectionInfo: {
                    from: {
                      nodeId: data.jobOutput.vConnection.from._vNode,
                      portId: 'port' + data.jobOutput.vConnection.from.ethernetPortNo,
                      portName:
                        fromNode.properties.ports['port' + data.jobOutput.vConnection.from.ethernetPortNo].properties
                          .name,
                    },
                    to: {
                      nodeId: data.jobOutput.vConnection.to._vNode,
                      portId: 'port' + data.jobOutput.vConnection.to.ethernetPortNo,
                      portName:
                        toNode.properties.ports['port' + data.jobOutput.vConnection.to.ethernetPortNo].properties.name,
                    },
                  },
                },
              };
              toolkit.removeEdge(
                addConnectionModal.data?.linkData.edge || addDockerConnectionModal.data?.linkData.edge
              );
              toolkit.connect(edgeDataToUpdate);
              // toolkit.updateEdge(edgeData.id, edgeDataToUpdate);
              // console.log('toolkit.exportData() :>> ', toolkit.exportData());
              addConnectionModal.data = null;
              addDockerConnectionModal.data = null;
              this.setState({ addConnectionModal, addDockerConnectionModal });
            } else {
              // console.log('No such Node :>> ', data.jobOutput._vNode);
            }
          }
        }
      } else if (data.jobCategory === 'disconnect-nodes') {
        if (data.jobStatus === 'successful') {
          // console.log('data :>> ', data);
          const { deleteNodeConfirmationModal, multipleDelete } = deepClone(this.state);
          // console.log('deleteNodeConfirmationModal.data :>> ', deleteNodeConfirmationModal.data);
          if (deleteNodeConfirmationModal.data?.linkData?.edge?.data?.id === data.jobOutput._vConnection) {
            let fromNodeId = deleteNodeConfirmationModal.data?.linkData?.edge.data.connectionInfo.from.nodeId;
            let toNodeId = deleteNodeConfirmationModal.data?.linkData?.edge.data.connectionInfo.to.nodeId;
            let fromNode = deleteNodeConfirmationModal.data?.chartData?.nodes.find((e) => e.id === fromNodeId);
            let toNode = deleteNodeConfirmationModal.data?.chartData?.nodes.find((e) => e.id === toNodeId);
            let fromPortId = deleteNodeConfirmationModal.data?.linkData?.edge.data.connectionInfo.from.portId;
            let toPortId = deleteNodeConfirmationModal.data?.linkData?.edge.data.connectionInfo.to.portId;
            fromNode.properties.ports[fromPortId].properties.isUsed = false;
            toNode.properties.ports[toPortId].properties.isUsed = false;
            deleteNodeConfirmationModal.data.toolkit.updateNode(fromNodeId, { properties: fromNode.properties });
            deleteNodeConfirmationModal.data.toolkit.updateNode(toNode, { properties: toNode.properties });
            deleteNodeConfirmationModal.data.toolkit.removeEdge(deleteNodeConfirmationModal.data.linkData.edge);
            this._closeDeleteNodeConfirmationModal('cancel');
          } else if (multipleDelete.enable) {
            if (multipleDelete.data?.connectedLinks.length) {
              let edgeData = multipleDelete.data?.connectedLinks.find((e) => e.data.id === data.jobOutput._vConnection);
              let latestTopologyData = this.state.toolkit.exportData();
              let fromNodeId = edgeData.source;
              let toNodeId = edgeData.target;
              let fromNode = latestTopologyData.nodes.find((e) => e.id === fromNodeId);
              let toNode = latestTopologyData.nodes.find((e) => e.id === toNodeId);
              let fromPortId = edgeData.data.connectionInfo.from.portId;
              let toPortId = edgeData.data.connectionInfo.to.portId;
              fromNode.properties.ports[fromPortId].properties.isUsed = false;
              toNode.properties.ports[toPortId].properties.isUsed = false;
              this.state.toolkit.updateNode(fromNodeId, { properties: fromNode.properties });
              this.state.toolkit.updateNode(toNode, { properties: toNode.properties });
              this.state.toolkit.removeEdge(data.jobOutput._vConnection);
              multipleDelete.data.connectedLinks = this.state.multipleDelete.data.connectedLinks.filter(
                (e) => e.data.id !== data.jobOutput._vConnection
              );
              this.setState({ multipleDelete }, () => {
                if (!this.state.multipleDelete.data?.connectedLinks.length) {
                  this._proceedMultipleDelete();
                }
              });
            } else {
              // console.log('all linked deleted :>> ', multipleDelete.data?.connectedLinks);
            }
          } else {
            // console.log('No such Node :>> ', data.jobOutput._vConnection);
          }
        }
      } else if (data.jobCategory === 'modify-vm-node') {
        if (data.jobStatus === 'successful') {
          let latestTopologyData = this.state.toolkit.exportData();
          let nodeData = latestTopologyData.nodes.find((e) => e.id === data.jobOutput.vNode.id);
          if (nodeData) {
            nodeData.properties.status = data.jobStatus;
            let portCount = data.jobOutput.vNode.ethernets;
            nodeData.properties.ports = {};
            for (let i = 0; i < portCount; i++) {
              nodeData.properties.ports['port' + (i + 1)] = {
                id: 'port' + (i + 1),
                type: 'output',
                properties: {
                  name: data.jobOutput.vNode?.dataFromTemplate?.ethernetNames
                    ? data.jobOutput.vNode?.dataFromTemplate?.ethernetNames[i] || `Port ${i + 1}`
                    : `Port ${i + 1}`,
                  linkColor: '#6495ed',
                  ethernetPortNo: i + 1,
                  vmState: 'stopped',
                  isUsed: false,
                },
              };
            }
            this.state.toolkit.updateNode(data.jobOutput.vNode.id, { properties: nodeData.properties });
          } else {
            // console.log('No such Node :>> ', data.jobOutput.vNode);
          }
        }
      } else {
        // console.log('else :>> ', data.jobCategory);
      }
    });
  };

  // on component unmount
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
  }

  // on component update
  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      if (navigator.platform.indexOf('Win') > -1) {
        let tables = document.querySelectorAll('.table-responsive');
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };

  // To get the icon assets
  _getAllIcons = async () => {
    let res = await getAllFilesList({ isIcon: true });
    // console.log("res :>> ", res);
    this.setState({ iconList: res.files });
  };

  // To get the default icons
  _getDefaultIcons = async () => {
    let res = await getDefaultIcons();
    // console.log('res :>> ', res);
    this.setState({ defaultIcons: res.defaultIcons });
  };

  // To get all vmTemplates
  _getAllvmTemplates = async () => {
    let res = await getAllvmTemplates();
    // console.log("res :>> ", res);
    this.setState({ vmTemplates: res.vmTemplates });
  };

  // To get Lab details
  _getLabDetails = async (labId) => {
    try {
      this.props.showLoader();
      let res = await getLabDetails(labId);
      console.log('res :>> ', res);
      this.setState({ labName: res.lab.name, bgImageUrl: res.lab._bgImage?.url || '' });
      this.props.hideLoader();
      this.setState({ isLoading: true });
      this._renderLabDataInTopologyEditor(res.lab);
    } catch (error) {
      this.props.hideLoader();
      // console.error(error, "BREAKING4");
      showToast(error.reason, 'error');
    }
  };

  // To render the lab components in topology editor
  _renderLabDataInTopologyEditor = (labData) => {
    // console.log("labData :>> ", labData);
    const { chartData } = deepClone(this.state);
    chartData.scale = labData.zoom || 0.7;
    chartData.nodes = [];
    let map = {};
    labData.components.forEach((each) => {
      let eachNode = deepClone(each);
      if (eachNode?._object?._id) {
        map[eachNode._object._id] = eachNode._object;
      }
      console.log('chartData', map);
      if (each.type === 'VNode' && each._object) {
        let nodeData = {
          id: each._object.id,
          type: 'node',
          left: each._object.position ? Number(each._object.position?.x).toFixed(2) : 10,
          top: each._object.position ? Number(each._object.position?.y).toFixed(2) : 10,
          w: 100,
          h: 80,
          ports: {
            port1: {
              id: 'port1',
              type: 'output',
              properties: {
                linkColor: '#6495ed',
                ethernetPortNo: 1,
                vmState: each._object.vmState,
                isUsed: false,
              },
            },
          },
          properties: {
            name: each._object.name,
            description: each._object.description,
            uuid: each._object.uuid,
            networkType: each._object.isNetworkNode ? each._object.networkType : each.type,
            icon:
              each._object._icon && each._object._icon.url
                ? each._object._icon.url
                : require('../../../assets/img/computer.png'),
            iconId: each._object._icon && each._object._icon.id,
            vmState: each._object.vmState,
            id: each._object.id,
            isDockerNode: each._object.baseBin === 'docker',
            guacamoleWebSocketTunnel: each._object.guacamoleWebSocketTunnel || {},
            ports: {},
          },
        };
        let noOfPorts = each._object.isNetworkNode ? 1 : each._object.ethernets;
        for (let i = 0; i < noOfPorts; i++) {
          nodeData.properties.ports['port' + (i + 1)] = {
            id: 'port' + (i + 1),
            type: 'output',
            properties: {
              name: each._object?.dataFromTemplate?.ethernetNames
                ? each._object?.dataFromTemplate?.ethernetNames[i] || `Port ${i + 1}`
                : `Port ${i + 1}`,
              linkColor: '#6495ed',
              ethernetPortNo: i + 1,
              vmState: each._object.vmState,
              isUsed: each._object.isNetworkNode ? false : this._checkPortHasLink(labData, each._object.id, i + 1),
            },
          };
        }
        chartData.nodes.push(nodeData);
      } else if (each.type === 'LabObject') {
        if (each._object) {
          each._object.data.width = each._object?.data.width || 100;
          each._object.data.height = each._object?.data.height || 80;
          let shapeData = {
            id: each._object?.id,
            type: each._object?.data.shape === 'image' ? 'image' : 'shape',
            shapeType: each._object?.data.shape === 'image' ? 'image' : each._object?.data.shape,
            left: each._object?.data ? Number(each._object?.data?.x || each._object?.data?.x1).toFixed(2) : 10,
            top: each._object?.data ? Number(each._object?.data?.y || each._object?.data?.y1).toFixed(2) : 10,
            w: this._getShapeDimentions(each._object.data).width,
            h: this._getShapeDimentions(each._object.data).height,
            shapeData: each._object?.data,
          };
          chartData.nodes.push(shapeData);
        }
      } else if (each.type === 'VConnection' && each._object) {
        let edgeData = {
          source: each._object.from._vNode,
          target: each._object.to._vNode,
          data: {
            uuid: each._object.uuid,
            id: each._object.id,
            label: 'Link',
            status: 'connected',
            type: each._object.position?.lineType || 'default',
            color: each._object.position?.color || '#89bcde',
            strokeType: each._object.position?.strokeType === 'dashed' ? '4,4' : '0,0',
            connectionInfo: {
              from: {
                nodeId: each._object.from._vNode,
                portId: 'port' + each._object.from.ethernetPortNo,
                portName: this._getPortName(labData, each._object.from._vNode, each._object.from.ethernetPortNo),
              },
              to: {
                nodeId: each._object.to._vNode,
                portId: 'port' + each._object.to.ethernetPortNo,
                portName: this._getPortName(labData, each._object.to._vNode, each._object.to.ethernetPortNo),
              },
            },
          },
        };
        chartData.edges.push(edgeData);
      }
    });

    console.log('res :>', chartData);
    console.log('chartData :>> ', this.state.nodeMap);
    this.setState({ chartData, isLoading: false, nodeMap: map });
  };

  _getShapeDimentions = (data) => {
    switch (data.shape) {
      case 'rect':
        return { width: data.width, height: data.height };
      case 'circle':
        return { width: data.radius * 2, height: data.radius * 2 };
      case 'image':
        return { width: data.width || 100, height: data.height || 100 };
      case 'text':
        return { width: getTextWidth(data.textContent, data.fontSize || 16), height: (data.fontSize || 16) * 2 };
      case 'line':
        return { width: data.x2, height: data.y2 };
      default: {
        return { width: 100, height: 80 };
      }
    }
  };

  _checkPortHasLink = (labData, nodeId, portId) => {
    const links = labData.components.filter((each) => each.type === 'VConnection');
    let link = links.find((each) => {
      // eslint-disable-next-line
      return (
        (!each._object?.from._vNode.isNetworkNode &&
          each._object?.from._vNode === nodeId &&
          each._object?.from.ethernetPortNo === portId) ||
        // eslint-disable-next-line
        (!each._object?.to._vNode.isNetworkNode &&
          each._object?.to._vNode === nodeId &&
          each._object?.to.ethernetPortNo === portId)
      );
    });
    return link ? true : false;
  };

  _getPortName = (labData, nodeId, ethernetPortNo) => {
    const nodeData = labData.components.find((each) => each.type === 'VNode' && each?._object?._id === nodeId);
    if (nodeData?._object?.dataFromTemplate?.ethernetNames) {
      return nodeData?._object?.dataFromTemplate?.ethernetNames[ethernetPortNo - 1] || 'Port' + ethernetPortNo;
    } else {
      return 'Port' + ethernetPortNo;
    }
  };

  _onResizeObject = async (data) => {
    // console.log('data :>> ', data);
    data.shapeData.x = data.left;
    data.shapeData.y = data.top;
    data.shapeData.width = data.w;
    data.shapeData.height = data.h;
    if (data.shapeData.shape === 'circle') {
      data.shapeData.radius = data.shapeData.width / 2;
    } else if (data.shapeData.shape === 'text') {
      data.shapeData.fontSize = data.shapeData.height / 2;
    } else if (data.shapeData.shape === 'line') {
      data.shapeData.strokeWidth = data.shapeData.strokeWidth || 2;
      data.shapeData.x1 = 0;
      data.shapeData.y1 = 0;
      data.shapeData.x2 = data.w;
      data.shapeData.y2 = data.h;
    }
    // console.log('data.shapeData :>> ', data.shapeData);
    try {
      await editLabObject(data.id, { data: data.shapeData });
      showToast('Object Updated', 'success');
    } catch (error) {
      // console.log('error :>> ', error);
      showToast(error.reason, 'error');
    }
  };

  // To get all Lab Objects / Annotations - Not Using Now
  _getAllLabObjects = async (labId) => {
    let res = await getAllLabObjects(labId);
    // console.log('res :>> ', res.labObjects);
    const { chartData } = deepClone(this.state);
    if (res.labObjects.length) {
      let labObjectNodeId = uuidv4();
      chartData.nodes[labObjectNodeId] = {
        id: labObjectNodeId,
        type: 'output-only',
        position: { x: 0, y: 0 },
        ports: {},
        properties: {
          type: 'LabObject',
          childrens: res.labObjects,
          onResize: this._onResizeObject,
        },
      };
      this.setState({ chartData, labObjectNodeId });
    }
  };

  _openTerminal = () => {
    let terminal = deepClone(this.state.terminal);
    terminal.open = true;
    this.setState({ terminal });
  };

  /**
   * Handle on topology editor update
   * @param {*} state latest chart data or selected nodes from topology editor
   * @param {*} data data related to event
   * @param {*} eventName name of the event
   * @param {*} instance toolkit
   * @param {*} surface surface
   */
  _onFlowChartUpdate = (state, data, eventName, instance, surface) => {
    // console.log("data, eventName :", eventName);
    if (instance) {
      this.setState({ toolkit: instance });
    }
    if (eventName === 'onCanvasClick') {
      this._handleOnCanvasClick();
    } else if (eventName === 'onNodeRightClick') {
      this._handleOnNodeRightClick(state, data);
    } else if (eventName === 'onShapeRightClick') {
      this._handleOnShapeRightClick(state, data);
    } else if (eventName === 'onSelectNode') {
      this._handleOnNodeSelect(instance, data);
    } else if (eventName === 'onDragNodeEnd') {
      this._updateNodePosition(data);
    } else if (eventName === 'onShapeClick') {
      this._onResizeObject(data);
    } else if (eventName === 'onZoomCanvas') {
      // this.setState({ chartData: state });
    } else if (eventName === 'onLinkStart') {
      // this._updateTempLinkProperties(state, data);
    } else if (eventName === 'onLinkComplete') {
      this._conntectNodes(state, data, null, instance);
    } else if (eventName === 'onLinkClick') {
      this._openConnectionInfoModal(state, data, instance);
    } else if (eventName === 'onLoadTopology') {
      this.setState({ toolkit: instance, surface: surface }, () => {
        if (this.state.bgImageUrl) {
          surface._el.style.backgroundImage = `url('${this.state.bgImageUrl}')`;
          surface._el.style.backgroundRepeat = 'no-repeat';
          surface._el.style.backgroundSize = 'cover';
        }
      });
    } else if (eventName === 'onSnapShot') {
      this._handleOnSnapShot();
    }
  };

  // Handle on context menu option click
  _onActionClick = (e, action) => {
    // console.log('action :>> ', action);
    e.preventDefault();
    e.stopPropagation();
    // showToast("This is a dummy action!", "warning");
    this._hideNodeMenu();
    switch (action) {
      case 'Start':
        this._startVMNode();
        break;
      case 'Stop':
        this._stopVMNode();
        break;
      case 'Wipe':
        this._wipeVMNode();
        break;
      case 'Delete':
        const { deleteNodeConfirmationModal, showNodeMenu } = deepClone(this.state);
        deleteNodeConfirmationModal.isOpen = true;
        deleteNodeConfirmationModal.type = 'node';
        deleteNodeConfirmationModal.data = showNodeMenu.node.node.data;
        this.setState({ deleteNodeConfirmationModal });
        break;
      case 'Edit':
        this._openEditVNodeModal();
        break;
      case 'Modify':
        this._openModifyVirtualMachineModal();
        break;
      case 'Connect(vnc)':
        this._openGuacamoleModal('vnc');
        break;
      case 'Connect(telnet)':
        this._openGuacamoleModal('telnet');
        break;
      case 'Horizontal Align':
        this._alignSelectedNodes('h');
        break;
      case 'Vertical Align':
        this._alignSelectedNodes('v');
        break;
      case 'Path Emulation':
        // showToast('Under Development', 'warning');
        this._openPathEmulationModal();
        break;
      case 'Capture Packets':
        this.openWiresharkModal();
        break;
      case 'View Live Capture':
        this.viewWireshark();
        break;
      case 'Stop Capture':
        this.removeWireshark();
        break;
      case 'Terminal':
        this._openTerminal();
        break;
      default:
        break;
    }
  };

  openWiresharkModal = async () => {
    try {
      const res = await getPortsAndExistingEmulations(this.state.showNodeMenu.node.node.data.id);
      console.log('response ===> ', res);
      this.setState({ wiresharkModal: { isOpen: true, portList: res.portInterfaceMapping } });
    } catch (err) {
      console.log('Error => ', err);
    }
  };

  // Handle on action menu click and open create modals
  _handleOnMenuClick = async (key, position) => {
    // console.log("key :>> ", key);
    const {
      addVirtualNetworkModal,
      iconList,
      closeLabConfirmationModal,
      addVirtualMachineModal,
      addLabObjectModal,
      defaultIcons,
      changeCanvasBgModal,
      bgImageUrl,
      drawingListModal,
      chatGPTModal,
    } = deepClone(this.state);
    switch (key) {
      case 'vn':
        addVirtualNetworkModal.isOpen = true;
        if (defaultIcons.length) {
          defaultIcons[0].isFirstDefaultIcon = true;
        }
        if (iconList.length) {
          iconList[0].isFirstIcon = true;
        }
        addVirtualNetworkModal.data = {
          iconList: defaultIcons.concat(iconList),
          position: position,
        };
        this.setState({ addVirtualNetworkModal });
        break;
      case 'vm':
        addVirtualMachineModal.isOpen = true;
        if (defaultIcons.length) {
          defaultIcons[0].isFirstDefaultIcon = true;
        }
        if (iconList.length) {
          iconList[0].isFirstIcon = true;
        }
        this.props.showLoader();
        let response = await getAllvmTemplates({ baseBin: 'virsh' });
        this.setState({ vmTemplates: response.vmTemplates });
        addVirtualMachineModal.data = {
          vmTemplates: response.vmTemplates,
          iconList: defaultIcons.concat(iconList),
        };
        this.props.hideLoader();
        this.setState({ addVirtualMachineModal });
        break;
      case 'close':
        closeLabConfirmationModal.isOpen = true;
        closeLabConfirmationModal.data = {};
        this.setState({ closeLabConfirmationModal });
        break;
      case 'nodes':
        this._getLabNodesAndOpenModal();
        break;
      case 'networks':
        this._getLabNetworksAndOpenModal();
        break;
      case 'add-object':
        this.props.showLoader();
        let res = await getAllFilesList({});
        addLabObjectModal.isOpen = true;
        addLabObjectModal.data = { iconList: res.files };
        this.props.hideLoader();
        this.setState({ addLabObjectModal });
        break;
      case 'annotations':
        this._getLabObjectsAndOpenModal();
        break;
      case 'container':
        this._openAddDockerContainerModal();
        break;
      case 'canvas-bg':
        this.props.showLoader();
        let resp = await getAllFilesList({});
        changeCanvasBgModal.isOpen = true;
        changeCanvasBgModal.data = { iconList: resp.files, bgImageUrl };
        this.props.hideLoader();
        this.setState({ changeCanvasBgModal });
        break;
      case 'drawings':
        this.props.showLoader();
        let result = await getAllLabDrawings(this.props.match.params.id);
        // console.log('result :>> ', result);
        drawingListModal.isOpen = true;
        drawingListModal.data = result.labDrawings;
        this.setState({ drawingListModal });
        this.props.hideLoader();
        break;
      case 'chat':
        this.props.showLoader();
        // let result = await getAllLabDrawings(this.props.match.params.id);
        // console.log('result :>> ', result);
        chatGPTModal.isOpen = true;
        chatGPTModal.data = result.labDrawings;
        this.setState({ chatGPTModal });
        this.props.hideLoader();
        break;
      case 'lab-storage':
        this.setState((prev) => {
          prev.storageModal.isOpen = true;
          return prev;
        });
        let labFiles = await getLabFiles(this.props.match.params.id);
        this.setState((prev) => {
          prev.storageModal.data = labFiles.storage._storage;
          return prev;
        });
        break;
      default:
        break;
    }
    document.getElementsByTagName('body')[0].classList.add('sidebar-mini');
  };

  // Handle close and save events from modals
  _closeAddVirtualNetworkModal = (action, data) => {
    const { addVirtualNetworkModal } = deepClone(this.state);
    if (action === 'close') {
      addVirtualNetworkModal.isOpen = false;
      this.setState({ addVirtualNetworkModal });
    } else {
      // console.log("data :>> ", data);
      this._closeAddVirtualNetworkModal('close');
      this._addNodeOnTopologyEditor(data, 'vn', false);
    }
  };

  // To make API call to create New Virtual Network
  _createNewNetwork = async (data) => {
    const { activeJobs } = deepClone(this.state);
    try {
      let res = await createNewNetwork(data);
      // console.log("res :>> ", res);
      activeJobs.push(res.jobId);
      this.setState({ activeJobs });
      // showToast("Virtual Network is in queue", "success");
    } catch (error) {
      // console.log("error :>> ", error);
      showToast(error.reason, 'error');
    }
  };

  // To create a temp node on topology editor before getting response form server
  _addNodeOnTopologyEditor = (node, type, isDockerNode) => {
    // console.log('node :>> ', node);
    const dataToSave = [];
    node.portCount = type === 'vm' ? node.ethernets : 1;
    for (let i = 1; i <= Number(node.count); i++) {
      let tempData = deepClone(node);
      let tempName = deepClone(node.name) + '-' + i;
      tempData.name = node.count > 1 ? tempName : node.name;
      let nodeId = uuidv4();
      tempData.jobCode = nodeId;
      let nodeData = {
        id: nodeId,
        type: 'node',
        left: Number(node.position?.x),
        top: Number(node.position?.y),
        w: 100,
        h: 80,
        properties: {
          name: node.count > 1 ? tempName : node.name,
          description: node.description || '',
          networkType: type === 'vm' ? 'VNode' : node.networkType,
          icon: node.iconURL,
          status: 'queued',
          vmState: 'stopped',
          id: nodeId,
          isDockerNode: isDockerNode,
          ports: {},
        },
        ports: {
          port1: {
            id: 'port1',
            type: 'output',
            properties: {
              linkColor: '#6495ed',
              ethernetPortNo: 1,
              vmState: 'stopped',
              isUsed: false,
            },
          },
        },
      };
      for (let i = 0; i < node.portCount; i++) {
        nodeData.properties.ports['port' + (i + 1)] = {
          id: 'port' + (i + 1),
          type: 'output',
          properties: {
            name: node.dataFromTemplate?.ethernetNames?.[i] || `Port ${i + 1}`,
            linkColor: '#6495ed',
            ethernetPortNo: i + 1,
            vmState: 'stopped',
            isUsed: false,
          },
        };
      }
      dataToSave.push({
        dataToSend: tempData,
        dataToShow: nodeData,
      });
    }
    // console.log("chartData :>> ", dataToSave);
    dataToSave.forEach((eachNode) => {
      setTimeout(() => {
        this.state.toolkit.addNode(eachNode.dataToShow);
        if (type === 'vn') {
          this._createNewNetwork(eachNode.dataToSend);
        } else if (type === 'vm') {
          this._createNewMachine(eachNode.dataToSend);
        }
      }, 1000);
    });
  };

  // To update canvas position on lab components
  _updateNodePosition = async (nodeData) => {
    // console.log("node :>> ", nodeData);
    let promiseArray = [];
    nodeData.forEach((each) => {
      if (each.type === 'node') {
        let updateData = {
          type: 'node',
          uuid: each.properties.uuid,
          position: {
            x: each.left,
            y: each.top,
          },
        };
        if (updateData.uuid) {
          promiseArray.push(updateLabComponentPosition(updateData));
        }
      } else if (each.type === 'shape' || each.type === 'image') {
        each.shapeData.x = each.left;
        each.shapeData.y = each.top;
        promiseArray.push(editLabObject(each.id, { data: each.shapeData }));
      }
    });
    // console.log("updateData :>> ", promiseArray);
    try {
      await Promise.all(promiseArray);
      // console.log("res :>> ", res);
      showToast('Position updated successfully', 'success');
    } catch (error) {
      // console.log("error :>> ", error);
      showToast(error.reason, 'error');
    }
  };

  // To Handle close and save events from modals
  _closeCloseLabConfirmationModal = (action) => {
    const { closeLabConfirmationModal } = deepClone(this.state);
    if (action === 'cancel') {
      closeLabConfirmationModal.isOpen = false;
      closeLabConfirmationModal.data = {};
      this.setState({ closeLabConfirmationModal });
    } else {
      // close
      this.props.history.goBack();
    }
  };

  // To Handle close and save events from add vm modals
  _closeAddVirtualMachineModal = (action, data) => {
    const { addVirtualMachineModal } = deepClone(this.state);
    if (action === 'close') {
      addVirtualMachineModal.isOpen = false;
      this.setState({ addVirtualMachineModal });
    } else {
      // console.log("data :>> ", data);
      this.props.showLoader();
      this._addNodeOnTopologyEditor(data, 'vm', false);
      this._closeAddVirtualMachineModal('close');
    }
  };

  // API call to create new VM
  _createNewMachine = async (data) => {
    const { activeJobs, chartData } = deepClone(this.state);
    try {
      // const status = await axios.get('/ronin/license-status/');
      const status = await axios.get('/ronin/license-status/', {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          // Pragma: 'no-cache',
          Expires: '0',
        },
      });
    } catch (error) {
      this.props.hideLoader();
      toast.error(`Your license has expired. New virtual machines cannot be added.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    // if ('status.data.license_created' != null) {
    try {
      // if (valid) {
      // console.info('This is inside the valid', valid);
      let res = await createNewVirtualMachine(data);
      console.log('res :>> ', res);
      activeJobs.push(res.jobId);
      this.setState({ activeJobs });
      this.props.hideLoader();
      toast.success(`Virtual Machine is in queue`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // showToast('Virtual Machine is in queue', 'success');
      // }
      // else {
      //   this.props.hideLoader();
      //   toast.error(`License is invalid`, {
      //     position: toast.POSITION.TOP_RIGHT
      //   });
      // }
    } catch (error) {
      this.props.hideLoader();
      delete chartData.nodes[data.jobCode];
      this.setState({ chartData });
      toast.error(error.reason, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // }
    }
    // else {
    //   this.props.hideLoader();
    //   toast.error(`Your license has expired. New virtual machines cannot be added.`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    // }
  };

  _isDisableAction = (nodeType, vmState, action, nodeId) => {
    if (nodeType === 'VNode') {
      // for VM
      if (vmState === 'running' && (action === 'Modify' || action === 'Delete')) {
        return true;
      } else if (
        vmState !== 'running' &&
        (action === 'Connect(vnc)' || action === 'Connect(telnet)' || action === 'Path Emulation')
      ) {
        return true;
      } else {
        const hasConnection = this._checkNodeHasConnection(nodeId);
        return hasConnection && action === 'Modify'; // disable if true
      }
    } else {
      // network
      const hasConnection = this._checkNodeHasConnection(nodeId);
      console.log('hasConnection :>> ', hasConnection);
      return hasConnection && action === 'Delete'; // disable if true
    }
  };

  _checkNodeHasConnection = (nodeId) => {
    let latestTopologyData = this.state.toolkit.exportData();
    return latestTopologyData.edges.find((edge) => {
      return edge.source === nodeId || edge.target === nodeId;
    });
  };

  // To render right click contect menu
  _renderContectMenuOptions = () => {
    this.setGuacToken()
    const { showNodeMenu, selectedNodes } = deepClone(this.state);
    const nodeData = showNodeMenu.node.node.data;
    console.log('show node menu', showNodeMenu.node.node.data);
    const vmState = nodeData.properties.vmState;
    const type = nodeData.properties.networkType;
    const isDockerNode = nodeData.properties.isDockerNode;
    const guacamoleWebSocketTunnel = nodeData.properties?.guacamoleWebSocketTunnel;

    let contextActionMenuOptions =
      type === 'VNode' ? deepClone(config.vmContextActionMenuOptions) : deepClone(config.vnContextActionMenuOptions);
    if (type === 'VNode') {
      if (vmState === 'stopped') {
        contextActionMenuOptions.splice(1, 1); // to delete stop action
      } else if (vmState === 'running') {
        contextActionMenuOptions.shift(); // to delete start action
        if (!guacamoleWebSocketTunnel?.vnc) {
          let index = contextActionMenuOptions.findIndex((e) => e === 'Connect(vnc)');
          contextActionMenuOptions.splice(index, 1);
        }
        if (!guacamoleWebSocketTunnel?.telnet) {
          let index = contextActionMenuOptions.findIndex((e) => e === 'Connect(telnet)');
          contextActionMenuOptions.splice(index, 1);
        }
      }
      if (isDockerNode) {
        contextActionMenuOptions.splice(2, 1); // to delete modify action for docker
        contextActionMenuOptions.pop(); // to delete path emulation for docker
      }
      if (vmState === 'running')
        contextActionMenuOptions.push(
          this.state.nodeMap[nodeData.id]?.guacamoleWebSocketTunnel?.wireshark !== undefined
            ? 'Stop Capture'
            : 'Capture Packets'
        );
      if (vmState === 'running' && this.state.nodeMap[nodeData.id]?.guacamoleWebSocketTunnel?.wireshark)
        contextActionMenuOptions.push('View Live Capture');
      // if (vmState === "running") contextActionMenuOptions.push("Terminal")
    }
    // console.log('object :>> ', selectedNodes);
    if (selectedNodes.length) {
      contextActionMenuOptions = contextActionMenuOptions.concat(['Horizontal Align', 'Vertical Align']);
    }
    return contextActionMenuOptions.map((action) => (
      <li
        href='#'
        key={action}
        className={this._isDisableAction(type, vmState, action, nodeData.id) ? 'disable-action' : ''}
        onClick={(e) => this._onActionClick(e, action)}>
        {action === 'Path Emulation' ? (
          <i className='fa fa-circle'></i>
        ) : (
          // <img src={config.actionIcons[action]} className='action-menu-img' alt='pulse-icon'></img>
          <i className={config.actionIcons[action]}></i>
        )}
        <span className='ml-2'>{action}</span>
      </li>
    ));
  };

  // API call to start VM
  _startVMNode = async () => {
    const { showNodeMenu } = deepClone(this.state);
    const uuid = showNodeMenu.node.node.data.properties.uuid;
    showNodeMenu.node.node.data.properties.status = 'processing';
    // console.log('object :>> ', showNodeMenu.node.node.data, this.state.toolkit);
    this.state.toolkit.updateNode(showNodeMenu.node.node.data.id, {
      properties: showNodeMenu.node.node.data.properties,
    });
    try {
      this.props.showLoader();
      await startVMNode({
        jobCode: uuidv4(),
        uuid: uuid,
      });
      // console.log('res :>> ', res);
      this.props.hideLoader();
    } catch (error) {
      // console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  // API call to stop VM
  _stopVMNode = async () => {
    const { showNodeMenu } = deepClone(this.state);
    const uuid = showNodeMenu.node.node.data.properties.uuid;
    showNodeMenu.node.node.data.properties.status = 'processing';
    this.state.toolkit.updateNode(showNodeMenu.node.node.data.id, {
      properties: showNodeMenu.node.node.data.properties,
    });
    try {
      this.props.showLoader();
      await stopVMNode({
        jobCode: uuidv4(),
        uuid: uuid,
      });
      // console.log('res :>> ', res);
      this.props.hideLoader();
    } catch (error) {
      // console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  // API call to wipe VM // NOT Using
  _wipeVMNode = async () => {
    const { selectedNode, chartData } = deepClone(this.state);
    const uuid = chartData.nodes[selectedNode].properties.uuid;
    chartData.nodes[selectedNode].properties.status = 'processing';
    this.setState({ chartData });
    try {
      this.props.showLoader();
      let res = await wipeVMNode({
        jobCode: uuidv4(),
        uuid: uuid,
      });
      console.log('res :>> ', res);
      this.setState({ selectedNode: '' });
      this.props.hideLoader();
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  // To Handle close and save events from delete node confirmation modal
  _closeDeleteNodeConfirmationModal = (action) => {
    // console.log('action :>> ', action);
    const { deleteNodeConfirmationModal } = deepClone(this.state);
    if (action === 'cancel') {
      deleteNodeConfirmationModal.isOpen = false;
      deleteNodeConfirmationModal.data = null;
      deleteNodeConfirmationModal.type = '';
      this.setState({ deleteNodeConfirmationModal });
    } else if (action === 'hide') {
      deleteNodeConfirmationModal.isOpen = false;
      this.setState({ deleteNodeConfirmationModal });
    } else {
      if (deleteNodeConfirmationModal.type === 'node') {
        // make api call to delete node

        let latestTopologyData = this.state.toolkit.exportData();
        // console.log('latestTopologyData :>> ', latestTopologyData);
        let nodeId = deleteNodeConfirmationModal.data.properties.id;
        let connectedLinks = latestTopologyData.edges.filter((edge) => {
          return edge.source === nodeId || edge.target === nodeId;
        });
        let connectedRunningNodes = latestTopologyData.nodes.filter((e) => {
          return (
            e.id !== nodeId &&
            e.properties?.networkType === 'VNode' &&
            e.properties?.vmState === 'running' &&
            connectedLinks.find((edge) => edge.source === e.id || edge.target === e.id)
          );
        });
        // console.log('delete node :>> ', connectedLinks, connectedRunningNodes);
        if (!connectedLinks.length && !connectedRunningNodes.length) {
          // console.log('delete  sinsgle:>> ');
          if (deleteNodeConfirmationModal.data.properties.networkType === 'VNode') {
            // for vm
            this._deleteVmNode();
          } else {
            // for vn
            this._deleteVirtualNetwork();
          }
        } else {
          this.setState(
            {
              multipleDelete: {
                enable: true,
                data: {
                  nodeId,
                  connectedRunningNodes,
                  connectedLinks,
                },
              },
            },
            () => {
              this._closeDeleteNodeConfirmationModal('hide');
              this._proceedMultipleDelete();
            }
          );
        }
      } else {
        let fromNodeId = deleteNodeConfirmationModal.data?.linkData?.edge.data.connectionInfo.from.nodeId;
        let toNodeId = deleteNodeConfirmationModal.data?.linkData?.edge.data.connectionInfo.to.nodeId;
        let fromNode = deleteNodeConfirmationModal.data?.chartData?.nodes.find((e) => e.id === fromNodeId);
        let toNode = deleteNodeConfirmationModal.data?.chartData?.nodes.find((e) => e.id === toNodeId);
        // check one of the end nodes is VM and is running - delete not allowed
        if (
          (fromNode.properties.networkType === 'VNode' && fromNode.properties.vmState === 'running') ||
          (toNode.properties.networkType === 'VNode' && toNode.properties.vmState === 'running')
        ) {
          showToast('Connection can not be deleted on running state!', 'error');
          this._closeDeleteNodeConfirmationModal('cancel');
        } else {
          // make api call to delete connection
          // console.log('delete :>> ');
          this._deleteConnection();
        }
      }
    }
  };

  // handle delete device with connections
  _proceedMultipleDelete = async () => {
    const { multipleDelete, deleteNodeConfirmationModal } = deepClone(this.state);
    if (multipleDelete.enable && multipleDelete.data) {
      if (multipleDelete.data.connectedRunningNodes.length) {
        let stopNodesPromiseList = [];
        multipleDelete.data.connectedRunningNodes.forEach((each) => {
          let uuid = each.properties.uuid;
          each.properties.status = 'processing';
          this.state.toolkit.updateNode(each.id, { properties: each.properties });
          stopNodesPromiseList.push(
            stopVMNode({
              jobCode: uuidv4(),
              uuid: uuid,
            })
          );
        });
        await Promise.all(stopNodesPromiseList);
      } else if (!multipleDelete.data.connectedRunningNodes.length && multipleDelete.data.connectedLinks.length) {
        let deleteLinkPromiseList = [];
        multipleDelete.data.connectedLinks.forEach((each) => {
          const uuid = each.data.uuid;
          deleteLinkPromiseList.push(
            disconnectNodes({
              jobCode: uuidv4(),
              uuid: uuid,
            })
          );
        });
      } else {
        if (deleteNodeConfirmationModal.data.properties.networkType === 'VNode') {
          // for vm
          this._deleteVmNode();
        } else {
          // for vn
          this._deleteVirtualNetwork();
        }
      }
    }
  };

  // API call to delete VM
  _deleteVmNode = async () => {
    const { showNodeMenu } = deepClone(this.state);
    const uuid = showNodeMenu.node.node.data.properties.uuid;
    showNodeMenu.node.node.data.properties.status = 'processing';
    this.state.toolkit.updateNode(showNodeMenu.node.node.data.id, {
      properties: showNodeMenu.node.node.data.properties,
    });
    try {
      this.props.showLoader();
      await deleteVMNode({
        jobCode: uuidv4(),
        uuid: uuid,
      });
      // console.log('res :>> ', res);
      this._closeDeleteNodeConfirmationModal('cancel');
      this.props.hideLoader();
    } catch (error) {
      // console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  // API call to delete VN
  _deleteVirtualNetwork = async () => {
    const { showNodeMenu } = deepClone(this.state);
    const uuid = showNodeMenu.node.node.data.properties.uuid;
    showNodeMenu.node.node.data.properties.status = 'processing';
    this.state.toolkit.updateNode(showNodeMenu.node.node.data.id, {
      properties: showNodeMenu.node.node.data.properties,
    });
    try {
      this.props.showLoader();
      await deleteVirtualNetwork({
        jobCode: uuidv4(),
        uuid: uuid,
      });
      // console.log('res :>> ', res);
      this._closeDeleteNodeConfirmationModal('cancel');
      this.props.hideLoader();
    } catch (error) {
      // console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  _conntectNodes = async (chartData, data, portData, toolkit) => {
    // console.log('chartData :>> ', chartData, data, toolkit);
    let fromNode = chartData.nodes.find((e) => e.id === data.sourceId);
    let toNode = chartData.nodes.find((e) => e.id === data.targetId);
    // console.log('fromNode, :>> ', fromNode, toNode);
    if (data.sourceId === data.targetId) {
      showToast('Connection between same nodes are not allowed!', 'error');
      toolkit.removeEdge(data.edge);
      return;
    } else if (fromNode.properties.networkType !== 'VNode' && toNode.properties.networkType !== 'VNode') {
      showToast('Connection between 2 networks are not allowed!', 'error');
      toolkit.removeEdge(data.edge);
      return;
    } else if (
      (fromNode.properties.networkType === 'VNode' && fromNode.properties.vmState === 'running') ||
      (toNode.properties.networkType === 'VNode' && toNode.properties.vmState === 'running')
    ) {
      showToast('Connection can not be done on running state!', 'error');
      toolkit.removeEdge(data.edge);
      return;
    }
    // else if(
    //   fromNode.ports[data.fromPortId].properties.isUsed ||
    //   toNode.ports[data.toPortId].properties.isUsed
    //   ){
    //     // delete chartData.links[data.linkId];
    //     // this.setState({ chartData });
    //     return;
    // }
    else if ((fromNode.properties.isDockerNode || toNode.properties.isDockerNode) && !portData) {
      // console.log('Docker Connection :>> ');
      const { addDockerConnectionModal } = deepClone(this.state);
      addDockerConnectionModal.isOpen = true;
      addDockerConnectionModal.data = { chartData, linkData: data, toolkit };
      this.setState({ addDockerConnectionModal });
      return;
    } else if (!portData) {
      // console.log('Node Connection :>> ');
      this._openAddConnectionModal(chartData, data, toolkit);
      return;
    }
    // chartData.links[data.linkId].properties={
    //   status : 'loading'
    // }
    //  chartData.links[data.linkId].from.portId = portData.fromPort;
    // chartData.links[data.linkId].to.portId = portData.toPort;
    // this.setState({ chartData });
    let linkData = {
      jobCode: data.edge.data.id,
      labId: this.props.match.params.id,
      from: {
        uuid: fromNode.properties.uuid,
        ethernetPortNo: fromNode.properties.ports[portData.fromPort].properties.ethernetPortNo,
      },
      to: {
        uuid: toNode.properties.uuid,
        ethernetPortNo: toNode.properties.ports[portData.toPort].properties.ethernetPortNo,
      },
      position: {
        lineType: portData?.lineType || 'bezier',
        color: portData?.color || '#89bcde',
        strokeType: portData?.strokeType || 'solid',
      },
    };
    if (portData?.fromIpAddress) {
      linkData.from.ipAddress = portData?.fromIpAddress;
    }
    if (portData?.toIpAddress) {
      linkData.to.ipAddress = portData?.toIpAddress;
    }
    console.log('linkData :>> ', linkData);
    try {
      let res = await connectNodes(linkData);
      console.log('res :>> ', res);
    } catch (error) {
      console.log('error :>> ', error);
      showToast(error.reason, 'error');
    }
  };

  _openDeleteLinkConfirmationModal = (data) => {
    console.log('data :>> ', data);
    const { deleteNodeConfirmationModal } = deepClone(this.state);
    deleteNodeConfirmationModal.isOpen = true;
    deleteNodeConfirmationModal.type = 'link';
    deleteNodeConfirmationModal.data = data;
    this.setState({ deleteNodeConfirmationModal });
  };

  _deleteConnection = async () => {
    const { deleteNodeConfirmationModal } = deepClone(this.state);
    const uuid = deleteNodeConfirmationModal.data.linkData.edge.data.uuid;
    console.log('link uuid :>> ', deleteNodeConfirmationModal.data);
    deleteNodeConfirmationModal.data.linkData.connection.addOverlay([
      'Custom',
      {
        create: function (component) {
          var el = document.createElement('i');
          el.className = 'fa fa-spinner fa-spin';
          return el;
        },
        location: 0.5,
        id: 'loadingLabel',
      },
    ]);
    // chartData.links[deleteNodeConfirmationModal.data.linkId].properties.status = 'loading';
    // this.setState({ chartData });
    try {
      this.props.showLoader();
      let res = await disconnectNodes({
        jobCode: uuidv4(),
        uuid: uuid,
      });
      console.log('res :>> ', res);
      this._closeDeleteNodeConfirmationModal('hide');
      this._closeConnectionInfoModal('close');
      this.props.hideLoader();
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  _getLabNodesAndOpenModal = async () => {
    const { labNodeListModal } = deepClone(this.state);
    this.props.showLoader();
    try {
      let res = await getLabNodes({ onlyMachine: true }, this.props.match.params.id);
      console.log('res :>> ', res);
      labNodeListModal.isOpen = true;
      labNodeListModal.data = res.vNodes;
      this.setState({ labNodeListModal });
      this.props.hideLoader();
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason || 'Something went wrong!', 'error');
    }
  };

  _closeLabNodeListModal = () => {
    const { labNodeListModal } = deepClone(this.state);
    labNodeListModal.isOpen = false;
    labNodeListModal.data = null;
    this.setState({ labNodeListModal });
  };

  _getLabNetworksAndOpenModal = async () => {
    const { labNetworkListModal } = deepClone(this.state);
    this.props.showLoader();
    try {
      let res = await getLabNodes({ onlyNetwork: true }, this.props.match.params.id);
      console.log('res :>> ', res);
      labNetworkListModal.isOpen = true;
      labNetworkListModal.data = res.vNodes;
      this.setState({ labNetworkListModal });
      this.props.hideLoader();
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason || 'Something went wrong!', 'error');
    }
  };

  _closeLabNetworkListModal = () => {
    const { labNetworkListModal } = deepClone(this.state);
    labNetworkListModal.isOpen = false;
    labNetworkListModal.data = null;
    this.setState({ labNetworkListModal });
  };

  _updateTempLinkProperties = (chartData, data) => {
    // this.setState({ isLoading: true });
    chartData.nodes[data.fromNodeId].ports[data.fromPortId].properties.linkColor = '#ccc';
    console.log('chartData :>> ', chartData);
    this.setState({ chartData }, () => {
      // this.setState({ isLoading: false });
    });
  };

  _closeAddLabObjectModal = async (action, labObjectData) => {
    const { addLabObjectModal } = deepClone(this.state);
    if (action === 'cancel') {
      addLabObjectModal.isOpen = false;
      addLabObjectModal.data = null;
      addLabObjectModal.type = '';
      this.setState({ addLabObjectModal });
    } else {
      let dataToSave = {
        labId: labObjectData.labId,
        name: labObjectData.name,
        type: labObjectData.type,
        data: labObjectData.data,
      };
      console.log('dataToSave :>> ', dataToSave);
      try {
        let res = await addLabObject(dataToSave);
        console.log('res :>> ', res);
        this._closeAddLabObjectModal('cancel');
        setTimeout(() => {
          this.state.toolkit.addNode({
            id: res.labObject.id,
            type: res.labObject?.data.shape === 'image' ? 'image' : 'shape',
            shapeType: res.labObject?.data.shape === 'image' ? 'image' : res.labObject?.data.shape,
            left: res.labObject?.data ? Number(res.labObject?.data?.x || res.labObject?.data?.x1).toFixed(2) : 10,
            top: res.labObject?.data ? Number(res.labObject?.data?.y || res.labObject?.data?.y1).toFixed(2) : 10,
            w: this._getShapeDimentions(res.labObject.data).width,
            h: this._getShapeDimentions(res.labObject.data).height,
            shapeData: res.labObject?.data,
          });
          this.state.surface.zoomToFit();
        });
        showToast('Added successfully', 'success');
      } catch (error) {
        console.log('error :>> ', error);
        showToast(error.reason, 'error');
      }
    }
  };

  _getLabObjectsAndOpenModal = async () => {
    const { labObjectListModal } = deepClone(this.state);
    this.props.showLoader();
    try {
      let res = await getAllLabObjects(this.props.match.params.id);
      console.log('res :>> ', res);
      labObjectListModal.isOpen = true;
      labObjectListModal.data = res.labObjects;
      this.setState({ labObjectListModal });
      this.props.hideLoader();
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason || 'Something went wrong!', 'error');
    }
  };

  _closeLabObjectListModal = () => {
    const { labObjectListModal } = deepClone(this.state);
    labObjectListModal.isOpen = false;
    labObjectListModal.data = null;
    this.setState({ labObjectListModal });
  };

  _openDeleteLabObjectConfirmationModal = (data) => {
    const { deleteLabObjectConfirmationModal } = deepClone(this.state);
    deleteLabObjectConfirmationModal.isOpen = true;
    deleteLabObjectConfirmationModal.data = data;
    this.setState({ deleteLabObjectConfirmationModal });
  };

  _closeDeleteLabObjectConfirmationModal = (action) => {
    const { deleteLabObjectConfirmationModal } = deepClone(this.state);
    if (action === 'cancel') {
      deleteLabObjectConfirmationModal.isOpen = false;
      deleteLabObjectConfirmationModal.data = null;
      this.setState({ deleteLabObjectConfirmationModal });
    } else {
      this._deleteLabObject();
    }
  };

  _deleteLabObject = async () => {
    const { deleteLabObjectConfirmationModal } = deepClone(this.state);
    try {
      this.props.showLoader();
      let res = await deleteLabObject(deleteLabObjectConfirmationModal.data._id);
      console.log('res :>> ', res);
      this.state.toolkit.removeNode(deleteLabObjectConfirmationModal.data._id);
      this._closeDeleteLabObjectConfirmationModal('cancel');
      this._closeLabObjectListModal();
      this.props.hideLoader();
      showToast('Deleted successfully', 'success');
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  _openEditVNodeModal = () => {
    const { showNodeMenu, editVNodeModal, defaultIcons, iconList } = deepClone(this.state);
    editVNodeModal.isOpen = true;
    editVNodeModal.data = showNodeMenu.node.node.data;
    if (defaultIcons.length) {
      defaultIcons[0].isFirstDefaultIcon = true;
    }
    if (iconList.length) {
      iconList[0].isFirstIcon = true;
    }
    editVNodeModal.data.iconList = defaultIcons.concat(iconList);
    this.setState({ editVNodeModal });
  };

  _closeEditVNodeModal = async (action, data) => {
    const { editVNodeModal } = deepClone(this.state);
    if (action === 'cancel') {
      editVNodeModal.isOpen = false;
      editVNodeModal.data = null;
      this.setState({ editVNodeModal });
    } else {
      console.log('data :>> ', data);
      try {
        this.props.showLoader();
        let res = await editVNodeBasicInfo(editVNodeModal.data.id, data);
        console.log('res :>> ', res);
        editVNodeModal.data.properties.name = data.name;
        editVNodeModal.data.properties.description = data.description;
        editVNodeModal.data.properties.iconId = data.iconId;
        editVNodeModal.data.properties.icon = data.iconURL;
        this.state.toolkit.updateNode(editVNodeModal.data.id, {
          properties: editVNodeModal.data.properties,
        });
        this.props.hideLoader();
        showToast('Updated successfully', 'success');
        this._closeEditVNodeModal('cancel');
      } catch (error) {
        console.log('error :>> ', error);
        this.props.hideLoader();
        showToast(error.reason, 'error');
      }
    }
  };

  _openModifyVirtualMachineModal = async () => {
    const { showNodeMenu, modifyVirtualMachineModal } = deepClone(this.state);
    modifyVirtualMachineModal.isOpen = true;
    try {
      this.props.showLoader();
      let res = await getVNodeDetails(showNodeMenu.node.node.data.id);
      console.log('res :>> ', res);
      modifyVirtualMachineModal.data = {
        node: showNodeMenu.node.node.data.id,
        cpu: res.vNode.cpu,
        ram: res.vNode.ram,
        ethernets: res.vNode.ethernets,
      };
      this.setState({ modifyVirtualMachineModal });
      this.props.hideLoader();
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  _closeModifyVirtualMachineModal = async (action, data) => {
    const { modifyVirtualMachineModal, showNodeMenu } = deepClone(this.state);
    if (action === 'cancel') {
      modifyVirtualMachineModal.isOpen = false;
      modifyVirtualMachineModal.data = null;
      this.setState({ modifyVirtualMachineModal });
    } else {
      console.log('data :>> ', data);
      try {
        data.uuid = showNodeMenu.node.node.data.properties.uuid;
        data.jobCode = uuidv4();
        showNodeMenu.node.node.data.properties.status = 'updating';
        this.state.toolkit.updateNode(showNodeMenu.node.node.data.id, {
          properties: showNodeMenu.node.node.data.properties,
        });
        this.props.showLoader();
        let res = await modifyVMNode(data);
        console.log('res :>> ', res);
        this._closeModifyVirtualMachineModal('cancel');
        this.props.hideLoader();
      } catch (error) {
        console.log('error :>> ', error);
        this.props.hideLoader();
        showToast(error.reason, 'error');
      }
    }
  };

  _openGuacamoleModal = (type) => {
    const { showNodeMenu, openedGuacamoleModalList } = deepClone(this.state);
    if (showNodeMenu.node.node.data.properties.guacamoleWebSocketTunnel.vnc) {
      window.open(showNodeMenu.node.node.data.properties.guacamoleWebSocketTunnel.vnc, '_blank')
    } else if (showNodeMenu.node.node.data.properties.guacamoleWebSocketTunnel.telnet) {
      window.open(showNodeMenu.node.node.data.properties.guacamoleWebSocketTunnel.telnet, '_blank')
    }

    // TODO: This logic was previously. (for reference)
    // let index = openedGuacamoleModalList.findIndex((e) => e.data.id === showNodeMenu.node.node.data.id);
    // openedGuacamoleModalList.forEach((e) => {
    //   if (e.type === type) {
    //     const machine_url = e.data.properties.guacamoleWebSocketTunnel[type];
    //     window.open(machine_url, '_blank');
    //   }
    // });
    // if (index !== -1) {
    //   openedGuacamoleModalList[index].isOpen = true;
    //   openedGuacamoleModalList[index].isMinimised = false;
    //   openedGuacamoleModalList[index].isActive = true;
    // } else {
    //   let customModal = {
    //     isOpen: true,
    //     data: showNodeMenu.node.node.data,
    //     type: type,
    //     isMinimised: false,
    //     isActive: true,
    //   };
    //   openedGuacamoleModalList.push(customModal);
    // }
    // this.setState({ openedGuacamoleModalList });
  };

  _closeGuacamoleModal = (index) => {
    let { openedGuacamoleModalList } = deepClone(this.state);
    openedGuacamoleModalList.splice(index, 1);
    this.setState({ openedGuacamoleModalList });
  };

  _makeActiveModal = (index) => {
    let { openedGuacamoleModalList } = deepClone(this.state);
    openedGuacamoleModalList.forEach((e) => {
      e.isActive = false;
    });
    if (openedGuacamoleModalList[index]) {
      openedGuacamoleModalList[index].isActive = true;
    }
    this.setState({ openedGuacamoleModalList });
  };

  _onUpdateZoom = async (data) => {
    const { chartData } = deepClone(this.state);
    chartData.scale = data;
    this.setState({ chartData });
    try {
      let res = await editLab(this.props.match.params.id, { zoom: data });
      console.log('res :>> ', res);
    } catch (error) {
      console.log('error :>> ', error);
      showToast(error.reason, 'error');
    }
  };

  _openAddDockerContainerModal = async () => {
    const { addDockerContainerModal, defaultIcons, iconList } = deepClone(this.state);
    addDockerContainerModal.isOpen = true;
    if (defaultIcons.length) {
      defaultIcons[0].isFirstDefaultIcon = true;
    }
    if (iconList.length) {
      iconList[0].isFirstIcon = true;
    }
    this.props.showLoader();
    let response = await getAllvmTemplates({ baseBin: 'docker' });
    this.setState({ vmTemplates: response.vmTemplates });
    addDockerContainerModal.data = {
      vmTemplates: response.vmTemplates,
      iconList: defaultIcons.concat(iconList),
    };
    this.props.hideLoader();
    this.setState({ addDockerContainerModal });
  };

  _closeAddDockerContainerModal = (action, data) => {
    const { addDockerContainerModal } = deepClone(this.state);
    if (action === 'close') {
      addDockerContainerModal.isOpen = false;
      addDockerContainerModal.data = null;
      this.setState({ addDockerContainerModal });
    } else {
      console.log('data :>> ', data);
      this.props.showLoader();
      this._addNodeOnTopologyEditor(data, 'vm', true);
      this._closeAddDockerContainerModal('close');
    }
  };

  _closeAddDockerConnectionModal = (action, connectionData) => {
    const { addDockerConnectionModal } = deepClone(this.state);
    if (action === 'close') {
      addDockerConnectionModal.data.toolkit.removeEdge(addDockerConnectionModal.data.linkData.edge); // to remove temp connection
      addDockerConnectionModal.isOpen = false;
      addDockerConnectionModal.data = null;
      this.setState({ addDockerConnectionModal });
    } else {
      console.log('connectionData :>> ', connectionData);
      this._conntectNodes(
        addDockerConnectionModal.data.chartData,
        addDockerConnectionModal.data.linkData,
        connectionData
      );
      addDockerConnectionModal.isOpen = false;
      // addDockerConnectionModal.data = null;
      this.setState({ addDockerConnectionModal });
    }
  };

  _openAddConnectionModal = (chartData, linkData, toolkit) => {
    console.log('chartData, linkData :>> ', chartData, linkData);
    const { addConnectionModal } = deepClone(this.state);
    addConnectionModal.isOpen = true;
    addConnectionModal.data = { chartData, linkData, toolkit };
    this.setState({ addConnectionModal });
  };

  _closeAddConnectionModal = (action, portData) => {
    const { addConnectionModal } = deepClone(this.state);
    if (action === 'close') {
      addConnectionModal.data.toolkit.removeEdge(addConnectionModal.data.linkData.edge); // to remove temp connection
      addConnectionModal.isOpen = false;
      addConnectionModal.data = null;
      this.setState({ addConnectionModal });
    } else {
      console.log('portData :>> ', portData);
      this._conntectNodes(addConnectionModal.data.chartData, addConnectionModal.data.linkData, portData);
      addConnectionModal.isOpen = false;
      // addConnectionModal.data = null;
      this.setState({ addConnectionModal });
    }
  };

  _openConnectionInfoModal = (chartData, linkData, toolkit) => {
    const { connectionInfoModal } = deepClone(this.state);
    connectionInfoModal.isOpen = true;
    connectionInfoModal.data = { chartData, linkData, toolkit };
    this.setState({ connectionInfoModal });
  };

  _closeConnectionInfoModal = (action) => {
    if (action === 'close') {
      const { connectionInfoModal } = deepClone(this.state);
      connectionInfoModal.isOpen = false;
      connectionInfoModal.data = null;
      this.setState({ connectionInfoModal });
    }
  };

  // new event handlers
  _handleOnCanvasClick = () => {
    const { showCanvasMenu, canvasRightClick } = deepClone(this.state);
    this._hideNodeMenu();
    this._hideShapeMenu();
    if (canvasRightClick) {
      showCanvasMenu.visible = true;
      this.setState({ showCanvasMenu, canvasRightClick: false });
      document.getElementsByTagName('body')[0].className = 'no-scroll';
    } else {
      showCanvasMenu.visible = false;
      showCanvasMenu.x = 0;
      showCanvasMenu.y = 0;
      this.setState({ showCanvasMenu, canvasRightClick: false });
      document.getElementsByTagName('body')[0].className = '';
    }
  };

  _renderCanvasContextMenuOptions = () => {
    let actionMenuList = deepClone(config.canvasMenuActions);
    return actionMenuList.map((action) => (
      <li href='#' key={action.key} onClick={(e) => this._handleOnCanvasMenuClick(action.key)}>
        {!action.isImage ? (
          <i className={action.icon} />
        ) : (
          // <img src={action.icon} className='action-menu-img' alt='menu-icon'></img>
          <img src='/assets/img/docker-menu-icon.png' className='action-menu-img' alt='menu-icon'></img>
        )}
        <span className='ml-2'>{action.name}</span>
      </li>
    ));
  };

  _hideCanvasMenu = () => {
    const { showCanvasMenu } = deepClone(this.state);
    showCanvasMenu.visible = false;
    showCanvasMenu.x = 0;
    showCanvasMenu.y = 0;
    this.setState({ showCanvasMenu, canvasRightClick: false });
    document.getElementsByTagName('body')[0].className = '';
  };

  _handleOnCanvasMenuClick = (key) => {
    const { showCanvasMenu } = deepClone(this.state);
    this._handleOnMenuClick(key, { x: showCanvasMenu.x, y: showCanvasMenu.y });
    this._hideCanvasMenu();
  };

  _handleOnNodeRightClick = (selectedNodes, params) => {
    console.log('selectedNodes, params :>> ', selectedNodes, params);
    const { showNodeMenu } = this.state;
    showNodeMenu.visible = true;
    showNodeMenu.x = params.e.x;
    showNodeMenu.y = params.e.y;
    showNodeMenu.node = params;
    if (selectedNodes.length) {
      this.setState({ selectedNodes });
    }
    this._hideShapeMenu();
    this._hideCanvasMenu();
    this.setState({ nodeRightClick: true, showNodeMenu });
    document.getElementsByTagName('body')[0].className = 'no-scroll';
  };

  _hideNodeMenu = () => {
    const { showNodeMenu } = deepClone(this.state);
    showNodeMenu.visible = false;
    showNodeMenu.x = 0;
    showNodeMenu.y = 0;
    // showNodeMenu.node = null;
    this.setState({ showNodeMenu, nodeRightClick: false });
    document.getElementsByTagName('body')[0].className = '';
  };

  _handleOnNodeSelect = (toolkit, params) => {
    const { nodeRightClick, shapeRightclick } = this.state;
    if (nodeRightClick || shapeRightclick) {
      return;
    }
    toolkit.toggleSelection(params.node);
  };

  _handleOnShapeRightClick = (selectedNodes, params) => {
    console.log('selectedNodes, params :>> ', selectedNodes, params);
    const { showShapeMenu } = this.state;
    showShapeMenu.visible = true;
    showShapeMenu.x = params.e.x;
    showShapeMenu.y = params.e.y;
    showShapeMenu.node = params;
    if (selectedNodes.length) {
      this.setState({ selectedNodes });
    }
    this._hideCanvasMenu();
    this._hideNodeMenu();
    this.setState({ shapeRightclick: true, showShapeMenu });
    document.getElementsByTagName('body')[0].className = 'no-scroll';
  };

  _hideShapeMenu = () => {
    const { showShapeMenu } = deepClone(this.state);
    showShapeMenu.visible = false;
    showShapeMenu.x = 0;
    showShapeMenu.y = 0;
    // showShapeMenu.node = null;
    this.setState({ showShapeMenu, shapeRightclick: false });
    document.getElementsByTagName('body')[0].className = '';
  };

  _renderShapeContextMenuOptions = () => {
    const { selectedNodes } = deepClone(this.state);
    let actionMenuList = deepClone(config.shapeContextActionMenuOptions);
    if (!selectedNodes.length) {
      actionMenuList.splice(2, 2);
    }
    return actionMenuList.map((action) => (
      <li href='#' key={action} onClick={() => this._hanleOnShapeMenuClick(action)}>
        <i className={config.actionIcons[action]} />
        <span className='ml-2'>{action}</span>
      </li>
    ));
  };

  _hanleOnShapeMenuClick = async (action) => {
    const { showShapeMenu } = deepClone(this.state);
    switch (action) {
      case 'Edit':
        const { editLabObjectModal } = deepClone(this.state);
        let res = await getAllFilesList({});
        editLabObjectModal.isOpen = true;
        editLabObjectModal.data = showShapeMenu.node.node.data;
        editLabObjectModal.data.iconList = res.files;
        this.setState({ editLabObjectModal }, () => {
          this._hideShapeMenu();
        });
        break;
      case 'Delete':
        console.log('showShapeMenu :>> ', showShapeMenu);
        this._openDeleteLabObjectConfirmationModal({
          _id: showShapeMenu.node.node.data.id,
          data: {
            shape: showShapeMenu.node.node.data.shapeType,
          },
        });
        this._hideShapeMenu();
        break;
      case 'Horizontal Align':
        this._alignSelectedNodes('h');
        break;
      case 'Vertical Align':
        this._alignSelectedNodes('v');
        break;
      case 'Send To Back':
        showShapeMenu.node.node.data.shapeData.zIndex = 8;
        this.state.toolkit.updateNode(showShapeMenu.node.node.data.id, {
          shapeData: showShapeMenu.node.node.data.shapeData,
        });
        await editLabObject(showShapeMenu.node.node.data.id, { data: { ...showShapeMenu.node.node.data.shapeData } });
        this._hideShapeMenu();
        break;
      case 'Send To Front':
        showShapeMenu.node.node.data.shapeData.zIndex = 10;
        this.state.toolkit.updateNode(showShapeMenu.node.node.data.id, {
          shapeData: showShapeMenu.node.node.data.shapeData,
        });
        await editLabObject(showShapeMenu.node.node.data.id, { data: { ...showShapeMenu.node.node.data.shapeData } });
        this._hideShapeMenu();
        break;
      case 'Duplicate':
        console.log('showShapeMenu.node :>> ', showShapeMenu.node);
        let dataToSave = {
          labId: this.props.match.params.id,
          type: this._getLabObjectType(showShapeMenu.node.node.data.shapeData.shape),
          data: showShapeMenu.node.node.data.shapeData,
        };
        dataToSave.data.x = dataToSave.data.x + 20;
        dataToSave.data.y = dataToSave.data.y + 20;
        console.log('dataToSave :>> ', dataToSave);
        try {
          this.props.showLoader();
          let res = await addLabObject(dataToSave);
          console.log('res :>> ', res);
          setTimeout(() => {
            this.state.toolkit.addNode({
              id: res.labObject.id,
              type: res.labObject?.data.shape === 'image' ? 'image' : 'shape',
              shapeType: res.labObject?.data.shape === 'image' ? 'image' : res.labObject?.data.shape,
              left: res.labObject?.data ? Number(res.labObject?.data?.x || res.labObject?.data?.x1).toFixed(2) : 10,
              top: res.labObject?.data ? Number(res.labObject?.data?.y || res.labObject?.data?.y1).toFixed(2) : 10,
              w: this._getShapeDimentions(res.labObject.data).width,
              h: this._getShapeDimentions(res.labObject.data).height,
              shapeData: res.labObject?.data,
            });
            this.state.surface.zoomToFit();
          });
          this._hideShapeMenu();
          this.props.hideLoader();
          showToast('Added successfully', 'success');
        } catch (error) {
          console.log('error :>> ', error);
          this.props.hideLoader();
          showToast(error.reason, 'error');
        }
        break;
      default:
        this._hideShapeMenu();
        break;
    }
  };

  _getLabObjectType = (shapeType) => {
    switch (shapeType) {
      case 'image':
        return 'Picture';
      case 'rect':
      case 'circle':
        return 'Custom Shape';
      case 'text':
        return 'Text';
      case 'line':
        return 'Line';
      default:
        return;
    }
  };

  _alignSelectedNodes = (mode) => {
    const { selectedNodes, showNodeMenu, showShapeMenu } = deepClone(this.state);
    let lastClickedNode = showNodeMenu.visible ? showNodeMenu.node : showShapeMenu.node;
    console.log('lastClickedNode :>> ', lastClickedNode);
    let nodesToUpdate = [];
    selectedNodes.forEach((each) => {
      nodesToUpdate.push(each.data);
      let jtkNodeId = each.data.id;
      console.log('jtkNodeId :>> ', jtkNodeId);
      if (mode === 'v') {
        lastClickedNode.renderer.setPosition(jtkNodeId, lastClickedNode.node.data.left, each.data.top);
      } else if (mode === 'h') {
        lastClickedNode.renderer.setPosition(jtkNodeId, each.data.left, lastClickedNode.node.data.top);
      }
      lastClickedNode.renderer.repaint(each);
    });
    this._hideNodeMenu();
    this._hideShapeMenu();
    this._updateNodePosition(nodesToUpdate);
  };

  _openEditConnectionModal = (data) => {
    const { editConnectionModal } = deepClone(this.state);
    editConnectionModal.isOpen = true;
    editConnectionModal.data = data;
    this.setState({ editConnectionModal });
  };

  _closeEditConnectionModal = async (action, data) => {
    const { editConnectionModal } = deepClone(this.state);
    if (action === 'close') {
      editConnectionModal.isOpen = false;
      editConnectionModal.data = data;
      this.setState({ editConnectionModal });
    } else {
      console.log('data :>> ', data);
      try {
        let dataToUpdate = {
          type: 'connection',
          uuid: editConnectionModal.data.linkData.edge.data.uuid,
          position: data,
        };
        this.props.showLoader();
        let res = await updateLabComponentPosition(dataToUpdate);
        console.log('res :>> ', res, editConnectionModal.data.linkData.edge.data.id);
        editConnectionModal.data.toolkit.updateEdge(editConnectionModal.data.linkData.edge.data.id, {
          type: data.lineType,
          color: data.color,
          strokeType: data.strokeType === 'dashed' ? '4,4' : '0,0',
        });
        this.props.hideLoader();
        showToast('Connection updated successfully', 'success');
        this._closeEditConnectionModal('close');
      } catch (error) {
        console.log('error :>> ', error);
        this.props.hideLoader();
        showToast(error.reason, 'error');
      }
    }
  };

  _closeEditLabObjectModal = async (action, data) => {
    const { editLabObjectModal } = deepClone(this.state);
    if (action === 'cancel') {
      editLabObjectModal.isOpen = false;
      editLabObjectModal.data = null;
      this.setState({ editLabObjectModal });
    } else {
      console.log('data :>> ', data);
      try {
        this.props.showLoader();
        await editLabObject(data.id, { data });
        let dataToUpdate = {
          w: this._getShapeDimentions(data).width,
          h: this._getShapeDimentions(data).height,
          shapeData: data,
        };
        this.state.toolkit.updateNode(data.id, dataToUpdate);
        this._closeEditLabObjectModal('cancel');
        this.props.hideLoader();
        showToast('Object Updated successfully!', 'success');
      } catch (error) {
        console.log('error :>> ', error);
        showToast(error.reason, 'error');
        this.props.hideLoader();
      }
    }
  };

  _updateNodeState = async (action, nodeId) => {
    console.log('action, nodeId :>> ', action, nodeId);
    const latestTopologyData = this.state.toolkit.exportData();
    const nodeToUpdate = latestTopologyData.nodes.find((e) => e.id === nodeId);
    console.log('nodeToUpdate :>> ', nodeToUpdate);
    const uuid = nodeToUpdate.properties.uuid;
    nodeToUpdate.properties.status = 'processing';
    console.log('object :>> ', uuid);
    this.state.toolkit.updateNode(nodeToUpdate.id, { properties: nodeToUpdate.properties });
    try {
      this.props.showLoader();
      if (action === 'start') {
        let res = await startVMNode({
          jobCode: uuidv4(),
          uuid: uuid,
        });
        console.log('res :>> ', res);
      } else {
        let res = await stopVMNode({
          jobCode: uuidv4(),
          uuid: uuid,
        });
        console.log('res :>> ', res);
      }
      this.props.hideLoader();
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  _openPathEmulationModal = () => {
    const { pathEmulationModal, showNodeMenu } = deepClone(this.state);
    pathEmulationModal.isOpen = true;
    pathEmulationModal.data = showNodeMenu.node.node.data;
    this.setState({ pathEmulationModal });
  };

  _closePathEmulationModal = (action, data) => {
    const { pathEmulationModal } = deepClone(this.state);
    if (action === 'close') {
      pathEmulationModal.isOpen = false;
      pathEmulationModal.data = null;
      this.setState({ pathEmulationModal });
    } else {
      console.log('data :>> ', data);
    }
  };

  _closeChangeCanvasBgModal = async (action, data) => {
    const { changeCanvasBgModal } = deepClone(this.state);
    if (action === 'cancel') {
      changeCanvasBgModal.isOpen = false;
      changeCanvasBgModal.data = null;
      this.setState({ changeCanvasBgModal });
    } else if (action === 'remove') {
      try {
        this.props.showLoader();
        let res = await editLab(this.props.match.params.id, { bgImageId: null });
        console.log('res :>> ', res);
        this.state.surface._el.style.backgroundImage =
          'linear-gradient(90deg, rgba(255, 255, 255, 0.2) 1px, transparent 0px), linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 0px)';
        this.props.hideLoader();
        this._closeChangeCanvasBgModal('cancel');
      } catch (error) {
        console.log('error :>> ', error);
        this.props.hideLoader();
        showToast(error.reason, 'error');
      }
    } else if (action === 'save') {
      console.log('data :>> ', data);
      try {
        this.props.showLoader();
        let res = await editLab(this.props.match.params.id, { bgImageId: data.iconId });
        console.log('res :>> ', res);
        this.state.surface._el.style.backgroundImage = `url('${data.iconURL}')`;
        this.state.surface._el.style.backgroundRepeat = 'no-repeat';
        this.state.surface._el.style.backgroundSize = 'cover';
        this.setState({ bgImageUrl: data.iconURL });
        this.props.hideLoader();
        this._closeChangeCanvasBgModal('cancel');
      } catch (error) {
        console.log('error :>> ', error);
        this.props.hideLoader();
        showToast(error.reason, 'error');
      }
    }
  };

  _calculateContextMenuPosition = (data, type) => {
    switch (type) {
      case 'node':
      case 'shape':
        let height = document.getElementsByClassName('custom-context-menu')?.[0]?.offsetHeight || 253;
        let width = 180;
        return {
          top: data.y + height > window.innerHeight ? data.y - height : data.y,
          left: data.x + width > window.innerWidth ? data.x - width : data.x,
        };
      default:
        return {
          top: data.y,
          left: data.x,
        };
    }
  };

  _handleOnSnapShot = async () => {
    const { drawingModal } = deepClone(this.state);
    try {
      let audio = new Audio('../../../assets/audio/snapshot.mp3');
      audio.play();
      this.props.showLoader('Taking Screenshot');
      htmlToImage.toPng(document.getElementById('canvas')).then((dataUrl) => {
        console.log('dataUrl :>> ', dataUrl);
        drawingModal.isOpen = true;
        drawingModal.data = dataUrl;
        this.setState({ drawingModal });
        this.props.hideLoader();
      });
    } catch (error) {
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  _closeDrawingModal = async (action, data) => {
    const { drawingModal, labName } = deepClone(this.state);
    if (action === 'cancel') {
      drawingModal.isOpen = false;
      drawingModal.data = null;
      this.setState({ drawingModal });
    } else {
      console.log('data :>> ', data);
      try {
        this.props.showLoader();
        let block = data.dataUrl.split(';');
        let contentType = block[0].split(':')[1];
        let realData = block[1].split(',')[1];
        let blob = b64toBlob(realData, contentType);
        const assetData = new FormData();
        assetData.append('assetFile', blob, (data.name || labName) + '.png');
        assetData.append('name', data.name || labName);
        assetData.append('description', 'test');
        let res = await addDrawing(assetData);
        console.log('res :>> ', res);
        let response = await addDrawingToLab({
          name: data.name || labName,
          desc: 'test',
          drawingId: res.file.id,
          labId: this.props.match.params.id,
        });
        console.log('res :>> ', response);
        this._closeDrawingModal('cancel');
        this.props.hideLoader();
      } catch (error) {
        console.log('error :>> ', error);
        showToast(console.reason, 'error');
        this.props.hideLoader();
      }
    }
  };

  _closeDrawingListModal = () => {
    const { drawingListModal } = deepClone(this.state);
    drawingListModal.isOpen = false;
    drawingListModal.data = null;
    this.setState({ drawingListModal });
  };

  _closeChatGPTModal = () => {
    const { chatGPTModal } = deepClone(this.state);
    chatGPTModal.isOpen = false;
    chatGPTModal.data = null;
    this.setState({ chatGPTModal });
  };

  _closeStorageModal = () => {
    this.setState((prev) => {
      prev.storageModal.isOpen = false;
      return prev;
    });
  };
  _onFileSelectFromStorage = async (event) => {
    let formData = new FormData();
    formData.append('labFile', event.target.files[0]);
    addLabFiles(this.props.match.params.id, formData)
      .then((resp) => {
        const { storageModal } = deepClone(this.state);
        storageModal.data.push(resp.file);
        this.setState({ storageModal });
      })
      .catch((err) => console.log(err));
  };

  _onMinimize = (modalState) => {
    const lst = deepClone(this.state.minimizedModals);
    lst.push(modalState);
    this.setState({ minimizedModals: lst });
  };

  _reopenMinimizedModal = (item, index) => {
    let { openedGuacamoleModalList } = deepClone(this.state);
    openedGuacamoleModalList.forEach((each) => {
      each.isActive = false;
    });

    openedGuacamoleModalList.push({
      isOpen: true,
      data: item.data,
      isActive: true,
      type: 'vnc',
      ifStateAvailable: true,
      state: item,
    });
    this.setState({ openedGuacamoleModalList });
    let { minimizedModals } = deepClone(this.state);
    minimizedModals.splice(index, 1);
    this.setState({ minimizedModals });
  };

  _onStorageFileDelete = (index) => {
    const { storageModal } = deepClone(this.state);
    storageModal.data.splice(index, 1);
    this.setState({ storageModal: storageModal });
  };

  _onCloseVnc = (item, index) => {
    console.log(item);
    item.client && item.client.disconnect();
    const lst = deepClone(this.state.minimizedModals);
    lst.splice(index, 1);
    this.setState({ minimizedModals: lst });
  };

  render() {
    const {
      addVirtualNetworkModal,
      chartData,
      isLoading,
      closeLabConfirmationModal,
      addVirtualMachineModal,
      deleteNodeConfirmationModal,
      labNodeListModal,
      labNetworkListModal,
      labName,
      addLabObjectModal,
      labObjectListModal,
      deleteLabObjectConfirmationModal,
      editVNodeModal,
      modifyVirtualMachineModal,
      // guacamoleModal,
      addDockerContainerModal,
      addDockerConnectionModal,
      addConnectionModal,
      connectionInfoModal,
      showCanvasMenu,
      showNodeMenu,
      showShapeMenu,
      editConnectionModal,
      editLabObjectModal,
      pathEmulationModal,
      changeCanvasBgModal,
      drawingModal,
      drawingListModal,
      chatGPTModal,
    } = deepClone(this.state);

    return (
      <div className='wrapper'>
        <DesignLabSidebar
          {...this.props}
          bgColor={this.state.backgroundColor}
          actionMenu={config.actionMenu}
          toggleSidebar={this.toggleSidebar}
          onMenuAction={this._handleOnMenuClick}
          zoom={chartData.scale}
          onUpdateZoom={this._onUpdateZoom}></DesignLabSidebar>
        {/* <DialActionMenu
          {...this.props}
          bgColor={this.state.backgroundColor}
          actionMenu={config.actionMenu}
          toggleSidebar={this.toggleSidebar}
          onMenuAction={this._handleOnMenuClick}
          zoom={chartData.scale}
          onUpdateZoom={this._onUpdateZoom}
          addVirtualMachineModal={addVirtualMachineModal}
          _closeAddDockerContainerModal={this._closeAddDockerContainerModal}
        /> */}
        <div className='main-panel' ref='mainPanel' data={this.state.backgroundColor}>
          <AdminNavbar
            {...this.props}
            toggleSidebar={this.toggleSidebar}
            sidebarOpened={this.state.sidebarOpened}
            pageName={labName}
          />
          <div className='content designLabSideMenuClosedPadding'>
            {/* {!isLoading && (
              <TopologyEditor
                chartData={chartData}
                onUpdate={this._onFlowChartUpdate}
              ></TopologyEditor>
            )} */}
            {!isLoading && (
              <div className='jtk-demo-main' id='jtk-demo-flowchart'>
                <div className='jtk-demo-canvas' id='canvas'>
                  <TopologyEditor
                    chartData={chartData}
                    onUpdate={this._onFlowChartUpdate}
                    openWireshark={this.openWireshark}></TopologyEditor>
                </div>
              </div>
            )}
            {showNodeMenu.visible && (
              <div
                style={{
                  position: 'fixed',
                  top: this._calculateContextMenuPosition(showNodeMenu, 'node').top,
                  left: this._calculateContextMenuPosition(showNodeMenu, 'node').left,
                  background: '#fff',
                  height: 'auto',
                  border: '1px solid #ccc',
                  borderRadius: 4,
                  width: 180,
                }}>
                <ul className='custom-context-menu'>{this._renderContectMenuOptions()}</ul>
              </div>
            )}
            {showShapeMenu.visible && (
              <div
                id='right-click-menu'
                style={{
                  position: 'fixed',
                  top: this._calculateContextMenuPosition(showShapeMenu, 'shape').top,
                  left: this._calculateContextMenuPosition(showShapeMenu, 'shape').left,
                  background: '#fff',
                  height: 'auto',
                  border: '1px solid #ccc',
                  borderRadius: 4,
                  width: 180,
                }}>
                <ul className='custom-context-menu'>{this._renderShapeContextMenuOptions()}</ul>
              </div>
            )}
            {showCanvasMenu.visible && (
              <div
                id='right-click-menu'
                style={{
                  position: 'fixed',
                  top: showCanvasMenu.y + 181 > window.innerHeight ? showCanvasMenu.y - 181 : showCanvasMenu.y,
                  left: showCanvasMenu.x + 190 > window.innerWidth ? showCanvasMenu.x - 190 : showCanvasMenu.x,
                  background: '#fff',
                  height: 'auto',
                  border: '1px solid #ccc',
                  borderRadius: 4,
                  width: 190,
                }}>
                <ul className='custom-context-menu'>{this._renderCanvasContextMenuOptions()}</ul>
              </div>
            )}
            <VncList
              list={this.state.minimizedModals}
              callback={this._reopenMinimizedModal}
              onClose={this._onCloseVnc}
            />
          </div>
          {/* <MyComp /> */}
          {/* <ChatGPTModal /> */}
          <AddVirtualNetworkModal
            {...this.props}
            isOpen={addVirtualNetworkModal.isOpen}
            data={addVirtualNetworkModal.data}
            toggle={this._closeAddVirtualNetworkModal}></AddVirtualNetworkModal>
          <AddVirtualMachineModal
            {...this.props}
            isOpen={addVirtualMachineModal.isOpen}
            data={addVirtualMachineModal.data}
            toggle={this._closeAddVirtualMachineModal}></AddVirtualMachineModal>
          <CloseLabConfirmationModal
            {...this.props}
            isOpen={closeLabConfirmationModal.isOpen}
            data={closeLabConfirmationModal.data}
            toggle={this._closeCloseLabConfirmationModal}></CloseLabConfirmationModal>
          <DeleteNodeConfirmationModal
            {...this.props}
            isOpen={deleteNodeConfirmationModal.isOpen}
            data={deleteNodeConfirmationModal.data}
            type={deleteNodeConfirmationModal.type}
            toggle={this._closeDeleteNodeConfirmationModal}></DeleteNodeConfirmationModal>
          <LabNodeListModal
            {...this.props}
            isOpen={labNodeListModal.isOpen}
            data={labNodeListModal.data}
            toggle={this._closeLabNodeListModal}
            toogleNodeState={this._updateNodeState}></LabNodeListModal>
          <LabNetworkListModal
            {...this.props}
            isOpen={labNetworkListModal.isOpen}
            data={labNetworkListModal.data}
            toggle={this._closeLabNetworkListModal}></LabNetworkListModal>
          <AddLabObjectModal
            {...this.props}
            isOpen={addLabObjectModal.isOpen}
            data={addLabObjectModal.data}
            toggle={this._closeAddLabObjectModal}></AddLabObjectModal>
          <LabObjectListModal
            {...this.props}
            isOpen={labObjectListModal.isOpen}
            data={labObjectListModal.data}
            toggle={this._closeLabObjectListModal}
            openDeleteModal={this._openDeleteLabObjectConfirmationModal}
            closeDeleteModal={this._closeDeleteLabObjectConfirmationModal}></LabObjectListModal>
          <DeleteLabObjectConfirmationModal
            {...this.props}
            isOpen={deleteLabObjectConfirmationModal.isOpen}
            data={deleteLabObjectConfirmationModal.data}
            toggle={this._closeDeleteLabObjectConfirmationModal}></DeleteLabObjectConfirmationModal>
          <EditVNodeModal
            {...this.props}
            isOpen={editVNodeModal.isOpen}
            data={editVNodeModal.data}
            toggle={this._closeEditVNodeModal}></EditVNodeModal>
          <ModifyVirtualMachineModal
            {...this.props}
            isOpen={modifyVirtualMachineModal.isOpen}
            data={modifyVirtualMachineModal.data}
            toggle={this._closeModifyVirtualMachineModal}></ModifyVirtualMachineModal>
          {React.Children.toArray(
            this.state.openedGuacamoleModalList.map((each, index) =>
              each.isOpen ? (
                <GuacamoleModal
                  guac_url_telnet={each.data.properties.guacamoleWebSocketTunnel.telnet}
                  guac_url_vnc={each.data.properties.guacamoleWebSocketTunnel.vnc}
                  key={each.data.id ? each.data.id : index}
                  {...this.props}
                  isOpen={each.isOpen}
                  isActive={each.isActive}
                  data={each.data}
                  onMinimize={this._onMinimize}
                  type={each.type}
                  toggle={() => this._closeGuacamoleModal(index)}
                  minimizeModal={() => this._onMinimizeModal(index)}
                  makeActive={() => this._makeActiveModal(index)}
                  ifStateAvailable={each.ifStateAvailable}
                  state={each.state}></GuacamoleModal>
              ) : null
            )
          )}
          <AddDockerContainerModal
            {...this.props}
            isOpen={addDockerContainerModal.isOpen}
            data={addDockerContainerModal.data}
            toggle={this._closeAddDockerContainerModal}></AddDockerContainerModal>
          <AddDockerConnectionModal
            {...this.props}
            isOpen={addDockerConnectionModal.isOpen}
            data={addDockerConnectionModal.data}
            toggle={this._closeAddDockerConnectionModal}></AddDockerConnectionModal>
          <AddConnectionModal
            {...this.props}
            isOpen={addConnectionModal.isOpen}
            data={addConnectionModal.data}
            toggle={this._closeAddConnectionModal}></AddConnectionModal>
          <ConnectionInfoModal
            {...this.props}
            isOpen={connectionInfoModal.isOpen}
            data={connectionInfoModal.data}
            toggle={this._closeConnectionInfoModal}
            openEditModal={this._openEditConnectionModal}
            onDeleteConnection={this._openDeleteLinkConfirmationModal}></ConnectionInfoModal>
          <EditConnectionModal
            {...this.props}
            isOpen={editConnectionModal.isOpen}
            data={editConnectionModal.data}
            toggle={this._closeEditConnectionModal}></EditConnectionModal>
          <EditLabObjectModal
            {...this.props}
            isOpen={editLabObjectModal.isOpen}
            data={editLabObjectModal.data}
            toggle={this._closeEditLabObjectModal}></EditLabObjectModal>
          <PathEmulationModal
            {...this.props}
            isOpen={pathEmulationModal.isOpen}
            data={pathEmulationModal.data}
            toggle={this._closePathEmulationModal}></PathEmulationModal>
          <ChangeCanvasBgModal
            {...this.props}
            isOpen={changeCanvasBgModal.isOpen}
            data={changeCanvasBgModal.data}
            toggle={this._closeChangeCanvasBgModal}></ChangeCanvasBgModal>
          <DrawingModal
            {...this.props}
            isOpen={drawingModal.isOpen}
            data={drawingModal.data}
            toggle={this._closeDrawingModal}></DrawingModal>
          <DrawingListModal
            isOpen={drawingListModal.isOpen}
            data={drawingListModal.data}
            toggle={this._closeDrawingListModal}></DrawingListModal>
          <StorageModal
            isOpen={this.state.storageModal.isOpen}
            toggle={this._closeStorageModal}
            data={this.state.storageModal.data}
            id={this.props.match.params.id}
            onFileChange={this._onFileSelectFromStorage}
            onDelete={this._onStorageFileDelete}></StorageModal>
          <WiresharkModal
            isOpen={this.state.wiresharkModal.isOpen}
            portList={this.state.wiresharkModal.portList}
            toggle={() => this.setState({ wiresharkModal: { isOpen: false } })}
            onSubmit={this.createWireshark}></WiresharkModal>
          <TerminalModal
            open={this.state.terminal.open}
            toggle={() => this.setState({ terminal: { open: false } })}></TerminalModal>
          {/* <VncList /> */}
        </div>
        <Footer fluid />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(socketConnect(DesignLabLayout));
