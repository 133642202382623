import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import { deepClone } from "../../../../helper-methods";
import config from "../../../../config";

class EditConnectionModal extends Component {
  state = {
    vConnection: {
      lineType: "line",
      color: "#89bcde", // blue
      strokeType: "solid"
    },
  };

  _handleOnChange = (key, value)=>{
    const { vConnection } = deepClone(this.state);
    vConnection[key] = value;
    this.setState({ vConnection });
  }

  _handleOnSubmit = (e)=>{
    e.preventDefault();
    const { vConnection } = deepClone(this.state);    
    console.log('vConnection :>> ', vConnection);
    this.props.toggle('save', vConnection);
  }

  componentDidUpdate(prevProps){
    if(prevProps.isOpen!==this.props.isOpen){
      console.log('this.props.data.linkData :>> ', this.props.data?.linkData.edge.data.type);
      if(this.props.data?.linkData){
        let newState = {
          vConnection: {
            lineType: this.props.data?.linkData.edge.data.type || "line",
            color: this.props.data?.linkData.edge.data.color || "#89bcde", // blue
            strokeType: this.props.data?.linkData.edge.data.strokeType && this.props.data?.linkData.edge.data.strokeType === '4,4' ? 'dashed' : 'solid'
          },
        }
        this.setState(newState);
      }
    }
  }

  render() {
    const { vConnection } = deepClone(this.state);
    const { data } = deepClone(this.props);
    console.log('data :>> ', data);
    let fromNodeId = '';
    let toNodeId = '';
    let fromPortId = '';
    let toPortId = '';
    let fromNode = {};
    let toNode = {};
    if(data){
      fromNodeId = data?.linkData?.edge.data.connectionInfo.from.nodeId;
      toNodeId =  data?.linkData?.edge.data.connectionInfo.to.nodeId;
      fromNode = data?.chartData?.nodes.find((e)=>e.id===fromNodeId);
      toNode = data?.chartData?.nodes.find((e)=>e.id===toNodeId);
      // ports
      fromPortId = data?.linkData?.edge.data.connectionInfo.from.portId
      toPortId =  data?.linkData?.edge.data.connectionInfo.to.portId
    }
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle("close")}
      >
        <ModalHeader toggle={() => this.props.toggle("close")}>
          Edit Connection
        </ModalHeader>
        <ModalBody>
        {data?.chartData && <div className="connection-info-wrap">
            <div className="info">
              <img src={fromNode?.properties?.icon} alt="from-icon" width="50"/>
              <div>{fromNode?.properties?.name}</div>
              <div>({fromNode?.properties.ports?.[fromPortId]?.properties.name})</div>
              { fromNode?.properties?.isDockerNode &&
                <img src={require('../../../../assets/img/docker.png').default} alt="docker-img" style={{
                  right: 30,
                  top: -10,
                  position: 'absolute',
                  width: 22
                }}/>
              }
            </div>
            <div className="info">
              <i className="fa fa-arrows-h"></i>
            </div>
            <div className="info">
              <img src={toNode?.properties?.icon} alt="from-icon" width="50"/>
                <div>{toNode?.properties?.name}</div>
                <div>({toNode?.properties.ports?.[toPortId]?.properties.name})</div>
                { toNode?.properties?.isDockerNode &&
                <img src={require('../../../../assets/img/docker.png').default} alt="docker-img" style={{
                  right: 30,
                  top: -10,
                  position: 'absolute',
                  width: 22
                }}/>
              }
              </div>
          </div>}
          <div>
            <FormGroup>
              <Label>Link Type</Label>
              <Input
                type="select"
                placeholder="Select line type"
                value={vConnection.lineType}
                onChange={(e) =>
                  this._handleOnChange("lineType", e.target.value)
                }
              >
                {React.Children.toArray(
                  config.connectionLineTypes.map((each) => <option value={each.value}>{each.label}</option>)
                )}
              </Input>
              {/* {errors && <div className="validation-error">{errors.name}</div>} */}
            </FormGroup>
            <FormGroup>
              <Label>
                Link Color
                <div className="color-preview" style={{backgroundColor: vConnection.color || '#222'}}></div>
              </Label>
              <Input
                type="select"
                placeholder="Select line color"
                value={vConnection.color}
                onChange={(e) =>
                  this._handleOnChange("color", e.target.value)
                }
              >
                
                {React.Children.toArray(
                  config.connectionColors.map((each) => <option value={each.value}>{each.label}</option>)
                )}
              </Input>
              {/* {errors && <div className="validation-error">{errors.name}</div>} */}
            </FormGroup>
            <FormGroup>
              <Label>
                Link Stroke Type                
              </Label>
              <Input
                type="select"
                placeholder="Select stroke type"
                value={vConnection.strokeType}
                onChange={(e) =>
                  this._handleOnChange("strokeType", e.target.value)
                }
              >
                
                <option value="solid">Solid</option>
                <option value="dashed">Dashed</option>
              </Input>
              {/* {errors && <div className="validation-error">{errors.name}</div>} */}
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="modalBtnCancel"
            onClick={() => this.props.toggle("close")}
          >
            Close
          </Button>
          <Button
            className="modalBtnSave"
            onClick={(e) => this._handleOnSubmit(e)}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EditConnectionModal;
