import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  makeDeleteRequest,
  uploadFileMultiPart,
  makeGetFileRequest
} from './http-service';
import config from '../config/index';

// const baseUrl = (' ' + config.baseUrl).slice(1);

const makeUrl = (data) => {
  // return `http://localhost:7000/api/v1/${data}`
  return `${config.baseUrl}${data}`;
  // return `${baseUrl}${data}`
};

const makeMainServerUrl = (data) => {
  return `${config.mainServerUrl}${data}`;
};

export const login = ({ handle, password }) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('login');
    console.log('login ===> ', url);
    makePostRequest(url, false, {
      handle,
      password
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const forgotPassword = ({ handle }) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('forgotpassword');
    makePostRequest(url, false, {
      handle
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const signUp = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('signup');
    makePostRequest(url, false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const userList = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('users');
    makeGetRequest(url, true, { ...data })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const userDetails = (id) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl(`user/${id}`);
    makeGetRequest(url, true, null)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const userUpdate = (data, id) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl(`user/${id}`);
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const userDelete = (id) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl(`user/${id}`);
    makeDeleteRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const userInsert = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('user');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const profileDetails = () => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('me');
    makeGetRequest(url, true, null)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const profileUpdate = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('me');
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const licenseList = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('licenses');
    makeGetRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const addLicense = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('license');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const addLicenseUser = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl(`user/addlicense`);
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const addLicenseProfile = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl(`me/addlicense`);
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const sendSupportRequest = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('supportRequest');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const labsList = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('labs');
    makeGetRequest(url, true, { ...data })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const addLab = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('lab');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const deleteLab = (id) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl(`lab/${id}`);
    makeDeleteRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const fileList = (data) => {
  console.log('data :>> ', data);
  return new Promise((resolve, reject) => {
    let url = makeUrl('files');
    makeGetRequest(url, true, {
      ...data,
      category: 'asset'
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getAllFilesList = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('filesAll');
    makeGetRequest(url, true, {
      ...data,
      category: 'asset'
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const addAsset = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('file');
    uploadFileMultiPart(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getLabDetails = (id) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('lab/' + id);
    makeGetRequest(url, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const createNewNetwork = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('labActions/createNetworkNode');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const updateNetwork = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('labActions/modifyNetworkNode');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const updateLabComponentPosition = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('labcomponent/position');
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getAllvmTemplates = (data, flag) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('vmTemplates');
    makeGetRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const createNewVirtualMachine = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl('labActions/createVMNode');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const startVMNode = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labActions/startVMNode');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const stopVMNode = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labActions/stopVMNode');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const wipeVMNode = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labActions/wipeVMNode');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const deleteVMNode = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labActions/deleteVMNode');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const deleteVirtualNetwork = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labActions/deleteNetworkNode');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const connectNodes = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labActions/connectNodes');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const disconnectNodes = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labActions/disconnectNodes');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getLabNodes = (data, labid) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('vNodes/' + labid);
    makeGetRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getAllLabObjects = (labId) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labObjects');
    makeGetRequest(url, true, { labId })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const addLabObject = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labObject');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const editLabObject = (id, data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labObject/' + id);
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const exportLab = (labId, data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl(`lab/${labId}/export`);
    makeGetFileRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const importLab = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl(`lab/import`);
    uploadFileMultiPart(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const editLab = (id, data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('lab/' + id);
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const editAsset = (id, data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('file/' + id);
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const deleteLabObject = (id) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labObject/' + id);
    makeDeleteRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getDefaultIcons = () => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('defaultIcons');
    makeGetRequest(url, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const editVNodeBasicInfo = (id, data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('vNode/' + id);
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getVNodeDetails = (id) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('vNode/' + id);
    makeGetRequest(url, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const modifyVMNode = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labActions/modifyVMNode');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getSystemStats = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('dashboard/systemstats');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getResourceCount = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('dashboard/resourceCount');
    makeGetRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getResourceList = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('dashboard/vNodeResourceList');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const stopSinglevNode = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('vNode/stop');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const teamList = () => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('teams');
    makeGetRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const createTeam = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('team');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const editTeam = (id, data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('team/' + id);
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const Teamdelete = (id) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('team/' + id);
    makeDeleteRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const addMember = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('team/addmembers');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};
export const getTeamDetails = (id) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('team/' + id);
    makeGetRequest(url, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};
export const memberDelete = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('team/removemember');
    makeDeleteRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};
export const assignTeamToLab = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('assignteamtolab');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const unassignTeam = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('unassignteamfromlab');
    makeDeleteRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getUsersList = () => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('fullusers');
    makeGetRequest(url, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const updatePassword = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('me');
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const updatePasswordforUser = (id, data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('user/' + id);
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getPortsAndExistingEmulations = (id) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('portInterfaceMapping/' + id);
    makeGetRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const addPathEmulation = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('/pathEmulation/add');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const removePathEmulation = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('/pathEmulation/remove');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getEmulationProfiles = () => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('/pathEmulationProfiles');
    makeGetRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const createEmulationProfiles = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('/pathEmulationProfile');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const deleteEmulationProfiles = (id) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('/pathEmulationProfile/' + id);
    makeDeleteRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const deleteFile = (id) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('/file/' + id);
    makeDeleteRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const sendOTP = () => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('otp/send');
    makePostRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const verifyOTP = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('otp/verify');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const createVmImageReference = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('/file/vmImageReference');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const createPathEmulationProfile = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('/pathEmulationProfile');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getAllImagesList = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('filesAll');
    makeGetRequest(url, true, {
      ...data,
      category: 'vmimg'
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const createVmTemplate = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('vmTemplate');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const addDrawing = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('drawingfile');
    uploadFileMultiPart(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const addDrawingToLab = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labDrawing');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getAllLabDrawings = (labId) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('labDrawings/' + labId);
    makeGetRequest(url, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const addLabFiles = (labId, data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('/lab/storage/' + labId);
    uploadFileMultiPart(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getLabFiles = (labId) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('lab/storage/' + labId);
    makeGetRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getTemplate = (id) => {
  // http://65.220.37.101:8051/api/v1/vmTemplate/642c7e69b1f536001e8affdf
  console.error('this is the id', id)
  return new Promise((resolve, reject) => {
    const url = makeMainServerUrl('vmTemplate/' + id);
    makeGetRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllTemplates = () => {
  return new Promise((resolve, reject) => {
    const url = makeMainServerUrl('vmTemplates');
    makeGetRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const addTemplate = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('library/vmtemplate');
    uploadFileMultiPart(url, true, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const installTemplates = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('vmTemplate/install');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const editTemplate = (id, data) => {
  return new Promise((resolve, reject) => {
    // vmTemplate/642c67dcb1f536001e8affbd
    const url = makeUrl('vmTemplate/' + id);
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const deleteTemplate = (id) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('vmTemplate/' + id);
    makeDeleteRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteStorageFile = (id) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('lab/storage/' + id);
    makeDeleteRequest(url, true)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadStorageFile = (id) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('lab/download/' + id);
    makeGetFileRequest(url, true)
      .then((res) => {
        console.log('resp ===> ', res);
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};
export const addSerialKey = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('serialkey');
    makePostRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

export const getUpdate = () => {
  return new Promise((resolve, reject) => {
    const url = makeMainServerUrl('lastupdate');
    makeGetRequest(url, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const getSerialKey = () => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('serialkey');
    makeGetRequest(url, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const getUpdatedData = () => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('update');
    makeGetRequest(url, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const postUpdate = (data) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('update');
    makePostRequest(url, true, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const postWireshark = (labId, containerId, port, bridge) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('wireshark');
    makePostRequest(url, true, { labId, containerId, port, bridge })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const removeWiresharkDocker = (labId, containerId) => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('wireshark/remove');
    makePostRequest(url, true, { labId, containerId })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const addMfa = (data) => {
  return new Promise((resolve, reject) => {
    let url = makeUrl(`mfa`);
    makePutRequest(url, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log('API call error: ', e);
        reject(e);
      });
  });
};

export const getMfa = () => {
  return new Promise((resolve, reject) => {
    const url = makeUrl('mfa');
    makeGetRequest(url, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};
