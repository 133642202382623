import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Nav, NavItem, NavLink } from 'reactstrap';
// import { teamList, Teamdelete } from "../../../http/http-calls";
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
// import { ToastsStore } from "react-toasts";
// import AddTeamModal from "../components/add-team-modal";
// import moment from "moment";
// import { Paginate } from "./Pagination";
// import AddMemberModal from "../components/add-member-modal";
// import Switch from "react-switch";
// import config from "../../../config";
// import { template } from "lodash";
import SystemLogs from '../components/systemcontrol/system-logs';
import VmImage from '../components/systemcontrol/vmimage';
import TemplateCreation from '../components/systemcontrol/templatecreation';
import PathEmulation from '../components/systemcontrol/pathemulation';
import TemplateLibrary from '../components/systemcontrol/template-library';

class SystemControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  render() {
    console.log(this.state);
    // const { errors } = this.state;
    return (
      <div className='content'>
        <Row>
          <Col xs='12'>
            <Card>
              <CardHeader className='tableFilterWrap'>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      active={this.state.activeIndex === 0}
                      onClick={() => {
                        this.setState({ activeIndex: 0 });
                      }}>
                      VM Image Reference
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeIndex === 1}
                      onClick={() => {
                        this.setState({ activeIndex: 1 });
                      }}>
                      Template Creation
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeIndex === 2}
                      onClick={() => {
                        this.setState({ activeIndex: 2 });
                      }}>
                      Path Emulation Profile
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink active={this.state.activeIndex === 3} onClick={() => this.setState({ activeIndex: 3 })}>
                      Template Library
                    </NavLink>
                  </NavItem> */}
                  {/* <NavItem>
                    <NavLink active={this.state.activeIndex === 4} onClick={() => this.setState({ activeIndex: 4 })}>
                      System Logs
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </CardHeader>
              <CardBody>
                {this.state.activeIndex === 0 ? <VmImage /> : null}
                {this.state.activeIndex === 1 ? <TemplateCreation /> : null}
                {this.state.activeIndex === 2 ? <PathEmulation /> : null}
                {/* {this.state.activeIndex === 3 && <TemplateLibrary />} */}
                {/* {this.state.activeIndex === 4 && <SystemLogs />} */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemControl);
