import React, { Component } from 'react';
import { Bars } from 'react-loader-spinner';
import { connect } from 'react-redux';

class FullPageLoader extends Component {
  state = {};
  render() {
    const { loaderData } = this.props;
    if (loaderData && loaderData.isVisible) {
      return (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            zIndex: 9999999,
            opacity: 0.95,
            backgroundColor: 'white'
          }}>
          <div
            style={{
              // width: 200,
              height: 150,
              textAlign: 'center'
            }}>
            <Bars type='Oval' color='#f18f3c' />
            {/* <img width="100" className="fa-spin" src={require('../../../assets/img/topology/topology-loader.svg').default}></img> */}
            {loaderData && loaderData.loaderText ? (
              <p
                style={{
                  fontFamily: 'Poppins',
                  fontSize: 18,
                  marginTop: 10,
                  color: '#f18f3c',
                  fontWeight: 600
                }}>
                {loaderData.loaderText}
              </p>
            ) : (
              <div />
            )}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData
  };
};

export default connect(mapStateToProps, null)(FullPageLoader);
