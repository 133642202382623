import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  CardBody,
  Button,
  CardImg,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
} from 'reactstrap';
import AddEditAssetModal from '../components/add-edit-asset-modal';
import { deleteFile, fileList } from '../../../http/http-calls';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { ToastsStore } from 'react-toasts';
import moment from 'moment';
// import addEditAssetModal from "../components/add-edit-asset-modal";
import { deepClone } from '../../../helper-methods';
// import { Paginate } from "./Pagination";
import DeleteIconConfirmationModal from '../components/delete-icon-confirmation-modal';
import Badge from 'reactstrap/lib/Badge';

class Uploads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      addEditAssetModal: {
        isOpen: false,
        type: 'add',
        data: null,
      },
      files: [],
      currentPage: 1,
      totalPages: '',
      searchItem: '',
      deleteIconConfirmationModal: {
        isOpen: false,
        data: null,
      },
      dropdownOpen: false,
      currentDropDownSelected: 0,
    };
  }
  _toggleDropdownOpen = (index) => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen, currentDropDownSelected: index });
  };

  componentDidMount() {
    this._getFilesList(this.state.currentPage);
  }

  //handles toggle of add/edit asset modal
  _openAddEditAssetModal = (type, data) => {
    let { addEditAssetModal } = deepClone(this.state);
    addEditAssetModal.isOpen = !addEditAssetModal.isOpen;
    addEditAssetModal.type = type;
    addEditAssetModal.data = data;
    this.setState({ addEditAssetModal });
  };

  _onCloseAddEditAssetModal = (action) => {
    const { addEditAssetModal } = deepClone(this.state);
    if (action === 'cancel') {
      addEditAssetModal.isOpen = false;
      addEditAssetModal.type = 'add';
      addEditAssetModal.data = null;
      this.setState({ addEditAssetModal });
    } else {
      this._onCloseAddEditAssetModal('cancel');
      this._getFilesList();
    }
  };

  //get list of asset files with pagination
  _getFilesList = (pageNumber) => {
    const { searchItem } = this.state;
    let data = { page: pageNumber, limit: 100 };
    if (searchItem.trim().length) {
      data = {
        page: pageNumber,
        search: searchItem,
      };
    }
    this.props.showLoader();
    fileList(data)
      .then((resp) => {
        console.log('Files list response here :- ', resp);
        this.setState({
          files: resp.docs,
          totalPages: resp.totalPages,
          currentPage: resp.page,
        });
        this.props.hideLoader();
      })
      .catch((err) => {
        this.props.hideLoader();
        ToastsStore.error('Files loading failed :- ' + err.reason);
      });
  };

  //changing format of createdAt
  _changeFormat = (data) => {
    return moment(data).format('MMM DD, hh:mm a');
  };

  //changing bytes to others
  _bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    // eslint-disable-next-line
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  //showing list of files
  _showFilesList = () => {
    let fileListData = '';
    if (!this.state.files.length) {
      fileListData += 'No File Found';
    } else {
      fileListData = this.state.files.map((file, index) => {
        return (
          <>
            <Col md={6} lg={6} xl={3} key={file.id} className='mb-4'>
              <Card className='uploadCard labCard assetCard'>
                {file.url ? (
                  <CardImg top width='100%' alt='Asset Img' src={file.url} />
                ) : (
                  <CardImg alt='Asset Img' src='https://via.placeholder.com/728x190.png?text=Image' />
                )}
                <Badge>{this._bytesToSize(file.fileSize)}</Badge>
                <Dropdown
                  className='actionDrop'
                  isOpen={this.state.dropdownOpen && index === this.state.currentDropDownSelected}
                  toggle={() => this._toggleDropdownOpen(index)}>
                  <DropdownToggle>
                    <i className='fa fa-ellipsis-v'></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => this._openAddEditAssetModal('edit', file)}>
                      <i className='fa fa-pencil'></i>
                      Edit
                    </DropdownItem>
                    <DropdownItem onClick={() => this._deleteIcon(file)}>
                      <i className='fa fa-trash'></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <CardBody>
                  <ul className='uploadInfo'>
                    <li>
                      <span>File Name</span>
                      <span>{file.name}</span>
                    </li>
                    <li>
                      <span>Created At</span>
                      <span>{this._changeFormat(file.createdAt)}</span>
                    </li>
                  </ul>
                  <p className='para'>
                    {file.description}
                    {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid iusto obcaecati ducimus natus neque? */}
                  </p>
                </CardBody>
              </Card>
            </Col>

            {/* <tr key={file.id}>
              <td>
                <div className="d-flex justify-content-start align-items-center">
                  {file.url ?
                    <img alt="Asset Img" className="assetImg" src={file.url} />
                    : <img alt="Asset Img" className="assetImg" src={"https://via.placeholder.com/728x190.png?text=Image"} />
                  }
                  {file.name}
                </div>
              </td>
              <td>{file.description}</td>
              <td>{this._bytesToSize(file.fileSize)}</td>
              <td>
                {this._changeFormat(file.createdAt)}
              </td>
              <td>
                <Button
                  color="link"
                  onClick={() => this._openAddEditAssetModal('edit', file)}
                >
                  <i className="fa fa-pencil text-warning"></i>
                </Button>
                <Button color="link" onClick={() => this._deleteIcon(file)}>
                  <i className="fa fa-trash-o text-danger"></i>
                </Button>
              </td>
            </tr> */}
          </>
        );
      });
    }
    return fileListData;
  };

  //filters list according to search input based on name
  _filterList = (value) => {
    let { searchItem, currentPage } = this.state;
    searchItem = value;
    currentPage = 1;
    this.setState({ searchItem, currentPage }, () => {
      this._getFilesList(currentPage);
    });
  };

  //for pagination
  _paginate = (pageNumber) => {
    this._getFilesList(pageNumber);
  };

  _deleteIcon = (file) => {
    console.log('file :>> ', file);
    const { deleteIconConfirmationModal } = deepClone(this.state);
    deleteIconConfirmationModal.isOpen = true;
    deleteIconConfirmationModal.data = file;
    this.setState({ deleteIconConfirmationModal });
  };

  _onCloseDeleteIconConfirmationModal = async (action) => {
    const { deleteIconConfirmationModal, files } = deepClone(this.state);
    if (action === 'cancel') {
      deleteIconConfirmationModal.isOpen = false;
      deleteIconConfirmationModal.data = null;
      this.setState({ deleteIconConfirmationModal });
    } else {
      // delete
      try {
        this.props.showLoader();
        let res = await deleteFile(deleteIconConfirmationModal.data?.id);
        console.log('res :>> ', res);
        let newFiles = files.filter((e) => e.id !== deleteIconConfirmationModal.data.id);
        this.setState({ files: newFiles });
        this._onCloseDeleteIconConfirmationModal('cancel');
        this.props.hideLoader();
      } catch (error) {
        console.log('error :>> ', error);
        this.props.hideLoader();
        ToastsStore.error(error.reason);
      }
    }
  };

  render() {
    const {
      addEditAssetModal,
      deleteIconConfirmationModal,
      // dropdownOpen
    } = deepClone(this.state);

    return (
      <div className='content'>
        <Row className='mb-4'></Row>
        <Row>
          <Col xs='12'>
            <div>
              <CardHeader className='tableFilterWrap'>
                <Input
                  type='text'
                  placeholder='Search by Name...'
                  style={{ width: 180 }}
                  onChange={(e) => this._filterList(e.target.value)}
                />

                <Button className='addBtn ml-2' onClick={() => this._openAddEditAssetModal('add', null)}>
                  <i className='fa fa-plus mr-1'></i> Add
                </Button>
              </CardHeader>
              {/* <CardBody>
                <Table className="tablesorter" responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>File Size</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this._showFilesList()}
                  </tbody>
                </Table>
                <Paginate totalPages={this.state.totalPages}
                  currentPage={this.state.currentPage}
                  paginate={this._paginate}
                />
              </CardBody> */}
            </div>
          </Col>
          {this._showFilesList()}
        </Row>

        <AddEditAssetModal
          isOpen={addEditAssetModal.isOpen}
          data={addEditAssetModal.data}
          type={addEditAssetModal.type}
          toggle={this._onCloseAddEditAssetModal}></AddEditAssetModal>
        <DeleteIconConfirmationModal
          isOpen={deleteIconConfirmationModal.isOpen}
          data={deleteIconConfirmationModal.data}
          toggle={this._onCloseDeleteIconConfirmationModal}></DeleteIconConfirmationModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Uploads);
