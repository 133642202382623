import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ResizableBox } from 'react-resizable';
import Paper from '@material-ui/core/Paper';

import Termianl from '../../private/pages/Terminal';
import NewLicense from '../../private/components/new-license-modal';

const styles = (theme) => ({
  resizable: {
    position: 'relative',
    '& .react-resizable-handle': {
      position: 'absolute',
      width: 20,
      height: 20,
      bottom: 0,
      right: 0,
      background:
        "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
      'background-position': 'bottom right',
      padding: '0 3px 3px 0',
      'background-repeat': 'no-repeat',
      'background-origin': 'content-box',
      'box-sizing': 'border-box',
      cursor: 'se-resize'
    }
  }
});

function PaperComponent(props) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel="[class*='MuiDialogContent-root']"
      bounds='parent'
      // cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function DraggableResizableDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [maxWidth, setMaxWidth] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  React.useEffect(() => {
    const handleWindowResize = () => {
      const { innerWidth, innerHeight } = window;
      const maxWidth = innerWidth * 0.9;
      const maxHeight = innerHeight * 0.9;
      setMaxWidth(maxWidth);
      setMaxHeight(maxHeight);
    };

    // Calculate initial maximum width and height
    handleWindowResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleWindowResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    // onKeyDown={_listenPasteKey}
    //   data={props.data}
    //   onDrag={props.makeActive}
    //   isOpen={props.isOpen}
    //   ref={childRef}
    //   onResize={_onResize}
    //   isActive={props.isActive}
    //   dimensions={dimensions}
    //   type={props.type}
    //   _makeModalActive={_makeModalActive}
    //   _onClose={_onClose}
    //   _onMinimize={_onMinimize}
    <div>
      <Button onClick={handleClickOpen} className='addBtn'>
        ADD
      </Button>
      {open && (
        <Dialog
          // style={{ maxWidth: '80vw', maxHeight: '80vh' }}
          open={true}
          onClose={handleClose}
          maxWidth={true}
          PaperComponent={PaperComponent}
          aria-labelledby='draggable-dialog-title'>
          <ResizableBox
            height={400}
            width={600}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            className={props.classes.resizable}>
            <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
              <h2 style={{ color: 'white' }}>Modal</h2>
            </DialogTitle>
            <DialogContent>
              {/* <DialogContentText>
                To subscribe to this website, please enter your email address here. We will send updates occasionally.
              </DialogContentText>
              <TextField autoFocus margin='dense' id='name' label='Email Address' type='email' fullWidth /> */}
            </DialogContent>
            {/* <DialogActions>
              <Button onClick={handleClose} color='primary'>
                Cancel
              </Button>
              <Button onClick={handleClose} color='primary'>
                Subscribe
              </Button>
            </DialogActions> */}
          </ResizableBox>
        </Dialog>
      )}
    </div>
  );
}

export default withStyles(styles)(DraggableResizableDialog);
