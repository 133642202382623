import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Label, FormGroup, Input } from 'reactstrap';
import { deepClone } from '../../../../helper-methods';
import config from '../../../../config';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class AddConnectionModal extends Component {
  state = {
    fromPorts: [],
    toPorts: [],
    newConnection: {
      fromPort: '',
      toPort: '',
      lineType: 'line',
      color: '#007FFF', // blue
      strokeType: 'solid'
    },
    isDirty: {
      fromPort: false,
      toPort: false
    },
    errors: {},
    fromNode: {},
    toNode: {}
  };

  _handleOnChange = (field, value) => {
    const { newConnection, isDirty } = deepClone(this.state);
    newConnection[field] = value;
    isDirty[field] = true;
    this.setState({ newConnection, isDirty }, () => {
      this._validateForm();
    });
  };

  _validateForm = () => {
    const { newConnection, isDirty, errors } = deepClone(this.state);
    Object.keys(newConnection).forEach((each) => {
      if (each === 'fromPort' && isDirty.fromPort) {
        if (!newConnection.fromPort.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.fromPort = false;
        }
      } else if (each === 'toPort' && isDirty.toPort) {
        if (!newConnection.toPort.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.toPort = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.data) {
        let fromNode = this.props.data?.chartData.nodes.find((e) => e.id === this.props.data?.linkData.sourceId);
        let toNode = this.props.data?.chartData.nodes.find((e) => e.id === this.props.data?.linkData.targetId);
        // console.log('fromNode, toNode :>> ', fromNode, toNode);
        const fromPortList = this._generatePorts(fromNode);
        const toPortList = this._generatePorts(toNode);
        let newState = {
          fromPorts: fromPortList,
          toPorts: toPortList,
          newConnection: {
            fromPort: this._checkPortUsed(fromPortList, 'port1') ? '' : 'port1',
            toPort: this._checkPortUsed(toPortList, 'port1') ? '' : 'port1',
            // lineType: 'line',
            // color: '#007FFF', // blue
            // strokeType: 'solid'

            lineType: 'waypoints',
            color: '#777', // gray
            strokeType: 'dashed'
          },
          isDirty: {
            fromPort: false,
            toPort: false
          },
          errors: {},
          fromNode,
          toNode
        };
        this.setState(newState);
      }
    }
  }

  _generatePorts = (nodeData) => {
    console.log('nodeData :>> ', nodeData);
    return Object.keys(nodeData.properties.ports).map((each) => {
      return {
        label: nodeData.properties.ports[each].properties.name,
        // 'Port ' + nodeData.properties.ports[each].properties.ethernetPortNo,
        value: each,
        isUsed: nodeData.properties.ports[each].properties.isUsed
      };
    });
  };

  _checkPortUsed = (portList, portId) => {
    let data = portList.find((e) => e.value === portId && e.isUsed);
    return data;
  };

  _handleOnSubmit = () => {
    const { isDirty, newConnection } = deepClone(this.state);
    isDirty.fromPort = true;
    isDirty.toPort = true;
    this.setState({ isDirty }, () => {
      let errors = this._validateForm();
      if (!errors) {
        // console.log('newConnection :>> ', newConnection);
        this.props.toggle('save', newConnection);
      }
    });
  };

  render() {
    const { newConnection, errors, fromPorts, toPorts, fromNode, toNode } = deepClone(this.state);
    const { data } = deepClone(this.props);

    return (
      <Modal size={'md'} isOpen={this.props.isOpen} toggle={() => this.props.toggle('close')}>
        <ModalHeader toggle={() => this.props.toggle('close')}>Add Connection</ModalHeader>
        <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          {data?.chartData && fromNode && toNode && (
            <div className='connection-info-wrap'>
              <div className='info'>
                <img src={fromNode?.properties?.icon} alt='from-icon' width='50' />
                <div>{fromNode?.properties?.name}</div>
              </div>
              <div className='info'>
                <i className='fa fa-arrows-h'></i>
              </div>
              <div className='info'>
                <img src={toNode?.properties?.icon} alt='from-icon' width='50' />
                <div>{toNode?.properties?.name}</div>
              </div>
            </div>
          )}
          <div className='port-form-wrap'>
            <FormGroup>
              <Label>From Port</Label>
              <select
                className='form-control'
                value={newConnection.fromPort}
                onChange={(e) => this._handleOnChange('fromPort', e.target.value)}>
                <option value=''>Select ETH Port</option>
                {React.Children.toArray(
                  fromPorts.map((each) => (
                    <option value={each.value} disabled={each.isUsed}>
                      {each.label}
                    </option>
                  ))
                )}
              </select>
              {errors && <div className='validation-error'>{errors.fromPort}</div>}
            </FormGroup>
            <FormGroup>
              <Label>To Port</Label>
              <select
                className='form-control'
                value={newConnection.toPort}
                onChange={(e) => this._handleOnChange('toPort', e.target.value)}>
                <option value=''>Select ETH Port</option>
                {React.Children.toArray(
                  toPorts.map((each) => (
                    <option value={each.value} disabled={each.isUsed}>
                      {each.label}
                    </option>
                  ))
                )}
              </select>
              {errors && <div className='validation-error'>{errors.toPort}</div>}
            </FormGroup>
          </div>
          <div>
            <div>
              <Accordion>
                {/* <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'> */}
                <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
                  <Typography>Change Styles</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                    blandit leo lobortis eget.
                  </Typography> */}
                  <FormGroup>
                    <Label>Link Type</Label>
                    {/* {console.info(newConnection.lineType, '++++++ LINE TYPE')} */}
                    <Input
                      type='select'
                      placeholder='Select line type'
                      value={newConnection.lineType}
                      onChange={(e) => this._handleOnChange('lineType', e.target.value)}>
                      {React.Children.toArray(
                        config.connectionLineTypes.map((each) => <option value={each.value}>{each.label}</option>)
                      )}
                    </Input>
                    {/* {errors && <div className="validation-error">{errors.name}</div>} */}
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Link Color
                      <div className='color-preview' style={{ backgroundColor: newConnection.color || '#222' }}></div>
                    </Label>
                    <Input
                      type='select'
                      placeholder='Select line color'
                      // value={newConnection.color}
                      value='Red'
                      onChange={(e) => this._handleOnChange('color', e.target.value)}>
                      {React.Children.toArray(
                        config.connectionColors.map((each) => <option value={each.value}>{each.label}</option>)
                      )}
                    </Input>
                    {/* {errors && <div className="validation-error">{errors.name}</div>} */}
                  </FormGroup>
                  <FormGroup>
                    <Label>Link Stroke Type</Label>
                    {/* {console.info('stroke type', newConnection.strokeType)} */}
                    <Input
                      type='select'
                      placeholder='Select stroke type'
                      value={newConnection.strokeType}
                      onChange={(e) => this._handleOnChange('strokeType', e.target.value)}>
                      <option value='dashed'>Dashed</option>
                      <option value='solid'>Solid</option>
                    </Input>
                    {/* {errors && <div className="validation-error">{errors.name}</div>} */}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            </div>

            {/* <FormGroup>
              <Label>Link Type</Label>
              {console.info(newConnection.lineType, '++++++ LINE TYPE')}
              <Input
                type='select'
                placeholder='Select line type'
                value={newConnection.lineType}
                onChange={(e) => this._handleOnChange('lineType', e.target.value)}>
                {React.Children.toArray(
                  config.connectionLineTypes.map((each) => <option value={each.value}>{each.label}</option>)
                )}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>
                Link Color
                <div className='color-preview' style={{ backgroundColor: newConnection.color || '#222' }}></div>
              </Label>
              <Input
                type='select'
                placeholder='Select line color'
                // value={newConnection.color}
                value='Red'
                onChange={(e) => this._handleOnChange('color', e.target.value)}>
                {React.Children.toArray(
                  config.connectionColors.map((each) => <option value={each.value}>{each.label}</option>)
                )}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Link Stroke Type</Label>
              {console.info('stroke type', newConnection.strokeType)}
              <Input
                type='select'
                placeholder='Select stroke type'
                value={newConnection.strokeType}
                onChange={(e) => this._handleOnChange('strokeType', e.target.value)}>
                <option value='dashed'>Dashed</option>
                <option value='solid'>Solid</option>
              </Input>
            </FormGroup> */}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className='modalBtnCancel' onClick={() => this.props.toggle('close')}>
            Cancel
          </Button>
          <Button className='modalBtnSave' onClick={(e) => this._handleOnSubmit(e)}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddConnectionModal;
