import React, { useEffect, useRef, useState, useCallback } from 'react';
import { ModalFooter } from 'reactstrap';
import Guacamole from 'guacamole-common-js';
import { deepClone } from '../../../../helper-methods';
import config from '../../../../config';
import ModalBody from 'reactstrap/lib/ModalBody';
import ResizableModal from './resizableModal';

const GuacamoleModal = (props) => {
  const [state, setState] = useState({
    wrapperWidth: 750,
    guacamoleScale: 0.75,
    client: null,
    keyboard: null,
    mouse: null,
    resizingInterval: null,
    activeClass: '',
    height: 670,
    width: 750,
    data: null,
  });

  const [node, setNode] = useState(null);
  const [contentNode, setContentNode] = useState(null);
  const [dimensions, setDimensions] = useState({
    width: 750,
    height: 670,
  });
  const [clipboardClient, setClipboardClient] = useState(null);

  const childRef = useRef();

  useEffect(() => {
    const node = document.getElementById('guacamole-modal');
    setNode(node);
    // const content = node.getElementsByClassName("modal-content")
    if (state.client) {
      let { client, mouse, keyboard, resizingInterval } = deepClone(state);

      let doc = document.getElementById(`guacamole${props.data?.id}`);

      document.getElementById(`guacamole${props.data?.id}`).appendChild(client.getDisplay().getElement());
      // Guacamole connect
      if (!props.ifStateAvailable) {
        client.connect();
      }
      console.log('client :>> ', client);
      if (props.data?.properties.isDockerNode) {
        client.getDisplay().scale(0.57);
      } else {
        // let layer = client.getDisplay();
        client.getDisplay().scale(0.72);
        // _scaleWindow()
        if (props.type === 'vnc') {
          client.getDisplay().showCursor(false);
        }
      }

      client.getDisplay().onresize = (width, height) => {
        _scaleWindow();
      };
      // Mouse
      mouse = new Guacamole.Mouse(client.getDisplay().getElement());
      mouse.onmousedown =
        mouse.onmouseup =
        mouse.onmousemove =
          function (mouseState) {
            // client.sendMouseState(mouseState);
            let display = client.getDisplay();
            // Scale event by current scale
            var scaledState = new Guacamole.Mouse.State(
              mouseState.x / display.getScale(),
              mouseState.y / display.getScale(),
              mouseState.left,
              mouseState.middle,
              mouseState.right,
              mouseState.up,
              mouseState.down
            );

            // Send mouse event
            client.sendMouseState(scaledState);
          };
      // Touchscreen
      const touch = new Guacamole.Mouse.Touchscreen(client.getDisplay().getElement());
      touch.onmousedown =
        touch.onmouseup =
        touch.onmousemove =
          function (mouseState) {
            client.sendMouseState(mouseState);
          };
      // Keyboard
      keyboard = new Guacamole.Keyboard(document);
      keyboard.onkeydown = (keysym) => {
        console.log('this.props :>> ', props.isActive);
        let doc = document.getElementById(`guacamole${props.data?.id}`);
        console.log('event on keydown', props.data.properties.name, doc.getAttributeNode('value').value);
        if (doc.getAttributeNode('value').value === 'true') {
          client.sendKeyEvent(1, keysym);
        } else {
          console.log('no event :>> ');
        }
        // return true
      };
      keyboard.onkeyup = (keysym) => {
        let doc = document.getElementById(`guacamole${props.data?.id}`);
        console.log('event on keyup', props.data.properties.name, typeof doc.getAttributeNode('value').value);
        if (doc.getAttributeNode('value').value === 'true') {
          client.sendKeyEvent(0, keysym);
        } else {
          console.log('no event :>> ');
        }
        // return true
      };
      // Audio
      client.onaudio = function clientAudio(stream, mimetype) {
        const context = Guacamole.AudioContextFactory.getAudioContext();
        context.resume().then(() => console.log('play audio'));
      };
      resizingInterval = setInterval(() => {
        // _scaleWindow()
        console.log(
          'dimention :>> ',
          client.getDisplay().getElement().offsetWidth,
          client.getDisplay().getElement().offsetHeight
        );
        if (client.getDisplay().getElement().offsetWidth > 0 && client.getDisplay().getElement().offsetHeight > 0) {
          // childRef.current.resize(client.getDisplay().getElement().offsetWidth + 10, client.getDisplay().getElement().offsetHeight + 120)
          let displayWidth = client.getDisplay().getElement().offsetWidth;
          let displayHeight = client.getDisplay().getElement().offsetHeight;
          setState((prev) => {
            prev.width = displayWidth + 10;
            prev.height = displayHeight - 200;
            return prev;
          });
          _scaleWindow();
          // childRef.current.width = displayWidth + 10
          // childRef.current.height = displayHeight + 120
          clearInterval(resizingInterval);
          props.hideLoader();
        }
      }, 1000);
      // this.setState({ client, resizingInterval, keyboard, mouse });

      setState((prev) => {
        prev.client = client;
        prev.resizingInterval = resizingInterval;
        prev.keyboard = keyboard;
        prev.mouse = mouse;
        return prev;
      });
      console.log('Comes here in the last');
    }
  });

  useEffect(() => {
    if (node !== null) {
      let [content] = node.getElementsByClassName('modal-content');
      setContentNode(content);
    }
  }, [node]);

  useEffect(() => {
    console.log('Comes into init');
    setState((prev) => {
      prev.data = props.data;
      return prev;
    });

    let { client, keyboard, mouse, resizingInterval } = deepClone(state);
    console.log('this.props :>> ', props, client, keyboard);
    if (props.isOpen) {
      // props.showLoader('Connecting..');
      if (props.ifStateAvailable) {
        client = props.state.client;
      } else {
        if (props.guac_url_vnc) {
          // window.open(`${window.location.origin}${props.guac_url_vnc}`, '_blank');
        } else if (props.guac_url_telnet) {
          // window.open(`${window.location.origin}${props.guac_url_telnet}`, '_blank');
        } else if (props.guac_url_wireshark) {
          // window.open(`${window.location.origin}${props.guac_url_wireshark}`, '_blank');
        }
      }

      setState((prev) => {
        prev.client = client;
        return prev;
      });
    } else {
      client && client.disconnect();
      resizingInterval && clearInterval(resizingInterval);
      // this.setState({ client: null });
      setState((prev) => {
        prev.client = null;
        return prev;
      });
    }
  }, []);

  const scaleContentNode = useCallback(() => {
    if (contentNode !== null) {
      contentNode.style.width = state.width + 'px';
      contentNode.style.height = state.height + 'px';
    }
  }, [contentNode, state]);

  useEffect(() => {
    scaleContentNode();
  }, [contentNode, scaleContentNode]);

  const _onClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { client, resizingInterval, keyboard } = deepClone(state);
    console.log('close modal :>> ');
    client && client.disconnect();
    resizingInterval && clearInterval(resizingInterval);
    keyboard.onkeyup = keyboard.onkeydown = undefined;
    props.toggle();
    setState((prev) => {
      prev.client = null;
      return prev;
    });
  };

  const _updateSize = (e, value) => {
    console.log('_updateSize :>> ', value);
    e.preventDefault();
    e.stopPropagation();
    const { client } = deepClone(state);
    client.getDisplay().scale(value);
    setState((prev) => {
      prev.guacamoleScale = value;
      return prev;
    });

    _scaleWindow();
  };

  const _scaleWindow = useCallback(() => {
    setDimensions({
      width: state.client.getDisplay().getElement().offsetWidth + 10,
      height: state.client.getDisplay().getElement().offsetHeight + 120,
    });
  }, [state]);

  const _makeModalActive = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // this.setState({ activeClass: "active-guacamole-modal" });
    // setTimeout(()=>{
    //   this.setState({ activeClass: "" });
    // }, 1000);

    // setState(prev => {
    //   prev.activeClass = "active-guacamole-modal"
    //   return prev
    // })
    props.makeActive();
  };
  const _onResize = (event, direction, refToElement, delta) => {
    let guacWidth = state.client.getDisplay().getElement().offsetWidth;
    // let guacHeight = state.client.getDisplay().getElement().offsetHeight
    let increasedWidth = delta.width;
    // let increasedHeight = delta.height
    let percentageIncWidth = increasedWidth / guacWidth;
    // let percentageIncHeight = increasedHeight / guacHeight

    if (state.client.getDisplay().getElement().offsetWidth > 500 || (delta.width > 0 && delta.height > 0)) {
      setState((prev) => {
        prev.guacamoleScale += Number(percentageIncWidth.toFixed(2) / 38);
        return prev;
      });

      state.client.getDisplay().scale(state.guacamoleScale);

      _scaleWindow();
    }
  };

  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => console.log('Text copied to clipboard'))
      .catch((error) => console.error('Could not copy text: ', error));
  }

  const _onMinimize = (e) => {
    e.stopPropagation();
    let temp = { ...state };
    props.onMinimize(temp, e);
    props.toggle();
  };

  const clipboardStream = () => {
    console.log('ctrl ===> ', clipboardClient);
    if (clipboardClient) {
      // const writer = new state.client.StringWriter(clipboardClient)
      // clipboardClient.sendBlob("Hey there")
      // writer.sendText("hey there");
      // writer.sendEnd();
      // clipboardClient.sendBlob("Hey there")
    }
    const stream = state.client.createClipboardStream('text/plain');
    stream.sendBlob('Hey there');
    stream.sendEnd();
    // state.client.setClipboard("Hey there")
  };

  const _listenPasteKey = (event) => {
    event.preventDefault();
    console.log('ctrl comes here');
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
      console.log('ctrl v tapped');
      clipboardStream();
    }
  };

  return (
    <></>
  );
};

export default GuacamoleModal;
