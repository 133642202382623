import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
  Col,
  Row,
} from "reactstrap";
import { deepClone, formatLable } from "../../../../helper-methods";
import config from "../../../../config";
import Select from "react-dropdown-select";
import styled from "styled-components";

const StyledOption = styled.span`
  padding: 0;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  transition: all 1s ease-in;

  span {
    display: none;
    transition: all 1s ease-in;
  }

  a {
    margin: 0 5px;
  }

  :hover {
    background: #f2f2f2;

    span {
      display: inline;
      margin: 0 0 0 5px;
      color: red;
    }
  }
`;

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;

  :hover {
    background: #f2f2f2;
  }
`;
class AddLabObjectModal extends Component {
  state = {
    labObject: {
      labId: "",
      name: "",
      type: "",
      left: 50,
      top: 50,
      data: {},
    },
    selectedIcons: [],
    isDirty: {
      name: false,
      type: false,
      left: false,
      top: false
    },
    errors: {},
  };

  componentDidMount() {
    const { labObject } = deepClone(this.state);
    labObject.labId = this.props.match.params.id;
    this.setState({ labObject });
  }

  _handleOnChange = (field, value) => {
    let { labObject, isDirty, errors } = deepClone(this.state);
    labObject[field] = value;
    isDirty[field] = true;
    if (field === "type") {
      errors = {};
      if (value.trim().length) {
        const typeObj = config.objectTypes.find((e) => e.value === value);
        switch (typeObj.key) {
          case "picture":
            labObject.pictureId = "";
            isDirty.pictureId = false;
            break;
          case "rect":
            labObject.data = {
              width: 200,
              height: 200,
              borderRadius: 20,
              color: "#ffffff",
            };
            isDirty.width = false;
            isDirty.height = false;
            break;
          case "circle":
            labObject.data = {
              radius: 30,
              color: "#ffffff",
            };
            isDirty.radius = false;
            break;
          case "text":
            labObject.data = {
              textContent: "",
              color: "#222222",
            };
            isDirty.textContent = false;
            isDirty.color = false;
            break;
          case "line":
            labObject.data = {
              width: 2,
              color: "#222222",
              lineType: 'solid',      
              strokeOpacity: 80   
            };
            isDirty.width = false;
            isDirty.color = false;
            break;
          default:
            labObject.data = {};
            break;
        }
      } else {
        labObject.data = {};
        delete labObject.pictureId;
      }
    }
    this.setState({ labObject, isDirty, errors },()=>{
      this._validateFields();
    });
  };

  _handleOnDataChange = (field, value) => {
    const { labObject, isDirty } = deepClone(this.state);
    labObject.data[field] = value;
    isDirty[field] = true;
    this.setState({ labObject, isDirty }, ()=>{
      this._validateFields();
    });
  };

  _validateFields = () => {
    const { labObject, isDirty, errors, selectedIcons } = this.state;
    Object.keys(labObject).forEach((each) => {
      // if (each === "name" && isDirty.name) {
      //   if (!labObject.name.trim().length) {
      //     errors[each] = "*Required";
      //   } else {
      //     delete errors[each];
      //     isDirty.name = false;
      //   }
      // } else
       if (each === "type" && isDirty.type) {
        if (!labObject.type.trim().length) {
          errors[each] = "*Select object type";
        } else {
          delete errors[each];
          isDirty.type = false;
        }
      } else if (each === "left" && isDirty.left) {
        if (labObject.left.length && isNaN(labObject.left)) {
          errors[each] = "Should be Number";
        } else {
          delete errors[each];
          isDirty.left = false;
        }
      } else if (each === "top" && isDirty.top) {
        if (labObject.top.length && isNaN(labObject.top)) {
          errors[each] = "Should be Number";
        } else {
          delete errors[each];
          isDirty.top = false;
        }
      }
    });
    switch(labObject.type){
      case 'Picture':
        if(selectedIcons.length===0 && isDirty.pictureId){
          errors.pictureId = '*Select a Picture';
        } else {
          delete errors.pictureId;
          isDirty.pictureId = false;
        }
        break;
      case 'Rectangle':
        if(!labObject.data.width.toString().trim().length || isNaN(labObject.data.width) || labObject.data.width<=0){
          errors.width = 'Width should be a positive number';
        } else {
          delete errors.width;
          isDirty.width = false;
        }
        if(!labObject.data.height.toString().trim().length || isNaN(labObject.data.height) || labObject.data.height<=0){
          errors.height = 'Height should be a positive number';
        } else {
          delete errors.height;
          isDirty.height = false;
        }
        break;
      case 'Circle':
        if(!labObject.data.radius.toString().trim().length || isNaN(labObject.data.radius) || labObject.data.radius<=0){
          errors.radius = 'Radius should be a positive number';
        } else {
          delete errors.radius;
          isDirty.radius = false;
        }
        break;
      case 'Text':
        if(!labObject.data.textContent.trim().length && isDirty.textContent){
          errors.textContent = '*Required';
        } else {
          delete errors.textContent;
          isDirty.textContent = false;
        }
        break;
      case 'Line':
        if(!labObject.data.width.toString().trim().length || isNaN(labObject.data.width) || labObject.data.width<=0){
          errors.width = 'Width should be a positive number';
        } else {
          delete errors.width;
          isDirty.width = false;
        }
        break;
      default:
        break;

    }
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  _handleOnSubmit = () => {
    const { labObject, selectedIcons, isDirty } = deepClone(this.state);
    Object.keys(isDirty).forEach((key)=>{
      isDirty[key] = true;
    })
    this.setState({ isDirty },()=>{
      let errors = this._validateFields();
      if(!errors){         
        switch (labObject.type) {
          case "Picture":
            labObject.pictureId = selectedIcons[0].id;
            labObject.data.shape = "image";
            labObject.data.url = selectedIcons[0].url;
            labObject.data.x = labObject.left || 50;
            labObject.data.y = labObject.top || 50;
            break;
          case "Rectangle":
            labObject.type = "Custom Shape";
            labObject.data.shape = "rect";
            labObject.data.x = labObject.left || 50;
            labObject.data.y = labObject.top || 50;
            break;
          case "Circle":
            labObject.type = "Custom Shape";
            labObject.data.shape = "circle";
            labObject.data.x = labObject.left || 50;
            labObject.data.y = labObject.top || 50;
            break;
          case "Text":
            labObject.data.shape = "text";
            labObject.data.x = labObject.left || 50;
            labObject.data.y = labObject.top || 50;
            labObject.data.fontSize = 32;
            break;
          case "Line":
            labObject.data.shape = "line";
            labObject.data.x1 = labObject.left || 50;
            labObject.data.y1 = labObject.top || 50;
    
            labObject.data.x2 = Number(labObject.left || 50) + 50;
            labObject.data.y2 = Number(labObject.top || 50) + 50;
    
            labObject.data.x = labObject.left || 50;
            labObject.data.y = labObject.top || 50;
            break;
          default:
            break;
        }
        console.log("labObject :>> ", labObject);
        this.props.toggle("save", labObject);
      }
    });
  };

  _customOptionRenderer = ({ item, props, state, methods }) => {
    return (
      <StyledOption>
        <img src={item.url} width={30} alt={item.name} />
        &nbsp;
        {item.name}
        <span
          onClick={() => {
            methods.removeItem(null, item);
            this.setState({ selectedIcons: [] }, () => {
               this._validateFields();
            });
          }}
        >
          &times;
        </span>
      </StyledOption>
    );
  };

  _customItemRenderer = ({ item, itemIndex, props, state, methods }) => {
    const { selectedIcons } = deepClone(this.state);

    return (
      <StyledItem>
        <div
          onClick={() => {
            console.log("selectedIcons :>> ", item);
            methods.addItem(item);
            const { isDirty } = deepClone(this.state);
            isDirty.pictureId = true;
            const isAlreadyPresent =
              selectedIcons.length && selectedIcons[0].id === item.id;
            this.setState(
              {
                selectedIcons: isAlreadyPresent ? [] : [item],
                selectedFile: null,
                previewImg: null,
                isDirty,
              },
              () => {
                 this._validateFields()
              }
            );
          }}
        >
          <img src={item.url} width={30} alt={item.name} /> &nbsp;
          {item.name}
        </div>
      </StyledItem>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      let state = {
        labObject: {
          labId: this.props.match.params.id,
          name: "",
          type: "",
          left: 50,
          top: 50,
          data: {},
        },
        selectedIcons: [],
        isDirty: {
          name: false,
          type: false,
          left: false,
          top: false
        },
        errors: {},
      };
      this.setState(state);
    }
  }

  _getInputType = (field)=>{
    if(field==='color'){
      return 'color'
    } else if(field==='strokeOpacity'){
      return 'range'
    } else {
      return 'text'
    }
  }

  render() {
    const { labObject, errors } = deepClone(this.state);
    const { data } = deepClone(this.props);

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle("cancel")}
      >
        <ModalHeader toggle={() => this.props.toggle("cancel")}>
          Add Lab Object
        </ModalHeader>
        <ModalBody className="innerForm">
          {/* <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              placeholder="Enter name"
              value={labObject.name}
              onChange={(e) => this._handleOnChange("name", e.target.value)}
            />
            {errors && <div className="validation-error">{errors.name}</div>}
          </FormGroup> */}
          <FormGroup>
            <Label>Type</Label>
            <Input
              type="select"
              rows="3"
              placeholder="Select Type"
              value={labObject.type}
              onChange={(e) => this._handleOnChange("type", e.target.value)}
            >
              <option value="">Select Type</option>
              {React.Children.toArray(
                config.objectTypes.map((each) => (
                  <option value={each.value}>{each.label}</option>
                ))
              )}
            </Input>
            {errors && <div className="validation-error">{errors.type}</div>}
          </FormGroup>
          {labObject.type !== "Picture" ? (
            <FormGroup>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {React.Children.toArray(
                  Object.keys(labObject.data).map((each) => (
                    <div
                      style={{ width: "45%", margin: "0 10px 10px 0" }}
                    >
                      <Label>{formatLable(each)}</Label>
                      {
                        each==='strokeOpacity' ? 
                        <>
                        <input
                        className=""
                        type="range"
                        min="0"
                        max="100"
                        placeholder={`Enter ${formatLable(each)}`}
                        value={labObject.data[each]}
                        onChange={(e) =>
                          this._handleOnDataChange(each, e.target.value)
                        }
                      /> {labObject.data[each]}%</>
                     :   
                     each === 'lineType' ? 
                     <select className="form-control" value={labObject.data[each]}
                     onChange={(e) =>
                       this._handleOnDataChange(each, e.target.value)
                     }>
                       <option value="solid">Solid</option>
                       <option value="dashed">Dashed</option>
                     </select>
                     :
                      <input
                        className="form-control"
                        type={this._getInputType(each)}
                        rows="3"
                        placeholder={`Enter ${formatLable(each)}`}
                        value={labObject.data[each]}
                        onChange={(e) =>
                          this._handleOnDataChange(each, e.target.value)
                        }
                      />
                      }
                      {errors && (
                        <div className="validation-error">
                          {errors[each]}
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
            </FormGroup>
          ) : (
            <FormGroup>
              <Label>Image</Label>
              <Select
                placeholder="Select Image"
                multi={true}
                keepOpen={false}
                searchable={false}
                optionRenderer={this._customOptionRenderer}
                itemRenderer={this._customItemRenderer}
                onChange={(value) => this._handleOnChange("pictureId", value)}
                values={this.state.selectedIcons}
                options={data?.iconList}
                labelField={"name"}
                valueField={"id"}
                closeOnSelect={true}
              />
              {errors && (
              <div className="validation-error">{errors.pictureId}</div>
            )}
            </FormGroup>
          )}
          <FormGroup>
            <Label>Position</Label>
            <Row className="justify-content-between">
              <Col md={6}>
                <Label>Left</Label>
                <Input
                  type="text"
                  placeholder="Enter Left"
                  value={labObject.left}
                  onChange={(e) => this._handleOnChange("left", e.target.value)}
                />
                {errors && (
                  <div className="validation-error">{errors.left}</div>
                )}
              </Col>
              <Col md={6}>
                <Label>Top</Label>
                <Input
                  type="text"
                  placeholder="Enter Top"
                  value={labObject.top}
                  onChange={(e) => this._handleOnChange("top", e.target.value)}
                />
                {errors && <div className="validation-error">{errors.top}</div>}
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            className="modalBtnCancel"
            onClick={() => this.props.toggle("cancel")}
          >
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={(e) => this._handleOnSubmit()}
          >
            Create
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddLabObjectModal;
