import { store } from "../redux/store";

export const getToken = () => {
  return new Promise((resolve, reject) => {
    let evosToken = null;
    const oldState = store.getState();
    const state = { ...oldState };
    // Get token from localstorage
    if (
      state &&
      state.userData &&
      state.userData["token"]
    ) {
      evosToken = state.userData["token"];
    }
    resolve(evosToken);
  });
};
