import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button
} from "reactstrap";
import { deepClone } from '../../../../helper-methods';
class ConnectionInfoModal extends Component {
  state = {  }
  render() { 
    const { data } = deepClone(this.props);
    console.log('data :>> ', data);
    let fromNodeId = '';
    let toNodeId = '';
    let fromPortId = '';
    let toPortId = '';
    let fromNode = {};
    let toNode = {};
    if(data){
      fromNodeId = data?.linkData?.edge.data.connectionInfo.from.nodeId;
      toNodeId =  data?.linkData?.edge.data.connectionInfo.to.nodeId;
      fromNode = data?.chartData?.nodes.find((e)=>e.id===fromNodeId);
      toNode = data?.chartData?.nodes.find((e)=>e.id===toNodeId);
      // ports
      fromPortId = data?.linkData?.edge.data.connectionInfo.from.portId
      toPortId =  data?.linkData?.edge.data.connectionInfo.to.portId
    }
    
    return ( <Modal
      isOpen={this.props.isOpen}
      toggle={() => this.props.toggle("close")}
    >
      <ModalHeader toggle={() => this.props.toggle("close")}>
       Connection Information
      </ModalHeader>
      <ModalBody>
        {data?.chartData && <div className="connection-info-wrap">
            <div className="info">
              <img src={fromNode?.properties?.icon} alt="from-icon" width="50"/>
              <div>{fromNode?.properties?.name}</div>
              <div>({fromNode?.properties.ports?.[fromPortId]?.properties.name})</div>
              { fromNode?.properties?.isDockerNode &&
                <img src={require('../../../../assets/img/docker.png').default} alt="docker-img" style={{
                  right: 30,
                  top: -10,
                  position: 'absolute',
                  width: 22
                }}/>
              }
            </div>
            <div className="info">
              <i className="fa fa-arrows-h"></i>
            </div>
            <div className="info">
              <img src={toNode?.properties?.icon} alt="from-icon" width="50"/>
                <div>{toNode?.properties?.name}</div>
                <div>({toNode?.properties.ports?.[toPortId]?.properties.name})</div>
                { toNode?.properties?.isDockerNode &&
                <img src={require('../../../../assets/img/docker.png').default} alt="docker-img" style={{
                  right: 30,
                  top: -10,
                  position: 'absolute',
                  width: 22
                }}/>
              }
              </div>
          </div>}
        <div className="text-center">         
          Click <span className="delete-link-icon" onClick={()=>this.props.onDeleteConnection(data)}>Delete</span> to remove the Connection.
          Click <span className="delete-link-icon" onClick={()=>this.props.openEditModal(data)}>Edit</span> to edit the Connection.
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="modalBtnCancel"
          onClick={() => this.props.toggle("close")}
        >
          Close
        </Button>        
      </ModalFooter>
    </Modal> );
  }
}
 
export default ConnectionInfoModal;