import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Table, FormGroup, Input } from 'reactstrap';
import { deepClone } from '../../../helper-methods';
import { getResourceList } from '../../../http/http-calls';
import { Paginate } from '../pages/Pagination';

class DashboardNetworkListModal extends Component {
  state = {
    searchTerm: '',
    networkList: [],
    currentPage: 1,
    totalPages: 0
  };

  _getNetworkList = async (pageNumber) => {
    const { searchTerm } = deepClone(this.state);
    let data = {
      category: 'network',
      page: pageNumber
    };
    if (searchTerm.trim().length) {
      data.search = searchTerm;
    }
    try {
      let res = await getResourceList(data);
      console.log('res; :>> ', res);
      this.setState({
        networkList: res.docs,
        totalPages: res.totalPages,
        currentPage: res.page
      });
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  _handleOnSearch = (value) => {
    this.setState({ searchTerm: value, currentPage: 1 }, () => {
      this._getNetworkList(1);
    });
  };

  _handleOnPaginate = (pageNumber) => {
    this._getNetworkList(pageNumber);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.data) {
        this.setState({
          searchTerm: '',
          networkList: this.props.data.networkList,
          currentPage: 1,
          totalPages: this.props.data.totalPages
        });
      }
    }
  }
  render() {
    const { networkList, searchTerm } = deepClone(this.state);
    return (
      <Modal className='bg-dark' isOpen={this.props.isOpen} toggle={() => this.props.toggle()} size={'lg'}>
        <ModalHeader toggle={() => this.props.toggle()}>{/* Network List */}</ModalHeader>
        <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <FormGroup className='d-flex justify-content-end'>
            <Input
              type='text'
              placeholder='Search...'
              style={{ width: 240 }}
              value={searchTerm}
              onChange={(e) => this._handleOnSearch(e.target.value)}
            />
          </FormGroup>

          {networkList.length > 0 ? (
            <div>
              <Table className='tablesorter tableInModal' responsive>
                <thead>
                  <tr>
                    <th>Network Name</th>
                    {/* <th>Description</th> */}
                    <th>Type</th>
                    <th>Lab Name</th>
                    <th>Created By</th>
                  </tr>
                </thead>
                <tbody>
                  {React.Children.toArray(
                    networkList.map((each, index) => (
                      <tr>
                        <td>
                          {each._icon?.url ? <img src={each._icon?.url} alt={'icon-image' + index} width='20' className='mr-2' /> : ''}
                          {each.name}
                        </td>
                        {/* <td>{each.description || 'N/A'}</td> */}
                        <td>{each.networkType}</td>
                        <td>{each._lab?.name}</td>
                        <td>{each._createdBy?.name?.full}</td>
                        {/* <td>{each.vmState}</td> */}
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              <Paginate
                totalPages={this.state.totalPages}
                currentPage={this.state.currentPage}
                paginate={this._handleOnPaginate}
              />
            </div>
          ) : (
            <p className='text-center'>No Networks Found.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className='modalBtnSave' onClick={() => this.props.toggle()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DashboardNetworkListModal;
