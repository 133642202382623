import React, { useState } from 'react';
import {
  Row,
  Col,
  Input,
  FormGroup,
  Button,
  Label,
  Badge,
  CustomInput,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
// import Select from "react-dropdown-select";

const StepFirst = (props) => {
  const [errors, setErros] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentSelectedIcon, setCurrentSelectedIcon] = useState({});

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  console.log('iconList ===> ', props);
  const _goNext = () => {
    let templateErros = {};

    Object.keys(props.template).forEach((each) => {
      console.log(props.template[each]);
      if (each === 'baseBin' && !props.template.baseBin) {
        templateErros.baseBin = 'VM Type is required';
      } else if (each === 'description' && !props.template.description) {
        templateErros.description = 'Description is required';
      } else if (each === 'name' && !props.template.name) {
        templateErros.nameTemplate = 'Name is required';
      } else if (each === 'vmimage' || each === 'dockerimage') {
        if (props.template.vmimage === 0 && props.template.dockerimage === 0)
          templateErros.vmimage = 'VM Images is requied';
      }
    });

    setErros(templateErros);
    console.log(templateErros);
    if (!Object.keys(templateErros).length) {
      props.nextStep();
    }
    // props.nextStep()
  };

  // const _find = (id) => {
  //   let selected = props.iconList.find((each) => each._id === id);
  //   if (selected) return { name: selected.name, icon: selected.url };
  //   return { name: "Selected", icon: "" }
  // }

  return (
    <>
      <Row>
        <Col md='6'>
          <FormGroup>
            <Label>
              Name<sup>*</sup>
            </Label>
            <Input
              type='text'
              placeholder='Name'
              name='name'
              value={props.template.name}
              onChange={(e) => props._handleOnChange('name', e.target.value)}
            />
            {errors && <div className='validation-error'>{errors.nameTemplate}</div>}
          </FormGroup>
        </Col>
        <Col md='6'>
          <FormGroup>
            <Label>Description</Label>
            <Input
              type='textarea'
              placeholder='Description'
              name='description'
              value={props.template.description}
              onChange={(e) => props._handleOnChange('description', e.target.value)}
            />
            {errors && <div className='validation-error'>{errors.description}</div>}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md='6'>
          <FormGroup>
            <Label>
              VM Type<sup>*</sup>
            </Label>
            <Input
              type='select'
              placeholder='Vm Type'
              name='baseBin'
              value={props.template.baseBin}
              onChange={(e) => props._handleOnChange('baseBin', e.target.value)}>
              <option value={'virsh'}>virsh</option>
              <option value={'docker'}>docker</option>
            </Input>
            {errors && <div className='validation-error'>{errors.baseBin}</div>}
          </FormGroup>
        </Col>
        <Col md='6' className='mb-md-0 mb-4'>
          <div className='checkBox_inline'>
            <FormGroup>
              <CustomInput
                type='checkbox'
                placeholder='active'
                checked={props.template.active}
                onChange={(e) => props._handleOnChange('active', e.target.checked)}
                id='Active'
                label='Active'
              />
              {/* <Label check>
                <Input
                  type='checkbox'
                  placeholder='active'
                  checked={props.template.active}
                  onChange={(e) => props._handleOnChange('active', e.target.checked)}
                />{' '}
                Active
              </Label> */}
            </FormGroup>
            <FormGroup>
              <CustomInput
                type='checkbox'
                placeholder='active'
                checked={props.template.requiresSecondaryDisk}
                onChange={(e) => props._handleOnChange('requiresSecondaryDisk', e.target.checked)}
                id='SecondDisk'
                label=' Required Second Disk'
              />

              {/* <Label check>
                <Input
                  type='checkbox'
                  placeholder='active'
                  checked={props.template.requiresSecondaryDisk}
                  onChange={(e) => props._handleOnChange('requiresSecondaryDisk', e.target.checked)}
                />{' '}
                Required Second Disk
              </Label> */}
            </FormGroup>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md='12'>
          {
            // eslint-disable-next-line
            props.template.baseBin === 'docker' ? (
              <FormGroup>
                <Label>
                  {' '}
                  Docker Images<sup>*</sup>
                </Label>
                <Input
                  type='text'
                  placeholder='Enter Docker Images and Press Enter Key'
                  // value={props.template.dockerimage}
                  value={props.template.dockertext}
                  // onChange={(e) =>
                  //   this._handleOnChange("dockerimage", e.target.value)
                  // }
                  onChange={props.onDockerChange}
                  onKeyPress={(e) =>
                    e.key === 'Enter' &&
                    e.target.value.trim() !== '' &&
                    props._handleOnChange('dockerimage', e.target.value)
                  }>
                  {' '}
                  {/* <option>Select</option>
                  {props.imageList &&
                    props.imageList.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))} */}
                </Input>
                {errors && <div className='validation-error'>{errors.dockerimage}</div>}
                {props.template.dockerimage.map((each) => (
                  <Badge color='secondary'>
                    {each}
                    <Button color='link'>
                      <i
                        className='fa fa-times-circle text-danger'
                        onClick={() => props._handleOnDockerDelete(each)}></i>
                    </Button>
                  </Badge>
                ))}
              </FormGroup>
            ) : (
              <div>
                <FormGroup>
                  <Label>
                    {' '}
                    VM Images<sup>*</sup>
                  </Label>
                  <Input
                    type='select'
                    placeholder='VM Images'
                    value={props.template.vmimage}
                    onChange={(e) => props._handleOnChange('vmimage', e.target.value)}>
                    <option>Select</option>
                    {/* <option>Option 1</option>
                            <option>Option 2</option> */}
                    {props.imageList && props.imageList.map((item) => <option value={item.id}>{item.name}</option>)}
                  </Input>

                  {errors && <div className='validation-error'>{errors.vmimage}</div>}

                  {props.template.vmimage &&
                    props.imageList
                      .filter((item) =>
                        // eslint-disable-next-line
                        props.template.vmimage.some((each) => item.id === each)
                      )
                      .map((each) => (
                        <Badge color='secondary'>
                          {each.name}
                          <Button color='link'>
                            <i
                              className='fa fa-times-circle text-danger'
                              onClick={() => props._handleOnDelete(each.id)}></i>
                          </Button>
                        </Badge>
                      ))}
                </FormGroup>

                {props.template.vmimage &&
                  props.template.vmimage.forEach((each) => props.imageList.map((item) => item.id === each))}
                {/* <Badge color="secondary">
                  Image 1
                  <Button color="link">
                    <i
                      className="fa fa-times-circle text-danger"
                    // onClick={() => this._handleOnDelete(each.id)}
                    ></i>
                  </Button>
                </Badge> */}
              </div>
            )
          }
        </Col>
      </Row>
      <Row>
        <Col md='6'>
          <FormGroup>
            <Label>Default Icon</Label>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className='imageDropdown'>
              <DropdownToggle caret>
                {(() => {
                  return (
                    <div>
                      {currentSelectedIcon.url && <img src={currentSelectedIcon.url} alt='icon' />}
                      {currentSelectedIcon.name || 'Select'}
                    </div>
                  );
                })()}
              </DropdownToggle>
              <DropdownMenu>
                {props.iconList.map((each, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => {
                      props._handleOnChange('defaultIcon', each._id);
                      setCurrentSelectedIcon(each);
                    }}>
                    <img src={each.url} alt='icon' />
                    {each.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            {/* <Input
              type="select"
              placeholder="Name"
              onChange={(e) =>
                this._handleOnChange("defaulticon", e.target.value)
              }
            >
              {props.iconList.map(item => (
                <option>{item.name}</option>
              ))}

            </Input> */}
          </FormGroup>
        </Col>
        <Col md='6'>
          <FormGroup>
            <Label>
              Default{' '}
              {
                // eslint-disable-next-line
                props.template.baseBin === 'docker' ? 'Docker' : 'VM'
              }{' '}
              Image
            </Label>
            <Input
              type='select'
              placeholder='Default Vm Image'
              // value={user.name}
              value={props.template.defaultvmimage}
              onChange={(e) => props._handleOnChange('defaultvmimage', e.target.value)}>
              <option>Select</option>
              {props.imageList
                .filter((item) =>
                  // eslint-disable-next-line
                  props.template.vmimage.some((each) => item.id === each)
                )
                .map((each) => (
                  <option value={each.id}>{each.name}</option>
                ))}

              {props.template.dockerimage.map((each) => (
                <option value={each}>{each}</option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={12} className='text-right'>
          <Button className='addBtn' onClick={_goNext}>
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default StepFirst;
