import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
// import config from "../../../../config";
import { deepClone } from "../../../../helper-methods";
import isCidr from "is-cidr";
import config from "../../../../config";

class AddDockerConnectionModal extends Component {
  state = {
    fromPorts:[],
    toPorts:[],
    connection: {
      fromIpAddress: "",
      toIpAddress: "",
      fromPort: "",
      toPort: "",
      lineType: "line",
      color: "#89bcde", // blue
      strokeType: "solid"
    },
    isDirty:{
      fromIpAddress: false,
      toIpAddress: false,
      fromPort: false,
      toPort: false,
    },
    errors: {},
    fromNode: {},
    toNode: {}
  };

  _handleOnChange = (field, value)=>{
    const { connection, isDirty } = deepClone(this.state);
    connection[field] = value;
    isDirty[field] = true;
    this.setState({ connection, isDirty },()=>{
      this._validateForm();
    });
  }

  _validateForm = ()=>{
    const { connection, isDirty, errors, fromNode, toNode } = this.state;
    // const { data } = deepClone(this.props);    
    // const ipRegex = new RegExp("^(([1-9]?\\d|1\\d\\d|2[0-5][0-5]|2[0-4]\\d)\\.){3}([1-9]?\\d|1\\d\\d|2[0-5][0-5]|2[0-4]\\d)$");
    const isFromNodeDocker = fromNode.properties.isDockerNode;
    const isToNodeDocker = toNode.properties.isDockerNode;
    Object.keys(connection).forEach((each) => {
      if (each === "fromPort" && isDirty.fromPort) {
        if (!connection.fromPort.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.fromPort = false;
        }
      } else if (each === "toPort" && isDirty.toPort) {
        if (!connection.toPort.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.toPort = false;
        }
      }
      else if (each === "fromIpAddress" && isDirty.fromIpAddress) {
        if (!connection.fromIpAddress.trim().length && isFromNodeDocker) {
          errors[each] = "*Required";
        } else if(connection.fromIpAddress && 
            !isCidr(connection.fromIpAddress)
          ){
            console.log('1 :>> ', 1);
            errors[each] = "*Enter a Valid IP Address with cidr notation";
        }
         else {
          delete errors[each];
          isDirty.fromIpAddress = false;
        }
      } else if (each === "toIpAddress" && isDirty.toIpAddress) {
        if (!connection.toIpAddress.trim().length && isToNodeDocker) {
          errors[each] = "*Required";
        } else if(connection.toIpAddress && 
          !isCidr(connection.toIpAddress)
        ){
          errors[each] = "*Enter a Valid IP Address with cidr notation";
      } else {
          delete errors[each];
          isDirty.toIpAddress = false;
        }
      }
    })
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  _handleOnSubmit = ()=>{
    const { isDirty, connection } = deepClone(this.state);
    isDirty.fromIpAddress = true;
    isDirty.toIpAddress = true;
    isDirty.fromPort = true;
    isDirty.toPort = true;
    this.setState({ isDirty },()=>{
      let errors = this._validateForm();
      if(!errors){
        this.props.toggle('save', connection);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.data) {
        let fromNode = this.props.data?.chartData.nodes.find((e)=>e.id===this.props.data?.linkData.sourceId);
        let toNode = this.props.data?.chartData.nodes.find((e)=>e.id===this.props.data?.linkData.targetId);
        const fromPortList = this._generatePorts(fromNode);
        const toPortList = this._generatePorts(toNode);
        let newState = {         
          fromPorts: fromPortList,
          toPorts: toPortList,
          connection: {
            fromIpAddress: "",
            toIpAddress: "",
            fromPort: this._checkPortUsed(fromPortList, 'port1') ? "" : "port1", 
            toPort: this._checkPortUsed(toPortList, 'port1') ? "" : "port1",
            lineType: "line",
            color: "#89bcde", // blue
            strokeType: "solid"
          },
          isDirty: {
            fromIpAddress: false,
            toIpAddress: false,
            fromPort: false,
            toPort: false,
          },
          errors: {},
          fromNode,
          toNode
        };
        this.setState(newState);
      }
    }
  }

  _generatePorts = (nodeData)=>{
    return Object.keys(nodeData.properties.ports).map((each)=>{
      return {
        label: nodeData.properties.ports[each].properties.name,
        // 'Port ' + nodeData.properties.ports[each].properties.ethernetPortNo,
        value: each,
        isUsed: nodeData.properties.ports[each].properties.isUsed
      }
    });
  }

  _checkPortUsed = (portList, portId)=>{
    let data = portList.find((e)=>e.value===portId && e.isUsed);
    return data;
  }

  render() {
    const { connection, errors, fromPorts, toPorts, fromNode, toNode } = deepClone(this.state);
    const { data } = deepClone(this.props);   
    const isFromNodeDocker = fromNode?.properties?.isDockerNode;
    const isToNodeDocker = toNode?.properties?.isDockerNode; 
    return (
      <Modal
        size={"md"}
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle("close")}
      >
        <ModalHeader toggle={() => this.props.toggle("close")}>
          Add Connection
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
        >
          {data?.chartData && fromNode && toNode && <div className="connection-info-wrap">
            <div className="info">
              <img src={fromNode?.properties?.icon} alt="from-icon" width="50"/>
              <div>{fromNode?.properties?.name}</div>
              { fromNode?.properties?.isDockerNode &&
                <img src={require('../../../../assets/img/docker.png').default} alt="docker-img" style={{
                  right: 30,
                  top: -10,
                  position: 'absolute',
                  width: 22
                }}/>
              }
            </div>
            <div className="info">
              <i className="fa fa-arrows-h"></i>
            </div>
            <div className="info">
              <img src={toNode?.properties?.icon} alt="from-icon" width="50"/>
                <div>{toNode?.properties?.name}</div>
                { toNode?.properties?.isDockerNode &&
                <img src={require('../../../../assets/img/docker.png').default} alt="docker-img" style={{
                  right: 30,
                  top: -10,
                  position: 'absolute',
                  width: 22
                }}/>
              }
              </div>
          </div>}
          <div className="port-form-wrap">          
            <FormGroup>
              <Label>From Port</Label>
              <select className="form-control" value={connection.fromPort}
                onChange={(e) => this._handleOnChange("fromPort", e.target.value)}>
                <option value="">Select ETH Port</option>
                {React.Children.toArray(fromPorts.map((each)=>
                  <option value={each.value} disabled={each.isUsed}>{each.label}</option>
                ))}
              </select>
              {errors && (
                <div className="validation-error">{errors.fromPort}</div>
              )}
            </FormGroup>
            <FormGroup>
            <Label>To Port</Label>
            <select className="form-control" value={connection.toPort}
                onChange={(e) => this._handleOnChange("toPort", e.target.value)}>
                <option value="">Select ETH Port</option>
                {React.Children.toArray(toPorts.map((each)=>
                  <option value={each.value} disabled={each.isUsed}>{each.label}</option>
                ))}
              </select>
            {errors && <div className="validation-error">{errors.toPort}</div>}
          </FormGroup>
          </div> 
          <div className="port-form-wrap">      
            {isFromNodeDocker && <FormGroup>
              <Label>{isFromNodeDocker && isToNodeDocker && 'From'} Docker IP Address</Label>
              <Input
                type="test"
                placeholder="Docker IP Address"
                value={connection.fromIpAddress}
                onChange={(e) =>
                  this._handleOnChange("fromIpAddress", e.target.value)
                }
              />
              {errors && (
                <div className="validation-error">{errors.fromIpAddress}</div>
              )}
            </FormGroup>}
            {isToNodeDocker && <FormGroup>
              <Label>{isFromNodeDocker && isToNodeDocker && 'To'} Docker IP Address</Label>
              <Input
                type="test"
                placeholder="Docker IP Address"
                value={connection.toIpAddress}
                onChange={(e) => this._handleOnChange("toIpAddress", e.target.value)}
              />
              {errors && <div className="validation-error">{errors.toIpAddress}</div>}
            </FormGroup>}
          </div>
          <div>
            <FormGroup>
              <Label>Link Type</Label>
              <Input
                type="select"
                placeholder="Select line type"
                value={connection.lineType}
                onChange={(e) =>
                  this._handleOnChange("lineType", e.target.value)
                }
              >
                {React.Children.toArray(
                  config.connectionLineTypes.map((each) => <option value={each.value}>{each.label}</option>)
                )}
              </Input>
              {/* {errors && <div className="validation-error">{errors.name}</div>} */}
            </FormGroup>
            <FormGroup>
              <Label>
                Link Color
                <div className="color-preview" style={{backgroundColor: connection.color || '#222'}}></div>
              </Label>
              <Input
                type="select"
                placeholder="Select line color"
                value={connection.color}
                onChange={(e) =>
                  this._handleOnChange("color", e.target.value)
                }
              >
                
                {React.Children.toArray(
                  config.connectionColors.map((each) => <option value={each.value}>{each.label}</option>)
                )}
              </Input>
              {/* {errors && <div className="validation-error">{errors.name}</div>} */}
            </FormGroup>
            <FormGroup>          
              <Label>
                Link Stroke Type                
              </Label>
              <Input
                type="select"
                placeholder="Select stroke type"
                value={connection.strokeType}
                onChange={(e) =>
                  this._handleOnChange("strokeType", e.target.value)
                }
              >
                
                <option value="solid">Solid</option>
                <option value="dashed">Dashed</option>
              </Input>
              {/* {errors && <div className="validation-error">{errors.name}</div>} */}
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="modalBtnCancel"
            onClick={() => this.props.toggle("close")}
          >
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={(e) => this._handleOnSubmit(e)}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddDockerConnectionModal;
