import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup
} from "reactstrap";
import { sendSupportRequest } from "../../../http/http-calls";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { ToastsStore } from "react-toasts";

class ContactUs extends Component {
  state = {
    requestData: {
      subject: '',
      details: ''
    },
    isDirty: {
      subject: false,
      details: false
    },
    errors: {}
  };

  _closeModal = (action) => {
    console.log("in _closeModal");
  };

  componentDidMount() {
    console.log("componentDidMount", this.props);
  }

  _handleToggle = () => {
    this.setState({
      requestData: {
        subject: '',
        details: ''
      },
      isDirty: {
        subject: false,
        details: false
      },
      errors: {}
    });
    this.props.toggle();
  }

  _handleOnChange = (field, value) => {
    const { requestData, isDirty } = this.state;
    requestData[field] = value;
    isDirty[field] = true;
    this.setState({ requestData, isDirty }, () => {
      this._validateField();
    });
  }

  _validateField = () => {
    const { requestData, isDirty, errors } = this.state;
    Object.keys(requestData).forEach((each) => {
      if (each === "subject" && isDirty.subject) {
        if (requestData.subject === "Select" 
          || requestData.subject === ""
        ) {
          errors[each] = "Select an issue";
        } else {
          delete errors[each];
          isDirty.subject = false;
        }
      } else if (each === "details" && isDirty.details) {
        if (!requestData.details.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.subject = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  _sendSupportRequest = (e) => {
    e.preventDefault();
    let isDirty = {
      subject: true,
      details: true
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateField();
      if (!errors) {
        let supportReqData = {
          subject: this.state.requestData.subject,
          content: this.state.requestData.details
        };
        this.props.showLoader();
        sendSupportRequest(supportReqData).then((resp) => {
          console.log("Support request response here :- ", resp);
          this.props.hideLoader();
          ToastsStore.success("Support request sent successfully");
          this._handleToggle();
        }).catch((err) => {
          this.props.hideLoader();
          ToastsStore.error("Sending support request failed :- " + err.reason);
        });
      }
    });
  }

  render() {
    const { requestData, errors } = this.state;
    // console.log('from modal', this.props);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._handleToggle()}
      >
        <ModalHeader toggle={() => this._handleToggle()}>
          Contact Us
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Subject</Label>
            <Input type="select"
              value={requestData.subject}
              onChange={(e) => this._handleOnChange("subject", e.target.value)}
            >
              <option>Select</option>
              <option>Issue 1</option>
              <option>Issue 2</option>
            </Input>
            {errors && (
              <div className="validation-error">
                {errors.subject}
              </div>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Details</Label>
            <Input type="textarea" rows="3" placeholder="Enter details"
              value={requestData.details}
              onChange={(e) => this._handleOnChange("details", e.target.value)}
            />
            {errors && (
              <div className="validation-error">
                {errors.details}
              </div>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            className="modalBtnCancel"
            onClick={() => this._handleToggle()}
          >
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={(e) => this._sendSupportRequest(e)}
          >
            Send
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
