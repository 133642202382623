/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from './modules/private/pages/Dashboard';
import Users from './modules/private/pages/users';
import Teams from './modules/private/pages/teams';
import Licenses from './modules/private/pages/licenses';
import Labs from './modules/private/pages/labs';
// import VirtualImages from "./modules/private/pages/virtual-images";
import Uploads from './modules/private/pages/uploads';
import MyProfile from './modules/private/pages/my-profile';
import SystemControl from './modules/private/pages/system-control';
import AppInfo from './modules/private/pages/app-info';
import TemplateLibrary from './modules/private/components/systemcontrol/template-library.jsx'
import templateCreation from './modules/private/pages/templateCreation';
import Chat from './modules/private/pages/chat.jsx';
import Terminal from './modules/private/pages/Terminal';

var routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fa fa-area-chart',
    component: Dashboard,
    isOnlyAdminRoute: false
  },
  {
    path: '/sanboxes',
    name: 'Sandboxes',
    icon: 'fa fa-flask',
    component: Labs,
    isOnlyAdminRoute: false
  },
  {
    path: "/template-library",
    name: "Template Library",
    icon: "fa fa-clone",
    component: TemplateLibrary
  },
  {
    path: '/uploads',
    name: 'Uploads',
    icon: 'fa fa-cloud-upload',
    component: Uploads,
    isOnlyAdminRoute: false
  },
  {
    path: '/users',
    name: 'users',
    icon: 'fa fa-user-plus',
    component: Users,
    isOnlyAdminRoute: true
  },
  {
    path: '/teams',
    name: 'teams',
    icon: 'fa fa-users',
    component: Teams,
    isOnlyAdminRoute: true
  },
  {
    path: '/licenses',
    name: 'Access Keys',
    icon: 'fa fa-shield',
    component: Licenses,
    isOnlyAdminRoute: true
  },
  {
    path: '/my-profile',
    name: 'My Profile',
    // icon: "fa fa-user",
    icon: 'fa fa-id-badge',
    component: MyProfile,
    isOnlyAdminRoute: false
    // isImage: true
  },
  {
    path: '/systemcontrol',
    name: 'System Control',
    icon: 'fa fa-cogs',
    component: SystemControl,
    isOnlyAdminRoute: true
  },
  // {
  //   path: '/templateCreation',
  //   name: 'Template Creation',
  //   icon: 'fa fa-circle',
  //   component: templateCreation,
  //   isOnlyAdminRoute: true
  // },
  {
    path: '/appinfo',
    name: 'App Info',
    icon: 'fa fa-codepen',
    component: AppInfo,
    isOnlyAdminRoute: true
  }
];
export default routes;
