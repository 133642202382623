import React, { useEffect, useState } from 'react';
import { Col, Card, CardHeader, Input, Button, CardBody, Table, Badge, Row } from 'reactstrap';
import {
  getAllTemplates,
  installTemplates,
  getAllvmTemplates,
  getTemplate,
  editTemplate,
  deleteTemplate,
} from '../../../../http/http-calls.js';

import DeleteTemplateConfirmationModal from './delete-template-modal.jsx';

import TemplateEditModal from './template-edit-modal';
import TemplateJSONView from './template-json-view.jsx';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../../redux/actions/loader-data.js';
import { ToastsStore } from 'react-toasts';
import CardImg from 'reactstrap/lib/CardImg';
import CardTitle from 'reactstrap/lib/CardTitle';

import swal from 'sweetalert';

import './templateLibrary.css';

import defaultvm from '../../../../assets/img/template-library/defaultvm.png';
import aruba from '../../../../assets/img/template-library/aruba.png';
import cisco from '../../../../assets/img/template-library/cisco.png';
import fortinet from '../../../../assets/img/template-library/fortinet.png';
import juniper from '../../../../assets/img/template-library/juniper.png';
import kali from '../../../../assets/img/template-library/kali.png';
import linux from '../../../../assets/img/template-library/linux.png';
import nvidia from '../../../../assets/img/template-library/nvidia.png';
import palto from '../../../../assets/img/template-library/palto.png';
import pfsense from '../../../../assets/img/template-library/pfsense.png';
import redhat from '../../../../assets/img/template-library/redhat.png';
import splunk from '../../../../assets/img/template-library/splunk.png';
import ubuntu from '../../../../assets/img/template-library/ubuntu.png';
import vyos from '../../../../assets/img/template-library/vyos.png';
import windows from '../../../../assets/img/template-library/windows.png';
import axios from 'axios';
import config from '../../../../config/index.js';
import { toast } from 'react-toastify';

const imageMap = {
  aruba,
  cisco,
  fortinet,
  juniper,
  kali,
  linux,
  nvidia,
  palto,
  pfsense,
  redhat,
  splunk,
  ubuntu,
  vyos,
  windows,
};

const TemplateLibrary = (props) => {
  const [templates, setTemplates] = useState([]);
  const [checkedTemplates, setCheckedTemplates] = useState([]);
  const [isInstallLock, setIsInstallLock] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [modalSelected, setModalSelected] = useState({ data: {}, index: null, ifInstalled: false });
  const [templateMapping, setTemplateMapping] = useState([]);
  const [installedTemplates, setInstalledTemplates] = useState([]);
  const [imageToShowForModal, setImageToShowForModal] = useState([]);

  useEffect(() => {
    _fetchTemplates();
    _installedTemplates();
  }, []);

  const _fetchTemplates = () => {
    props.showLoader();
    getAllTemplates()
      .then((response) => {
        let arr = new Array(response.templates.length).fill(false);
        setCheckedTemplates(arr);
        setTemplates(response.templates);
        props.hideLoader();
      })
      .catch((err) => {
        props.hideLoader();
      });
  };

  const _installedTemplates = () => {
    props.showLoader();
    getAllvmTemplates()
      .then((response) => {
        // console.log('installed templates ===> ', response);
        setInstalledTemplates(response.vmTemplates);
        const map = response.vmTemplates.map((each) => each.name);
        setTemplateMapping(map);
        props.hideLoader();
      })
      .catch((error) => {
        props.hideLoader();
        // console.log(error);
      });
  };

  // const _fetchLocalTemplates = () => {
  //     getAllTemplates({}, ).then(response => {
  //         console.log(response)
  //     }).catch(error => { console.log(error) });
  // }

  const _filterList = () => { };

  const _install = () => {
    props.showLoader();
    let payload = [];
    templates.forEach((el, index) => {
      if (checkedTemplates[index]) {
        payload.push(el);
      }
    });

    installTemplates({ templates: payload })
      .then((response) => {
        props.hideLoader();
      })
      .catch((err) => {
        props.hideLoader();
        // console.log(err);
      });
  };

  useEffect(() => {
    const checkedTemplate = checkedTemplates.filter((el) => el);
    if (checkedTemplate.length) setIsInstallLock(false);
    else setIsInstallLock(true);
  }, [checkedTemplates]);
  /*
        const _onCheck = (event, index) => {
            setCheckedTemplates(prev => {
                prev[index] = !prev[index]
                return [...prev]
            })
        }
    */
  const _onRowClick = (data, index, ifInstalled) => {
    setModalSelected({ data, index, ifInstalled });
    setIsOpen(true);
  };

  const _toggle = () => {
    setIsOpen(false);
  };

  const _onInstall = (data, imagesToShow) => {
    data.imagesToShow = imagesToShow;
    if (data._id) {
      delete data._id;
    }
    installTemplates({ templates: [data] })
      .then((response) => {
        ToastsStore.success('Template Installed');
        // console.log(response);
        setIsOpen(false);
      })
      .catch((error) => {
        ToastsStore.error(error.message);
        setIsOpen(false);
      });
  };

  const onChangeField = (value, fieldIndex) => {
    setTemplates((prev) => {
      prev[modalSelected.index].fieldsToShow[fieldIndex].defaultValue = value;
      return [...prev];
    });
  };

  const deleteTemplateHandler = (id) => {
    setTimeout(() => {
      swal('Warning!', 'Are you sure you want to delete this template', 'warning');
    }, 500);

    // deleteTemplate(id);
    props.showLoader();
  };

  installedTemplates.sort((a, b) => a.name.localeCompare(b.name));

  const [shouldReload, setShouldReload] = useState('initial state');

  const handleReload = (newState) => {
    setShouldReload(newState);
  };

  const _onFileSelect = async (event) => {
    if (event.target.files) {
      try {
        props.showLoader();

        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
          const fileContent = e.target.result;
          console.log('File Content:', fileContent);


          const jsonData = JSON.parse(fileContent);
          console.log('Parsed JSON Data:', jsonData);


          let cookies = document.cookie;
          let cookieArray = cookies.split('; ');
          let authToken;

          for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            let [name, value] = cookie.split('=');
            if (name === 'bearer') {
              authToken = value
            }
          }

          const res = await axios({
            method: 'POST',
            url: `${config.baseUrl}vmTemplate`,
            withCredentials: true,
            // transformRequest: (formData) => formData,

            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
            data: jsonData,
          });

          toast.success(`Successfully uploaded a new License file`, {
            position: toast.POSITION.TOP_RIGHT,
          });

          props.hideLoader();
        };

        // Read the file as text
        reader.readAsText(file);
      } catch (error) {
        props.showLoader();
        toast.error(`Error while uploading the license file ${error}`, {
          position: toast.POSITION.TOP_RIGHT,
        });

        props.hideLoader();
      }
    }
  };


  return (
    <>
      <Col xs='12' className='content'>
        <Card style={{ boxShadow: 'none' }}>
          <CardHeader className='d-flex justify-content-between p-0'>
            <Input
              type='text'
              placeholder='Search...'
              style={{ width: 180 }}
              onChange={(e) => _filterList(e.target.value)}
            />
            {!isInstallLock && (
              <Button className='addBtn ml-2' onClick={() => _install()}>
                Install
              </Button>
            )}
            <Button className='viewBtn import-btn'>
              <input type='file' className='file-input' accept='.json' onChange={(e) => _onFileSelect(e)} />
              Import
            </Button>
          </CardHeader>
        </Card>
      </Col>


      <Row className='LibraryColumn container-TL content'>
        {installedTemplates.map((el, index) => (
          <div className='feature-TL'>
            <Col className=''>
              <Card className='cardLibrary labCard-Lib item-TL'>
                <CardBody className='text-center'>
                  <span class='icon-pencil icon-3x icon' id='phonewrap' onClick={() => _onRowClick(el, index)}></span>
                  <TemplateJSONView el={el} />
                  <CardImg
                    className='lib-imgWidth'
                    top
                    src={`${imageMap[el.name.toLowerCase().split(' ')[0]] || defaultvm}`}
                    alt='Card image cap'
                  />
                  <CardTitle className='libraryName'>
                    <span style={{ margin: '0 auto' }}>{el.name}</span>
                  </CardTitle>
                  {templateMapping.includes(el.name) ? <Badge>Installed</Badge> : ''}

                  <DeleteTemplateConfirmationModal onReload={handleReload} id={el._id} />
                </CardBody>
              </Card>
            </Col>
          </div>
        ))}
      </Row>
      <TemplateEditModal
        imagesToShowProp={imageToShowForModal}
        isOpen={isOpen}
        toggle={_toggle}
        data={modalSelected.data}
        ifInstalled={modalSelected.ifInstalled}
        onInstall={_onInstall}
        onChangeField={onChangeField}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateLibrary);
// New Code
