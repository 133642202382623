import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Input, Label, FormGroup } from 'reactstrap';
// import config from "../../../../config";
import { deepClone } from '../../../../helper-methods';
import Select from 'react-dropdown-select';
import styled from 'styled-components';
import { defaultIcons } from '../../../../config/index';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const StyledOption = styled.span`
  padding: 0;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  transition: all 1s ease-in;

  span {
    display: none;
    transition: all 1s ease-in;
  }

  a {
    margin: 0 5px;
  }

  :hover {
    background: #f2f2f2;

    span {
      display: inline;
      margin: 0 0 0 5px;
      color: red;
    }
  }
`;

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;

  :hover {
    background: #f2f2f2;
  }
`;
class AddVirtualMachineModal extends Component {
  state = {
    machine: {
      name: '',
      description: '',
      iconId: '',
      labId: '',
      cpu: '',
      ram: '',
      ethernets: '',
      templateId: '',
      vmImageId: '',
      delay: '',
      left: 100,
      top: 100,
      count: 1
    },
    selectedTemplate: {},
    isDirty: {
      name: false,
      iconId: false,
      templateId: false,
      vmImageId: false,
      left: false,
      top: false,
      count: false
    },
    errors: {},
    selectedFile: null,
    selectedIcons: [],
    showAdvancedParameters: false
  };

  componentDidMount() {
    const { machine } = deepClone(this.state);
    machine.labId = this.props.match.params.id;
    this.setState({ machine });
  }

  _handleOnChange = (field, value) => {
    let { machine, selectedTemplate, isDirty, selectedIcons } = deepClone(this.state);
    const { data } = deepClone(this.props);
    isDirty[field] = true;
    if (field === 'templateId') {
      if (value) {
        machine.templateId = value;
        selectedTemplate = data.vmTemplates.find((e) => e._id === value);
        console.log('selectedTemplate :>> ', selectedTemplate);
        if (selectedTemplate.requiresSecondaryDisk) {
          machine.secondaryVMImageId = '';
          isDirty.secondaryVMImageId = false;
        }
        selectedTemplate.fieldsToShow.forEach((each) => {
          machine[each.fieldName] = each.defaultValue;
        });
        machine.vmImageId = selectedTemplate?._defaultVMImage?.id || '';
        selectedIcons = selectedTemplate?._defaultIcon?.id
          ? this.props?.data.iconList.filter((e) => e.id === selectedTemplate?._defaultIcon?.id)
          : [];
      } else {
        machine.templateId = value;
        selectedTemplate = {};
      }
      this.setState({ machine, selectedTemplate, isDirty, selectedIcons }, () => {
        this._validateFields();
      });
    } else {
      machine[field] = value;
      this.setState({ machine, isDirty }, () => {
        this._validateFields();
      });
    }
  };

  _validateFields = () => {
    const { machine, isDirty, errors, selectedIcons, selectedTemplate } = this.state;
    Object.keys(machine).forEach((each) => {
      if (each === 'name' && isDirty.name) {
        if (!machine.name.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.name = false;
        }
      } else if (each === 'templateId' && isDirty.templateId) {
        if (!machine.templateId.trim().length) {
          errors[each] = '*Select a template';
        } else {
          delete errors[each];
          isDirty.templateId = false;
        }
      } else if (each === 'vmImageId' && isDirty.vmImageId) {
        if (!machine.vmImageId.trim().length) {
          errors[each] = '*Selct a vmImage';
        } else {
          delete errors[each];
          isDirty.vmImageId = false;
        }
      } else if (each === 'secondaryVMImageId' && isDirty.secondaryVMImageId) {
        if (!machine.secondaryVMImageId.trim().length && selectedTemplate.requiresSecondaryDisk) {
          errors[each] = '*Selct a Secondary vmImage';
        } else {
          delete errors[each];
          isDirty.secondaryVMImageId = false;
        }
      } else if (each === 'iconId' && isDirty.iconId) {
        if (!selectedIcons.length) {
          errors[each] = '*Selct an Icon';
        } else {
          delete errors[each];
          isDirty.iconId = false;
        }
      } else if (each === 'left' && isDirty.left) {
        if (machine.left.length && isNaN(machine.left)) {
          errors[each] = 'Should be Number';
        } else {
          delete errors[each];
          isDirty.left = false;
        }
      } else if (each === 'top' && isDirty.top) {
        if (machine.top.length && isNaN(machine.top)) {
          errors[each] = 'Should be Number';
        } else {
          delete errors[each];
          isDirty.top = false;
        }
      } else if (each === 'count' && isDirty.count) {
        if (!machine.count.toString().length) {
          errors[each] = '*Required';
        } else if (machine.count.length && isNaN(machine.count)) {
          errors[each] = 'Should be Number';
        } else if (machine.count.length && !isNaN(machine.count) && (machine.count <= 0 || machine.count > 10)) {
          errors[each] = 'Number should be between 1 to 10';
        } else {
          delete errors[each];
          isDirty.count = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  _customOptionRenderer = ({ item, props, state, methods }) => {
    return (
      <StyledOption>
        <img src={item.url} width={40} alt={item.name} />
        &nbsp;
        {item.name}
        <span
          onClick={() => {
            methods.removeItem(null, item);
            this.setState({ selectedIcons: [] }, () => {
              this._validateFields();
            });
          }}
        >
          &times;
        </span>
      </StyledOption>
    );
  };

  _customItemRenderer = ({ item, itemIndex, props, state, methods }) => {
    const { selectedIcons } = deepClone(this.state);

    return (
      <StyledItem>
        <div
          onClick={() => {
            console.info('selectedIcons :>> ', item);
            methods.addItem(item);
            const { isDirty } = deepClone(this.state);
            isDirty.iconId = true;
            const isAlreadyPresent = selectedIcons.length && selectedIcons[0].id === item.id;
            this.setState(
              {
                selectedIcons: isAlreadyPresent ? [] : [item],
                selectedFile: null,
                previewImg: null,
                isDirty
              },
              () => {
                this._validateFields();
              }
            );
          }}>
          {/* Default Icons will always show */}
          {/* {defaultIcons.map((icon) => { */}
          {/* return ( */}
          {/* <> */}
          {/* <img src={`/assets/icons/${icon}`} width={30} alt='default icon' /> */}
          {/* {icon.split('_')[0]} */}
          {/* {console.info(icon.split('_')[0])} */}
          {/* <br />{' '} */}
          {/* </> */}
          {/* ); */}
          {/* })} */}
          {item.isFirstDefaultIcon && <div className='custom-select-section'>System Icons</div>}
          {item.isFirstIcon && <div className='custom-select-section'>Custom Icons</div>}
          <img src={item.url} width={30} alt={item.name} /> &nbsp;
          {item.name}
        </div>
      </StyledItem>
    );
  };

  _inputType = (field) => {
    switch (field.dataType) {
      case 'String':
        return 'text';
      case 'Number':
        return 'number';
      case 'Boolean':
        return 'checkbox';
      default:
        return 'text';
    }
  };

  _handleOnSubmit = async () => {
    try {
      const status = await axios.get('/ronin/license-status/');
    } catch (error) {
      this.props.hideLoader();
      toast.error(`Your license has expired. New virtual machines cannot be added.`, {
        position: toast.POSITION.TOP_RIGHT
      });
      this.props.toggle('close');
      return;
    }

    // if (status.data.license_created) {
    const { machine, selectedTemplate, selectedIcons } = deepClone(this.state);
    let isDirty = {
      name: true,
      iconId: true,
      templateId: true,
      vmImageId: true,
      left: true,
      top: true,
      secondaryVMImageId: true,
      count: true
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateFields();
      if (!errors) {
        let dataToSend = {
          name: machine.name,
          description: machine.description,
          iconId: selectedIcons[0].id,
          iconURL: selectedIcons[0].url,
          labId: machine.labId,
          cpu: Number(machine.vcpus),
          ram: Number(machine.ram),
          ethernets: Number(machine.ethernets),
          delay: Number(machine.delay),
          templateId: machine.templateId,
          vmImageId: machine.vmImageId,
          dataFromTemplate: {},
          position: {
            x: machine.left ? Number(machine.left) : 100,
            y: machine.top ? Number(machine.top) : 100
          },
          count: machine.count
        };
        if (selectedTemplate.requiresSecondaryDisk) {
          dataToSend.secondaryVMImageId = machine.secondaryVMImageId;
        }
        selectedTemplate.fieldsToShow.forEach((each) => {
          dataToSend.dataFromTemplate[each.fieldName] = machine[each.fieldName];
        });
        console.log('dataToSend :>> ', dataToSend);
        this.props.toggle('save', dataToSend);
      }
    });
    // } else {
    //   this.props.hideLoader();
    //   toast.error(`Your license has expired. New virtual machines cannot be added.`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   this.props.toggle('close');
    // }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      let state = {
        machine: {
          name: '',
          description: '',
          iconId: '',
          labId: this.props.match.params.id,
          cpu: '',
          ram: '',
          ethernets: '',
          templateId: '',
          vmImageId: '',
          delay: '',
          left: 100,
          top: 100,
          count: 1
        },
        selectedTemplate: {},
        isDirty: {
          name: false,
          iconId: false,
          templateId: false,
          vmImageId: false,
          left: false,
          top: false,
          count: false
        },
        errors: {},
        selectedFile: null,
        selectedIcons: [],
        showAdvancedParameters: false
      };
      this.setState(state);
    }
  }

  _checkFieldCanbeShown = (each) => {
    const { showAdvancedParameters } = deepClone(this.state);
    const fieldKeys = ['ethernets', 'vcpus', 'ram'];
    if (fieldKeys.includes(each.fieldName)) {
      return true; // Always show
    } else {
      return showAdvancedParameters; // Show if 'Show Advanced Parameters' checkbox is checked
    }
  };

  _onAdvParamsToggle = () => {
    const { showAdvancedParameters } = deepClone(this.state);
    this.setState({ showAdvancedParameters: !showAdvancedParameters });
  };

  render() {
    const { machine, errors, selectedTemplate, showAdvancedParameters } = deepClone(this.state);
    const { data } = deepClone(this.props);
    return (
      <Modal size={'lg'} isOpen={this.props.isOpen} toggle={() => this.props.toggle('close')}>
        <ModalHeader toggle={() => this.props.toggle('close')}>
          <p className='text-white'>Add New Virtual Machine</p>
        </ModalHeader>
        <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }} className='innerForm'>
          <FormGroup>
            <Label>Template</Label>
            <Input
              type='select'
              placeholder='Select Template'
              value={machine.templateId}
              onChange={(e) => this._handleOnChange('templateId', e.target.value)}>
              <option value=''>Select Template</option>
              {React.Children.toArray(data?.vmTemplates?.map((each) => <option value={each._id}>{each.name}</option>))}
            </Input>
            {errors && <div className='validation-error'>{errors.templateId}</div>}
          </FormGroup>
          {machine.templateId && (
            <div>
              <FormGroup>
                <Label>Number of Nodes to add</Label>
                <Input
                  type='number'
                  placeholder='Enter'
                  min={1}
                  max={10}
                  value={machine.count}
                  onChange={(e) => this._handleOnChange('count', e.target.value)}
                />
                {errors && <div className='validation-error'>{errors.count}</div>}
              </FormGroup>
              <FormGroup>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={selectedTemplate.requiresSecondaryDisk ? { width: '30%' } : { width: '45%' }}>
                    <Label>Image</Label>
                    <Input
                      type='select'
                      placeholder='Select Image'
                      value={machine.vmImageId}
                      onChange={(e) => this._handleOnChange('vmImageId', e.target.value)}>
                      <option value=''>Select Image</option>
                      {React.Children.toArray(
                        selectedTemplate.imagesToShow.map((each) => (
                          <option disabled={machine.secondaryVMImageId === each.id} value={each.id}>
                            {each.name}
                          </option>
                        ))
                      )}
                    </Input>
                    {errors && <div className='validation-error'>{errors.vmImageId}</div>}
                  </div>
                  {selectedTemplate.requiresSecondaryDisk && (
                    <div style={{ width: '33%' }}>
                      <Label>Secondary Image</Label>
                      <Input
                        type='select'
                        placeholder='Select Secondary Image'
                        value={machine.secondaryVMImageId}
                        onChange={(e) => this._handleOnChange('secondaryVMImageId', e.target.value)}>
                        <option value=''>Select Secondary Image</option>
                        {React.Children.toArray(
                          selectedTemplate.imagesToShow.map((each) => (
                            <option disabled={machine.vmImageId === each.id} value={each.id}>
                              {each.name}
                            </option>
                          ))
                        )}
                      </Input>
                      {errors && <div className='validation-error'>{errors.secondaryVMImageId}</div>}
                    </div>
                  )}
                  <div style={selectedTemplate.requiresSecondaryDisk ? { width: '30%' } : { width: '45%' }}>
                    <Label>Icon</Label>
                    <Select
                      placeholder='Select Icon'
                      multi={true}
                      keepOpen={false}
                      searchable={false}
                      optionRenderer={this._customOptionRenderer}
                      itemRenderer={this._customItemRenderer}
                      onChange={(value) => this._handleOnChange('iconId', value)}
                      values={this.state.selectedIcons}
                      options={data?.iconList}
                      labelField={'name'}
                      valueField={'id'}
                      closeOnSelect={true}
                    />
                    {errors && <div className='validation-error'>{errors.iconId}</div>}
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type='text'
                  rows='3'
                  placeholder='Enter name'
                  value={machine.name}
                  onChange={(e) => this._handleOnChange('name', e.target.value)}
                />
                {errors && <div className='validation-error'>{errors.name}</div>}
              </FormGroup>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  type='textarea'
                  rows='3'
                  placeholder='Enter description'
                  value={machine.description}
                  onChange={(e) => this._handleOnChange('description', e.target.value)}
                />
              </FormGroup>
              <FormGroup className='adv-check-wrap'>
                <div className='mr-2 cussor-pointer' onClick={this._onAdvParamsToggle}>
                  Show Advanced Parameters
                </div>
                <div className='checkbox-wrap'>
                  <input type='checkbox' checked={showAdvancedParameters} onChange={() => this._onAdvParamsToggle()} />
                </div>
              </FormGroup>
              <FormGroup>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                  }}>
                  {React.Children.toArray(
                    selectedTemplate?.fieldsToShow.map((each) => {
                      return this._checkFieldCanbeShown(each) ? (
                        <div style={{ margin: '0 10px 10px 0' }}>
                          <Label>{each.label}</Label>
                          <input
                            className={each.dataType !== 'Boolean' ? 'form-control' : 'boolean-input'}
                            type={this._inputType(each)}
                            rows='3'
                            placeholder={`Enter ${each.fieldName}`}
                            disabled={!each.isEditable}
                            value={machine[each.fieldName]}
                            checked={each.defaultValue}
                            onChange={(e) => this._handleOnChange(each.fieldName, e.target.value)}
                          />
                          {errors && <div className='validation-error'>{errors[each.fieldName]}</div>}
                        </div>
                      ) : (
                        <></>
                      );
                    })
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Position</Label>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '45%' }}>
                    <Label>Left</Label>
                    <Input
                      type='text'
                      placeholder='Enter Left'
                      value={machine.left}
                      onChange={(e) => this._handleOnChange('left', e.target.value)}
                    />
                    {errors && <div className='validation-error'>{errors.left}</div>}
                  </div>
                  <div style={{ width: '45%' }}>
                    <Label>Top</Label>
                    <Input
                      type='text'
                      placeholder='Enter Top'
                      value={machine.top}
                      onChange={(e) => this._handleOnChange('top', e.target.value)}
                    />
                    {errors && <div className='validation-error'>{errors.top}</div>}
                  </div>
                </div>
              </FormGroup>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className='modalBtnCancel' onClick={() => this.props.toggle('close')}>
            Cancel
          </Button>
          <Button className='modalBtnSave' onClick={(e) => this._handleOnSubmit(e)}>
            Create
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddVirtualMachineModal;
