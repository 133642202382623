import React, { Component } from 'react';
import { Row, Col, Card, Input, CardBody, FormGroup, Button, Label } from 'reactstrap';
import { createPathEmulationProfile } from '../../../../http/http-calls';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../../redux/actions/loader-data';
import { ToastsStore } from 'react-toasts';
import config from '../../../../config';

import Select from 'react-dropdown-select';
import styled from 'styled-components';

const StyledOption = styled.span`
  padding: 0;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  transition: all 1s ease-in;

  span {
    display: none;
    transition: all 1s ease-in;
  }

  a {
    margin: 0 5px;
  }

  :hover {
    background: #f2f2f2;

    span {
      display: inline;
      margin: 0 0 0 5px;
      color: red;
    }
  }
`;

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;

  :hover {
    background: #f2f2f2;
  }
`;

class PathEmulation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathemul: {
        name: '',
        description: '',
        icon: '',
        latency: '',
        jitter: '',
        rate: '',
        packet: '',
      },
      isDirtyPath: {
        name: false,
        description: false,
        icon: false,
        latency: false,
        jitter: false,
        rate: false,
        packet: false,
      },
      errors: {},
      currentPage: 1,
      totalPages: '',
      searchItem: '',
    };
  }

  data = () => {
    return config?.emulationProfiles;
    // config?.emulationProfiles.filter(
    //   (tag, index, array) =>
    //     // eslint-disable-next-line
    //     array.findIndex((t) => t.icon == tag.icon) == index
    // );
    // .map((item) => {
    //   return <option value={item.icon}>{item.icon}</option>;
    // });
  };

  _customOptionRenderer = ({ item, props, state, methods }) => {
    return (
      <StyledOption>
        <img src={item.url} width={30} alt={item.name} />
        &nbsp;
        {item.name}
        <span
          onClick={() => {
            methods.removeItem(null, item);
            this.setState({ selectedIcons: [] }, () => {
              this._validateFields();
            });
          }}>
          &times;
        </span>
      </StyledOption>
    );
  };

  //for pagination
  _paginate = (pageNumber) => {
    this._getTeamsList(pageNumber);
  };

  // handleChange
  handleChange = () => {
    this.setState({ input: !this.state.input });
  };

  //handling input here
  _handleOnChange = (field, value) => {
    console.log(field, value);
    const { template, isDirtyTemplate } = this.state;
    template[field] = value;
    // isDirtyTemplate[field] = true;
    // this.setState({ template });
    this.setState({ template }, () => {
      console.log(isDirtyTemplate[field], field);
      if (isDirtyTemplate[field]) this._validationTemplate(value);
    });
  };

  markDirty = (fieldName, fieldvalue) => {
    const { isDirtyTemplate } = this.state;
    console.log(fieldName);
    isDirtyTemplate[fieldName] = true;

    this.setState({ isDirtyTemplate }, () => this._validationTemplate(fieldvalue));
    // console.log(this.state.errors,this.state.isDirtyTemplate);
  };

  _handleChangePathEmulation = (e) => {
    console.log(e.target.value, e.target.name);
    this.setState(
      {
        pathemul: {
          ...this.state.pathemul,
          [e.target.name]: e.target.value,
        },
        isDirtyPath: {
          ...this.state.isDirtyPath,
          [e.target.name]: true,
        },
      },
      () => this._validationPathEmulation()
    );
  };

  _validationPathEmulation = () => {
    const { pathemul, errors, isDirtyPath } = this.state;

    Object.keys(pathemul).forEach((each) => {
      if (each === 'name' && isDirtyPath.name) {
        if (!pathemul.name.trim().length) {
          errors.name = 'Name is Required';
        } else {
          delete errors[each];
          isDirtyPath.name = false;
        }
      } else if (each === 'icon' && isDirtyPath.icon) {
        if (!pathemul.icon.trim().length) {
          errors.icon = 'Icon is Required';
        } else {
          delete errors[each];
          isDirtyPath.icon = false;
        }
      } else if (
        (each === 'latency' && isDirtyPath.latency) ||
        (each === 'jitter' && isDirtyPath.jitter) ||
        (each === 'rate' && isDirtyPath.rate) ||
        (each === 'packet' && isDirtyPath.packet)
      ) {
        console.log('inside all validat');
        if (
          !pathemul.latency.trim().length &&
          !pathemul.rate.trim().length &&
          !pathemul.packet.trim().length &&
          !pathemul.jitter.trim().length
        ) {
          errors.all = 'Please provide atleast one value';
          // eslint-disable-next-line
        } else if (each == 'latency' && isDirtyPath.latency) {
          if (Number(pathemul.latency) < 0) {
            errors.latency = 'Latency Should be positive';
          } else {
            delete errors[each];
            isDirtyPath.latency = false;
            delete errors['all'];
            isDirtyPath.all = false;
          }
        } else if (each === 'jitter' && pathemul.latency && isDirtyPath.jitter) {
          if (Number(pathemul.jitter) < 0) {
            errors.jitter = 'Jitter Should be positive';
          } else {
            delete errors[each];
            isDirtyPath.jitter = false;
            delete errors['all'];
            isDirtyPath.all = false;
          }
        } else if (each === 'rate' && isDirtyPath.rate) {
          if (Number(pathemul.rate) < 0) {
            errors.rate = 'Rate Should be positive';
          } else {
            delete errors[each];
            isDirtyPath.rate = false;
            delete errors['all'];
            isDirtyPath.all = false;
          }
        } else if (each === 'packet' && isDirtyPath.packet) {
          if (Number(pathemul.packet) < 0) {
            errors.packet = 'Packet Loss Should be positive';
          } else {
            delete errors[each];
            isDirtyPath.packet = false;
            delete errors['all'];
            isDirtyPath.all = false;
          }
        }
        //  else {
        //   delete errors["all"];
        //   isDirtyPath.all = false;
        // }
      }
      // else if (each === "latency" && isDirtyPath.latency) {
      // if (!pathemul.latency.trim().length) {
      // errors.latency = "Please provide atleast one value";
      // } else
      //   if (Number(pathemul.latency) < 0) {
      //     errors.latency = "Latency Should be positive";
      //   } else {
      //     delete errors[each];
      //     isDirtyPath.latency = false;
      //   }
      // } else if (each === "jitter" && pathemul.latency && isDirtyPath.jitter) {
      // if (!pathemul.jitter.trim().length) {
      // errors.jitter = "Please provide atleast one value";
      // } else
      //   if (Number(pathemul.jitter) < 0) {
      //     errors.jitter = "Jitter Should be positive";
      //   } else {
      //     delete errors[each];
      //     isDirtyPath.jitter = false;
      //   }
      // } else if (each === "rate" && isDirtyPath.rate) {
      //   if (!pathemul.rate.trim().length) {
      // errors.rate = "Please provide atleast one value";
      // } else
      //  if (Number(pathemul.rate) < 0) {
      //   errors.rate = "Rate Should be positive";
      // } else {
      //   delete errors[each];
      //   isDirtyPath.rate = false;
      // }
      // } else if (each === "packet" && isDirtyPath.packet) {
      //   if (!pathemul.packet.trim().length) {
      //     // errors.packet = "Please provide atleast one value";
      //   } else
      //   if (Number(pathemul.packet) < 0) {
      //     errors.packet = "Packet Loss Should be positive";
      //   } else {
      //     delete errors[each];
      //     isDirtyPath.packet = false;
      //   }
      // }
    });
    this.setState({ errors });
    console.log('err', this.state.errors);
    return Object.keys(errors).length ? errors : null;
  };

  _pathEmulationProfile = () => {
    let { pathemul } = this.state;
    let isDirtyPath = {
      name: true,
      description: true,
      icon: true,
      latency: true,
      jitter: true,
      rate: true,
      packet: true,
    };
    this.setState({ isDirtyPath }, () => {
      let errors = this._validationPathEmulation();
      if (!errors) {
        console.log('object');
        this.props.showLoader();
        let data = {
          name: pathemul.name || undefined,
          description: pathemul.description || undefined,
          icon: pathemul?.icon.split('.')[0] || undefined,
          latency: pathemul.latency || undefined,
          jitter: pathemul.jitter || undefined,
          rate: pathemul.rate || undefined,
          packetLoss: pathemul.packet || undefined,
        };
        console.log(data);
        createPathEmulationProfile(data)
          .then((resp) => {
            console.log('Path Emulation Profile response :- ', resp);
            this.props.hideLoader();
            ToastsStore.success('Path Emulation Profile Created successfully');
            this.setState({
              pathemul: {
                name: '',
                description: '',
                icon: '',
                latency: '',
                jitler: '',
                rate: '',
                packet: '',
              },
            });
          })
          .catch((err) => {
            this.props.hideLoader();
            ToastsStore.error('Path Emulation Profile Creation failed :- ', err.reason);
          });
      }
    });
  };

  render() {
    console.log(this.state);
    const { errors, pathemul } = this.state;
    return (
      <>
        <Row>
          <Col xs='12'>
            <Card>
              <CardBody className='innerCard innerForm'>
                <Row>
                  <Col md='6'>
                    <FormGroup>
                      <Label>Name </Label>
                      <Input
                        type='text'
                        placeholder='Name'
                        name='name'
                        value={pathemul.name}
                        onChange={(e) => this._handleChangePathEmulation(e)}
                      />
                      {errors && <div className='validation-error'>{errors.name}</div>}
                    </FormGroup>
                    <FormGroup>
                      <Label>Icon</Label>
                      <Input
                        type='select'
                        placeholder='Icon'
                        name='icon'
                        value={pathemul.icon}
                        onChange={(e) => this._handleChangePathEmulation(e)}>
                        <option>Select</option>
                        {config?.emulationProfiles
                          .filter(
                            (tag, index, array) =>
                              // eslint-disable-next-line
                              array.findIndex((t) => t.icon == tag.icon) == index
                          )
                          .map((item) => {
                            return (
                              <>
                                <option value={item.icon}>
                                  {item.name === 'Wifi'
                                    ? '🛜'
                                    : item.name === '4G LTE Good'
                                    ? '📶'
                                    : item.name === 'SatCom'
                                    ? '📡'
                                    : '🔌'}
                                  &nbsp; &nbsp; &nbsp;
                                  {item.name}
                                </option>
                              </>
                            );
                          })}
                      </Input>
                      {errors && <div className='validation-error'>{errors.icon}</div>}
                    </FormGroup>
                  </Col>
                  <Col md='6'>
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        type='textarea'
                        placeholder='Description'
                        name='description'
                        value={pathemul.description}
                        onChange={(e) => this._handleChangePathEmulation(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md='6'>
                    <FormGroup>
                      <Label>Latency(ms) </Label>
                      <Input
                        // type="text"
                        type='number'
                        placeholder='Latency'
                        name='latency'
                        value={pathemul.latency}
                        onChange={(e) => this._handleChangePathEmulation(e)}
                      />
                      {errors && <div className='validation-error'>{errors.latency}</div>}
                    </FormGroup>
                  </Col>
                  <Col md='6'>
                    <FormGroup>
                      <Label>Jitter(ms) </Label>
                      <Input
                        // type="text"
                        type='number'
                        placeholder='Jitter'
                        name='jitter'
                        value={pathemul.jitter}
                        onChange={(e) => this._handleChangePathEmulation(e)}
                        // eslint-disable-next-line
                        readOnly={pathemul.latency == ''}
                      />
                      {errors && <div className='validation-error'>{errors.jitter}</div>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md='6'>
                    <FormGroup>
                      <Label>Rate(Kbps)</Label>
                      <Input
                        type='number'
                        placeholder='Rate'
                        name='rate'
                        value={pathemul.rate}
                        onChange={(e) => this._handleChangePathEmulation(e)}
                      />
                      {errors && <div className='validation-error'>{errors.rate}</div>}
                    </FormGroup>
                  </Col>
                  <Col md='6'>
                    <FormGroup>
                      <Label>Packet Loss(%)</Label>
                      <Input
                        // type="text"
                        type='number'
                        placeholder='Packet Loss'
                        name='packet'
                        value={pathemul.packet}
                        onChange={(e) => this._handleChangePathEmulation(e)}
                      />
                      {errors && <div className='validation-error'>{errors.packet}</div>}
                    </FormGroup>
                  </Col>
                </Row>
                {errors && <div className='validation-error'>{errors.all}</div>}
                <div className='text-right'>
                  <Button
                    className='addBtn'
                    onClick={() => this._pathEmulationProfile()}
                    //   onClick={() => this._onToggleAddTeamModal()}
                  >
                    Save
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PathEmulation);
