import React, { useState } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// react plugin used to create charts
import { Line, Chart, Bar } from 'react-chartjs-2';
// react plugin for creating vector maps
import { VectorMap } from 'react-jvectormap';
import './custom.css';

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Progress,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from 'reactstrap';

// core components
import { chartExample1, chartExample2, chartExample3, chartExample4 } from '../../../variables/charts.js';
import { useEffect } from 'react';
import './custom.css';

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920
};
const FullChart = (props) => {
  // const [bigChartData, setbigChartData] = React.useState(cpuUtil);
  // const [bigChartData, setbigChartData] = React.useState('data1');
  // const setBgChartData = (name) => {
  //   setbigChartData(name);
  // };
  Chart.defaults.global.legend.display = false; // Hide the legend
  const initState = props.cpuUtilizationChatConfig;
  const [lineChart, setLineChart] = React.useState(initState);
  const [percentageTotal, setPercentageTotal] = React.useState(props.totalPercentage.cpu);
  const setLnChart = (name) => {
    setLineChart(name);
  };
  useEffect(() => {
    setLineChart(props.cpuUtilizationChatConfig);
    setPercentageTotal(props.totalPercentage.cpu);
  }, [props.cpuUtilizationChatConfig, props.totalPercentage.cpu]);
  console.log(props);

  return (
    <Col xs='12'>
      {/* <CardHeader>
        <Row>
          <Col className='text-left' sm='6'>
            <h5 className='card-category'>{props.totalPercentage.cpu}</h5>
            {props.totalPercentage}
            <CardTitle tag='h2'>{props.totalPercentage}</CardTitle>
          </Col>
        <Col sm='6'> */}

      {/* </Col>
        </Row>
      </CardHeader>  */}
      {/* <Col className='center-text' lg='4' xl='4'></Col> */}
      <Card className='card-chart'>
        <CardTitle tag='h3'>
          {/* <Col className='center-text' lg='4' xl='4'> */}
          <span className='group-by-wrap bg-dark'>
            <Label className='mr-2'>Show Statistics By: </Label>
            <select
              className='form-control'
              value={props.groupBy}
              onChange={(e) => {
                props._onGroupByChange(e.target.value);
              }}>
              <option value='hour'>Hour</option>
              <option value='day'>Day</option>
              <option value='month'>Month</option>
            </select>
          </span>
          {/* </Col> */}
          <span className='percent'>
            <i className='fa fa-hdd-o d-inline' /> {percentageTotal}%
          </span>
          <ButtonGroup className='btn-group-toggle float-right' data-toggle='buttons'>
            <Button
              color='info'
              id='0'
              size='sm'
              tag='label'
              className={classNames('btn-simple', {
                active: lineChart === props.cpuUtilizationChatConfig
                // active: bigChartData === 'data1'
              })}
              // onClick={() => setBgChartData('data1')}
              onClick={() => {
                setLnChart(props.cpuUtilizationChatConfig);
                setPercentageTotal(props.totalPercentage.cpu);
              }}>
              <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>CPU</span>
              <span className='d-block d-sm-none'>
                <i className='tim-icons icon-single-02' />
              </span>
            </Button>
            <Button
              color='info'
              id='1'
              size='sm'
              tag='label'
              className={classNames('btn-simple', {
                // active: bigChartData === 'data2'
                active: lineChart === props.ramUtilizationChatConfig
              })}
              // onClick={() => setBgChartData('data2')}
              onClick={() => {
                setLnChart(props.ramUtilizationChatConfig);
                setPercentageTotal(props.totalPercentage.ram);
              }}>
              <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>RAM</span>
              <span className='d-block d-sm-none'>
                <i className='tim-icons icon-gift-2' />
              </span>
            </Button>
            <Button
              color='info'
              id='2'
              size='sm'
              tag='label'
              className={classNames('btn-simple', {
                active: lineChart === props.storageUtilizationChatConfig
                // active: bigChartData === 'data3'
              })}
              // onClick={() => setBgChartData('data3')}
              onClick={() => {
                setLnChart(props.storageUtilizationChatConfig);
                setPercentageTotal(props.totalPercentage.storage);
              }}>
              <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>STORAGE</span>
              <span className='d-block d-sm-none'>
                <i className='tim-icons icon-tap-02' />
              </span>
            </Button>
          </ButtonGroup>
        </CardTitle>
        {/* <CardBody>
          <div className='chart-area'>
            {loadChart && (
              <Line data={props.cpuUtilizationChatConfig.data} options={props.cpuUtilizationChatConfig.options} />
            )}
          </div>
        </CardBody> */}
        <CardBody>
          <div className='chart-area full-chart'>
            {/* {props.loadChart && (
              <Line data={props.cpuUtilizationChatConfig.data} options={props.cpuUtilizationChatConfig.options} />
            )} */}
            {/* <Line data={chartExample1[bigChartData]} options={chartExample1.options} /> */}

            {/* <Line data={props.cpuUtilizationChatConfig.data} options={props.cpuUtilizationChatConfig.options} />
            <Line data={props.ramUtilizationChatConfig.data} options={props.ramUtilizationChatConfig.options} />
            <Line data={props.storageUtilizationChatConfig.data} options={props.storageUtilizationChatConfig.options} /> */}

            <Line
              // width={300}
              height={350}
              options={(lineChart.options, { maintainAspectRatio: false })}
              // options={lineChart.options}
              className='line-chart__height'
              data={lineChart.data}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default FullChart;
