import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  CardImg,
  CardBody,
  Button,
  Badge,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { userList, userDelete } from '../../../http/http-calls';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { ToastsStore } from 'react-toasts';
import AddUserModal from '../components/add-user-modal';
import moment from 'moment';
// import { Paginate } from "./Pagination";
import CardTitle from 'reactstrap/lib/CardTitle';
import { DeleteOutline } from '@mui/icons-material';
import { Edit } from '@mui/icons-material';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      addUserModal: {
        isOpen: false,
        data: null,
      },
      usersData: [],
      currentPage: 1,
      totalPages: '',
      searchItem: '',
    };
  }

  componentDidMount() {
    this._getUserList(this.state.currentPage);
  }

  //get list of users here
  _getUserList = (pageNumber) => {
    const { searchItem } = this.state;
    let data = { page: pageNumber };
    if (searchItem.trim().length) {
      data = {
        page: pageNumber,
        search: searchItem,
      };
    }
    this.props.showLoader();
    userList(data)
      .then((resp) => {
        this.setState({
          usersData: resp.docs,
          totalPages: resp.totalPages,
          currentPage: resp.page,
        });
        this.props.hideLoader();
      })
      .catch((err) => {
        this.props.hideLoader();
        ToastsStore.error('User list loading failed :- ' + err.reason);
      });
  };

  //changing format of createdAt
  _changeFormat = (data) => {
    return moment(data).format('MMM DD, hh:mm a');
  };

  //delete user here
  _deleteUser = (id, labCount) => {
    if (labCount !== 0) {
      ToastsStore.error('Please delete the lab(s) created by this user');
      return;
    } else {
      console.log('delete :>> ');
      this.props.showLoader();
      userDelete(id)
        .then((resp) => {
          console.log('User delete response :- ', resp);
          this.props.hideLoader();
          ToastsStore.success('User deleted successfully');
          this._getUserList();
        })
        .catch((err) => {
          this.props.hideLoader();
          ToastsStore.error('User deletion failed :- ', err.reason);
        });
    }
  };

  //Toggling add user modal here
  _onToggleAddUserModal = () => {
    let { addUserModal } = JSON.parse(JSON.stringify(this.state));
    addUserModal.isOpen = !addUserModal.isOpen;
    // subscriberModal.data = creator;
    this.setState(
      {
        addUserModal,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  //for showing list of labs
  _showUsersList = () => {
    let userListData = '';
    if (!this.state.usersData.length) {
      userListData += 'No User Found';
    } else {
      userListData = this.state.usersData.map((user) => {
        return (
          <>
            <Col className='cardGrid' key={user._id}>
              <Card className='userCard'>
                <Badge color={`${user.isActive ? 'success' : 'danger'}`}>{user.isActive ? 'Active' : 'Inactive'}</Badge>
                <CardBody>
                  <CardTitle>
                    {user.name.full}
                    {/* <span>
                      <Link
                        to={{
                          pathname: '/edit-user',
                          state: { id: user._id },
                        }}>
                        <Edit />
                        <DeleteOutline onClick={() => this._deleteUser(user._id, user._labCount)} />
                      </Link>
                    </span> */}
                  </CardTitle>
                  <ListGroup>
                    <ListGroupItem className='justify-content-between'>
                      <span>Username </span> <span>{user.username}</span>
                    </ListGroupItem>
                    <ListGroupItem className='justify-content-between'>
                      <span>Email </span> <span>{user.email}</span>
                    </ListGroupItem>
                    <ListGroupItem className='justify-content-between'>
                      <span>Role </span> <span>{user.isAdmin ? 'Admin' : 'User'}</span>
                    </ListGroupItem>
                    <ListGroupItem className='justify-content-between'>
                      <span>Teams </span>{' '}
                      <span>
                        {user._teams?.length > 0
                          ? user?._teams.map((item) => {
                              return item?.name;
                            })
                          : '-'}
                      </span>
                    </ListGroupItem>
                    <ListGroupItem className='justify-content-between'>
                      <span>Created At </span> <span> {this._changeFormat(user.createdAt)} </span>
                    </ListGroupItem>
                  </ListGroup>
                  <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: '2rem' }}>
                    <Link
                      // style={{ color: 'white', backgroundColor: '#f18f3c', padding: '1rem' }}
                      className='viewBtn mr-3 word btn btn-secondary'
                      to={{
                        pathname: '/edit-user',
                        state: { id: user._id },
                      }}>
                      {/* <EditIcon /> */}
                      <span>
                        Edit: <Edit />
                      </span>
                    </Link>

                    <div
                      className='viewBtn mr-3 word btn btn-secondary'
                      onClick={() => this._deleteUser(user._id, user._labCount)}>
                      Delete: <DeleteOutline />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* <tr key={user._id}> */}
            {/* <td>{user.username}</td> */}
            {/* <td>{user.name.full}</td> */}
            {/* <td>{user.email}</td> */}
            {/* <td>{user._teams?.length > 0 ? user?._teams.map(item => { return (<Badge color="secondary">{item?.name}</Badge>) }) : "-"}</td> */}
            {/* <td>{user.isAdmin ? "Admin" : "User"}</td> */}
            {/* <td>
                <span className={user.isActive ? "text-success" : "text-danger"}>
                  {user.isActive ? "Active" : "Inactive"}
                </span>
              </td> */}
            {/* <td>
                {this._changeFormat(user.createdAt)}
              </td> */}
            {/* <td>
                <Link to={{
                  pathname: "/edit-user",
                  state: { id: user._id }
                }}
                  className="px-2">
                  <i className="fa fa-pencil text-warning"></i>
                </Link>
                <Button color="link">
                  <i className="fa fa-trash-o text-danger"
                    onClick={() => this._deleteUser(user._id, user._labCount)}
                  ></i>
                </Button>
              </td> */}
            {/* </tr> */}
          </>
        );
      });
    }
    return userListData;
  };

  //filters list according to search input
  _filterUserList = (value) => {
    let { searchItem, currentPage } = this.state;
    searchItem = value;
    currentPage = 1;
    this.setState({ searchItem, currentPage }, () => {
      this._getUserList(currentPage);
    });
  };

  //for pagination
  _paginate = (pageNumber) => {
    this._getUserList(pageNumber);
  };

  render() {
    return (
      <div className='content'>
        <Row>
          <Col xs='12'>
            <Card>
              <CardHeader className='tableFilterWrap'>
                <Input
                  type='text'
                  placeholder='Search...'
                  style={{ width: 180 }}
                  onChange={(e) => this._filterUserList(e.target.value)}
                />

                <Button className='addBtn ml-2' onClick={() => this._onToggleAddUserModal()}>
                  <i className='fa fa-plus mr-1'></i> Add
                </Button>
              </CardHeader>
            </Card>
          </Col>
          {this._showUsersList()}
        </Row>

        <AddUserModal
          isOpen={this.state.addUserModal.isOpen}
          creatorData={this.state.addUserModal.data}
          toggle={() => this._onToggleAddUserModal()}
          reload={() => this._getUserList()}></AddUserModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
