import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  // Input,
  // Label,
  // FormGroup,
} from "reactstrap";
class DeleteNodeConfirmationModal extends Component {
  state = {};
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle("cancel")}
      >
        <ModalHeader toggle={() => this.props.toggle("cancel")}>
          Delete Confirmation
        </ModalHeader>
        <ModalBody>
          <div>
            {this.props?.type === "node" ? (
              <p>
                Are you sure to delete the{" "}
                {this.props.data?.properties?.networkType === "VNode"
                  ? "Virtual Machine"
                  : "Virtual Network"}?
              </p>
            ) : (
              <p>Are you sure to delete this Link?</p>
            )}           
            {/* Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="modalBtnCancel"
            onClick={() => this.props.toggle("cancel")}
          >
            No
          </Button>
          <Button
            className="modalBtnSave"
            onClick={(e) => this.props.toggle("delete")}
          >
            Yes, Delete
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DeleteNodeConfirmationModal;
