import React, { Component } from 'react';
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Label, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { signUp, login } from '../../../http/http-calls';
import { connect } from 'react-redux';
import { ToastsStore } from 'react-toasts';
import { updateUserData } from '../../../redux/actions/user-data';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';

class SignUp extends Component {
  state = {
    user: {
      userName: '',
      fullName: '',
      email: '',
      phone: '',
      password: '',
      cnfPassword: '',
      license: '',
    },
    isDirty: {
      userName: false,
      fullName: false,
      email: false,
      phone: false,
      password: false,
      cnfPassword: false,
      license: false,
    },
    errors: {},
    isVisible: [false, false],
  };

  //handling visibility of password
  _handleVisibilityToggle = (index) => {
    let { isVisible } = this.state;
    isVisible[index] = !isVisible[index];
    this.setState({ isVisible });
  };

  //handling input here
  _handleOnChange = (field, value) => {
    const { user, isDirty } = this.state;
    user[field] = value;
    isDirty[field] = true;
    this.setState({ user, isDirty }, () => {
      this._validateField();
    });
  };

  //for validation
  _validateField = () => {
    const { user, isDirty, errors } = this.state;
    Object.keys(user).forEach((each) => {
      if (each === 'userName' && isDirty.userName) {
        if (!user.userName.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.userName = false;
        }
      } else if (each === 'fullName' && isDirty.fullName) {
        if (!user.fullName.trim().length) {
          errors[each] = '*Required';
        } else if (user.fullName.trim().length && !user.fullName.match(/^[a-zA-Z ]*$/)) {
          errors[each] = 'Invalid Name format';
        } else if (
          user.fullName.trim().length &&
          user.fullName.match(/^[a-zA-Z ]*$/) &&
          user.fullName.trim().length <= 2
        ) {
          errors[each] = 'Name should be greater than two characters';
        } else {
          delete errors[each];
          isDirty.fullName = false;
        }
      } else if (each === 'email' && isDirty.email) {
        if (!user.email.trim().length) {
          errors[each] = '*Required';
        } else if (
          user.email.trim().length &&
          !new RegExp('^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$').test(user.email)
        ) {
          errors[each] = 'Enter a valid email ID';
        } else {
          delete errors[each];
          isDirty.email = false;
        }
      } else if (each === 'phone' && isDirty.phone) {
        if (!user.phone.trim().length) {
          errors[each] = '*Required';
        } else if (user.phone.trim().length && !user.phone.match(/^[0-9]{10}$/)) {
          errors[each] = 'Enter valid phone number';
        } else {
          delete errors[each];
          isDirty.phone = false;
        }
      } else if (each === 'password' && isDirty.password) {
        if (!user.password.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.password = false;
        }
      } else if (each === 'cnfPassword' && isDirty.cnfPassword) {
        if (!user.cnfPassword.trim().length) {
          errors[each] = '*Required';
        } else if (user.cnfPassword.trim().length && !(user.cnfPassword === user.password)) {
          errors[each] = 'Password not matching';
        } else {
          delete errors[each];
          isDirty.cnfPassword = false;
        }
      } else if (each === 'license' && isDirty.license) {
        if (!user.license.trim().length) {
          errors[each] = '*Required';
        } else {
          delete errors[each];
          isDirty.license = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  //SignUp here
  _signUp = (e) => {
    e.preventDefault();
    let isDirty = {
      userName: true,
      fullName: true,
      email: true,
      phone: true,
      password: true,
      cnfPassword: true,
      license: true,
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateField();
      if (!errors) {
        let signupData = {
          email: this.state.user.email,
          phone: this.state.user.phone,
          name: {
            first: this.state.user.fullName.split(' ')[0],
            last: this.state.user.fullName.split(' ')[1] ? this.state.user.fullName.split(' ')[1] : '',
          },
          username: this.state.user.userName,
          password: this.state.user.password,
          license: this.state.user.license,
        };
        this.props.showLoader();
        signUp(signupData)
          .then((resp) => {
            console.log(resp);
            this.props.hideLoader();
            ToastsStore.success('User registration successful');
            this._login();
          })
          .catch((err) => {
            this.props.hideLoader();
            ToastsStore.error('User registration failed :- ' + err.reason);
          });
      }
    });
  };

  //login after successfully signup
  _login = () => {
    let loginData = {
      handle: this.state.user.userName,
      password: this.state.user.password,
    };
    this.props.showLoader();
    login(loginData)
      .then((resp) => {
        this.props.updateUserData(resp);
        ToastsStore.success('Successfully Logged-In');
        this.setState({
          user: {
            userName: '',
            fullName: '',
            email: '',
            phone: '',
            password: '',
            cnfPassword: '',
            license: '',
          },
          isDirty: {
            userName: false,
            fullName: false,
            email: false,
            phone: false,
            password: false,
            cnfPassword: false,
            license: false,
          },
          errors: {},
          isVisible: [false, false],
        });
        this.props.hideLoader();
        this.props.history.push('/dashboard');
      })
      .catch((err) => {
        this.props.hideLoader();
        ToastsStore.error('Login failed :- ' + err.reason);
      });
  };

  render() {
    const { user, errors, isVisible } = this.state;

    return (
      <div className='full-page login-page'>
        <div className='authFormWrap' style={{ maxWidth: 700 }}>
          <Form onSubmit={(e) => this._resetPassword(e)}>
            <Card className=''>
              <CardHeader>
                <img
                  alt='EVOS Logo'
                  className='companyLogo'
                  src='../../../assets/img/evos-logo-1.png'
                  style={{ marginBottom: 25 }}
                />

                <h1 className='card-title'>Create Account</h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm='6'>
                    <Label>Username</Label>
                    <FormGroup>
                      <i className='fa fa-user formIcon'></i>
                      <Input
                        placeholder='Enter'
                        type='text'
                        value={user.userName}
                        onChange={(e) => this._handleOnChange('userName', e.target.value)}
                      />
                      {errors && <div className='validation-error'>{errors.userName}</div>}
                    </FormGroup>
                  </Col>
                  <Col sm='6'>
                    <Label>Name</Label>
                    <FormGroup>
                      <i className='fa fa-user formIcon'></i>
                      <Input
                        placeholder='Enter'
                        type='text'
                        value={user.fullName}
                        onChange={(e) => this._handleOnChange('fullName', e.target.value)}
                      />
                      {errors && <div className='validation-error'>{errors.fullName}</div>}
                    </FormGroup>
                  </Col>

                  <Col sm='6'>
                    <Label>Email</Label>
                    <FormGroup>
                      <i className='fa fa-envelope-o mailIcon formIcon'></i>
                      <Input
                        placeholder='Enter'
                        type='email'
                        value={user.email}
                        onChange={(e) => this._handleOnChange('email', e.target.value)}
                      />
                      {errors && <div className='validation-error'>{errors.email}</div>}
                    </FormGroup>
                  </Col>
                  <Col sm='6'>
                    <Label>Phone</Label>
                    <FormGroup>
                      <i className='fa fa-phone formIcon callIcon'></i>
                      <Input
                        placeholder='Enter'
                        type='number'
                        value={user.phone}
                        onChange={(e) => this._handleOnChange('phone', e.target.value)}
                      />
                      {errors && <div className='validation-error'>{errors.phone}</div>}
                    </FormGroup>
                  </Col>

                  <Col sm='6'>
                    <Label>Password</Label>
                    <FormGroup>
                      <i className='fa fa-lock formIcon lockIcon'></i>
                      <Input
                        placeholder='Enter'
                        type={isVisible[0] ? 'text' : 'password'}
                        className='passwordField'
                        value={user.password}
                        onChange={(e) => this._handleOnChange('password', e.target.value)}
                      />
                      <i
                        className={isVisible[0] ? 'fa fa-eye showPassword' : 'fa fa-eye-slash showPassword'}
                        onClick={() => this._handleVisibilityToggle(0)}></i>
                      {/* <i className="fa fa-eye showPassword d-none"></i> */}
                      {errors && <div className='validation-error'>{errors.password}</div>}
                    </FormGroup>
                  </Col>
                  <Col sm='6'>
                    <Label>Confirm Password</Label>
                    <FormGroup>
                      <i className='fa fa-lock formIcon lockIcon'></i>
                      <Input
                        placeholder='Enter'
                        type={isVisible[1] ? 'text' : 'password'}
                        className='passwordField'
                        value={user.cnfPassword}
                        onChange={(e) => this._handleOnChange('cnfPassword', e.target.value)}
                      />
                      <i
                        className={isVisible[1] ? 'fa fa-eye showPassword' : 'fa fa-eye-slash showPassword'}
                        onClick={() => this._handleVisibilityToggle(1)}></i>
                      {/* <i className="fa fa-eye showPassword d-none"></i> */}
                      {errors && <div className='validation-error'>{errors.cnfPassword}</div>}
                    </FormGroup>
                  </Col>

                  <Col sm='6'>
                    <Label>Serial Number</Label>
                    <FormGroup>
                      <i className='fa fa-key formIcon'></i>
                      <Input
                        placeholder='Enter serial number'
                        type='text'
                        value={user.license}
                        onChange={(e) => this._handleOnChange('license', e.target.value)}
                      />
                      {errors && <div className='validation-error'>{errors.license}</div>}
                    </FormGroup>
                  </Col>
                </Row>

                <Button className='btn themeBtn' type='submit' onClick={(e) => this._signUp(e)}>
                  Get Started
                </Button>

                <Link className='link createAccount' to='/login'>
                  Already an existing user? <span>Login</span>
                </Link>
              </CardBody>
            </Card>
          </Form>
        </div>

        <div className='authFooter' style={{ maxWidth: 700 }}>
          EVOS &#169; {new Date().getFullYear()}.
          <div style={{ display: 'none' }}>
            Powered By:{' '}
            <a href='https://www.logic-square.com/' target='_blank' rel='noreferrer'>
              Logic Square
            </a>
          </div>
          <div>
            Powered By:{' '}
            <a href='http://www.gurooit.com' target='_blank' rel='noreferrer'>
              GuROO
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log('state :>> ', state);
  return {
    userData: state.userData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserData: (userData) => dispatch(updateUserData(userData)),
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
