import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Input,
  CardBody,
  // CardFooter,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import {
  updatePassword,
  updatePasswordforUser,
} from "../../../http/http-calls";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { ToastsStore } from "react-toasts";

class Password extends Component {
  state = {
    field: {
      password: "",
      confpassword: "",
    },
    showPassword: {
      password: true,
      confpassword: true,
    },
    isDirty: {
      name: false,
      confpassword: false,
      phone: false,
    },
    errors: {},
    licenseList: [],
    licenseKey: "",
  };

  _handleOnChange = (e) => {
    const { field, isDirty } = this.state;
    console.log("zxzx", e.target.field);
    field[e.target.name] = e.target.value;
    isDirty[e.target.name] = true;
    if(e.target.name==='password'){
      this.setState({showPassword:{
        ...this.state.showPassword,
        // password:false
      }, field });
    }else if(e.target.name==="confpassword"){
      this.setState({showPassword:{
        ...this.state.showPassword,
        // confpassword:false
      }, field });
    }
    console.log(this.state.field);
    this._validateForm();
  };



  _handleOnSubmit = (event) => {
    event.preventDefault();
    const { isDirty } = this.state;
    isDirty.confpassword = true;
    isDirty.password = true;
    this.setState({ isDirty });
    console.log(this.state.isDirty);
    let errors = this._validateForm();
    console.log(errors);
    // this.setState({errors});
    if (!errors) {
      console.log("Make API call");
      //   this._login();
      if (this.props.type === "my-profile") {
        this.updatePasswordForProfile();
      } else if (this.props.type === "edit-user") {
        this.updatePasswordForEditUser();
      }
    }
  };

  updatePasswordForEditUser = () => {
    this.props.showLoader();

    updatePasswordforUser(this.props.id, {
      password: this.state.field.password,
    })
      .then((res) => {
        console.log(res);
        this.setState({
          field: {
            password: "",
            confpassword: "",
          },
          showPassword: {
            password: false,
            confpassword: false,
          },
        });
        this.props.hideLoader();
        ToastsStore.success("Password Updated successfully");
      })
      .catch((error) => {
        console.log("error :>> ", error);
        this.props.hideLoader();
        ToastsStore.error("Password Update failed :- " + error.reason);
      });
  };

  updatePasswordForProfile = () => {
    this.props.showLoader();

    updatePassword({ password: this.state.field.password })
      .then((res) => {
        console.log(res);
        this.setState({
          field: {
            password: "",
            confpassword: "",
          },
          showPassword: {
            password: false,
            confpassword: false,
          },
        });
        this.props.hideLoader();
        ToastsStore.success("Password Updated successfully");
      })
      .catch((error) => {
        console.log("error :>> ", error);
        this.props.hideLoader();
        ToastsStore.error("Password Update failed :- " + error.reason);
      });
  };

  _validateForm = () => {
    const { field, isDirty, errors } = this.state;

    Object.keys(field).forEach((each) => {
      if (each === "password" && isDirty.password) {
        if (!field.password.trim().length) {
          // console.log(admin.password);
          errors[each] = "Password is Required";
        }
        // else if (
        //   !new RegExp("^(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$").test(
        //     field.password
        //   )
        // ) {
        //   errors[each] =
        //     "Min password length should be 8 , Req 1 digit , a special char";
        // }
        else {
          delete errors[each];
          isDirty.password = false;
        }
      } else if (each === "confpassword" && isDirty.confpassword) {
        if (!field.confpassword.trim().length && field.password) {
          errors[each] = "Confirm password is Required";
        } else if (field.confpassword !== field.password) {
          errors[each] = "Confirm Password Does Not Match with Password";
        } else {
          delete errors[each];
          isDirty.password = false;
        }
      }
    });

    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  };

  render() {
    const { errors, showPassword } = this.state;
    console.log(this.state);
    console.log("this.props", this.props);

    return (
      <div>
        <Card className="userInfo">
          <CardHeader>Change Password</CardHeader>
          <CardBody className="innerForm innerCard">
            <FormGroup>
              <Label>Password:</Label>
              <Input
                // type="text"
                type={showPassword.password ? "text" : "password"}
                name="password"
                placeholder="Password"
                value={this.state.field.password}
                onChange={(e) => this._handleOnChange(e)}
              />
              <i
                className={
                  showPassword.password
                    ? "fa fa-eye change-pwd-eye-icon showPassword"
                    : "fa fa-eye-slash change-pwd-eye-icon showPassword"
                }
                onClick={() => this.setState({showPassword:{
                  ...this.state.showPassword,
                   password:!this.state.showPassword.password
                 }})}
              ></i>
              {errors && (
                <div className="validation-error">{errors.password}</div>
              )}
            </FormGroup>
            <FormGroup className="mt-4">
              <Label>Confirm Password:</Label>
              <Input
                // type="text"
                type={showPassword.confpassword ? "text" : "password"}
                name="confpassword"
                placeholder="Confirm Password"
                value={this.state.field.confpassword}
                onChange={(e) => this._handleOnChange(e)}
              />
              <i
                className={
                  showPassword.confpassword
                    ? "fa fa-eye change-pwd-eye-icon showPassword"
                    : "fa fa-eye-slash change-pwd-eye-icon showPassword"
                }
                onClick={() => this.setState({showPassword:{
                  ...this.state.showPassword,
                  confpassword:!this.state.showPassword.confpassword
                }})}
              ></i>
              {errors && (
                <div className="validation-error">{errors.confpassword}</div>
              )}
            </FormGroup>
          </CardBody>
          <div className="text-center mb-4">
            <Button
              className="mx-2 saveBtn"
              onClick={(e) => this._handleOnSubmit(e)}
              >
              Update
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}
// export default Password;

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
