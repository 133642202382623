import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { updateUserData } from '../../../../redux/actions/user-data';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavLink,
  Container,
  // Modal,
} from 'reactstrap';

// import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#141414',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AdminNavbar = ({ sidebarOpened, toggleSidebar, pageName }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [modalSearch, setModalSearch] = useState(false);
  const [color, setColor] = useState('navbar-transparent');
  const dispatch = useDispatch();
  const history = useHistory();

  //! Privacy modal

  const [open, setOpen] = React.useState(false);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //! Privacy modal end

  useEffect(() => {
    window.addEventListener('resize', updateColor);
    return () => {
      window.removeEventListener('resize', updateColor);
    };
  }, []);

  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor('bg-white');
    } else {
      setColor('navbar-transparent');
    }
  };

  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor('navbar-transparent');
    } else {
      setColor('bg-white');
    }
    setCollapseOpen(!collapseOpen);
  };

  const toggleModalSearch = () => {
    setModalSearch(!modalSearch);
  };

  const logout = () => {
    dispatch(updateUserData({}));
    localStorage.clear();
    toast.success(`Logged out successfully...`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    history.push('/login');
  };

  const myProfile = () => {
    history.push('/my-profile');
  };

  return (
    <>
      <Navbar className={classNames(color)} expand='lg'>
        <Container fluid>
          <div className='navbar-wrapper'>
            {/* <div className={classNames('navbar-toggle d-inline', { toggled: sidebarOpened })}>
              <button className='navbar-toggler' type='button' onClick={toggleSidebar}>
                <span className='navbar-toggler-bar bar1' />
                <span className='navbar-toggler-bar bar2' />
                <span className='navbar-toggler-bar bar3' />
              </button>
            </div> */}
            <NavbarBrand href='#pablo' onClick={(e) => e.preventDefault()}>
              <img alt='EVOS Logo' className='sideMenuLogo' src='../../../../assets/img/evos-logo-1.png' />
            </NavbarBrand>
          </div>
          {pageName && <div className='lab-name'>{pageName}</div>}
          <UncontrolledDropdown nav className='userDetail'>
            <DropdownToggle caret color='default' data-toggle='dropdown' nav onClick={(e) => e.preventDefault()}>
              <div className='photo'>
                <img alt='...' src='../../../../assets/img/anime3.png' />
              </div>
              <b className='caret d-none d-lg-block d-xl-block' />
            </DropdownToggle>
            <DropdownMenu className='dropdown-navbar' right tag='ul'>
              <NavLink tag='li' onClick={() => myProfile()}>
                <DropdownItem className='nav-item'>My Profile</DropdownItem>
              </NavLink>
              <NavLink tag='li' onClick={() => logout()}>
                <DropdownItem className='nav-item'>Log out</DropdownItem>
              </NavLink>
              {/* <NavLink>
                <DropdownItem>
                  <li className='nav-item' style={{ color: '#969696', padding: '0' }} onClick={handleClickOpen}>
                    Privacy Policy
                  </li>
                  <div>
                    <Modal
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      aria-labelledby='keep-mounted-modal-title'
                      aria-describedby='keep-mounted-modal-description'>
                      <Box sx={style}>
                        <Typography id='keep-mounted-modal-title' variant='h6' component='h2'>
                          Text in a modal
                        </Typography>
                        <Typography id='keep-mounted-modal-description' sx={{ mt: 2 }}>
                          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography>
                      </Box>
                    </Modal>
                  </div>
                </DropdownItem>
              </NavLink> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Container>
      </Navbar>
      <Modal modalClassName='modal-search' isOpen={modalSearch} toggle={toggleModalSearch}>
        <div className='modal-header'>
          <Input id='inlineFormInputGroup' placeholder='SEARCH' type='text' />
          <button aria-label='Close' className='close' data-dismiss='modal' type='button' onClick={toggleModalSearch}>
            <i className='tim-icons icon-simple-remove' />
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AdminNavbar;
