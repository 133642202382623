/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// core components
import AdminNavbar from '../components/Navbars/AdminNavbar.js';
import Footer from '../components/Footer/Footer.js';
import Sidebar from '../components/Sidebar/Sidebar.js';
import PrivacyPolicy from '../pages/privacy-policy.jsx';
// import FixedPlugin from "../components/FixedPlugin/FixedPlugin.js";

import routes from '../../../routes.js';

import logo from '../../../assets/img/react-logo.png';

import ProtectedRoute from '../../general/components/protected-route';
import EditUser from '../pages/edit-user';
import Nodes from '../pages/nodes';
// import DesignLab from "../pages/design-lab.jsx";
import DesignLabSidebar from '../components/Sidebar/DesignLabSidebar.js';
import config from '../../../config/index.js';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data.js';
import jwt_decode from 'jwt-decode';
import { connect } from 'react-redux';
import { deepClone } from '../../../helper-methods/index.js';

var ps;

// console.log('routes :>> ', routes);
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: 'blue',
      sidebarOpened: document.documentElement.className.indexOf('nav-open') !== -1,
      loggedInUserData: {}
    };
  }
  componentDidMount() {
    console.log('window.location :>> ', window.location);
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll('.table-responsive');
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    if (this.props.userData?.token) {
      this.setState({ loggedInUserData: jwt_decode(this.props.userData.token) });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      if (navigator.platform.indexOf('Win') > -1) {
        let tables = document.querySelectorAll('.table-responsive');
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return <ProtectedRoute path={prop.path} component={prop.component} key={key} redirectRoute={'/login'} />;
    });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  render() {
    const { loggedInUserData } = deepClone(this.state);
    const idDesignLabPage = window.location.pathname.includes('design-lab') ? true : false;
    return (
      <>
        <div className='wrapper'>
          {!idDesignLabPage ? (
            <Sidebar
              {...this.props}
              routes={routes}
              bgColor={this.state.backgroundColor}
              logo={{
                outterLink: 'https://www.creative-tim.com/',
                text: 'Creative Tim',
                imgSrc: logo
              }}
              toggleSidebar={this.toggleSidebar}
              isAdmin={loggedInUserData.isAdmin}
            />
          ) : (
            <DesignLabSidebar
              {...this.props}
              bgColor={this.state.backgroundColor}
              actionMenu={config.actionMenu}
              toggleSidebar={this.toggleSidebar}></DesignLabSidebar>
          )}
          <div className='main-panel' ref='mainPanel' data={this.state.backgroundColor}>
            <AdminNavbar
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname)}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={this.state.sidebarOpened}
            />
            <Switch>
              <ProtectedRoute path={'/edit-user'} component={EditUser} redirectRoute={'/login'} />
              <ProtectedRoute path={'/nodes'} component={Nodes} redirectRoute={'/login'} />
              <ProtectedRoute path={'/privacy-policy'} component={PrivacyPolicy} redirectRoute={'/login'} />
              {/* <ProtectedRoute
                path={"/design-lab/:id"}
                component={DesignLab}
                redirectRoute={"/login"}
              /> */}
              {this.getRoutes(routes)}
              <Route path='/' render={() => <Redirect to='/dashboard' />} />
            </Switch>
            <Footer fluid />
          </div>
        </div>
        {/* <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    loaderData: state.loaderData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

// export default Layout;
