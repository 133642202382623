import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Badge, Button, ModalFooter } from 'reactstrap';
import { FormGroup, Label, Input, Row } from 'reactstrap';
import { getAllImagesList, getAllTemplates, getTemplate, editTemplate } from '../../../../http/http-calls';
// import { update } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import { hideLoader, showLoader } from '../../../../redux/actions/loader-data';
import { update } from 'lodash';
import CircleLoader from 'react-spinners/ClipLoader';
import ButtonMUI from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import { userDetails } from '../../../../http/http-calls';

import { osVariant_AcceptedValues } from '../../../../config/index.js';

const TemplateModal = (props) => {
  const [images, setImages] = useState([]);
  const [preView, setPreView] = useState([]);
  // const [ram, setRam] = useState();
  const [error, setError] = useState({});
  const [updated, setUpdated] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [isEditable, setIsEditable] = useState();

  // useEffect(() => {
  //   async function getData() {
  //     const template = await getTemplate(props.data._id);
  //     let { vMTemplate } = template;
  //     console.info('This is the vmTemlate', vMTemplate);
  //   }
  //   getData();
  // }, []);

  const [disabledInput, setDisabledInput] = useState({
    ram: true,
    vcpus: true,
    vcpusCores: true,
    vcpusSockets: true,
    vcpusThreads: true,
    ethernets: true,
    defaultNetworkModel: true,
    diskBus: true,
    diskFormat: true,
    diskSparse: true,
    diskCache: true,
    graphics: true,
    osVariant: true,
    accelerateFlag: true,
    hvmFlag: true,
    arch: true,
    importFlag: true,
    noRebootFlag: true,
    noAutoConsoleFlag: true,
    delay: true,
    serial: true,
    serialHost: true,
    serialMode: true,
    serialProtocol: true,
    ethernetNames: true,
    cpu: true,
  });
  const makeDisabled = (field) => {
    if (disabledInput[field]) return false;
    else return true;
  };

  const getImages = async () => {
    getAllImagesList()
      .then((response) => {
        setImages(response.files);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    // Setting user data

    //getting imagestoshow for the template
    async function getData() {
      if (props.data._id) {
        const template = await getTemplate(props.data._id);
        let { vMTemplate } = template;
        setPreView(vMTemplate.imagesToShow);
      }
    }
    getData();
    getImages();
  }, [props.data._id]);

  // const getForEditModalDropDown = async () => {
  //   const template = await getTemplate(props.data._id);
  //   // console.info('this is the template', template.vMTemplate.imagesToShow[0].name);
  //   const name = template.vMTemplate.imagesToShow;
  //   setPreView(name);
  // };
  // getForEditModalDropDown();

  const _inputType = (field) => {
    switch (field.dataType) {
      case 'String':
        return 'text';
      case 'Number':
        return 'number';
      case 'Boolean':
        return 'checkbox';
      default:
        return 'text';
    }
  };

  const _onImageSelect = (event) => {
    console.log('EVENT', event);
    let index = Number(event.target.value);
    let image = images[index];
    setPreView((prev) => {
      prev.push(image);
      return [...prev];
    });
  };

  const _getAllTemplates = () => {
    getAllTemplates();
  };

  const _handleOnDelete = (index) => {
    console.log(index);
    setPreView((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const [data, setData] = useState({});

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const imagesToShow = [];
  const defaultvmimage = [];
  const checkBoxArr = [];

  const templateNameMap = {
    'cisco-asav_9-15-1.qcow2': 'Cisco Asav',
    'windows-desktop_10-x86-21H1v1.qcow2': 'Windows 10 Desktop',
    'linux-ubuntu-server_22.04.qcow2': 'Ubuntu Server',
  };

  const submit = async (e) => {
    e.preventDefault();
    setUpdateLoader(true);
    // This is the new default value for ram and cpu, Check if this is the new default value
    // const { id: updatedID, name: updatedName } = preView[0];
    // console.info('This is the while', preView[0]);

    let {
      ram,
      vcpus,
      vcpusCores,
      vcpusSockets,
      vcpusThreads,
      ethernets,
      defaultNetworkModel,
      diskBus,
      osVariant,
      ethernetNames,
      extraFlags,
      graphics,
      serial,
      serialProtocol,
    } = data;

    // extraFlags = {-h: 20, -t: 30}

    // console.info('This is image to show', imagesToShow);
    // console.info('This is default vm images to show', defaultvmimage);
    // console.info('This is whole data', data);
    console.info('this is the state ========', data);
    const template = await getTemplate(props.data._id);

    let { imagesToShow } = template;
    let { vMTemplate } = template;
    let { fieldsToShow } = vMTemplate;
    // imagesToShow = preView

    template.vMTemplate.imagesToShow = preView;

    const fieldCheckBox = document.getElementsByClassName('fieldCheck');
    for (let itemFields of fieldsToShow) {
      for (let itemCheckBox of fieldCheckBox) {
        if (itemCheckBox.getAttribute('name') === itemFields._id) {
          if (itemFields.isEditable === true) {
            itemCheckBox.checked = true;
            console.info('SAME so is checked');
          }
        }
      }
      // if (item._id === id) {
      //   if (item.isEditable === true) {
      //     item.isEditable = true;
      //   }
      //   try {
      //     const a = await editTemplate(props.data._id, vMTemplate);
      //     console.info('EDITTTTEDDDDDD');
      //   } catch (error) {
      //     console.info('there was an error editing the field', error);
      //   }
      // }
    }
    // console.info(templateNameMap[preView[0].name], 'This is the mapped name');
    // const updateImages = (newName, newId) => {
    //   // vMTemplate._id = updatedID;
    //   vMTemplate.name = templateNameMap[updatedName];
    //   vMTemplate.imagesToShow = [
    //     {
    //       _id: updatedID,
    //       name: preView[0].name,
    //       id: updatedID // same as _id
    //     }
    //   ];
    //   try {
    //     editTemplate(props.data._id, vMTemplate);
    //     toast.success('Template updated successfully', {
    //       position: toast.POSITION.TOP_RIGHT
    //     });
    //   } catch (error) {
    //     toast.error('Template update failed', {
    //       position: toast.POSITION.TOP_RIGHT
    //     });
    //   }
    // };

    // updateImages(updatedName, updatedID);

    const defaultNetworkModel_AcceptedValues = [
      'virtio',
      'e1000',
      'e1000-82544gc',
      'e1000-82545em',
      'e1000e',
      'i82550',
      'i82551',
      'i82557a',
      'i82557b',
      'i82557c',
      'i82558a',
      'i82558b',
      'i82559a',
      'i82559b',
      'i82559c',
      'i82559er',
      'i82562',
      'i82801',
      'ne2k_pci',
      'pcnet',
      'pvrdma',
      'rtl8139',
      'tulip',
      'virtio-net-pci',
      'virtio-net-pci-non-transitional',
      'virtio-net-pci-transitional',
      'vmxnet3',
      'virtio',
      'virtio-pci',
    ];

    const diskBus_AcceptedValues = ['sata', 'virtio'];
    const graphics_AcceptedValues = ['none', 'tcp'];

    console.info('THIS IS THE DATA THAT WILL BE SENT FOR THE PUT============', data);
    fieldsToShow.forEach((each) => {
      if (each.fieldName === 'ram') {
        if (ram) each.defaultValue = ram;
        // window.location.reload();
      }
      if (each.fieldName === 'vcpus') {
        if (vcpus) each.defaultValue = vcpus;
        // window.location.reload();
      }
      if (each.fieldName === 'vcpusCores') {
        if (vcpusCores) each.defaultValue = vcpusCores;
        // window.location.reload();
      }
      if (each.fieldName === 'vcpusSockets') {
        if (vcpusSockets) each.defaultValue = vcpusSockets;
        // window.location.reload();
      }
      if (each.fieldName === 'vcpusThreads') {
        if (vcpusThreads) each.defaultValue = vcpusThreads;
        // window.location.reload();
      }
      if (each.fieldName === 'ethernets') {
        if (ethernets <= 48) {
          if (ethernets) each.defaultValue = ethernets;
        }
        // window.location.reload();
      }
      if (each.fieldName === 'defaultNetworkModel') {
        for (let i = 0; i < defaultNetworkModel_AcceptedValues.length; i++) {
          if (defaultNetworkModel === defaultNetworkModel_AcceptedValues[i]) {
            if (defaultNetworkModel) each.defaultValue = defaultNetworkModel;
            // window.location.reload();
          }
        }
      }
      if (each.fieldName === 'diskBus') {
        for (let i = 0; i < diskBus_AcceptedValues.length; i++) {
          if (diskBus === diskBus_AcceptedValues[i]) {
            if (diskBus) each.defaultValue = diskBus;
            // window.location.reload();
          }
        }
      }
      if (each.fieldName === 'osVariant') {
        for (let i = 0; i < osVariant_AcceptedValues.length; i++) {
          if (osVariant === osVariant_AcceptedValues[i]) {
            if (osVariant) each.defaultValue = osVariant;
            // window.location.reload();
          }
        }
      }
      if (each.fieldName === 'ethernetNames') {
        function expandPorts(str) {
          const result = [];

          const parts = str.split(',').map((s) => s.trim());

          for (const part of parts) {
            if (part.includes('/')) {
              let [base, range] = part.split('/');
              const [start, end] = range.split('-').map(Number);

              for (let i = start; i <= end; i++) {
                base = base.trim();
                result.push(`${base}/${i}`);
              }
            } else {
              result.push(part);
            }
          }

          return result;
        }

        const inputStr = ethernetNames;
        const output = expandPorts(inputStr);

        const ports = ethernetNames.split(',');

        each.defaultValue = output;
        // output.forEach((item) => {
        //   console.log(item, 'this is the item');
        // });

        // [mgmt0, eth1/1, eth1/2, eth1/3, ...., eth1/48]
      }

      // if (each.fieldName === 'graphics') {
      //   if (serialProtocol.defaultValue === 'tcp') {
      //     if (graphics === 'none')
      //       if (graphics) each.defaultValue = graphics;
      //       else if (graphics.defaultValue !== 'tcp') {
      //         if (graphics === 'vnc') if (graphics) each.defaultvalue = graphics;
      //       }
      //   }
      // }
    });

    try {
      // setUpdateLoader(!updateLoader);
      const a = await editTemplate(props.data._id, vMTemplate);
      // setUpdateLoader(!updateLoader);
      toast.success(`Template updated successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    } catch (error) {
      toast.error('Error updating the template', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    props.toggle(false);
    // (() => _getAllTemplates())();
  };

  const fieldnameIsEditable = (name, isEditable) => {
    disabledInput.name = isEditable;
    console.info(disabledInput.name, 'This is the disabled Input');
  };

  // const fieldCheckBox = document.getElementsByClassName('fieldCheck');
  const handleCheckBox = async (id) => {
    const template = await getTemplate(props.data._id);
    let { vMTemplate } = template;
    let { fieldsToShow } = vMTemplate;
    console.info('fieldstoShow', fieldsToShow);
    for (let item of fieldsToShow) {
      if (item._id === id) {
        if (item.isEditable === false) {
          console.info('setting to true');
          fieldnameIsEditable(item.fieldName, true);
          item.isEditable = true;
        } else if (item.isEditable === true) {
          console.info('setting to false');
          fieldnameIsEditable(item.fieldName, false);
          item.isEditable = false;
        }
        try {
          const a = await editTemplate(props.data._id, vMTemplate);
          console.info('EDITTTTEDDDDDD');
        } catch (error) {
          console.info('there was an error editing the field', error);
        }
      }
    }
  };

  const getUser = async (id) => {
    try {
      const user = await userDetails(id);
      console.info('userrrrr', user);
    } catch (error) {
      console.info('Error fetching the user details');
    }
  };
  getUser();

  const userData = JSON.parse(localStorage.getItem('root')).userData;
  const user = JSON.parse(userData);
  console.info(user.user.isAdmin, 'is he an admin');
  // stringfiy(localStorage.getItem('root').userData));

  return (
    <Modal isOpen={props.isOpen} size={'lg'}>
      <ModalHeader toggle={props.toggle}>Edit Template</ModalHeader>
      <ModalBody>
        {/* <FormGroup> */}
        <form onSubmit={submit}>
          {/* <label htmlFor='ram'>RAM</label>
          <input style={{ color: 'red' }} id='ram' name='ram' type='text' onChange={updateData} />
          <label htmlFor='cpu'>CPU</label>
          <input style={{ color: 'red' }} id='cpu' name='cpu' type='text' onChange={updateData} /> */}
          {props.data?.baseBin === 'virsh' && (
            <FormGroup>
              <Label>Images to show</Label>
              <Input style={{}} type='select' onChange={_onImageSelect}>
                <option>Select</option>
                {React.Children.toArray(images.map((each, index) => <option value={index}>{each?.name}</option>))}
              </Input>
              {error.vmimage && <div className='validation-error'>{error.vmimage}</div>}
              <Row>
                {preView.map((each, index) => (
                  <Badge color='secondary'>
                    {each.name}
                    <Button color='link'>
                      <i className='fa fa-times-circle text-danger' onClick={() => _handleOnDelete(index)}></i>
                    </Button>
                  </Badge>
                ))}
              </Row>
              <Label>Default VM Image</Label>
              <Input type='select'>
                <option>Select</option>
                {preView.map((each, index) => (
                  <option key={index}>{each.name}</option>
                ))}
              </Input>
            </FormGroup>
          )}

          {props.data?.fieldsToShow &&
            React.Children.toArray(
              props.data.fieldsToShow.map((each, index, arr) => {
                //! This function is for temporary use to decide weather to make the input disabled or not
                const disabled = makeDisabled(each.fieldName);
                if (_inputType(each) === 'checkbox') checkBoxArr.push(each);
                else {
                  return (
                    // <div style={{ margin: '0 10px 10px 0' }}>
                    <FormGroup
                      style={{
                        width: '20%',
                        display: 'inline-block',
                        margin: '0 17px',
                      }}>
                      <Label>{each.fieldName}</Label>
                      {/* {true ? checked : ''} */}
                      {/* <input
                        type='checkbox'
                        style={{ float: 'right', padding: '0 important', margin: '0 !important' }}
                        onChange={() => {
                          handleCheckBox(each._id);
                        }}
                        checked={isEditable}
                        name={each._id}
                        className='fieldCheck'
                      /> */}
                      {user && user.user && user.user.isAdmin ? (
                        <Tooltip className='float-right' title='Toggle edit access for the user' arrow>
                          {each.isEditable ? (
                            <Switch onClick={() => handleCheckBox(each._id)} defaultChecked color='warning' />
                          ) : (
                            <Switch onClick={() => handleCheckBox(each._id)} color='warning' />
                          )}
                        </Tooltip>
                      ) : (
                        console.info('Not an admin')
                      )}
                      {console.info('this is the value for this fields diabled prop', disabled)}
                      <input
                        className={each.dataType !== 'Boolean' ? 'form-control' : 'boolean-input'}
                        type={_inputType(each)}
                        name={each.fieldName}
                        //   value={each.defaultValue}
                        placeholder={each.defaultValue}
                        disabled={disabled}
                        // onChange={(e) => onChangeField(e, index)}
                        onChange={updateData}
                      />
                      {arr.length - 1 === index ? <Label style={{ paddingTop: '.5rem' }}>Extra Flags</Label> : ''}

                      {arr.length - 1 === index ? (
                        <input
                          className='form-control'
                          type='input'
                          name='extraFlags'
                          //   value={each.defaultValue}
                          placeholder='Extra Flags'
                          // onChange={(e) => onChangeField(e, index)}
                          onChange={updateData}
                        />
                      ) : (
                        ''
                      )}
                    </FormGroup>
                    // </div>
                  );
                }
              })
            )}

          {/* {checkBoxArr.map((each, index) => {
            const disabled = makeDisabled(each.fieldName);
            return (
              // <div style={{ margin: '0 10px 10px 0' }}>
              <FormGroup style={{ width: '20%', display: 'inline-block', margin: '0 10px' }}>
                <Label>{each.label}</Label>
                <input
                  className={each.dataType !== 'Boolean' ? 'form-control' : 'boolean-input'}
                  type={_inputType(each)}
                  name={each.fieldName}
                  //   value={each.defaultValue}
                  placeholder={each.defaultValue}
                  disabled={disabled}
                  // onChange={(e) => onChangeField(e, index)}
                  onChange={updateData}
                />
              </FormGroup>
              // </div>
            );
          })} */}
          {/* <button type='submit'>Submit</button> */}
          <div className='btn-and-loader'>
            <Button
              color='modalBtnSave'
              className='modalBtnSave addBtn btn-secondary'
              style={{ display: 'block' }}
              // onClick={setUpdateLoader(false)}
              type='submit'>
              Update
            </Button>
            <CircleLoader
              className='tl-update-loader'
              color={'#777'}
              // cssOverride={override}
              loading={updateLoader}
              size={20}
              aria-label='Loading Spinner'
              data-testid='loader'
            />
          </div>
        </form>
        {/* </FormGroup> */}
      </ModalBody>
      <ModalFooter>{/* <Button className='modalBtnSave'>Save</Button> */}</ModalFooter>
    </Modal>
  );
};

export default TemplateModal;
