import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
// import Terminal from 'react-terminal-ui';
import Terminal from '../../../private/pages/Terminal';
import './styles.css';

function PaperComponent(props) {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper className='zuhaib' {...props} />
    </Draggable>
  );
}

export default function DraggableDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        style={{ position: 'absolute', right: '0', top: '81vh' }}
        className='chat-btn-open'
        onClick={handleClickOpen}>
        {/* Open draggable dialog */}
        <div class='toolbar-chatBtn'>
          <i className='fa fa-robot btn-chat-live btn_live d-flex'>
            <span class='icon-img'>
              <img className='icon-img' src='/assets/img/chatbtnicon.png' alt='' />
            </span>
            <div className='div'>
              <span className='live-icon h-v-center'></span>
            </div>
          </i>
          {/* <a href='#' class='btn-chat-live btn_live'></a> */}
        </div>
      </Button>
      <div>
        <Dialog
          className='diaglog-width'
          // style={{ textAlign: 'center' }}
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby='draggable-dialog-title'>
          <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
            {/* SamurAI */}
            {/* <img src='/assets/img/samurai.png' style={{ width: '10vw' }} alt='askjh' /> */}
            <div className='chat-heading-container' style={{ textAlign: 'center' }}>
              <h1 className='nothing-font' style={{ color: 'white' }}>
                SamurAI
              </h1>
            </div>
            <hr color='white' />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Terminal />
            </DialogContentText>
          </DialogContent>
          {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions> */}
        </Dialog>
      </div>
    </>
  );
}
