// New Code
import React, { Component } from 'react';
// import Chips, { Chip } from "react-chips";
import {
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  CardBody,
  Table,
  Button,
  Badge,
  // CustomInput,
} from 'reactstrap';

import { logout } from '../../../helper-methods';

import AddEditLabModal from '../components/add-edit-lab-modal';
import { labsList, deleteLab, exportLab, importLab } from '../../../http/http-calls';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/actions/loader-data';
import { ToastsStore } from 'react-toasts';
import moment from 'moment';
import DeleteLabConfirmationModal from '../components/delete-lab-confirmation-modal';
import { deepClone, showToast } from '../../../helper-methods';
import jwt_decode from 'jwt-decode';
import { Paginate } from './Pagination';
import AssignTeamToLab from '../components/assign-team';
import './labsCard.css';
import LabsCard from './labsCard';
import swal from 'sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Labs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      addEditLabModal: {
        isOpen: false,
        data: null,
        type: 'add',
      },
      tags: [],
      labs: [],
      selectedLabId: '',
      deleteLabConfirmationModal: {
        isOpen: false,
        data: null,
      },
      assignTeamToLab: {
        isOpen: false,
        data: null,
      },
      loggedInUserData: {},
      currentPage: 1,
      totalPages: '',
      searchItem: '',
    };
  }

  componentDidMount() {
    this.setState({ loggedInUserData: jwt_decode(this.props.userData.token) });
    this._getLabsList(this.state.currentPage);
  }

  //get list of labs here
  _getLabsList = (pageNumber) => {
    const { searchItem } = this.state;
    let data = { page: pageNumber };
    if (searchItem.trim().length) {
      data = {
        page: pageNumber,
        search: searchItem,
      };
    }
    this.props.showLoader();
    labsList(data)
      .then((resp) => {
        this.setState({
          labs: resp.docs,
          totalPages: resp.totalPages,
          currentPage: resp.page,
        });
        this.props.hideLoader();
        console.log('Labs list here :- ', resp);
      })
      .catch((err) => {
        this.props.hideLoader();
        // ToastsStore.error('Labs list loading failed :- ' + err.reason);
        toast.error(`Labs list loading failed :- ${err}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          logout();
        }, 2000);
      });
  };

  _openAddEditLabModal = (type, data) => {
    let { addEditLabModal } = JSON.parse(JSON.stringify(this.state));
    addEditLabModal.isOpen = !addEditLabModal.isOpen;
    addEditLabModal.type = type;
    addEditLabModal.data = data;
    this.setState({ addEditLabModal });
  };

  _onCloseAddEditModal = (action, labData) => {
    const { addEditLabModal } = deepClone(this.state);
    if (action === 'cancel') {
      addEditLabModal.isOpen = false;
      addEditLabModal.type = 'add';
      addEditLabModal.data = null;
      this.setState({ addEditLabModal });
    } else {
      this._onCloseAddEditModal('cancel');
      this.props.history.push('/design-lab/' + labData.id);
      // this._getLabsList();
    }
  };

  _openAssignTeamModal = (data) => {
    const { assignTeamToLab } = JSON.parse(JSON.stringify(this.state));
    assignTeamToLab.isOpen = !assignTeamToLab.isOpen;
    assignTeamToLab.data = data;
    this.setState({ assignTeamToLab });
  };

  _onCloseAssignTeamModal = () => {
    const { assignTeamToLab } = deepClone(this.state);
    assignTeamToLab.isOpen = false;
    assignTeamToLab.data = null;
    this.setState({ assignTeamToLab });
  };

  onChange = (tags) => {
    this.setState({ tags });
  };

  //changing format of createdAt
  _changeFormat = (data) => {
    return moment(data).format('MMM DD, hh:mm a');
  };

  //handles selection of lab by checkbox
  _handleSelectLab = (temp, id) => {
    if (temp) {
      this.setState({ selectedLabId: id });
    } else {
      this.setState({ selectedLabId: '' });
    }
  };

  //deleting any lab
  _deleteLab = (labId) => {
    this.props.showLoader();
    deleteLab(labId)
      .then((resp) => {
        this._onCloseDeleteLabConfirmationModal('cancel');
        this.props.hideLoader();
        console.log('Lab deletion response here :- ', resp);
        // ToastsStore.success('Lab deleted successfully');
        setTimeout(() => {
          swal('Success!', 'Lab deleted successfully', 'success');
        }, 1500);
        this._getLabsList();
      })
      .catch((err) => {
        this.props.hideLoader();
        // ToastsStore.error('Lab deletion failed :- ' + err.reason);
        setTimeout(() => {
          swal('Error!', `Lab deletion failed :- ${err.reason}`, 'error');
        }, 1500);
      });
  };

  _openDeleteLabConfirmationModal = (lab) => {
    if (lab.isRunning) {
      setTimeout(() => {
        swal('Error!', 'Please stop all the virtual machines before deleting the lab', 'error');
      }, 1800);
      // showToast('Please stop all the virtual machines before deleting the lab', 'error');
    } else {
      const { deleteLabConfirmationModal } = deepClone(this.state);
      deleteLabConfirmationModal.isOpen = true;
      deleteLabConfirmationModal.data = {
        labName: lab.name,
        id: lab.id,
      };
      this.setState({ deleteLabConfirmationModal });
    }
  };

  _onCloseDeleteLabConfirmationModal = (action) => {
    const { deleteLabConfirmationModal } = deepClone(this.state);
    if (action === 'cancel') {
      deleteLabConfirmationModal.isOpen = false;
      deleteLabConfirmationModal.data = null;
      this.setState({ deleteLabConfirmationModal });
    } else {
      this._deleteLab(deleteLabConfirmationModal.data.id);
    }
  };

  _exportLab = async (lab) => {
    try {
      this.props.showLoader();
      let res = await exportLab(lab.id, { token: this.props.userData.token });
      console.log('res :>> ', res);
      this.props.hideLoader();
      var url = window.URL.createObjectURL(res);
      var a = document.createElement('a');
      a.href = url;
      a.download = `${lab.name}.evos`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.log('error :>> ', error);
      this.props.hideLoader();
      showToast(error.reason, 'error');
    }
  };

  //for showing list of labs
  _showLabList = () => {
    const { loggedInUserData } = deepClone(this.state);
    let labListData = '';
    if (!this.state.labs.length) {
      labListData += 'No Lab Found';
    } else {
      labListData = this.state.labs.map((lab) => {
        return (
          <LabsCard
            className='cardhover'
            lab={lab}
            loggedInUserData={loggedInUserData}
            _openAddEditLabModal={this._openAddEditLabModal}
            _openDeleteLabConfirmationModal={this._openDeleteLabConfirmationModal}
            _changeFormat={this._changeFormat}
            _openLab={this._openLab}
            _exportLab={this._exportLab}
            _openAssignTeamModal={this._openAssignTeamModal}
          />
        );
      });
    }
    return labListData;
  };

  //filters list according to search input
  _filterLabList = (value) => {
    let { searchItem, currentPage } = this.state;
    searchItem = value;
    currentPage = 1;
    this.setState({ searchItem, currentPage }, () => {
      this._getLabsList(currentPage);
    });
  };

  _openLab = (lab) => {
    this.props.history.push('/design-lab/' + lab.id);
  };

  _onFileSelect = async (event) => {
    if (event.target.files) {
      console.log('event.target.files :>> ', event.target.files[0]);
      const filexExtension = event.target.files[0].name.split('.').pop();
      if (filexExtension === 'evos') {
        console.log('Upload file :>> ');
        try {
          const formData = new FormData();
          formData.append('labImportFile', event.target.files[0]);
          this.props.showLoader();
          let res = await importLab(formData);
          console.log('res :>> ', res);
          this._getLabsList();
          this.props.hideLoader();
        } catch (error) {
          console.log('error :>> ', error);
          this.props.hideLoader();
          showToast(error.reason, 'error');
        }
      }
    }
  };

  //for pagination
  _paginate = (pageNumber) => {
    this._getLabsList(pageNumber);
  };

  render() {
    const { deleteLabConfirmationModal, addEditLabModal, assignTeamToLab } = deepClone(this.state);

    return (
      <div className='content'>
        <Row>
          <Col xs='12'>
            <div>
              <CardHeader className='tableFilterWrap'>
                <div className='d-flex'>
                  <Input
                    type='text'
                    placeholder='Search...'
                    style={{ width: 180 }}
                    onChange={(e) => this._filterLabList(e.target.value)}
                  />
                </div>
                <div className='d-flex'>
                  <Button className='viewBtn import-btn'>
                    <input type='file' className='file-input' accept='.evos' onChange={(e) => this._onFileSelect(e)} />
                    Import
                  </Button>
                  <Button className='addBtn ml-3' onClick={() => this._openAddEditLabModal('add', null)}>
                    <i className='fa fa-plus mr-1'></i> Add
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <div className='mb-3 d-flex justify-content-end'></div>
                <div>
                  <div className='row'>{this._showLabList()}</div>
                </div>
                <Paginate
                  totalPages={this.state.totalPages}
                  currentPage={this.state.currentPage}
                  paginate={this._paginate}
                />
              </CardBody>
            </div>
          </Col>
        </Row>

        <AddEditLabModal
          isOpen={addEditLabModal.isOpen}
          data={addEditLabModal.data}
          type={addEditLabModal.type}
          toggle={this._onCloseAddEditModal}></AddEditLabModal>
        <DeleteLabConfirmationModal
          isOpen={deleteLabConfirmationModal.isOpen}
          data={deleteLabConfirmationModal.data}
          toggle={this._onCloseDeleteLabConfirmationModal}></DeleteLabConfirmationModal>
        <AssignTeamToLab
          isOpen={assignTeamToLab.isOpen}
          data={assignTeamToLab.data}
          toggle={this._onCloseAssignTeamModal}
          reload={() => this._getLabsList(this.state.currentPage)}></AssignTeamToLab>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Labs);
