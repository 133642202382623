/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// import LabelBottomNavigation from '../../components/Sidebar/bottom-nav';
import DesignLabSidebar from '../Sidebar/DialActionMenu';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};
import React from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// reactstrap components
import { Container, Row, Nav, NavItem, NavLink } from 'reactstrap';

const Footer = () => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <footer className='footer'>
        <div className='d-flex'>
          <Nav>
            {/* <NavItem>
            <NavLink href='#'>Terms</NavLink>
          </NavItem> */}
            <NavItem>
              {/* <NavLink>
              <Link to='/privacy-policy'>Privacy</Link>
            </NavLink> */}
            </NavItem>
          </Nav>
        </div>
        <div style={{ display: 'none' }}>
          Powered By:{' '}
          <a href='https://www.logic-square.com/' target='_blank' rel='noreferrer'>
            Logic Square
          </a>
        </div>
        <div style={{ display: 'block' }}>
          Powered By:{' '}
          <a href='http://www.gurooit.com' target='_blank' rel='noreferrer' className='orange-text'>
            GuROO
          </a>
        </div>
        <div className='copyright'>EVOS © {new Date().getFullYear()}</div>
      </footer>
    </>
  );
};

export default Footer;
