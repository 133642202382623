import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  FormGroup,
} from "reactstrap";
import Select from "react-dropdown-select";
import styled from "styled-components";
import { deepClone } from '../../../../helper-methods';
const StyledOption = styled.span`
  padding: 0;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  transition: all 1s ease-in;

  span {
    display: none;
    transition: all 1s ease-in;
  }

  a {
    margin: 0 5px;
  }

  :hover {
    background: #f2f2f2;

    span {
      display: inline;
      margin: 0 0 0 5px;
      color: red;
    }
  }
`;

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;

  :hover {
    background: #f2f2f2;
  }
`;
class ChangeCanvasBgModal extends Component {
  state = { 
    canvas: {
      iconId: "",
    },
    isDirty: {
      selectedFile: false
    },
    errors: {},
    selectedFile: null,
    previewImg: null,
    selectedIcons:[]
  }

  _customOptionRenderer =  ({ item, props, state, methods }) => {
    return<StyledOption>
       <img src={item.url} alt={item.name} width={30}/>
         &nbsp;
         {item.name}
       <span
         onClick={() => {          
           methods.removeItem(null, item);
           this.setState({ selectedIcons: [] },()=>{
             this._validateFields();
           });
         }}        
       >
         &times;
       </span>
     </StyledOption>
   }
 
   _customItemRenderer = ({ item, itemIndex, props, state, methods }) => {
     const { selectedIcons } = deepClone(this.state);
     
     return <StyledItem>
       <div       
       onClick={() => {
         console.log('selectedIcons :>> ', item);
         methods.addItem(item);
         const { isDirty } = deepClone(this.state);
         isDirty.selectedFile = true;
         const isAlreadyPresent = selectedIcons.length && selectedIcons[0].id === item.id;
         this.setState({ selectedIcons: isAlreadyPresent ? [] :[item], selectedFile : null,
           previewImg: null, isDirty },()=>this._validateFields());
       }
         }>
           {
             item.isFirstDefaultIcon &&
           <div className="custom-select-section">System Icons</div>  
         }
           {
             item.isFirstIcon &&
             <div className="custom-select-section">Custom Icons</div>  
 
           }
         <img src={item.url} alt={item.name} width={30}/> &nbsp;
         {item.name}
       </div>
     </StyledItem>
   };

  _handleOnChange = (field, value) => {
    const { canvas, isDirty } = this.state;
    canvas[field] = value;
    isDirty[field] = true;
    this.setState({ canvas, isDirty }, () => {
      this._validateFields();
    });
  };

  _validateFields = () => {
    const { isDirty, errors } = this.state;
    if (isDirty.selectedFile && (!this.state.selectedFile && !this.state.selectedIcons.length)) {
        errors.selectedFile = "*Select an image";
    } 
    // else if(isDirty.selectedFile && this.state.selectedFile && !config.supportedFileFormats.includes(this.state.selectedFile.type)){
    //     errors.selectedFile = "Please select an image file";
    // }
     else {
        delete errors.selectedFile;
    }
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  _handleOnSubmit = () => {
    console.log("this.state.canvas :>> ", this.state.canvas);
    const { canvas, selectedIcons } = deepClone(this.state);  
    let isDirty= {
      selectedFile: true
    };
    this.setState({ isDirty },async()=>{
      let errors = this._validateFields();
      if(!errors){        
          canvas.iconId = selectedIcons[0].id;
          canvas.iconURL = selectedIcons[0].url;             
        this.setState({ canvas },()=>{
          console.log('canvas :>> ', canvas);
          this.props.toggle('save', canvas);
        });
      }
    });
  };

  componentDidUpdate(prevProps){
    if(prevProps.isOpen!==this.props.isOpen){
      let newState = {
        canvas: {
          iconId: "",
        },
        isDirty: {
          selectedFile: false
        },
        errors: {},
        selectedFile: null,
        previewImg: null,
        selectedIcons: this.props.data?.iconList.filter((e)=>e.url===this.props.data?.bgImageUrl)  || []
      }
      this.setState(newState);
    }
  }

  _onRemoveImage = ()=>{
    this.props.toggle('remove');
  }

  render() { 
    const { errors } = deepClone(this.state);
    const { data } = deepClone(this.props);
    
    return ( <Modal isOpen={this.props.isOpen} toggle={() => this.props.toggle('cancel')}>
    <ModalHeader toggle={() => this.props.toggle('cancel')}>
      Add Canvas Image
    </ModalHeader>
    <ModalBody >
      <FormGroup>
        <Label>Select Image</Label>
        <div>
        <Select
          placeholder="Select Image"     
          multi={true} 
          keepOpen={false} 
          searchable={false}
          optionRenderer={this._customOptionRenderer}
          itemRenderer={this._customItemRenderer}
          onChange={(value) => this._handleOnChange('iconId', value)}
          values={this.state.selectedIcons}
          options={data?.iconList}
          labelField={'name'}
          valueField={'id'}
          closeOnSelect={true}
        />           
        </div>
        {/* <div className="or-text"> */}
        {/* <p className="ext-center">OR</p>
        </div>
        <div className="text-center">
          <Button>
            <Input
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              onChange={(e) => this._handleOnChange("selectedFile", e)}
            />
            Select File
          </Button>
        {this.state.previewImg && <img
          alt="Asset Img"
          className="assetImg"
          src={
            this.state.previewImg                 
          }
        />}
        </div> */}
        {errors && (
          <div className="validation-error">{errors.selectedFile}</div>
        )}
      </FormGroup>      
    </ModalBody>
    <ModalFooter>
      <Button
        className="modalBtnCancel"
        onClick={() => this.props.toggle('cancel')}
      >
        Cancel
      </Button>
     {this.props.data?.bgImageUrl && <Button
        className="modalBtnSave"
        onClick={(e) => this._onRemoveImage(e)}
      >
        Remove Image
      </Button>}
      <Button
        className="modalBtnSave"
        onClick={(e) => this._handleOnSubmit(e)}
      >
        Save
      </Button>
    </ModalFooter>
  </Modal> );
  }
}
 
export default ChangeCanvasBgModal;