import React, { useState, useEffect } from 'react';
import CopyToClipboardIcon from './copy-to-clipboard-icon.jsx';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IoClipboardOutline, IoCheckmarkDoneSharp } from 'react-icons/io5';
import MyComp from './my-comp.jsx';
import ScrollableFeed from 'react-scrollable-feed';
function MyButton(props) {
  return <button {...props} />;
  // return <CopyToClipboardIcon {...props} />;
}
const QuesAnswer = ({ ques, answer }) => {
  const [text, setText] = useState('');
  const [index, setIndex] = useState(0);
  const [value, setValue] = React.useState('');
  const [copied, setCopied] = React.useState(false);
  const [icon, setIcon] = useState(true);
  const onCopy = React.useCallback(() => {
    setIcon(false);
    setTimeout(() => {
      setIcon(true);
    }, 800);
    setCopied(true);
  }, []);
  // copy handler

  //used to set the typing effect
  useEffect(() => {
    setText('');
    setIndex(0);
  }, [answer]);

  useEffect(() => {
    setValue(text);
  }, [text]);

  useEffect(() => {
    if (index < answer.length) {
      setTimeout(() => {
        setText(text + answer[index]);
        setIndex(index + 1);
      }, 20);
    }
  }, [index]);
  return (
    <>
      <ScrollableFeed>
        <MyComp textVal={text} />
        <div className='media media-chat media-chat-width media-chat-reverse'>
          <div className='media-body'>
            <p>{ques}</p>
          </div>
        </div>
        <div className='media media-chat'>
          {/* <h5 className='nothing-font'>SamurAI</h5> */}
          <div className='media-body'>
            <div
              style={{
                display: 'block',
                unicodeBidi: 'embed',
                fontFamily: 'monospace',
                width: '100%'
              }}>
              {/* <img className='' src='/assets/img/samurai.png' style={{ width: '5vw' }} alt='...' /> */}
              {/* <p> */}
              <code>
                <pre
                  style={{
                    whiteSpace: 'pre-wrap'
                  }}>
                  {/* <input onClick={copyHandler} style={{ color: 'black' }} type='button' value='Copy' /> <br /> */}
                  <CopyToClipboard className='copy-icon' onCopy={onCopy} text={value}>
                    <div
                      style={{
                        width: '20px',
                        marginLeft: 'auto'
                      }}
                      className=''>
                      {/* <i style={{ cursor: 'pointer' }} className={`fa fa-${icon}`}></i>{' '} */}
                      {/* {copiedIcon && <IoClipboardOutline style={{ cursor: 'pointer' }} />}
                    {!setCopiedIcon && <IoCheckmarkDoneSharp />} */}
                      {icon && <IoClipboardOutline style={{ cursor: 'pointer' }} />}
                      {!icon && <IoCheckmarkDoneSharp style={{ cursor: 'pointer' }} />}
                    </div>
                  </CopyToClipboard>
                  {text}
                </pre>
              </code>
              {/* </p> */}
            </div>
          </div>
        </div>
      </ScrollableFeed>
    </>
  );
};

export default QuesAnswer;
