import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button
} from "reactstrap";

class DeleteLabConfirmationModal extends Component {
  state = {  }
  render() { 
    return ( <Modal
      isOpen={this.props.isOpen}
      toggle={() => this.props.toggle("cancel")}
    >
      <ModalHeader toggle={() => this.props.toggle("cancel")}>
        Delete Lab Confirmation
      </ModalHeader>
      <ModalBody>
        <div>         
            <p>
              Are you sure to delete "{this.props?.data?.labName}" ?
            </p>        
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="modalBtnCancel"
          onClick={() => this.props.toggle("cancel")}
        >
          No
        </Button>
        <Button
          className="modalBtnSave"
          onClick={(e) => this.props.toggle("delete")}
        >
          Yes, Delete
        </Button>
      </ModalFooter>
    </Modal> );
  }
}
 
export default DeleteLabConfirmationModal;