import React from "react";
import { BaseNodeComponent } from "jsplumbtoolkit-react";

export class NodeComponent extends BaseNodeComponent {
  state = {
    data: {},
    icon: null
  }
  componentDidMount() {
    const { vertex, surface, toolkit } = this.props.ctx;
    const data = vertex.data;
    surface.repaint(vertex);
    this.setState({ data, surface, toolkit });
  }

  componentDidUpdate() {
    if (!this.state.icon && this.state.data) {
      this.downloadIcon(this.state.data.properties.icon)
    }
  }

  // componentDidUpdate(){
  //   const { vertex, surface, toolkit } = this.props.ctx;
  //   const data = vertex.data;
  //   surface.repaint(vertex);
  // }


  //   const setLabel = (label) => {
  //     toolkit.updateNode(vertex, { label });
  //   };

  hitMe = () => {
    // this.state.surface.setPosition(this.state.data.id, -347, -510);
  };

  //   // This `useEffect` will cause the node to be repainted if its label has changed. This will run after the label has
  //   // been changed and React has repainted what it needs to, so it's the right time to tell the renderer, since the new
  //   // size of the element is known.
  // useEffect(() => {
  //   surface.repaint(vertex);
  //   eslint-disable-next-line
  //   jsPlumbToolkit && jsPlumbToolkit.DrawingTools({renderer: this.state.surface});
  // }, [data.label]);

  downloadIcon = async (url) => {
    try {
      if (url !== undefined) {
        const image = await fetch(url)
        const blob = await image.blob()
        const reader = new FileReader()
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result
          // console.log(base64data)
          this.setState({ icon: base64data })
        }
      }
      return url
    } catch (err) {
    }
  }


  render() {
    const { data } = this.state;
    return (
      <div
        className={data?.properties?.status && data?.properties?.status !== 'successful' ?
          'custom-node flowchart-object jtk-droppable processing-node-wrap' : 'custom-node flowchart-object jtk-droppable'}
        type="jtk"
        id={data?.properties?.id}
        style={{
          filter: data?.properties?.vmState === "stopped" ? "grayscale(100%)" : "",
          width: data.w - 40 + 'px',
          height: data.h + 'px',
          display: 'inline-block',
          overflowWrap: 'normal',
          // backgroundImage: `url('${data?.properties?.icon}')`,
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "center",
          // height: 70,
          // border: "1px solid black",
        }}
      >
        {/* {this.renderIcon(data?.properties?.icon)}   */}
        <img src={this.state.icon} alt="node-icon" width={60} />
        <div style={{ fontSize: "16px", color: "#222", position: "absolute", width: "100%" }}>{data?.properties?.name}</div>
        {data?.properties?.status && data?.properties.status !== 'successful' && <div className="processing-node">
          <i className="fa fa-spinner fa-spin"></i>
        </div>}
        {data?.properties?.isDockerNode &&
          <img src={require('../../../../assets/img/docker.png').default} alt="docker-img" style={{
            right: 30,
            top: -8,
            position: 'absolute',
            width: 30
          }} />
        }
        <div className="drag-start connect" />
        <jtk-source filter=".connect" />
        <jtk-target filter=".connect" />

      </div>
    );

    //     return <div style={{width:data.w + 'px', height:data.h + 'px'}} className="flowchart-object flowchart-question">
    //     <div style={{position:'relative'}}>

    //         <svg width={data.w} height={data.h}>
    //             <path d={'M' +  (data.w/2) + ' 10 L ' + (data.w - 10) + ' ' +  (data.h/2) + ' L ' + (data.w/2) + ' ' + (data.h - 10) + '  L 10 ' + (data.h/2) + '  Z'} className="inner"/>
    //             <text textAnchor="middle" x={data.w/2} y={data.h/2} dominantBaseline="central">{data.name}</text>
    //         </svg>
    //     </div>
    //     <div className="node-edit node-action" ></div>
    //     <div className="node-delete node-action delete" onClick={this.hitMe}></div>
    //     <div className="drag-start connect"></div>
    //     <jtk-source port-type="source" filter=".connect"/>
    //     <jtk-target port-type="target"/>
    // </div>
  }

}

export default NodeComponent