import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/scss/black-dashboard-react.scss";
import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";
import './index.css';
import App from './App';
import "./App.scss";

ReactDOM.render(<App />, document.getElementById('root'));
